@charset "UTF-8";

/***************************/
/***************************/
/********** ICONS **********/
/***************************/
/***************************/

@font-face {
	font-family:'icomoon';
	src:url('/static/assets/fonts/hybrid/icomoon.eot?version=2');
	src:url('/static/assets/fonts/hybrid/icomoon.eot#iefix?version=2') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/icomoon.woff?version=2') format('woff'),
		url('/static/assets/fonts/hybrid/icomoon.ttf?version=2') format('truetype'),
		url('/static/assets/fonts/hybrid/icomoon.svg#fontello?version=2') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}

/********** ICONS **********/

.icon-about:before {
content: "\e900";
}
.icon-airplay:before {
content: "\e901";
}
.icon-android:before {
content: "\e902";
}
.icon-apple-tv:before {
content: "\e903";
}
.icon-attach:before {
content: "\e904";
}
.icon-audio:before {
content: "\e905";
}
.icon-audiodescription:before {
content: "\e906";
}
.icon-bancontact:before {
content: "\e907";
}
.icon-blank:before {
content: "\e908";
}
.icon-bonus-archives:before {
content: "\e909";
}
.icon-bonus-back:before {
content: "\e90a";
}
.icon-bonus-exclusive:before {
content: "\e90b";
}
.icon-bonus:before {
content: "\e90c";
}
.icon-book:before {
content: "\e90d";
}
.icon-calendar:before {
content: "\e90e";
}
.icon-card:before {
content: "\e90f";
}
.icon-cart:before {
content: "\e910";
}
.icon-chair-new:before {
content: "\e911";
}
.icon-chair:before {
content: "\e912";
}
.icon-check:before {
content: "\e913";
}
.icon-chromecast-on:before {
content: "\e914";
}
.icon-chromecast:before {
content: "\e915";
}
.icon-cine-club-previous:before {
content: "\e916";
}
.icon-cine-club:before {
content: "\e917";
}
.icon-cinepass:before {
content: "\e918";
}
.icon-cinetek:before {
content: "\e919";
}
.icon-clipboard:before {
content: "\e91a";
}
.icon-clock:before {
content: "\e91b";
}
.icon-close:before {
content: "\e91c";
}
.icon-comment-add:before {
content: "\e91d";
}
.icon-comment-alt:before {
content: "\e91e";
}
.icon-comment-edit:before {
content: "\e91f";
}
.icon-comment-remove:before {
content: "\e920";
}
.icon-comment:before {
content: "\e921";
}
.icon-detonate:before {
content: "\e922";
}
.icon-discover:before {
content: "\e923";
}
.icon-down:before {
content: "\e924";
}
.icon-download:before {
content: "\e925";
}
.icon-drag:before {
content: "\e926";
}
.icon-drive:before {
content: "\e927";
}
.icon-dubbing:before {
content: "\e928";
}
.icon-duration-deadline:before {
content: "\e929";
}
.icon-duration-forever:before {
content: "\e92a";
}
.icon-duration:before {
content: "\e92b";
}
.icon-edit:before {
content: "\e92c";
}
.icon-expand:before {
content: "\e92d";
}
.icon-eye:before {
content: "\e92e";
}
.icon-facebook:before {
content: "\e92f";
}
.icon-favorite-alt:before {
content: "\e930";
}
.icon-favorite:before {
content: "\e931";
}
.icon-feet:before {
content: "\e932";
}
.icon-file:before {
content: "\e933";
}
.icon-filters:before {
content: "\e934";
}
.icon-footage:before {
content: "\e935";
}
.icon-forward:before {
content: "\e936";
}
.icon-fullscreen:before {
content: "\e937";
}
.icon-gift:before {
content: "\e938";
}
.icon-grid:before {
content: "\e939";
}
.icon-guest:before {
content: "\e93a";
}
.icon-help:before {
content: "\e93b";
}
.icon-history:before {
content: "\e93c";
}
.icon-hourglass:before {
content: "\e93d";
}
.icon-image:before {
content: "\e93e";
}
.icon-infos:before {
content: "\e93f";
}
.icon-instagram:before {
content: "\e940";
}
.icon-jump-next:before {
content: "\e941";
}
.icon-jump-previous:before {
content: "\e942";
}
.icon-key:before {
content: "\e943";
}
.icon-left:before {
content: "\e944";
}
.icon-less:before {
content: "\e945";
}
.icon-letterboxd:before {
content: "\e946";
}
.icon-link:before {
content: "\e947";
}
.icon-linkedin:before {
content: "\e948";
}
.icon-list-previous:before {
content: "\e949";
}
.icon-list-top:before {
content: "\e94a";
}
.icon-list:before {
content: "\e94b";
}
.icon-live:before {
content: "\e94c";
}
.icon-location:before {
content: "\e94d";
}
.icon-lock-off:before {
content: "\e94e";
}
.icon-lock-on:before {
content: "\e94f";
}
.icon-login:before {
content: "\e950";
}
.icon-logout:before {
content: "\e951";
}
.icon-loss:before {
content: "\e952";
}
.icon-mail:before {
content: "\e953";
}
.icon-megaphone:before {
content: "\e954";
}
.icon-messenger:before {
content: "\e955";
}
.icon-more:before {
content: "\e956";
}
.icon-mute:before {
content: "\e957";
}
.icon-notification-alt:before {
content: "\e958";
}
.icon-notification:before {
content: "\e959";
}
.icon-parameters:before {
content: "\e95a";
}
.icon-pause:before {
content: "\e95b";
}
.icon-paypal:before {
content: "\e95c";
}
.icon-pen:before {
content: "\e95d";
}
.icon-pin:before {
content: "\e95e";
}
.icon-play:before {
content: "\e95f";
}
.icon-playlist-add:before {
content: "\e960";
}
.icon-playlist-check:before {
content: "\e961";
}
.icon-playlist-favorite:before {
content: "\e962";
}
.icon-playlist-from:before {
content: "\e963";
}
.icon-playlist:before {
content: "\e964";
}
.icon-print:before {
content: "\e965";
}
.icon-profile:before {
content: "\e966";
}
.icon-projector:before {
content: "\e967";
}
.icon-reel-new:before {
content: "\e968";
}
.icon-reel:before {
content: "\e969";
}
.icon-refresh:before {
content: "\e96a";
}
.icon-reward:before {
content: "\e96b";
}
.icon-rewind:before {
content: "\e96c";
}
.icon-right:before {
content: "\e96d";
}
.icon-screen:before {
content: "\e96e";
}
.icon-search:before {
content: "\e96f";
}
.icon-selection-alt:before {
content: "\e970";
}
.icon-selection:before {
content: "\e971";
}
.icon-send:before {
content: "\e972";
}
.icon-share:before {
content: "\e973";
}
.icon-signal:before {
content: "\e974";
}
.icon-subtitles:before {
content: "\e975";
}
.icon-tag:before {
content: "\e976";
}
.icon-ticket:before {
content: "\e977";
}
.icon-trash:before {
content: "\e978";
}
.icon-twitter:before {
content: "\e979";
}
.icon-up:before {
content: "\e97a";
}
.icon-user:before {
content: "\e97b";
}
.icon-volume:before {
content: "\e97c";
}
.icon-whatsapp:before {
content: "\e97d";
}
.icon-youtube:before {
content: "\e97e";
}
.icon-offers:before {
content: "\e97f";
}

/********** CUSTOM **********/
/* multi color icon */

.icon-google:before {
	content:"";
	height:1em;
	width:1em;
	vertical-align:middle;
	background:url(/static/assets/icons/hybrid/google.svg) no-repeat;
	-webkit-background-size:contain;
	   -moz-background-size:contain;
	     -o-background-size:contain;
	        background-size:contain;
}

/********************************/
/********************************/
/********** ICON CLASS **********/
/********************************/
/********************************/

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family:"icomoon";
	font-style:normal;
	font-weight:normal;
	speak:none;
	display:inline-block;
	text-decoration:inherit;
	text-shadow:none !important;
	font-variant:normal;
	text-transform:none;
	font-size:inherit;
	line-height:inherit;
	color:inherit;
}
.collapsible-toggle.button,
.button.small,
.focus-nav a {
	-webkit-box-pack:left;
	-webkit-justify-content:left;
	   -moz-box-pack:left;
	    -ms-flex-pack:left;
	        justify-content:left;
}
.alert[class^="icon-"]:before,
.alert[class*=" icon-"]:before,
button[class^="icon-"]:before,
button[class*=" icon-"]:before,
a[class^="icon-"]:before,
a[class*=" icon-"]:before {
	margin-right:1em;
}
nav.nav-results a[class^="icon-"]:before,
nav.nav-results a[class*=" icon-"]:before {
	margin:0 auto;
}
h3:not(.section-title)[class^="icon-"]:before,
h3:not(.section-title)[class*="icon-"]:before,
h4:not(.section-title)[class^="icon-"]:before,
h4:not(.section-title)[class*="icon-"]:before,
section.block h2[class^="icon-"]:before,
section.block h2[class*=" icon-"]:before,
.film-event[class^="icon-"]:before,
.film-event[class*=" icon-"]:before {
	margin-right:1em;
}

/********** ICON AFTER **********/

.buttons .with-icon-after,
.button.with-icon-after {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}
.buttons .with-icon-after:before,
.button.with-icon-after:before {
	-webkit-box-ordinal-group:3;
	-webkit-order:2;
	   -moz-box-ordinal-group:3;
	    -ms-flex-order:2;
	        order:2;
	margin-right:0 !important;
	margin-left:1em;
}

/**********************************/
/**********************************/
/********** ICON ANCHORS **********/
/**********************************/
/**********************************/

/* icon-about */
.filters-tags .about-toggle:before {
	content: "\e900" !important;
}
/* icon-audio */
.version-audio:before {
	content: "\e905";
}
/* icon-audiodescription */
.version-audiodescription:before {
	content: "\e906";
}
/* icon-blank */
.grid .item .img.empty:after {
	content:"\e908";
}
/* icon-calendar */
.preview.film .infos .year:before,
.grid.films .infos .year:before,
.film-infos .year:before {
	content: "\e90e";
}
/* icon-cart */
a.add-to-cart:before,
a.cart:before {
	content: "\e910";
}
/* icon-check */
nav.contextual.checkout li.done:before,
a.validate:after,
.filters-tags a.active:before {
	content: "\e913" !important;
}
.editable input:focus + .edit:before {
	content: "\e913";
}
/* icon-clock */
.films.svod .svod-status:before {
    content: "\e91b";
}
/* icon-close */
nav.filters .results a:after,
.filters-tags a.active:hover:before,
.preview.modal.active.expanded .preview-reduce:before,
.overlay .overlay-container > .close:before,
.overlay .overlay-header > .close:before {
	content:"\e91c";
}
/* icon-comment-alt */
.list.films.has-comments a.collapsible-toggle.active:before {
	content: "\e91e";
}
/* icon-comment */
.list.films.has-comments a.collapsible-toggle:before {
	content: "\e921";
}
/* icon-detonate */
.detonate:before {
	content:"\e922";
}
 /* icon-down */
a.collapsible-toggle.button:after,
a.scroll-down:after {
	content:"\e924";
}
/* icon-dubbing */
.version-dubbing:before {
	content: "\e928";
}
/* icon-duration-deadline */
.films .is-rented .user-status:before {
	content: "\e929";
}
/* icon-duration-forever */
.films > .is-bought .user-status:before {
	content: "\e92a";
}
/* icon-duration */
.tooltip .duration:before,
.preview.film .infos .duration:before,
.grid.films .infos .duration:before,
.film-infos .duration:before {
	content: "\e92b";
}
/* icon-edit */
.editable .edit:before {
	content: "\e92c";
}
/* icon-expand */
nav.mode [data-mode="list"]:before {
	content: "\e92d";
}
/* icon-facebook */
.facebook:before {
	content: "\e92f";
}
/* icon-favorite */
.grid .featured a:before,
.grid .passed.featured a strong:before,
.notation[data-stars="1"]:before {
	content:"\e931";
}
.notation[data-stars="2"]:before {
	content:"\e931 \e931";
}
.notation[data-stars="3"]:before {
	content:"\e931 \e931 \e931";
}
.notation[data-stars="4"]:before,
.notation:after {
	content:"\e931 \e931 \e931 \e931";
}
/* icon-grid */
nav.mode [data-mode="grid"]:before {
	content: "\e939";
}
/* icon-help */
.help-me:before {
	content: "\e93b";
}
/* icon-instagram */
.instagram:before {
	content: "\e940";
}
/* icon-left */
.overlay-back:before,
a.back:before,
a.previous:before {
	content:"\e944";
}
/* icon-less */
.cart-less:after {
	content: "\e945";
}
/* icon-letterboxd */
.letterboxd:before {
	content: "\e946";
}
/* icon-link */
.url:before {
	content: "\e947";
}
/* icon-linkedin */
.linkedin:before {
	content: "\e948";
}
/* icon-live */
#primary a.on-air:before {
	content: "\e94c" !important;
}
/* icon-location */
.preview.film .infos .country:before,
.grid.films .infos .country:before,
.film-infos .country:before {
	content: "\e94d";
}
/* icon-lock-off */
.status.unlocked:before {
	content: "\e94e";
}
/* icon-lock-on */
a.video-link.locked:hover:before {
	content: "\e94f";
}
/* icon-login */
a.login:before {
	content: "\e950";
}
/* icon-loss */
.version-loss:before {
	content: "\e952";
}
/* icon-mail */
.mail:before {
	content: "\e953";
}
/* icon-messenger */
.messenger:before {
	content: "\e955";
}
/* icon-more */
.filters-tags a:hover:before,
.grid .add-a-film:before,
.cart-more:after,
.has-bonus > li:before,
.list.films a.collapsible-toggle:after {
	content: "\e956";
}
/* icon-notification-alt */
.icon-notification.active:before {
	content: "\e958";
}
/* icon-play */
.videoplayer .play:before,
a.video-link:before,
a.video-link.readable .label:before,
a.play:before {
	content: "\e95f";
}
/* icon-playlist-add */
a.add-to-playlist:before {
	content: "\e960";
}
/* icon-playlist-check */
.films .in-playlist .playlist-status:before {
	content: "\e961";
}
/* icon-right */
.grid.list.directors a:after,
a.next:after {
	content:"\e96d";
}
/* icon-screen */
.films .format:before {
	content: "\e96e";
}
/* icon-search */
.searchbar input[type=submit]:before,
.searchbar button:before,
a.enlarge:before {
	content: "\e96f";
}
/* icon-signal */
.signal:before {
	content: "\e974";
}
/* icon-subtitles */
.version-subtitles:before {
	content: "\e975";
}
/* icon-tag */
.filters-tags a:before {
	content: "\e976";
}
/* icon-trash */
.table .actions .remove:after {
	content: "\e978";
}
/* icon-twitter */
.twitter:before {
	content: "\e979";
}
/* icon-up */
nav.nav-site .scroll-up:after,
a.scroll-up:before {
	content:"\e97a";
}
/* icon-whatsapp */
.whatsapp:before {
	content: "\e97d";
}
/* icon-youtube */
.youtube:before {
	content: "\e97e";
}

/***************************/
/***************************/
/********** FONTS **********/
/***************************/
/***************************/

/****************************/
/********** ROBOTO **********/
/****************************/

/*
thin = out
light
regular
medium
bold
black = out
*/

/*
@font-face {
	font-family:'robotothin';
	src:url('/static/assets/fonts/hybrid/Roboto-Thin.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-Thin.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-Thin.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-Thin.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-Thin.svg#robotothin') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
	font-family:'robotothin_italic';
	src:url('/static/assets/fonts/hybrid/Roboto-ThinItalic.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-ThinItalic.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-ThinItalic.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-ThinItalic.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-ThinItalic.svg#robotothin_italic') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
*/
@font-face {
	font-family:'robotolight';
	src:url('/static/assets/fonts/hybrid/Roboto-Light.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-Light.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-Light.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-Light.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-Light.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-Light.svg#robotolight') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
	font-family:'robotolight_italic';
	src:url('/static/assets/fonts/hybrid/Roboto-LightItalic.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-LightItalic.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-LightItalic.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-LightItalic.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-LightItalic.svg#robotolight_italic') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
	font-family:'robotoregular';
	src:url('/static/assets/fonts/hybrid/Roboto-Regular.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-Regular.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-Regular.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-Regular.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-Regular.svg#robotoregular') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
	font-family:'robotoitalic';
	src:url('/static/assets/fonts/hybrid/Roboto-Italic.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-Italic.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-Italic.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-Italic.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-Italic.svg#robotoitalic') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
	font-family:'robotomedium';
	src:url('/static/assets/fonts/hybrid/Roboto-Medium.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-Medium.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-Medium.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-Medium.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-Medium.svg#robotomedium') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
	font-family:'robotomedium_italic';
	src:url('/static/assets/fonts/hybrid/Roboto-MediumItalic.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-MediumItalic.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-MediumItalic.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-MediumItalic.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-MediumItalic.svg#robotomedium_italic') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
	font-family:'robotobold';
	src:url('/static/assets/fonts/hybrid/Roboto-Bold.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-Bold.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-Bold.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-Bold.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-Bold.svg#robotobold') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
	font-family:'robotobold_italic';
	src:url('/static/assets/fonts/hybrid/Roboto-BoldItalic.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-BoldItalic.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-BoldItalic.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-BoldItalic.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-BoldItalic.svg#robotobold_italic') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}

/*
@font-face {
	font-family:'robotoblack';
	src:url('/static/assets/fonts/hybrid/Roboto-Black.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-Black.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-Black.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-Black.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-Black.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-Black.svg#robotoblack') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
	font-family:'robotoblack_italic';
	src:url('/static/assets/fonts/hybrid/Roboto-BlackItalic.eot');
	src:url('/static/assets/fonts/hybrid/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/Roboto-BlackItalic.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/Roboto-BlackItalic.woff') format('woff'),
		url('/static/assets/fonts/hybrid/Roboto-BlackItalic.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/Roboto-BlackItalic.svg#robotoblack_italic') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
*/

/****************************/
/********** OSWALD **********/
/****************************/

/*
extra light
light
regular
medium
semi bold = out
bold
*/

@font-face {
	font-family: 'oswaldextralight';
	src: url('/static/assets/fonts/hybrid/oswald-extralight.eot');
	src: url('/static/assets/fonts/hybrid/oswald-extralight.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/oswald-extralight.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/oswald-extralight.woff') format('woff'),
		url('/static/assets/fonts/hybrid/oswald-extralight.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/oswald-extralight.svg#oswaldregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'oswaldlight';
	src: url('/static/assets/fonts/hybrid/oswald-light.eot');
	src: url('/static/assets/fonts/hybrid/oswald-light.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/oswald-light.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/oswald-light.woff') format('woff'),
		url('/static/assets/fonts/hybrid/oswald-light.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/oswald-light.svg#oswaldregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'oswaldregular';
	src: url('/static/assets/fonts/hybrid/oswald-regular.eot');
	src: url('/static/assets/fonts/hybrid/oswald-regular.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/oswald-regular.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/oswald-regular.woff') format('woff'),
		url('/static/assets/fonts/hybrid/oswald-regular.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/oswald-regular.svg#oswaldregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'oswaldmedium';
	src: url('/static/assets/fonts/hybrid/oswald-medium.eot');
	src: url('/static/assets/fonts/hybrid/oswald-medium.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/oswald-medium.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/oswald-medium.woff') format('woff'),
		url('/static/assets/fonts/hybrid/oswald-medium.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/oswald-medium.svg#oswaldregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
/*
@font-face {
	font-family: 'oswaldsemibold';
	src: url('/static/assets/fonts/hybrid/oswald-semibold.eot');
	src: url('/static/assets/fonts/hybrid/oswald-semibold.eot?#iefix') format('embedded-opentype'),
		url('/static/assets/fonts/hybrid/oswald-semibold.woff2') format('woff2'),
		url('/static/assets/fonts/hybrid/oswald-semibold.woff') format('woff'),
		url('/static/assets/fonts/hybrid/oswald-semibold.ttf') format('truetype'),
		url('/static/assets/fonts/hybrid/oswald-semibold.svg#oswaldregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
*/
@font-face {
    font-family: 'oswaldbold';
    src: url('/static/assets/fonts/hybrid/oswald-bold.eot');
    src: url('/static/assets/fonts/hybrid/oswald-bold.eot?#iefix') format('embedded-opentype'),
         url('/static/assets/fonts/hybrid/oswald-bold.woff2') format('woff2'),
         url('/static/assets/fonts/hybrid/oswald-bold.woff') format('woff'),
         url('/static/assets/fonts/hybrid/oswald-bold.ttf') format('truetype'),
         url('/static/assets/fonts/hybrid/oswald-bold.svg#oswaldregular') format('svg');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}

/******************************/
/********** PLAYFAIR **********/
/******************************/

/*
regular / italic
bold = out
black = out
*/

@font-face {
    font-family: 'Playfair-regular';
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-Regular.eot');
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Regular.woff2') format('woff2'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Regular.woff') format('woff'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Regular.ttf') format('truetype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Regular.svg#PlayfairDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}
@font-face {
    font-family: 'Playfair-italic';
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-Italic.eot');
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-Italic.eot?#iefix') format('embedded-opentype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Italic.woff2') format('woff2'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Italic.woff') format('woff'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Italic.ttf') format('truetype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Italic.svg#PlayfairDisplay-Italic') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
/*
@font-face {
    font-family: 'Playfair-bold';
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-Bold.eot');
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Bold.woff2') format('woff2'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Bold.woff') format('woff'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Bold.ttf') format('truetype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Bold.svg#PlayfairDisplay-Bold') format('svg');
    font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
    font-family: 'Playfair-bold-italic';
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-BoldItalic.eot');
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-BoldItalic.woff2') format('woff2'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-BoldItalic.woff') format('woff'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-BoldItalic.ttf') format('truetype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-BoldItalic.svg#PlayfairDisplay-BoldItalic') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
    font-family: 'Playfair-black';
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-Black.eot');
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Black.woff2') format('woff2'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Black.woff') format('woff'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Black.ttf') format('truetype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-Black.svg#PlayfairDisplay-Black') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
@font-face {
    font-family: 'Playfair-black-italic';
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-BlackItalic.eot');
    src: url('/static/assets/fonts/hybrid/PlayfairDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-BlackItalic.woff2') format('woff2'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-BlackItalic.woff') format('woff'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-BlackItalic.ttf') format('truetype'),
        url('/static/assets/fonts/hybrid/PlayfairDisplay-BlackItalic.svg#PlayfairDisplay-BlackItalic') format('svg');
	font-weight:normal;
	font-style:normal;
	font-display: swap;
}
*/


/*********************************/
/*********************************/
/********** FONT FAMILY **********/
/*********************************/
/*********************************/

.director-title,
.title-alt {
	font-family:'robotolight';
}
body,
input, textarea, select, button,
.list.director-list.films .original,
.grid.films.playlists .infos h4 {
	font-family:'robotoregular';
}
.form-counter,
.form-total,
footer h3,
.offers .details strong,
ol li:before,
#primary .submenu,
nav.contextual a,
nav.contextual span,
.filters-tags a,
nav.focus-nav a,
button,
input[type="submit"],
.buttons > *,
.button,
.has-bonus a,
.has-bonus li,
.total,
.film-status,
.collection-link strong,
.director,
.title-alt strong,
.slider.films .has-bonus,
.films.user .format,
.video-link,
.switch a,
.nav-tabs.switch a,
.country-available {
	font-family:'robotomedium';
}
.film-title,
.grid.films.playlists .infos h4 strong,
.tooltip strong,
p b,
p strong,
.festival-date strong,
.table .format,
.grid.films.svod .svod-status.fixed,
.grid.films.user .user-status.fixed,
nav.contextual nav.mode > span,
nav.filters > span,
.buttons > b,
.button b,
.excerpt strong,
.title,
#overlay-directors .title-alt strong,
.timeline .year,
#overlay-introduction .title,
.focus .title {
	font-family:'robotobold';
}
.offer-infos,
#secondary,
.sum-up,
.offer-sum-up,
.nav-tabs a,
.main-title,
.introduction,
.offer-infos,
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5,
.offers h3 strong {
	font-family: 'oswaldextralight';
}
.slider .infos.fixed .type,
.offers em,
#primary,
.introduction strong,
nav.filters.larger a {
	font-family: 'oswaldlight';
}
.grid.posts h3,
.grid.directors .item.passed a:before,
.introduction b,
.offers .price small,
.alert,
h1 b, h2 b, h3 b, h4 b,
.film-counter,
.slider .infos h3:not(.title),
.grid.films.playlists:not(.archive) .infos,
.offer-infos b,
.list-link,
.list-link strong,
.section-title strong {
	font-family: 'oswaldregular';
}
.grid.directors .item.passed.featured a:before {
	font-family: 'oswaldregular' !important;
}
.offers h2,
.offers h3 {
	font-family:'oswaldmedium';
}
.slider .infos.fixed .title,
.oups .infos strong,
.film-counter strong,
.price {
	font-family: 'oswaldbold';
}
blockquote {
	font-family: 'Playfair-italic';
}

/*******************************/
/*******************************/
/********** FONT SIZE **********/
/*******************************/
/*******************************/

/*
.025em == test for readibility
*/

body {
	letter-spacing:.025em;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.main-title,
.section-title,
.overlay-title,
.introduction {
	line-height:1.25em;
}
.introduction {
	line-height:1.5em;
}
small {
	font-size:.5em;
}
.h1, .h2, .h3, .h4, .h5, .h6 {
	display: block;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	body {
		font-size:.75rem;
	}
	h1,
	.main-title {
		font-size:1.5rem;
	}
	h2,
	.h2,
	.section-title {
		font-size:1.25rem;
	}
	.excerpt {
		font-size:.875rem;
	}
}
@media (min-width:760px) {
	body {
		font-size:1rem;
	}
	h1,
	.main-title,
	.title-alt.large {
		font-size:2rem;
	}
}
@media (min-width:1000px) {
	.oups .infos > * {
		font-size:2rem;
	}
	h1,
	.main-title {
		font-size:3rem;
	}
	.main-title.large {
		font-size:3.5rem;
	}
	h2,
	.h2,
	.section-title,
	.introduction {
		font-size:1.25rem;
	}
	.excerpt {
		font-size:1.125rem;
	}
}
@media (min-width:1200px) {
	h1.title,
	h1.title-alt {
		font-size:2.5rem;
	}
	h2,
	.h2,
	.section-title,
	.introduction {
		font-size:1.5625rem;
	}
	h3 {
		font-size:1.25rem;
	}
}

/*************************************/
/*************************************/
/********** CONTENT SPACING **********/
/*************************************/
/*************************************/

.main-title + .introduction {
	padding:0;
	margin-top:0;
}

/********** DEFAULT **********/

h1.hidden + *,
h2.hidden + * {
	padding:0;
}
h1.hidden + *:not(.grid),
h2.hidden + *:not(.grid) {
	margin-top:0;
}
.reels.list > * + * {
	margin-top:1rem;
}

/********** FORM **********/

legend.hidden + * {
	margin-top:0;
}
.form-label.hidden + *,
label.hidden + * {
	margin-top:0;
	padding:0;
}
* + .form-label.hidden,
* + label.hidden {
	position:relative;
}

/********** @MEDIA **********/

/*
some are in others .css => ex. * + .grid
blockquote.large + * ==> for archive.institutions if not in quote.large
for single-director only if not in 2 separated sections
*/

/** default style **/

ul li + *,
ol li + * {
	margin-top:var(--margin-short);
}
/* V5 */
section.focus .alert.short.large + * {
	margin-top:var(--margin-short);
}

/** default style **/

p + *,
pre + *,
blockquote + *,
hr + *,
ul + *,
ol + *,
figure + *,
img + *,
svg + * {
	margin-top:var(--margin-normal);
}

h1 + *,
h2 + *,
h3 + *,
h4 + *,
h5 + *,
h6 + *,
.title + *,
.excerpt + *,
blockquote.large + * {
	margin-top:var(--margin-large);
}

/** custom style **/

.synopsis + *,
.description + *,
.videoplayer-container + *,
.source + *,
.in-collaboration + *,
.is-recommended + *,
.director + *,
.columnizer + *,
.offers + * {
	margin-top:var(--margin-normal);
}
* + .section-title,
* + .alert,
* + .slider-controls {
	margin-top:var(--margin-normal);
}
* + .videoplayer-container,
* + .inline.partners,
* + .collapsible.active {
	margin-top:var(--margin-normal) !important;
}

.slider .slider-controls:not(.min) > * {
	margin-top:-webkit-calc(var(--margin-large) * .75) !important;
	margin-top:-moz-calc(var(--margin-large) * .75) !important;
	margin-top:calc(var(--margin-large) * .75) !important;
}

.quote + *,
.film-versions + *,
.film-status + *,
.casting + *,
.price + *,
.videoplayer + *,
.splitted + *,
.columns + *,
.grid + *,
.slider + *,
.reels.list + *,
.collapsible + *,
.nav-tabs + *,
nav + *,
.inline + *,
.radios + .columns,
.slider-controls + *,
.results-container + *,
.buttons + *,
.button + *,
.table + * {
	margin-top:var(--margin-large);
}

* + .list-title,
* + .section-title,
* + .section-link,
* + .alert,
* + .quote.large,
* + .offers {
	margin-top:var(--margin-large);
}
* + .inline.partners,
* + .collapsible.active {
	margin-top:var(--margin-large) !important;
}

/** for index directors lists **/

section.has-background .list > li + li {
	margin-top:var(--margin-large);
}

/** default form **/

form + *,
label + *,
input + *,
select + *,
button + *,
textarea + * {
	margin-top:var(--margin-normal);
}
* + label,
* + .form-label,
* + input,
* + select,
* + button,
* + textarea {
	margin-top:var(--margin-normal);
}
h1 + .form-label,
h2 + .form-label,
h3 + .form-label,
h4 + .form-label {
	margin-top:var(--margin-normal);
}

/** custom form **/

.radios + *,
.inputs + *,
.input-container + *,
.select-container + * {
	margin-top:var(--margin-normal);
}
* + .input-container,
* + .select-container {
	margin-top:var(--margin-normal);
}
* + ul.checkboxes,
* + ul.radios,
h1 + ul.checkboxes,
h1 + ul.radios,
h2 + ul.checkboxes,
h2 + ul.radios,
h3 + ul.checkboxes,
h3 + ul.radios,
h4 + ul.checkboxes,
h4 + ul.radios,
* + ul.checkboxes.inline,
* + ul.radios.inline,
h1 + ul.checkboxes.inline,
h1 + ul.radios.inline,
h2 + ul.checkboxes.inline,
h2 + ul.radios.inline,
h3 + ul.checkboxes.inline,
h3 + ul.radios.inline,
h4 + ul.checkboxes.inline,
h4 + ul.radios.inline {
	margin-top:var(--margin-normal);
}

/** tables **/

.table + * {
	margin-top:var(--margin-normal);
}
