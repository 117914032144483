body.fr .grid.directors .item.passed a.title-alt:before {
	content: "Les films de leur vie";
}

body.en .grid.directors .item.passed a.title-alt:before {
	content: "The films of their lives";
}

body.de .grid.directors .item.passed a.title-alt:before {
	content: "Die Filme meines Lebens";
}

body.fr .grid.directors .item.featured.passed a.title-alt:before {
	content: "Les films de leur vie";
}

body.en .grid.directors .item.featured.passed a.title-alt:before {
	content: "The films of their lives";
}

body.fr .grid.directors .item.featured.passed a.title-alt:before {
	content: "Die Filme meines Lebens";
}

.grid .item.no-link:hover:before {
	border: solid .0625rem transparent !important;
}

.grid.playlists.films .item.no-link {
	justify-content: center;
}

.grid.playlists.films .item.no-link .item-content {
	width: auto;
	justify-content: center;
}
