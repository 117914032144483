@charset "UTF-8";

/*
inputs <=> <a>
mobile	== 2.25rem height (36px)
desktop	== 3rem height (48px)

height == for firefox
*/

/**************************/
/**************************/
/********** FORM **********/
/**************************/
/**************************/

fieldset {
	border:none;
	margin:0;
	padding:0;
}
form {
	text-align:left;
	margin-left:auto;
	margin-right:auto;
}
form.full {
	width:100%;
}
input {
	position:relative;
}
input,
textarea,
button,
select {
	display:block;
	border:0;
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
	-webkit-border-radius:var(--border-radius);
	   -moz-border-radius:var(--border-radius);
	        border-radius:var(--border-radius);
}
textarea {
	width:100%;
	resize:none;
	min-height:6rem;
	padding:1rem !important;
}
input[type=hidden] {
	position:absolute;
	font-size:0 !important;
	padding:0 !important;
}
form input,
form textarea,
form select,
input.full,
textarea.full,
button.full,
select.full {
	width:100%;
}
select,
input[type=submit],
button {
	cursor:pointer;
}

/********** BROWSERS **********/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
::-webkit-input-placeholder {
	color:inherit;
	opacity:.5;
}
:-moz-placeholder {
	color:inherit;
	opacity:.5;
}
::-moz-placeholder {
	color:inherit;
	opacity:.5;
}
:-ms-input-placeholder {
	color:inherit;
	opacity:.5;
}
input,
textarea,
button {
	-webkit-appearance:none;
	-moz-appearance:none;
}

/********** CHROME AUTOFILL **********/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
	-webkit-text-fill-color:var(--dark-color) !important;
	-webkit-box-shadow: 0 0 0px 1000px white inset;
	background-color: transparent;
}
header input:-webkit-autofill,
header input:-webkit-autofill:hover,
header input:-webkit-autofill:focus,
header input:-webkit-autofill:active,
header textarea:-webkit-autofill,
header textarea:-webkit-autofill:hover,
header textarea:-webkit-autofill:focus,
header textarea:-webkit-autofill:active {
	-webkit-text-fill-color:var(--bright-color) !important;
	-webkit-box-shadow: 0 0 0px 1000px var(--black-color) inset;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	input,
	textarea,
	button,
	select {
		font-size:.75rem;
		line-height:1em;
		padding:1em !important;
	}
	input,
	button,
	select {
		height:2.25rem;
	}
}
@media (min-width:760px) {
	input,
	textarea,
	button,
	select {
		font-size:.875rem;
		line-height:1rem;
		padding:1rem !important;
	}
	input,
	button,
	select {
		height:3rem;
	}
}

/**************************/
/**************************/
/********** TAGS **********/
/**************************/
/**************************/

/*
cf. playlist search films
*/

.input-tag {
	position:absolute;
	z-index:10;
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	background:rgb(121,121,130);
	-webkit-border-radius:var(--border-radius);
	   -moz-border-radius:var(--border-radius);
	        border-radius:var(--border-radius);
}
a.input-tag:hover {
	background:var(--red-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.input-tag {
		top:.25rem;
		left:.25rem;
		height:-webkit-calc(100% - .5rem);
		height:-moz-calc(100% - .5rem);
		height:calc(100% - .5rem);
		font-size:.625rem;
		line-height:1em;
		padding:.5rem;
	}
	.input-container[class*=" icon-"] .input-tag {
		left: 2.25rem !important;
	}
}
@media (min-width:760px) {
	.input-tag {
		top:.5rem;
		left:.5rem;
		height:-webkit-calc(100% - 1rem);
		height:-moz-calc(100% - 1rem);
		height:calc(100% - 1rem);
		font-size:.75rem;
		line-height:1em;
		padding:.5rem;
	}
	.input-container[class*=" icon-"] .input-tag {
		left: 3rem !important;
	}
}

/****************************/
/****************************/
/********** ERRORS **********/
/****************************/
/****************************/
/* V4 > align left as default */

/********** FORM-ERROR **********/

.form-error {
	font-size:.75rem;
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
	margin-top: .5rem;
	margin-bottom: .5rem;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
}
.form-error + * {
	margin-top:0;
}
.form-error + label.hidden,
.form-error + .form-label {
	position:absolute;
}

/********** FORM-COUNTER **********/

.form-counter,
.form-total {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.form-total {
	margin-right:auto;
}

/**************************************/
/**************************************/
/********** CHECKBOX / RADIO **********/
/**************************************/
/**************************************/

input[type="radio"],
input[type="checkbox"] {
	display:inline-block;
	vertical-align:middle;
	cursor:pointer;
	height:1em;
	width:auto;
	padding:.5rem !important;
	border:solid var(--border-radius) var(--bright-color);
}
input[type="radio"] {
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
}
input[type="radio"]:hover,
input[type="radio"]:checked,
input[type="checkbox"]:hover,
input[type="checkbox"]:checked {
	background:var(--red-color);
}
input[type="checkbox"] + label,
input[type="radio"] + label,
input[type="checkbox"] + .form-label,
input[type="radio"] + .form-label {
	vertical-align:middle;
	padding:0;
	margin:0 0 0 1em;
}

/********** RADIOS **********/

ul.checkboxes.inline,
ul.radios.inline {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	margin:-.5rem;
}
ul.checkboxes.inline > li,
ul.radios.inline > li {
	display: block;
	margin:.5rem;
}
ul.checkboxes > li,
ul.radios > li {
	display: block;
}
ul.checkboxes.inline.centered,
ul.radios.inline.centered {
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}

/********** RADIO **********/

.radio-container {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
ul.radios > li > * + .collapsible.active {
	margin:1rem 0 !important;
}

/***************************/
/***************************/
/********** LABEL **********/
/***************************/
/***************************/

label,
.form-label {
	display:inline-block;
}

/****************************/
/****************************/
/********** INPUTS **********/
/****************************/
/****************************/

.inputs {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.inputs > * {
	display:inline-block;
	vertical-align:middle;
	margin:0;
	padding:0;
}

/*************************************/
/*************************************/
/********** SUBMIT / BUTTON **********/
/*************************************/
/*************************************/

input[type=submit],
button {
	text-align:center !important;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}
input[type=submit]:hover,
button:hover {
	color:var(--red-color);
	text-shadow:none;
	background:var(--bright-color);
}

/********** CHECK **********/
/* cf. login */

button.check {
	font-size:0;
	padding:0 !important;
}
button.check:before {
	content:"OK";
	display:inline-block;
}
* + button.check {
	margin:0 0 0 1rem;
}

/********** LOGIN-CONNECT **********/
/* cf. login */

.buttons.login-connect .icon-facebook {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:rgb(59,89,152);
}
.buttons.login-connect .icon-google {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
}
.buttons.login-connect .icon-facebook:hover,
.buttons.login-connect .icon-google:hover {
	color:var(--dark-color);
	text-shadow:none;
	background:rgb(238,238,238) !important;
}

/********** COLORS **********/

/* cf. colors.css */

/********** @MEDIA **********/

@media (min-width:0px) {
	button.check {
		height:2.25rem;
		width:auto;
	}
	button.check:before {
		font-size:.75rem;
		line-height:1em;
		padding:1em;
	}
	.buttons.login-connect .icon-facebook:before,
	.buttons.login-connect .icon-google:before {
		margin-right:1em;
	}
	/** small **/
	.buttons.small > button,
	button.small,
	.buttons.small > input[type=submit],
	input[type=submit].small {
		height:2.25rem;
	}
}
@media (min-width:760px) {
	.buttons.login-connect .icon-facebook:before,
	.buttons.login-connect .icon-google:before {
		margin-right:1rem;
	}
	button.check {
		height:3rem;
	}
	button.check:before {
		font-size:.875rem;
		line-height:1rem;
		padding:1rem;
	}
}

/**********************************/
/**********************************/
/********** FRIENDS LIST **********/
/**********************************/
/**********************************/

form ol.friends-list li:before {
	background:rgb(121,121,130);
	padding:2em 0;
	padding:0;
	height:auto;
	-webkit-border-radius:var(--border-radius) 0 0 var(--border-radius);
	   -moz-border-radius:var(--border-radius) 0 0 var(--border-radius);
	        border-radius:var(--border-radius) 0 0 var(--border-radius);
}
form ol.friends-list li input {
	-webkit-border-radius:0 var(--border-radius) var(--border-radius) 0;
	   -moz-border-radius:0 var(--border-radius) var(--border-radius) 0;
	        border-radius:0 var(--border-radius) var(--border-radius) 0;
}
form ol.friends-list.columnizer li:not(:last-child) {
	margin:0 0 1rem 0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	form ol.friends-list li {
		padding-left:2.25rem !important;
	}
	form ol.friends-list li:before {
		width:2.25rem;
		line-height:2.25rem;
		max-height:2.25rem;
	}
}
@media (min-width:760px) {
	form ol.friends-list li {
		padding-left:3rem !important;
	}
	form ol.friends-list li:before {
		width:3rem;
		line-height:3rem;
		max-height:3rem;
	}
}

/*************************************/
/*************************************/
/********** INPUT CONTAINER **********/
/*************************************/
/*************************************/

.input-container {
	position:relative;
	z-index:20;
	display: block;
	width:100%;
	padding:0;
	font-size:0;
	line-height:0;
	letter-spacing:0;
}
.input-container input {
	margin:0;
	width:100%;
}
.input-container button {
	position:absolute;
	top:50%;
	right:0;
	font-size:.875rem;
	line-height:1em;
	height:100%;
	padding:.5rem 0;
	text-align:center;
	margin:0;
	border:0;
	color: var(--light-color);
	text-shadow:none;
	background:none;
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
}
.input-container button [class^="icon-"],
.input-container button [class*=" icon-"] {
	display:block;
}
.input-container .infobutton {
	position:absolute;
	z-index:10;
	top:50%;
	right:.5rem;
	margin:-.75rem 0 0 0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.input-container,
	.input-container input {
		height:2.25rem;
	}
	.input-container button {
		width:2.25rem;
	}
}
@media (min-width:760px) {
	.input-container,
	.input-container input {
		height:3rem;
	}
	.input-container button {
		width:3rem;
	}
}

/***************************/
/********** ICONS **********/
/***************************/

/* icon on input-container */

.input-container[class^="icon-"]:before,
.input-container[class*=" icon-"]:before {
	font-family:"icomoon";
	position:absolute;
	z-index:10;
	top:50%;
	left:0;
	color:var(--grey-color);
	text-shadow:none;
	opacity:.5;
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.input-container[class^="icon-"]:before,
	.input-container[class*=" icon-"]:before {
		font-size:.75rem;
		line-height:1em;
		padding:1em;
	}
	.input-container[class^="icon-"] input,
	.input-container[class*=" icon-"] input {
		padding-left:2.25rem !important;
	}
}
@media (min-width:760px) {
	.input-container[class^="icon-"]:before,
	.input-container[class*=" icon-"]:before {
		font-size:.875rem;
		line-height:1rem;
		padding:1rem;
	}
	.input-container[class^="icon-"] input,
	.input-container[class*=" icon-"] input {
		padding-left:3rem !important;
	}
}

/******************************/
/********** EDITABLE **********/
/******************************/

.input-container.editable {
	background:none !important;
}
.input-container.editable:before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:100%;
	-webkit-border-radius:.25rem;
	   -moz-border-radius:.25rem;
	        border-radius:.25rem;
	border:solid var(--stroke-width) var(--bright-color);
}
.editable .edit:before {
	font-family:'icomoon';
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.editable .edit {
		font-size:0 !important;
		line-height:0 !important;
	}
	.editable .edit:before {
		display:block;
		font-size:.75rem;
		line-height:1em;
	}
}
@media (min-width:760px) {
	.editable .edit:before {
		font-size:.875rem;
		line-height:1rem;
	}
}
@media (min-width:1000px) {
	.editable .edit {
		font-size:.875rem !important;
		line-height:1rem !important;
	}
	.editable .edit:before {
		display:none;
	}
}

/********************************/
/********** HAS-BUTTON **********/
/********************************/

.input-container.has-button {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	background:var(--bright-color);
	-webkit-border-radius:-webkit-calc(var(--border-radius) * 2);
	   -moz-border-radius:-moz-calc(var(--border-radius) * 2);
	        border-radius:calc(var(--border-radius) * 2);
}
.input-container.has-button input {
	background:none;
}
.input-container.has-button input[type=submit],
.input-container.has-button button {
	position:relative;
	top:auto;
	display:block;
	height:auto;
	width:auto;
	white-space:nowrap;
	margin:.5rem;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
	-webkit-transform:none;
	   -moz-transform:none;
	    -ms-transform:none;
	     -o-transform:none;
	        transform:none;
}
.input-container.has-button input[type=submit]:hover,
.input-container.has-button button:hover {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
	background:var(--bright-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.input-container.has-button input[type=submit],
	.input-container.has-button button {
		font-size:.75rem;
		line-height:1em;
		padding:.75rem !important;
	}
}
@media (min-width:760px) {
	.input-container.has-button input[type=submit],
	.input-container.has-button button {
		font-size:.875rem;
		line-height:1rem;
		padding:1rem !important;
	}
}

/***************************/
/********** PROMO **********/
/***************************/

/* to mix with .has-button !!!!! */

.input-promo.input-container {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	background:rgb(201,201,210) !important;
	-webkit-border-radius:-webkit-calc(var(--border-radius) * 2);
	   -moz-border-radius:-moz-calc(var(--border-radius) * 2);
	        border-radius:calc(var(--border-radius) * 2);
}
.input-promo.input-container > input {
	background:none;
}
.input-promo.input-container > input[type=submit],
.input-promo.input-container > button {
	position:relative;
	top:auto;
	display:block;
	height:auto;
	width:auto;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
	-webkit-transform:none;
	   -moz-transform:none;
	    -ms-transform:none;
	     -o-transform:none;
	        transform:none;
}
.input-promo.input-container > input[type=submit]:hover,
.input-promo.input-container > button:hover {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
	background:var(--bright-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.input-promo.input-container > input[type=submit],
	.input-promo.input-container > button {
		font-size:.625rem;
		line-height:1em;
		padding:.5rem !important;
		margin:.25rem;
	}
}
@media (min-width:760px) {
	.input-promo.input-container > input[type=submit],
	.input-promo.input-container > button {
		font-size:.75rem;
		line-height:1em;
		padding:.5rem !important;
		margin:.5rem;
	}
}

/*******************************/
/*******************************/
/********** SEARCHBAR **********/
/*******************************/
/*******************************/

.searchbar {
	position:relative;
}
.searchbar .input-container {
	width:100%;
}
.searchbar .input-container > input,
.searchbar button {
	-webkit-border-radius:0;
	   -moz-border-radius:0;
	        border-radius:0;
}
.searchbar .input-container > input[type=submit]:before,
.searchbar .input-container > button:before {
	font-family:'icomoon';
	font-size:1rem;
	line-height:1em;
}
.searchbar .input-container > input[type=submit],
.searchbar .input-container > button {
	font-size:0 !important;
	line-height:0 !important;
	background:none !important;
	background: -webkit-gradient(linear, left top, right top, from(rgba(var(--bright-rgb),0)), to(rgba(var(--bright-rgb),1))) !important;
	background: -webkit-linear-gradient(left, rgba(var(--bright-rgb),0) 0%, rgba(var(--bright-rgb),1) 100%) !important;
	background: -moz-linear-gradient(left, rgba(var(--bright-rgb),0) 0%, rgba(var(--bright-rgb),1) 100%) !important;
	background: -o-linear-gradient(left, rgba(var(--bright-rgb),0) 0%, rgba(var(--bright-rgb),1) 100%) !important;
	background: linear-gradient(90deg, rgba(var(--bright-rgb),0) 0%, rgba(var(--bright-rgb),1) 100%) !important;
}

/********************************/
/********** IN OVERLAY **********/
/********************************/

.overlay .searchbar .input-container > input[type=submit],
.overlay .searchbar .input-container > button {
	background:none !important;
}

/*******************************/
/********** IN HEADER **********/
/*******************************/

header .searchbar {
	z-index:1200;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	   -moz-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	margin:auto 0;
}
header * + .searchbar {
	margin:auto 0 auto -webkit-calc(var(--gutter-gap) / 2);
	margin:auto 0 auto -moz-calc(var(--gutter-gap) / 2);
	margin:auto 0 auto calc(var(--gutter-gap) / 2)
}
header .searchbar input {
	color:inherit;
	text-shadow:inherit;
	padding:1rem !important;
	background:var(--darker-color);
}
header .searchbar button {
	color:inherit;
	text-shadow:inherit;
	padding:1rem !important;
	background:none;
}
header .searchbar button:hover {
	-webkit-transform:translate3d(0,-50%,0) scale(.8);
	   -moz-transform:translate3d(0,-50%,0) scale(.8);
	        transform:translate3d(0,-50%,0) scale(.8);
}
header .searchbar .input-container > input[type=submit],
header .searchbar .input-container > button {
	background: -webkit-gradient(linear, left top, right top, from(rgba(var(--black-rgb),0)), to(rgba(var(--black-rgb),1))) !important;
	background: -webkit-linear-gradient(left, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%) !important;
	background: -moz-linear-gradient(left, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%) !important;
	background: -o-linear-gradient(left, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%) !important;
	background: linear-gradient(90deg, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%) !important;
}

/********** @MEDIA **********/

@media (max-width:1000px) {
	header .searchbar {
		display:none;
	}
}
@media (min-width:1000px) {
	header .icon-search {
		display:none;
	}
}

/**************************************/
/**************************************/
/********** SELECT CONTAINER **********/
/**************************************/
/**************************************/

.select-container {
	position:relative;
	display:block;
	z-index:20;
	width:100%;
	padding:0;
	font-size:0;
	line-height:0;
	letter-spacing:0;
}
.select-container select {
	width:100%;
	-webkit-appearance:none;
	-moz-appearance:none;
}
.select-container:after {
	content:'';
	position:absolute;
	z-index:10;
	top:0;
	right:0;
	height:0;
	width:0;
	pointer-events:none;
	border-top:var(--stroke-width-medium) solid var(--dark-color);
	border-right:var(--stroke-width-medium) solid transparent;
	border-left:var(--stroke-width-medium) solid transparent;
	border-bottom:0 dotted;
}
.select-container:hover:after {
	-webkit-transform:rotate(-180deg);
	   -moz-transform:rotate(-180deg);
	    -ms-transform:rotate(-180deg);
	     -o-transform:rotate(-180deg);
	        transform:rotate(-180deg);
}
.select-container.select2:after {
	display:none;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.select-container select {
		padding-right:2.25rem !important;
	}
	.select-container:after {
		margin:1rem .875rem;
	}
}
@media (min-width:760px) {
	.select-container select {
		padding-right:3rem !important;
	}
	.select-container:after {
		margin:1.375rem 1.25rem;
	}
}

/************************************/
/************************************/
/********** AUTOCOMPLETION **********/
/************************************/
/************************************/

.autocompletion {
	position:relative;
	width:100%;
	margin:0;
	line-height:normal;
	padding:1rem;
	background:var(--dark-color);
	overflow:hidden;
}
.autocompletion:before {
	content: "";
	position: absolute;
	z-index: -1;
	bottom:0;
	left:0;
	height:100%;
	width:100%;
	background:inherit;
}
.autocompletion:after {
	content: "";
	position: absolute;
	z-index: -2;
	bottom:0;
	left:0;
	height:100%;
	width:100%;
	background:inherit;
	-webkit-filter:drop-shadow(0 0 .125rem var(--dark-color));
	        filter:drop-shadow(0 0 .125rem var(--dark-color));
	-webkit-clip-path: polygon(0% -webkit-calc(0% + .125rem), 100% -webkit-calc(0% + .125rem), -webkit-calc(100% + .25rem) -webkit-calc(100% + .25rem), -webkit-calc(0% - .25rem) -webkit-calc(100% + .25rem));
	        clip-path: polygon(0% -moz-calc(0% + .125rem), 100% -moz-calc(0% + .125rem), -moz-calc(100% + .25rem) -moz-calc(100% + .25rem), -moz-calc(0% - .25rem) -moz-calc(100% + .25rem));
	        clip-path: polygon(0% calc(0% + .125rem), 100% calc(0% + .125rem), calc(100% + .25rem) calc(100% + .25rem), calc(0% - .25rem) calc(100% + .25rem));
	opacity:.5;
}
.autocompletion .title + *,
.autocompletion * + .title,
.autocompletion * + input[type=submit],
.autocompletion * + button {
	margin-top:1rem;
}
.autocompletion .title,
.autocompletion .title-alt {
	font-size:.875rem !important;
}
.autocompletion .title .counter {
	opacity:.25;
}
/*
.autocompletion .grid.min.films .cover {
	max-width:5rem;
}
*/
/* WIP */
.autocompletion .grid.min.films .cover {
	width:5rem;
}
.autocompletion .grid.min.films .cover + .infos {
	width:-webkit-calc(100% - 5rem);
	width:-moz-calc(100% - 5rem);
	width:calc(100% - 5rem);
}
.autocompletion .grid.min.films .director {
	white-space: nowrap;
}
/* end WIP */
.autocompletion .avatar {
	height:3rem;
	width:3rem;
}

/********** GRID **********/

.autocompletion .grid.films .infos {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.autocompletion .grid.films .director {
	margin:0 0 0 1em !important;
}

/** reset private settings **/

.autocompletion .grid.films .item {
	background:var(--grey-color);
}
.autocompletion .grid.list.films > * .infos > *,
.autocompletion .grid.films .infos {
	color:var(--light-color);
	text-shadow:0 0 0 var(--light-color);
}
body.private main .autocompletion .grid.films .infos .title,
.autocompletion .grid.films .infos .title {
	color: var(--bright-color);
	text-shadow: 0 0 0 var(--bright-color);
}
.autocompletion .grid.films .extras {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}

/********** AUTOCOMPLETION DIRECTION **********/
/* direction == cf. in page user */

.input-container .autocompletion.to-top {
	top:auto;
}

/********** IN OVERLAY **********/

.overlay .autocompletion input[type=submit]:hover,
.overlay .autocompletion button:hover {
	background:rgb(201,201,210) !important;
}

/********** IN INPUT-CONTAINER **********/

.input-container .autocompletion {
	position: absolute;
	z-index:10;
    left: 0;
	height:0;
    opacity: 0;
    visibility: hidden;
}
.input-container:hover .autocompletion,
.input-container .autocompletion.active {
	height:auto;
	opacity:1;
	visibility:visible;
	overflow:visible;
}
.autocompletion input[type=submit],
.autocompletion button {
	width:100%;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	background:var(--red-color) !important;
}
.autocompletion input[type=submit]:hover,
.autocompletion button:hover {
	color:var(--red-color) !important;
	text-shadow:0 0 0 var(--red-color) !important;
	background:var(--bright-color) !important;
}

/********** IN SEARCHBAR **********/

.searchbar .autocompletion {
	background:var(--dark-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	/** in searchbar **/
	.searchbar .autocompletion {
		min-width:20rem;
	}
	/** in input-container **/
	.input-container .autocompletion {
		top: 2.25rem;
		max-height:-webkit-calc(100vh - 2.25rem);
		max-height:-moz-calc(100vh - 2.25rem);
		max-height:calc(100vh - 2.25rem);
	}
	/** direction **/
	.input-container .autocompletion.to-top {
		bottom:2.25rem;
	}
}
@media (min-width:760px) {
	/** in input-container **/
	.input-container .autocompletion {
		top: 3rem;
		max-height:-webkit-calc(100vh - 3rem);
		max-height:-moz-calc(100vh - 3rem);
		max-height:calc(100vh - 3rem);
	}
	/** direction **/
	.input-container .autocompletion.to-top {
		bottom:3rem;
	}
}
@media (min-width:1000px) {
	/** in searchbar **/
	.searchbar .autocompletion {
		min-width:30rem;
	}
}

/****************************/
/****************************/
/********** STRIPE **********/
/****************************/
/****************************/

/* in iframe */