@charset "UTF-8";

/******************************/
/******************************/
/********** OVERLAYS **********/
/******************************/
/******************************/

/* visibility == for :focus-visible */

/********** OVERLAYS **********/

#overlays {
	position:absolute;
	z-index:10000;
	top:0;
	right:0;
	bottom:0;
	left:0;
	-webkit-transform:none !important;
	   -moz-transform:none !important;
	    -ms-transform:none !important;
	     -o-transform:none !important;
	        transform:none !important;
	margin:0 !important;
	pointer-events: none;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
}

/********** OVERLAY DEFAULT **********/

.overlay {
	position:fixed;
	z-index:1000;
	top:0;
	left:0;
	right:0;
	bottom:0;
	display:block;
	max-height:100%;
	width:100%;
	opacity:0;
	overflow:hidden;
	-webkit-overflow-scrolling:touch;
	pointer-events:none;
	visibility: hidden;
}
.overlay.active {
	opacity:1;
	cursor:pointer;
	pointer-events:all; /* issue with accessibility */
	opacity:1;
	overflow: hidden auto; /* bug in ios */
	overflow-y: auto;
	overflow-x: hidden;
	visibility: visible;
	scroll-behavior: smooth;
}

/********** OVERLAY CONTAINER **********/

.overlay .overlay-container {
	position:relative;
	z-index:100;
	min-height:100%;
	margin:0 auto;
	pointer-events:none;
	cursor:auto;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
}
.overlay.active .overlay-container {
	position:relative;
	pointer-events:all;
}

/********** OVERLAYED **********/

body.overlayed {
	overflow:hidden !important;
}
/*
body.overlayed:after {
	content:"";
	position:fixed;
	z-index:4000;
	top:0;
	right:0;
	bottom:0;
	left:0;
	pointer-events: none;
	-webkit-backdrop-filter:blur(.25rem);
	        backdrop-filter:blur(.25rem);
}
*/
body.overlayed main,
body.overlayed footer {
	pointer-events:none;
}

/********** OVERLAY-CONTENT **********/

.overlay .overlay-content {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	overflow-x:hidden;
	max-width: var(--max-width);
	margin-left:auto;
	margin-right:auto;
}

/********** OVERLAYS SECTIONS **********/
/* same as main > section */

.overlay > section {
	max-width: none;
	overflow: hidden;
	padding-left:var(--gutter-gap);
	padding-right: var(--gutter-gap);
}
.overlay section > * {
	max-width:var(--max-width);
	margin-left:auto;
	margin-right:auto;
}
.overlay > section:first-child,
.overlay > section:not(.focus):first-child + section.focus,
.overlay > nav + section.focus {
	margin-top:0 !important;
}

/****************************************************/
/****************************************************/
/********** OVERLAY POSITIONS / ANIMATIONS **********/
/****************************************************/
/****************************************************/

/* if no position == fading animation */

.overlay.top {
	-webkit-transform:translate3d(0,-100%,0);
	   -moz-transform:translate3d(0,-100%,0);
	        transform:translate3d(0,-100%,0);
}
.overlay.bottom {
	-webkit-transform:translate3d(0,100%,0);
	   -moz-transform:translate3d(0,100%,0);
	        transform:translate3d(0,100%,0);
}
.overlay.left {
	-webkit-transform:translate3d(-100%,0,0);
	   -moz-transform:translate3d(-100%,0,0);
	        transform:translate3d(-100%,0,0);
}
.overlay.right {
	-webkit-transform:translate3d(100%,0,0);
	   -moz-transform:translate3d(100%,0,0);
	        transform:translate3d(100%,0,0);
}
.overlay.top.active,
.overlay.bottom.active,
.overlay.left.active,
.overlay.right.active {
	-webkit-transform:translate3d(0,0,0);
	   -moz-transform:translate3d(0,0,0);
	        transform:translate3d(0,0,0);
}

/********** OVERLAY RESTRICTIONS **********/
/*
body.is-vod .overlay.top {
	top:var(--header-height);
}
*/
/***********************************/
/***********************************/
/********** OVERLAY SHORT **********/
/***********************************/
/***********************************/

/* cf. newsletter */

/*
not fullscreen
top, bottom == full width / min height
left, right == min width / full height
*/

/********** SHORT DEFAULT **********/

.overlay.short.bottom {
	top:unset;
}
.overlay.short.right,
.overlay.slide.right {
	margin-left:auto;
}
.overlay.short.top .overlay-container,
.overlay.short.bottom .overlay-container {
	min-height:auto;
	width:auto;
}
.overlay.short.top .overlay-container {
	margin:0 auto auto auto;
}
.overlay.short.bottom .overlay-container {
	margin:auto auto 0 auto;
}
.overlay.short.left .overlay-container {
	margin:auto auto auto 0;
}
.overlay.short.right .overlay-container {
	margin:auto 0 auto auto;
}

/********** @MEDIA **********/

@media (min-width:760px) {
	.overlay.short.left,
	.overlay.short.right {
		max-width:80%;
	}
}
@media (min-width:1000px) {
	.overlay.short.left,
	.overlay.short.right {
		max-width:60%;
	}
}

/***********************************/
/***********************************/
/********** OVERLAY LARGE **********/
/***********************************/
/***********************************/

/* no max-width = cf. liste de, filter films, etc. */

.overlay.large .overlay-content {
	max-width: none;
}

/***********************************/
/***********************************/
/********** OVERLAY MODAL **********/
/***********************************/
/***********************************/

/* floating modal box */

/********** OVERLAY MODAL DEFAULT **********/

.overlay.modal {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.overlay.modal .overlay-container {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	margin:auto;
	min-height:auto;
	width: 100%;
	max-width: var(--max-width);
	-webkit-filter:drop-shadow(0 0 .25em rgba(var(--dark-rgb), var(--alpha-rgb)));
	        filter:drop-shadow(0 0 .25em rgba(var(--dark-rgb), var(--alpha-rgb)));
}

/********** OVERLAY MODAL POSITIONS **********/

.overlay.modal.top .overlay-container {
	margin-top:0;
}
.overlay.modal.bottom .overlay-container {
	margin-bottom:0;
}
.overlay.modal.left .overlay-container {
	margin-left:0;
}
.overlay.modal.right .overlay-container {
	margin-right: 0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.overlay.modal.bottom .overlay-content {
		padding-right:4rem;
	}
}
@media (min-width:1000px) {
	.overlay.modal.short .overlay-container {
		max-width:60%;
	}
}
@media (min-width:1200px) {
	.overlay.modal .overlay-container {
		min-width:60%;
	}
	.overlay.modal .overlay-content {
		width:100%;
	}
}

/********** MOBILE **********/

@media (max-width:640px) {
	.overlay.modal .overlay-container {
		min-width:-webkit-calc(100% - 2rem);
		min-width:-moz-calc(100% - 2rem);
		min-width:calc(100% - 2rem);
	}
	.overlay.modal .overlay-content {
		width:100%;
	}
}

/*******************************************************/
/*******************************************************/
/********** OVERLAY HEADER / CONTENT / FOOTER **********/
/*******************************************************/
/*******************************************************/

/* .overlay-container > .overlay-header + .overlay-content + .overlay-footer */

/********** DEFAULT **********/

.overlay .overlay-container .overlay-header,
.overlay .overlay-container .overlay-content,
.overlay .overlay-container .overlay-footer {
	position:relative;
	width:100%;
}

/********** STICKY **********/

.overlay .overlay-container .overlay-header.sticky,
.overlay .overlay-container .overlay-footer.sticky {
	position:-webkit-sticky;
	position:sticky;
	z-index:30;
	background:var(--bright-color);
}
.overlay .overlay-container .overlay-header.sticky {
	top:0;
}
.overlay .overlay-container .overlay-footer.sticky {
	bottom:0;
}

/********** FIXED **********/

.overlay-header.fixed,
.overlay-footer.fixed {
	position:-webkit-sticky !important;
	position:sticky !important;
	z-index:30;
	width:100%;
	background:rgba(var(--grey-rgb), var(--alpha-rgb));
}
.overlay-header.fixed {
    top:0;
}
.overlay-footer.fixed {
    bottom:0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.overlay-header,
	.overlay-content,
	.overlay-footer {
		padding:1rem;
	}
}
@media (min-width:760px) {
	.overlay-header,
	.overlay-footer {
		padding:2rem 4rem;
	}
	.overlay-content {
		padding:4rem;
	}
}

/********** VIDEOPLAYER [BITMOVIN] **********/

@media (min-width:0px) {
	.overlay .videoplayer-container {
		max-height:-webkit-calc(100vh - 1rem * 2);
		max-height:-moz-calc(100vh - 1rem * 2);
		max-height:calc(100vh - 1rem * 2);
	}
}
@media (min-width:760px) {
	.overlay .videoplayer-container {
		max-height:-webkit-calc(100vh - 4rem * 2);
		max-height:-moz-calc(100vh - 4rem * 2);
		max-height:calc(100vh - 4rem * 2);
	}
}

/*********************************/
/*********************************/
/********** OVERLAY MIN **********/
/*********************************/
/*********************************/

/********** POSITIONS **********/

.overlay.bottom {
	top:unset;
}
.overlay.top .overlay-container,
.overlay.bottom .overlay-container {
	min-height:auto;
	width:auto;
}
.overlay.top .overlay-container {
	margin:0 auto auto auto;
}
.overlay.bottom .overlay-container {
	margin:auto auto 0 auto;
}
.overlay.left .overlay-container {
	margin:auto auto auto 0;
}
.overlay.right .overlay-container {
	margin:auto 0 auto auto;
}

/********** @MEDIA **********/

@media (min-width:760px) {
	.overlay.min .overlay-header,
	.overlay.min .overlay-content,
	.overlay.min .overlay-footer {
		padding:1rem;
	}
}

/*************************************/
/*************************************/
/********** OVERLAY LOADING **********/
/*************************************/
/*************************************/

.overlay.is-loading:before {
	position:absolute;
	z-index:110;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	   -moz-transform:translate(-50%,-50%);
	    -ms-transform:translate(-50%,-50%);
	     -o-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	pointer-events: none;
}
.overlay.is-loading .overlay-content {
	opacity:0;
}

/***********************************/
/***********************************/
/********** OVERLAY CLOSE **********/
/***********************************/
/***********************************/

/* .close > .overlay-close ??? */

/********** DEFAULT **********/

.close {
	opacity:0;
}
.overlay.active .close {
	opacity:1;
}

/********** ICONS **********/

.overlay .overlay-container > .close:first-child:before,
.overlay .overlay-header > .close:first-child:before {
	font-family:'icomoon';
	font-size:1rem;
	line-height:1em;
}
.overlay .overlay-container > .close:first-child:hover,
.overlay .overlay-header > .close:first-child:hover {
	-webkit-transform:scale(.8);
	   -moz-transform:scale(.8);
	    -ms-transform:scale(.8);
	     -o-transform:scale(.8);
	        transform:scale(.8);
}

/********** ONLY TOP ONE **********/

.overlay .overlay-container > .close:first-child,
.overlay .overlay-header > .close:first-child {
	position:-webkit-sticky;
	position:sticky;
	z-index:40;
	top:0;
	left:100%;
	right:auto;
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	width:3rem;
	font-size:0;
	line-height:0;
	border:0;
	padding:1rem;
	margin:0;
}
.overlay .overlay-header > .close:first-child {
	margin:0 0 0 auto;
}
.overlay .overlay-container > .close + *,
.overlay .overlay-header > .close + * {
	margin-top:-3rem !important;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.overlay .overlay-container > .close,
	.overlay .overlay-header > .close {
		font-size:1.5em;
	}
}
@media (min-width:760px) {
	.overlay .overlay-container > .close,
	.overlay .overlay-header > .close {
		font-size:2em;
	}
}

/**********************************/
/**********************************/
/********** CLICKOUTSIDE **********/
/**********************************/
/**********************************/

/* zone for closing in overlays */

.clickoutside {
	position:fixed;
	z-index:900;
	top:0;
	left:0;
	right:0;
	bottom:0;
	font-size:0;
	opacity:0;
	pointer-events:none;
}
body.overlayed .clickoutside {
	opacity:var(--alpha-rgb);
	pointer-events:auto;
}

/***************************************/
/***************************************/
/********** MULTIPLE OVERLAYS **********/
/***************************************/
/***************************************/

.overlay-back {
	position:-webkit-sticky;
	position:sticky;
	z-index:500;
	top:0;
	display:none;
	width:3rem;
	font-size:0;
	line-height:0;
	border:0;
	padding:1rem;
	margin:0 !important;
}
.overlay-back:before {
	font-family:'icomoon';
	font-size:1rem;
	line-height:1em;
	-webkit-transform: none;
	   -moz-transform: none;
	    -ms-transform: none;
	     -o-transform: none;
	        transform: none;
}
.overlay-back:hover {
	-webkit-transform:scale(.8);
	   -moz-transform:scale(.8);
	    -ms-transform:scale(.8);
	     -o-transform:scale(.8);
	        transform:scale(.8);
}
.overlay-back.active {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.overlay-back.active + * {
	margin-top: -3rem !important;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.overlay-back {
		left:-webkit-calc(100% - 6rem);
		left:-moz-calc(100% - 6rem);
		left:calc(100% - 6rem);
	}
}
@media (min-width:760px) {
	.overlay-back {
		left:0;
	}
}

/*****************************************/
/*****************************************/
/********** OVERLAY INTERACTIVE **********/
/*****************************************/
/*****************************************/

/* keeps page interactive == no clickoutside */

body.overlayed.interactive {
	overflow:hidden auto !important;
}
body.overlayed.interactive main {
	pointer-events:all;
}
body.overlayed.interactive .clickoutside {
	display:none;
}

/**********************************************/
/**********************************************/
/********** OVERLAY FREEZE / INSTANT **********/
/**********************************************/
/**********************************************/

/* no behaviours until overlay closed == for ex. cookies */

.overlay.freeze:after,
.overlay.instant:after {
	content:"";
	position:fixed;
	z-index:1;
	top:0;
	left:0;
	bottom:0;
	right:0;
	background:rgba(var(--dark-rgb), var(--alpha-rgb));
}
.overlay.freeze,
body.overlayed.freeze,
body.freeze.overlayed main,
body.freeze.overlayed .clickoutside {
	-webkit-user-select:none;
	   -moz-user-select:none;
	    -ms-user-select:none;
	        user-select:none;
	pointer-events:none;
}
body.freeze.overlayed .clickoutside,
body.instant.overlayed .clickoutside {
	opacity:0;
	pointer-events:none;
}

/**************************/
/**************************/
/********** FULL **********/
/**************************/
/**************************/

.overlay.full .overlay-content {
	padding:0;
}
.overlay.full .overlay-container > .close + * {
	margin-top: -webkit-calc(var(--gutter-gap) * -3) !important;
	margin-top: -moz-calc(var(--gutter-gap) * -3) !important;
	margin-top: calc(var(--gutter-gap) * -3) !important;
}

/********** OVERLAY NO-BORDER **********/

.overlay.no-border .overlay-container {
	background:none;
}

/*************************************/
/*************************************/
/********** OVERLAY CONTENT **********/
/*************************************/
/*************************************/

/********** OVERLAY TITLE **********/

.overlay-title {
	padding:0 0 0 1rem !important;
	border-left:solid .375rem var(--red-color);
}
.overlay-title[class^="icon-"]:before,
.overlay-title[class*=" icon-"]:before {
	padding-right:1rem;
}

/* in filters overlay */

.film-counter {
	font-size:2rem;
}

/********** OVERLAY SECTIONS **********/

.overlay-content section:first-child {
	padding-top:0;
}
.overlay-content section:first-child,
.overlay-content section:not(.focus):first-child + section.focus {
	margin-top:0 !important;
}

/****************************/
/****************************/
/********** COLORS **********/
/****************************/
/****************************/

.overlay input[type=submit]:hover,
.overlay button:hover {
	color:var(--red-color);
	text-shadow:none;
	background:var(--bright-color);
}
.overlay .input-container.editable input {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.overlay.freeze:after,
.overlay.instant:after,
.clickoutside {
	background:rgba(var(--black-rgb), var(--alpha-rgb));
}
.overlay > .overlay-container {
	background:rgba(var(--grey-rgb), var(--alpha-rgb));
}

/**************************************/
/**************************************/
/********** OVERLAY CENTERED **********/
/**************************************/
/**************************************/

/*
vertical centered content
cf. overlay-teaser, overlay-restrictions, overlay-gallery
*/

.overlay.vertical-centered .overlay-container {
	display:-webkit-box !important;
	display:-webkit-flex !important;
	display:-moz-box !important;
	display:-ms-flexbox !important;
	display:flex !important;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
}
.overlay.vertical-centered .overlay-container .overlay-header,
.overlay.vertical-centered .overlay-container .overlay-content,
.overlay.vertical-centered .overlay-container .overlay-footer {
	width:100%;
}
.overlay.vertical-centered .overlay-container .overlay-content {
	-webkit-box-flex:initial;
	-webkit-flex-grow:initial;
	   -moz-box-flex:initial;
	    -ms-flex-positive:initial;
	        flex-grow:initial;
	margin-top:auto;
	margin-bottom: auto;
}
.overlay.vertical-centered .overlay-title {
	text-align:left;
}

/********** OVERLAY VIDEO FULLSCREEN **********/

.overlay:not(.modal).vertical-centered .close {
	position:absolute;
	top:0;
	right:0;
	left:auto;
}
.overlay:not(.modal).vertical-centered .overlay-container .overlay-content {
	margin-top: auto !important;
}

/****************************************/
/****************************************/
/********** OVERLAY IS-ARCHIVE **********/
/****************************************/
/****************************************/
/* selection archive / cine-club archive */

.overlay.is-archive .overlay-content > *:not(section.focus) {
	padding-left: -webkit-calc(var(--gutter-gap) * 1.5);
	padding-left: -moz-calc(var(--gutter-gap) * 1.5);
	padding-left: calc(var(--gutter-gap) * 1.5);
	padding-right: -webkit-calc(var(--gutter-gap) * 1.5);
	padding-right: -moz-calc(var(--gutter-gap) * 1.5);
	padding-right: calc(var(--gutter-gap) * 1.5);
}
.overlay.is-archive .overlay-content,
.overlay.is-archive .overlay-content > section.focus {
	padding:0;
}

/********** CINE-CLUB **********/

.overlay.is-archive section.focus .film-link {
	display:block;
}
.overlay.is-archive section.focus * + .film-link {
	padding:2rem 0 0 0;
}
.overlay.is-archive section.focus .film-link .title {
	font-size:1.625rem;
	line-height:1em;
}
.overlay.is-archive section.focus .film-link .film-infos {
	padding:.5rem 0 0 0;
}

/***********************************/
/***********************************/
/********** OVERLAY INTRO **********/
/***********************************/
/***********************************/

/*
.overlay.intro == same as section.focus.front
*/

.overlay.intro {
	z-index:2000;
	background:var(--black-color);
}
.overlay.intro > .overlay-container,
.overlay.intro .overlay-content {
	height:100%;
}
.overlay.intro .overlay-content {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-box-align:end;
	-webkit-align-items:flex-end;
	   -moz-box-align:end;
	    -ms-flex-align:end;
	        align-items:flex-end;
	overflow:hidden;
	max-width: none;
}

/********** INFOS **********/

.overlay.intro .infos {
	position:relative;
	z-index:10;
	display:block;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.overlay.intro .infos > * + * {
	padding:.5rem 0 0 0;
}
.overlay.intro .infos a > * {
	display:block;
}
.overlay.intro .infos a > * + * {
	padding:.5rem 0 0 0;
}
.overlay.intro .infos a > .title {
	display:inline-block;
	border-bottom:solid var(--stroke-width) transparent;
}
.overlay.intro .infos a:hover .title {
	border-bottom:solid var(--stroke-width);
}
.overlay.intro .title {
	text-transform:none;
}
.overlay.intro .director {
	display:block;
}
.overlay.intro .is-recommended p b {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.overlay.intro .lacinetek {
	position:fixed;
	top:2rem;
	right:2rem;
	height:auto;
	width:10rem;
	padding:0;
}
.overlay.intro .buttons a {
	background:rgba(var(--black-rgb),.5);
}
.overlay.intro .buttons a.alt {
	background:var(--red-color);
}
.overlay.intro .buttons a:hover {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
	background: var(--bright-color);
}
.overlay.intro .buttons a:before {
	display:none;
}
.overlay.intro .background {
	-webkit-background-size:contain !important;
	   -moz-background-size:contain !important;
	     -o-background-size:contain !important;
	        background-size:contain !important;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.overlay.intro .infos {
		width:100%;
		margin-bottom:3.5rem;
	}
	.overlay.intro .infos > a + a {
		margin:.5rem 0 0 0;
	}
	.overlay.intro .title {
		font-size:1rem;
		line-height:1rem;
	}
	.overlay.intro .buttons {
		position:fixed;
		left:1rem;
		bottom:1rem;
		right:1rem;
		width:auto;
	}
}
@media (min-width:760px) {
	.overlay.intro .infos {
		width:auto;
		margin:0;
	}
	.overlay.intro .infos > a + a {
		margin:2rem 0 0 0;
	}
	.overlay.intro .buttons {
		position:fixed;
		top:auto;
		left:auto;
		bottom:2rem;
		right:2rem;
		width:auto;
	}
}
@media (min-width:1200px) {
	.overlay.intro .title {
		font-size:2.5rem;
		line-height:2.5rem;
	}
	.overlay.intro .is-recommended .list-link {
		font-size:1.5rem;
	}
}
@media (min-width:1400px) {
	.overlay.intro .title {
		font-size:3rem;
		line-height:3rem;
	}
}

/*******************************/
/*******************************/
/********** POSITIONS **********/
/*******************************/
/*******************************/

/*
for introduction buttons position
.is-bottom-right == default
*/

/********** @MEDIA **********/

@media (min-width:0px) {
	.overlay.intro .buttons.is-top-left,
	.overlay.intro .buttons.is-top-center,
	.overlay.intro .buttons.is-top-right {
		top:1rem;
		bottom:auto;
	}
	.overlay.intro .buttons.is-center-left,
	.overlay.intro .buttons.is-center-center,
	.overlay.intro .buttons.is-center-right {
		bottom:50%;
	}
	.overlay.intro .buttons.is-bottom-left,
	.overlay.intro .buttons.is-bottom-center,
	.overlay.intro .buttons.is-bottom-right {
		bottom:1rem;
	}
}
@media (min-width:760px) {
	.overlay.intro .buttons.is-top-left,
	.overlay.intro .buttons.is-top-center,
	.overlay.intro .buttons.is-top-right {
		top:2rem;
		bottom:auto;
	}
	.overlay.intro .buttons.is-center-left,
	.overlay.intro .buttons.is-center-center,
	.overlay.intro .buttons.is-center-right {
		bottom:50%;
	}
	.overlay.intro .buttons.is-bottom-left,
	.overlay.intro .buttons.is-bottom-center,
	.overlay.intro .buttons.is-bottom-right {
		bottom:2rem;
	}
	.overlay.intro .buttons.is-top-left,
	.overlay.intro .buttons.is-center-left,
	.overlay.intro .buttons.is-bottom-left {
		right:auto;
		left:2rem;
	}
	.overlay.intro .buttons.is-top-center,
	.overlay.intro .buttons.is-center-center,
	.overlay.intro .buttons.is-bottom-center {
		right:50%;
		-webkit-transform:translate3d(50%,0,0);
		   -moz-transform:translate3d(50%,0,0);
		        transform:translate3d(50%,0,0);
	}
	.overlay.intro .buttons.is-top-right,
	.overlay.intro .buttons.is-center-right,
	.overlay.intro .buttons.is-bottom-right {
		right:2rem;
	}
}
@media (min-width:1200px) {
	.overlay.intro .buttons.is-top-left,
	.overlay.intro .buttons.is-top-center,
	.overlay.intro .buttons.is-top-right {
		top:4rem;
	}
	.overlay.intro .buttons.is-bottom-left,
	.overlay.intro .buttons.is-bottom-center,
	.overlay.intro .buttons.is-bottom-right {
		bottom:4rem;
	}
	.overlay.intro .buttons.is-top-left,
	.overlay.intro .buttons.is-center-left,
	.overlay.intro .buttons.is-bottom-left {
		left:4rem;
	}
	.overlay.intro .buttons.is-top-right,
	.overlay.intro .buttons.is-center-right,
	.overlay.intro .buttons.is-bottom-right {
		right:4rem;
	}
}
@media (min-width:1400px) {
	.overlay.intro .buttons.is-top-left,
	.overlay.intro .buttons.is-top-center,
	.overlay.intro .buttons.is-top-right {
		top:8rem;
	}
	.overlay.intro .buttons.is-bottom-left,
	.overlay.intro .buttons.is-bottom-center,
	.overlay.intro .buttons.is-bottom-right {
		bottom:8rem;
	}
	.overlay.intro .buttons.is-top-left,
	.overlay.intro .buttons.is-center-left,
	.overlay.intro .buttons.is-bottom-left {
		left:8rem;
	}
	.overlay.intro .buttons.is-top-right,
	.overlay.intro .buttons.is-center-right,
	.overlay.intro .buttons.is-bottom-right {
		right:8rem;
	}
}

/*****************************/
/*****************************/
/********** PREVIEW **********/
/*****************************/
/*****************************/

/*
called by [data-preview-url]
[data-preview-mode]
*/

.slider.films.svod:hover,
.grid.films.svod:hover {
	z-index:150;
}
.slider.films.svod .slide:before,
.grid.films.svod .item:before {
	display:none;
}
.slider.films.svod .film-extras,
.grid.films.svod .film-extras {
	display:none;
	opacity:0;
	max-height:0;
	visibility: hidden;
}

/*************************************/
/********** PREVIEW BUTTONS **********/
/*************************************/

/* is-previewed == prevent toggling while preview is animating... */

/********** PREVIEW TOGGLE **********/

.preview-toggle {
	position:relative;
}
.preview-toggle.is-previewed {
	pointer-events: none;
}

/***********************************/
/********** SHORT PREVIEW **********/
/***********************************/
/* in position */

/********** DEFAULT **********/

.preview {
	position: absolute;
	z-index: 150;
	top:50%;
	left:50%;
	-webkit-transform: translate3d(-50%,-50%,0) scale(1);
	   -moz-transform: translate3d(-50%,-50%,0) scale(1);
	        transform: translate3d(-50%,-50%,0) scale(1);
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	height:auto;
	width:100%;
	margin:0 !important;
	pointer-events: auto;
	overflow: hidden;
	opacity:0;
}
.preview .preview-content {
	position:relative;
	height:auto;
	width: 100%;
	margin:0 auto;
	-webkit-transform: scale(1);
	   -moz-transform: scale(1);
	    -ms-transform: scale(1);
	     -o-transform: scale(1);
	        transform: scale(1);
	background:var(--grey-color);
}
.preview .film-extras {
	display:block !important;
	overflow: hidden;
	max-height:0;
	visibility: visible;
	opacity:0;
	pointer-events: none;
}

/********** COLORS **********/

section.grey .preview .preview-content {
	background:var(--dark-color);
}

/********** ACTIVE **********/

.preview.active {
	-webkit-transform: translate3d(-50%,-50%,0) scale(1.2);
	   -moz-transform: translate3d(-50%,-50%,0) scale(1.2);
	        transform: translate3d(-50%,-50%,0) scale(1.2);
	opacity:1;
}
.preview.active .preview-content {
	-webkit-transform: scale(.9);
	   -moz-transform: scale(.9);
	    -ms-transform: scale(.9);
	     -o-transform: scale(.9);
	        transform: scale(.9);
}
.preview.active:not(.modal) .film-extras {
	opacity:1;
	max-height:100rem; /* JS ? */
	visibility: visible;
	pointer-events: auto;
}

/********** ANIMATIONS **********/
/*
in	= active
out	= default
*/
.preview.active {
	-webkit-transition:
	opacity  var(--duration-fastest) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease);
	transition:
	opacity  var(--duration-fastest) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease);
	-o-transition:
	opacity  var(--duration-fastest) var(--ease),
	-o-transform var(--duration-normal) var(--ease);
	-moz-transition:
	opacity  var(--duration-fastest) var(--ease),
	transform var(--duration-normal) var(--ease),
	-moz-transform var(--duration-normal) var(--ease);
	transition:
	opacity  var(--duration-fastest) var(--ease),
	transform var(--duration-normal) var(--ease);
	transition:
	opacity  var(--duration-fastest) var(--ease),
	transform var(--duration-normal) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease),
	-moz-transform var(--duration-normal) var(--ease),
	-o-transform var(--duration-normal) var(--ease);
}
.preview.active .preview-content {
	-webkit-transition:
	opacity var(--duration-normal) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease);
	-o-transition:
	opacity var(--duration-normal) var(--ease),
	-o-transform var(--duration-normal) var(--ease);
	-moz-transition:
	opacity var(--duration-normal) var(--ease),
	transform var(--duration-normal) var(--ease),
	-moz-transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease),
	transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease),
	transform var(--duration-normal) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease),
	-moz-transform var(--duration-normal) var(--ease),
	-o-transform var(--duration-normal) var(--ease);
}
.preview.active .film-extras {
	-webkit-transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-normal) var(--ease);
	-o-transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-normal) var(--ease);
	-moz-transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-normal) var(--ease);
}
.preview {
	-webkit-transition:
	opacity  var(--duration-fastest) var(--ease) -webkit-calc(var(--duration-fast) - var(--duration-fastest)),
	-webkit-transform var(--duration-fast) var(--ease);
	transition:
	opacity  var(--duration-fastest) var(--ease) calc(var(--duration-fast) - var(--duration-fastest)),
	-webkit-transform var(--duration-fast) var(--ease);
	-o-transition:
	opacity  var(--duration-fastest) var(--ease) calc(var(--duration-fast) - var(--duration-fastest)),
	-o-transform var(--duration-fast) var(--ease);
	-moz-transition:
	opacity  var(--duration-fastest) var(--ease) -moz-calc(var(--duration-fast) - var(--duration-fastest)),
	transform var(--duration-fast) var(--ease),
	-moz-transform var(--duration-fast) var(--ease);
	transition:
	opacity  var(--duration-fastest) var(--ease) calc(var(--duration-fast) - var(--duration-fastest)),
	transform var(--duration-fast) var(--ease);
	transition:
	opacity  var(--duration-fastest) var(--ease) calc(var(--duration-fast) - var(--duration-fastest)),
	transform var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--ease),
	-moz-transform var(--duration-fast) var(--ease),
	-o-transform var(--duration-fast) var(--ease);
}
.preview .preview-content {
	-webkit-transition:
	opacity var(--duration-fastest) var(--ease),
	-webkit-transform var(--duration-fastest) var(--ease);
	transition:
	opacity var(--duration-fastest) var(--ease),
	-webkit-transform var(--duration-fastest) var(--ease);
	-o-transition:
	opacity var(--duration-fastest) var(--ease),
	-o-transform var(--duration-fastest) var(--ease);
	-moz-transition:
	opacity var(--duration-fastest) var(--ease),
	transform var(--duration-fastest) var(--ease),
	-moz-transform var(--duration-fastest) var(--ease);
	transition:
	opacity var(--duration-fastest) var(--ease),
	transform var(--duration-fastest) var(--ease);
	transition:
	opacity var(--duration-fastest) var(--ease),
	transform var(--duration-fastest) var(--ease),
	-webkit-transform var(--duration-fastest) var(--ease),
	-moz-transform var(--duration-fastest) var(--ease),
	-o-transform var(--duration-fastest) var(--ease);
}
.preview .film-extras {
	-webkit-transition:
	opacity var(--duration-fastest) var(--ease),
	max-height var(--duration-fastest) var(--ease);
	-o-transition:
	opacity var(--duration-fastest) var(--ease),
	max-height var(--duration-fastest) var(--ease);
	-moz-transition:
	opacity var(--duration-fastest) var(--ease),
	max-height var(--duration-fastest) var(--ease);
	transition:
	opacity var(--duration-fastest) var(--ease),
	max-height var(--duration-fastest) var(--ease);
}


/***********************************/
/********** PREVIEW MODAL **********/
/***********************************/

/*
in #previews
from previous preview position to...
under overlay z-index
preview > preview-container > preview-content

expanded	= for after positioning
active		= for displaying full content
*/

/* positions are done in JS */

/********** MODAL / DEFAULT **********/

.preview.modal {
	position:fixed;
	z-index: 1000;
	top:0%;
	left:50%;
	opacity:1;
	max-height:100%;
	overflow: hidden;
	-webkit-transform: translate3d(-50%,0,0) scale(1.2);
	   -moz-transform: translate3d(-50%,0,0) scale(1.2);
	        transform: translate3d(-50%,0,0) scale(1.2);
	cursor:pointer;
}
.preview.modal .preview-container {
	width:100%;
	margin:auto;
	max-width: -webkit-calc(var(--max-width) * 4 / 5);
	max-width: -moz-calc(var(--max-width) * 4 / 5);
	max-width: calc(var(--max-width) * 4 / 5);
	cursor:auto;
}
.preview.modal .preview-content {
	margin:0 auto;
	-webkit-transform: scale(.9);
	   -moz-transform: scale(.9);
	    -ms-transform: scale(.9);
	     -o-transform: scale(.9);
	        transform: scale(.9);
}
.preview.modal section.focus .infos {
	position:absolute;
	bottom:0;
	left:0;
	max-width: none;
	opacity:0;
}
.preview.modal section.focus:after {
	opacity:0;
}
.preview.modal nav.preview-actions {
	height:0;
	opacity:0;
	pointer-events: none;
}
.preview.modal .preview-content > .film-extras {
	display:block;
	overflow: hidden;
	opacity:0;
	max-height:0;
	margin-top:0;
}

/********** ACTIVE **********/

.preview.modal.active {
	-webkit-transform: translate3d(-50%,0,0) scale(1);
	   -moz-transform: translate3d(-50%,0,0) scale(1);
	        transform: translate3d(-50%,0,0) scale(1);
	opacity: 1;
}
.preview.modal.active .preview-content {
	-webkit-transform:scale(1);
	   -moz-transform:scale(1);
	    -ms-transform:scale(1);
	     -o-transform:scale(1);
	        transform:scale(1);
}

/********** MODAL / EXPANDED **********/

.preview.modal.active.expanded {
	height:100%;
	overflow: auto;
}
.preview.modal.expanded .preview-content .infos {
	opacity:1;
}
.preview.modal.expanded section.focus .infos,
.preview.modal.expanded section.focus:after {
	opacity:1;
}
.preview.modal.active.expanded .preview-reduce {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	visibility: visible;
	pointer-events: auto;
}
.preview.modal.active.expanded nav.preview-actions {
	height: auto;
	opacity:1;
	pointer-events: auto;
}
.preview.modal.active.expanded .preview-content > .film-extras {
	display:block;
	opacity:1;
	max-height:1000rem; /* JS !!! */
	pointer-events: auto;
}

/********** PREVIEW REDUCE **********/

.preview-reduce {
	display:none;
	visibility: hidden;
	pointer-events: none;
}
.preview.modal.active.expanded .preview-reduce {
	position:-webkit-sticky;
	position:sticky;
	z-index:500;
	top:0;
	left:100%;
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	width:3rem;
	font-size:0;
	line-height:0;
	border:0;
	padding:1rem;
	margin:0;
}
.preview.modal.active.expanded .preview-reduce:before {
	font-family:'icomoon';
	font-size:1rem;
	line-height:1rem;
}
.preview.modal.active.expanded .preview-reduce + * {
	margin-top:-3rem;
}

/********** MODAL / STANDALONE **********/
/* not from .preview-toggle */


.preview.modal.standalone {
	opacity: 0;
	-webkit-transform: translate3d(-50%,0,0) scale(1);
	   -moz-transform: translate3d(-50%,0,0) scale(1);
	        transform: translate3d(-50%,0,0) scale(1);
}
.preview.modal.standalone.active.expanded {
	opacity: 1;
}

/********** MODAL / ANIMATIONS **********/

.preview.modal.active {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease),
	height var(--duration-fast) var(--ease),
	width var(--duration-normal) var(--ease),
	top var(--duration-normal) var(--ease),
	left var(--duration-normal) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	height var(--duration-fast) var(--ease),
	width var(--duration-normal) var(--ease),
	top var(--duration-normal) var(--ease),
	left var(--duration-normal) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease);
	-o-transition:
	opacity var(--duration-fast) var(--ease),
	height var(--duration-fast) var(--ease),
	width var(--duration-normal) var(--ease),
	top var(--duration-normal) var(--ease),
	left var(--duration-normal) var(--ease),
	-o-transform var(--duration-normal) var(--ease);
	-moz-transition:
	opacity var(--duration-fast) var(--ease),
	height var(--duration-fast) var(--ease),
	width var(--duration-normal) var(--ease),
	top var(--duration-normal) var(--ease),
	left var(--duration-normal) var(--ease),
	transform var(--duration-normal) var(--ease),
	-moz-transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	height var(--duration-fast) var(--ease),
	width var(--duration-normal) var(--ease),
	top var(--duration-normal) var(--ease),
	left var(--duration-normal) var(--ease),
	transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	height var(--duration-fast) var(--ease),
	width var(--duration-normal) var(--ease),
	top var(--duration-normal) var(--ease),
	left var(--duration-normal) var(--ease),
	transform var(--duration-normal) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease),
	-moz-transform var(--duration-normal) var(--ease),
	-o-transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-normal) var(--ease);
}
.preview.modal.active .preview-container {
	-webkit-transition:
	height var(--duration-normal) var(--ease),
	width var(--duration-normal) var(--ease);
	-o-transition:
	height var(--duration-normal) var(--ease),
	width var(--duration-normal) var(--ease);
	-moz-transition:
	height var(--duration-normal) var(--ease),
	width var(--duration-normal) var(--ease);
	transition:
	height var(--duration-normal) var(--ease),
	width var(--duration-normal) var(--ease);
}
.preview.modal section.focus .infos,
.preview.modal section.focus:after {
	-webkit-transition:
	opacity var(--duration-normal) var(--ease);
	-o-transition:
	opacity var(--duration-normal) var(--ease);
	-moz-transition:
	opacity var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease);
}
.preview.modal.active .film-extras {
	-webkit-transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-normal) var(--ease);
	-o-transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-normal) var(--ease);
	-moz-transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-normal) var(--ease);
	-webkit-transition:
	opacity var(--duration-normal) var(--ease);
	-o-transition:
	opacity var(--duration-normal) var(--ease);
	-moz-transition:
	opacity var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease);
}
.preview.modal.active:after {
	-webkit-transition:
	opacity var(--duration-normal) var(--ease);
	-o-transition:
	opacity var(--duration-normal) var(--ease);
	-moz-transition:
	opacity var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease);
}

/* out */

.preview.modal.active.expanded .film-extras {
	-webkit-transition:
	opacity var(--duration-normal) var(--ease);
	-o-transition:
	opacity var(--duration-normal) var(--ease);
	-moz-transition:
	opacity var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease);
}
.preview.modal.active .film-extras {
	-webkit-transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-fast) var(--ease);
	-o-transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-fast) var(--ease);
	-moz-transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-fast) var(--ease);
	transition:
	opacity var(--duration-normal) var(--ease),
	max-height var(--duration-fast) var(--ease);
}

/* standalone */

.preview.modal.standalone {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease);
	-o-transition:
	opacity var(--duration-fast) var(--ease),
	-o-transform var(--duration-normal) var(--ease);
	-moz-transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-normal) var(--ease),
	-moz-transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-normal) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-normal) var(--ease),
	-webkit-transform var(--duration-normal) var(--ease),
	-moz-transform var(--duration-normal) var(--ease),
	-o-transform var(--duration-normal) var(--ease);
}

/****************************/
/********** @MEDIA **********/
/****************************/

@media (min-width:0px) {
	.preview .synopsis {
		display:none;
	}
}
@media (min-width:1000px) {
	.preview .synopsis {
		display:block;
	}
}

/************************************/
/********** BODY PREVIEWED **********/
/************************************/

body.previewed.has-modal {
	overflow:hidden;
}
body.previewed.has-modal .preview:not(.modal) {
	opacity:0;
	-webkit-transition: none;
	-o-transition: none;
	-moz-transition: none;
	transition: none;
}

/********** PREVIEW CLICKOUTSIDE **********/

body.previewed.has-modal #overlays .clickoutside {
	opacity:var(--alpha-rgb);
	pointer-events:auto;
}

/**********************************/
/**********************************/
/********** PREVIEW FILM **********/
/**********************************/
/**********************************/

/********** DEFAULT **********/

.preview.film .infos .year:before,
.preview.film .infos .country:before,
.preview.film .infos .duration:before {
	font-family:"icomoon";
	display:inline-block;
    font-size: .75em;
    padding-right: 0.75em;
}
/*
.preview.film .infos > * + * {
	padding-right:.75em;
}
*/
.preview.film .director {
	display:block;
	margin-top:.5em;
}
.preview.film .film-extras {
	margin-top:.75em;
}
.preview.film * + .director,
.preview.film .director + * {
	padding-left:0;
}
.preview.film .synopsis {
	color:var(--bright-color);
	text-shadow: 0 0 0 var(--bright-color);
	font-size:.875em;
}

/********** MODAL **********/

.preview.modal .preview-container.is-loading:before {
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform: translate3d(-50%,-50%,0);
	   -moz-transform: translate3d(-50%,-50%,0);
	        transform: translate3d(-50%,-50%,0);
}
.preview.modal section.focus {
	margin-top:0;
	margin-bottom:0;
}
.preview.modal .film-title {
	text-transform: none;
}
.preview.modal .director {
	margin-bottom:.5em;
}
.preview.modal .year,
.preview.modal .country,
.preview.modal .duration {
	margin:0 1em .5em 0;
}
.preview.modal .preview-content .film-extras > .infos .film-title + * {
	margin-top:.5em;
}
.preview.modal .preview-content .film-extras {
	padding-left:-webkit-calc(var(--gutter-gap) * 1.5);
	padding-left:-moz-calc(var(--gutter-gap) * 1.5);
	padding-left:calc(var(--gutter-gap) * 1.5);
	padding-right:-webkit-calc(var(--gutter-gap) * 1.5);
	padding-right:-moz-calc(var(--gutter-gap) * 1.5);
	padding-right:calc(var(--gutter-gap) * 1.5);
}
.preview.modal .preview-content * + .film-extras {
	margin-top:var(--gutter-gap);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.preview.film.modal .film-title {
		font-size:1.5rem;
	}
}
@media (min-width:1000px) {
	.preview.film.modal .film-title {
		font-size:2.5rem;
	}
}

@media (max-width:1000px) {
	.preview.film nav.preview-actions {
		margin-top:0;
	}
}

/**************************************/
/**************************************/
/********** HAS STICKY ASIDE **********/
/**************************************/
/**************************************/

/* is-sticky (eg. #overlay-filters-films) */

/********** DEFAULT **********/

.overlay .overlay-content.has-sticky-aside {
	overflow-x:visible !important;
	margin:0;
}

/********** IS STICKY INFOS **********/

.has-sticky-infos .is-sticky-infos {
	position:fixed;
	z-index:100;
	font-size:.875rem;
	line-height:normal;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:none;
	background:var(--dark-color);
	border:solid var(--stroke-width);
	opacity:0;
	overflow:auto;
	height:auto;
	visibility: hidden;

}
.has-sticky-infos a:hover > .is-sticky-infos {
	opacity:1;
	visibility: visible;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.has-sticky-infos .is-sticky-infos {
		display:none;
	}
}
@media (min-width:1000px) {
	.overlay .overlay-content.has-sticky-aside {
		width:70vw;
		padding-right:0 !important;
	}
	.overlay .overlay-content.has-sticky-aside .columns,
	.overlay .overlay-content.has-sticky-aside .columns > * {
		margin:0;
	}
	.overlay-content.has-sticky-aside .overlay-title + .columns {
		margin-top:2rem !important;
	}
	.has-sticky-infos .is-sticky-infos {
		display:block;
		padding:2rem;
		top:2rem;
		right:2rem;
		bottom:2rem;
		left:70vw;
		width:-webkit-calc(30vw - 4rem);
		width:-moz-calc(30vw - 4rem);
		width:calc(30vw - 4rem);
	}
	.has-sticky-infos .filters-tags > a:after {
		content:"";
		position:absolute;
		z-index:110;
		top:0;
		left:0;
		height:100%;
		width:-webkit-calc(100% - var(--stroke-width));
		width:-moz-calc(100% - var(--stroke-width));
		width:calc(100% - var(--stroke-width));
		border:solid var(--stroke-width) var(--bright-color);
		border-right:solid var(--stroke-width) var(--dark-color);
		opacity:0;
	}
	.has-sticky-infos .filters-tags > a:hover:after {
		opacity:1;
	}
}
@media (min-width:1200px) {
	.overlay .overlay-content.has-sticky-aside {
		width:50vw;
	}
	.has-sticky-infos .is-sticky-infos {
		padding:4rem;
		top:4rem;
		right:4rem;
		bottom:4rem;
		left:50vw;
		width:-webkit-calc(50vw - 4rem);
		width:-moz-calc(50vw - 4rem);
		width:calc(50vw - 4rem);
	}
}
