@charset "UTF-8";

/******************************/
/******************************/
/********** VERSIONS **********/
/******************************/
/******************************/

/*
website alterations...
no SVOD
abroad
*/

/*******************************/
/********** SVOD SOON **********/
/*******************************/

#primary [data-soon] a {
	opacity:.5 !important;
	pointer-events:none !important;
}
#primary [data-soon]:before {
	content:attr(data-soon);
	position:absolute;
	right:0;
	top:0;
	display:inline-block;
	text-transform:uppercase;
	white-space:nowrap;
	font-size:.625rem;
    color:var(--yellow-color);
    text-shadow:0 0 0 var(--yellow-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	#primary [data-soon]:before {
		right:1rem;
		-webkit-transform:translate3d(0,-20%,0);
		   -moz-transform:translate3d(0,-20%,0);
		        transform:translate3d(0,-20%,0);
	}
}
@media (min-width:1000px) {
	#primary [data-soon]:before {
		right:.5rem;
		-webkit-transform:translate3d(0,60%,0);
		   -moz-transform:translate3d(0,60%,0);
		        transform:translate3d(0,60%,0);
	}
}
@media (min-width:1200px) {
	#primary [data-soon]:before {
		right:.75rem;
		-webkit-transform:translate3d(0,80%,0);
		   -moz-transform:translate3d(0,80%,0);
		        transform:translate3d(0,80%,0);
	}
}
@media (min-width:1400px) {
	#primary [data-soon]:before {
		right:1rem;
		-webkit-transform:translate3d(0,110%,0);
		   -moz-transform:translate3d(0,110%,0);
		        transform:translate3d(0,110%,0);
	}
}
@media (min-width:1500px) {
	#primary [data-soon]:before {
		-webkit-transform:translate3d(0,130%,0);
		   -moz-transform:translate3d(0,130%,0);
		        transform:translate3d(0,130%,0);
	}
}

/*******************************/
/********** IS ABROAD **********/
/*******************************/

/* films as unavailable not in searchbar */

body.is-abroad main .films .cover,
body.is-abroad main .films .infos > *,
body.is-abroad main .films .is-recommended {
	opacity:.5;
}
.country-available {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
}

/***************************/
/***************************/
/********** PRINT **********/
/***************************/
/***************************/

/*
for playlists == print.php
*/

body.print {
	zoom:1;
	color:var(--dark-color);
	text-shadow:var(--dark-color);
	background:var(--bright-color);
	width: 210mm;
	min-height: 297mm;
	padding:1cm;
	display: block;
	margin: 0 auto;
	max-width:none;
}
body.print main {
	padding:0;
}

/********** LIST FILMS **********/

body.print .list.films {
	pointer-events:none;
}
body.print .list.films .item {
	page-break-inside: avoid;
}
body.print .list.films .item-content {
	padding:.5cm;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	-webkit-flex-wrap:unset;
	    -ms-flex-wrap:unset;
	        flex-wrap:unset;
}
body.print .list.films .item-content + .item-content {
	padding-top:0 !important;
}
body.print .list.films .item-content + .item-content .comments {
	margin-top:0 !important;
}
body.print .list.films.playlist .cover {
	-webkit-box-flex: unset;
	-webkit-flex-grow: unset;
	   -moz-box-flex: unset;
	    -ms-flex-positive: unset;
	        flex-grow: unset;
	-webkit-flex-basis:3.5cm;
	    -ms-flex-preferred-size:3.5cm;
	        flex-basis:3.5cm;
	margin:0 .5cm 0 0;
}
body.print .list.films.playlist .title,
body.print .list.films.playlist b,
body.print .list.films.playlist .director,
body.print .list.films.playlist .comments {
    color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
}

/********** TITLE-HEADING **********/

body.print .title-heading {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	gap:.25cm;
}
body.print .title-heading > * {
	margin:0;
}
body.print .title-heading svg {
	width:4cm;
}
body.print .title-heading svg + * {
	margin-left:auto;
}
body.print .title-heading + * {
	margin-top:.25cm;
}

/********** FONT SIZES **********/

body.print {
	font-size:8pt;
}
body.print .title-heading h1 {
	font-size:12pt;
}
body.print .list.films .title {
	font-size:10pt;
}
body.print .list.films .original,
body.print .list.films.has-comments .film-infos,
body.print .list.films.has-comments .is-recommended,
body.print .list.films.has-comments .comments {
	font-size:8pt;
}

/***************************/
/***************************/
/********** STYLE **********/
/***************************/
/***************************/

* {
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}
html {
	font-size:100%;
}
html,
input,
textarea,
select,
button {
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	-webkit-text-size-adjust:100%;
}
h1, h2, h3, h4, h5, h6,
ul, ol, p, a, span, b, strong, blockquote, label, div, table,
input, textarea, button, select {
	margin:0;
	padding:0;
	font-weight:normal;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight:normal;
	text-transform:uppercase;
}
p {
	font-size:inherit;
	line-height:inherit;
	color:inherit;
	text-shadow:inherit;
}
b, strong, del,
i, em {
	font-weight:normal;
	font-size:inherit;
	line-height:inherit;
}

/********** HR **********/

hr {
	width:100%;
	border:0;
	border-bottom:solid var(--stroke-width) var(--bright-color);
}
hr.blank {
	border:0 !important;
}

/********** IFRAME **********/

iframe {
	max-width:100%;
	border:none;
}

/********** FIGURE **********/

figure {
	position:relative;
	display:block;
	height:auto;
	max-width:100%;
	margin:0;
	border:0;
}
img,
.img {
	display:block;
	height:auto;
	max-width:100%;
	margin:0 auto;
	border:0;
}

/********** SVG **********/

svg {
	display:block;
	-webkit-transform:translate3d(0,0,0);
	   -moz-transform:translate3d(0,0,0);
	        transform:translate3d(0,0,0);
	-webkit-backface-visibility:hidden;
	   -moz-backface-visibility:hidden;
	        backface-visibility:hidden;
}
figure > svg {
	display:block;
	width:100%;
	margin:0 auto;
}

/********** @MEDIA **********/

@media screen and (max-device-width:320px) {
	body {
		-webkit-text-size-adjust:none;
	}
}

/********************************/
/********************************/
/********** RESPONSIVE **********/
/********************************/
/********************************/

@media (min-width:760px) {
	.only-mobile {
		visibility:hidden;
		display:none !important;
	}
}
@media (max-width:760px) {
	.only-desktop {
		visibility:hidden;
		display:none !important;
	}
}

/****************************/
/****************************/
/********** CUSTOM **********/
/****************************/
/****************************/

/*
various custom classes
.nowrap == for titles in login / for button in add-to-cart
*/

.hidden,
.hidden > * {
	position:absolute;
	font-size:0 !important;
	padding:0 !important;
}
.disabled {
	opacity:.25;
	pointer-events:none;
}
.centered {
	text-align:center;
}
.align-right {
	text-align:right;
}
.is-last {
	margin-left:auto !important;
}
.nowrap {
	white-space:nowrap;
}
.stroked {
	text-decoration: line-through;
}

/*****************************/
/*****************************/
/********** UL / OL **********/
/*****************************/
/*****************************/

ul,
ol {
	list-style-position:inside;
}
li {
	list-style-type: disc;
}

/********** OL **********/

ol {
	margin:0;
	list-style-type:none;
}
ol li {
	position:relative;
	display:block;
	counter-increment:step-counter;
	padding:0 0 0 1.375em !important;
}
ol li:before {
	position:absolute;
	z-index:10;
	top:0;
	left:0;
	content:counter(step-counter);
	display:block;
	text-align:center;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	font-size:.75em;
	line-height:inherit;
	padding:.25em 0;
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
}

/********** BULLET LIST **********/

.bullet li {
	position:relative;
	display:block;
	width:auto !important;
	padding:.25em 0 .25em 1.375em;
}
.bullet li:before {
	position:absolute;
	top:50%;
	color:var(--bright-color);
	background:var(--bright-color);
	margin:-.25em 0 0 -1.375em;
	content:"";
	display:block;
	height:.5em;
	width:.5em;
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
}

/******************************/
/******************************/
/********** COLUMNIZER ********/
/******************************/
/******************************/

/*
.columnizer > p | ul | ol | etc.
.columnizer > .break		== new column
.columnizer[data-columns]	== 2* / 3 / 4 (*2 == default)
*/

/********** DEFAULT **********/

.columnizer .break {
	-webkit-column-break-before:always;
	   -moz-column-break-before:always;
	        break-before:always;
	padding-top:0;
}

/********** @MEDIA **********/

@media (min-width:760px) {
	.columnizer {
		-webkit-column-count:2;
		   -moz-column-count:2;
		        column-count:2;
		-webkit-column-gap:var(--gutter-gap);
		   -moz-column-gap:var(--gutter-gap);
		        column-gap:var(--gutter-gap);
	}
}
@media (min-width:1000px) {
	.columnizer[data-columns="3"] {
		-webkit-column-count:3;
		   -moz-column-count:3;
		        column-count:3;
	}
	.columnizer[data-columns="4"] {
		-webkit-column-count:4;
		   -moz-column-count:4;
		        column-count:4;
	}
}

/********************************/
/********************************/
/********** BLOCKQUOTE **********/
/********************************/
/********************************/

/*
blockquote
blockquote.large
.quote			== default > blockquote + .source
.quote.large	== eg. in single selection
*/

/********** BLOCKQUOTE / DEFAULT **********/

blockquote {
	position:relative;
}
blockquote:before,
blockquote:after {
	display:inline;
	font-family:serif;
	font-size:1.5em;
	line-height:1em;
}
blockquote p {
	display:inline;
}
blockquote:before {
	text-align:left;
	content:"«";
	margin:0 .5em 0 0;
}
blockquote:after {
	text-align:right;
	content:"»";
	margin:0 0 0 .5em;
}

/********** QUOTE **********/

.quote {
	position:relative;
}
.quote .source {
	display:block;
	text-align:right;
	font-size:inherit;
	margin:0 !important;
}
.quote.centered {
	display:inline-block;
	left:50%;
	-webkit-transform:translate3d(-50%,0,0);
	   -moz-transform:translate3d(-50%,0,0);
	        transform:translate3d(-50%,0,0);
}

/********** QUOTE WITH BLOCKQUOTE **********/

/*
=> if aligned to ""
.quote blockquote + .source {
	padding:1rem 1.5em 0 1.5em;
}
*/
.quote blockquote + .source {
	padding-top:1rem;
}
.quote.large blockquote {
	text-align:center;
}

/********** @MEDIA **********/

@media (min-width:1000px) {
	.quote.large,
	blockquote.large {
		font-size:1.5rem;
	}
	.quote .source {
		font-size:.875rem;
	}
}

/******************************/
/******************************/
/********** ELLIPSIS **********/
/******************************/
/******************************/
/* WIP !!! */

.ellipsis {
	overflow:hidden;
	max-height:5rem; /* TEMP */
}
.ellipsis.expanded {
	max-height:100rem;
}
.is-truncated p {
	padding:0;
}
.ellipsis .expand {
	position:absolute;
	bottom:0;
	left:50%;
	-webkit-transform:translate3d(-50%,0,0);
	   -moz-transform:translate3d(-50%,0,0);
	        transform:translate3d(-50%,0,0);
}

/****************************/
/****************************/
/********** TITLES **********/
/****************************/
/****************************/

/*
.title
.title-alt
.section-title
.section-title.splitted
.selection-title
.cine-club-title == type [V5]
.list-title (list director / discover)
*/

/***************************/
/********** TITLE **********/
/***************************/

.title,
.title-alt {
	display:block;
	text-transform:none;
}
a.title {
	border-bottom:solid var(--stroke-width) transparent;
}
a.title:hover {
	border-bottom:solid var(--stroke-width);
}

/* need to put text in span for ellipsis */

.grid.films.archive .title {
	display:inline-block !important;
}

/********************************/
/********** MAIN-TITLE **********/
/********************************/

.main-title {
	text-transform:uppercase;
}
.main-title strong {
	color:var(--red-color);
	text-shadow:none;
}

/********** AS NAV **********/

nav.main-title > a,
nav.main-title:hover > a.active {
	opacity:.25;
}
nav.main-title > a:hover,
nav.main-title > a.active,
nav.main-title:hover > a.active:hover {
	opacity:1;
}

/***********************************/
/********** SECTION-TITLE **********/
/***********************************/

.section-title {
	padding:0 0 0 1rem !important;
	border-left:solid var(--stroke-width-large) var(--red-color);
}
.section-title:before,
.section-title > h2:before {
	font-family:'icomoon';
	font-size:inherit;
	line-height:inherit;
	padding-right:1rem;
}
.red .section-title {
	border-left:solid var(--stroke-width-large) var(--grey-color);
}

/********** SECTION-TITLE SPLITTED **********/

/* section-title with h2 + action == cf. single-selection.php */

.section-title.splitted {
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.section-title.splitted > h2:before {
	font-family:'icomoon';
	font-size:inherit;
	line-height:inherit;
	padding-right:1rem;
}
.section-title.splitted > p {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:end;
	-webkit-justify-content:flex-end;
	   -moz-box-pack:end;
	    -ms-flex-pack:end;
	        justify-content:flex-end;
}

/*  for film pack */

section.block .section-title.splitted {
	border:none;
	padding-left:0 !important;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.section-title.splitted > p {
		font-size:.875rem;
		padding:0;
	}
	.section-title.splitted > p > .button {
		margin:0 0 0 var(--gutter-gap);
	}
	.section-title.splitted * + * {
		margin:var(--gutter-gap) 0 0 0;
	}
}
@media (min-width:640px) {
	.section-title.splitted {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.section-title.splitted > p {
		font-size:.875rem;
	}
	.section-title.splitted > p > .button,
	.section-title.splitted * + * {
		margin:0 0 0 var(--gutter-gap);
	}
}
@media (min-width:760px) {
	.section-title.splitted > p {
		font-size:1rem;
	}
}

/*************************************/
/********** SELECTION-TITLE **********/
/*************************************/

/* single-selection.php */

.selection-title span {
	font-size:1.25rem;
}
.selection-title strong {
	display:block;
	padding-top:0;
}

/********************************/
/********** LIST-TITLE **********/
/********************************/

/* single-director.php / single-discover.php */

.list-title {
	padding-left:-webkit-calc(var(--gutter-gap) * 2);
	padding-left:-moz-calc(var(--gutter-gap) * 2);
	padding-left:calc(var(--gutter-gap) * 2);
}

/*************************************/
/********** CINE-CLUB-TITLE **********/
/*************************************/

/* single-cine-club.php : slider + overlay */

.cine-club-title {
	color:var(--red-color);
	text-shadow:none;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.grid .item .cine-club-title {
	position:absolute;
	top:-1rem;
	left:1rem;
	-webkit-transform:translate3d(0,-100%,0);
	   -moz-transform:translate3d(0,-100%,0);
	        transform:translate3d(0,-100%,0);
}

/***************************/
/***************************/
/********** ALERT **********/
/***************************/
/***************************/

/*
.alert
.alert.short
.alert.restored
.alert.restored.small
.alert.block == inverted
*/

/********** DEFAULT **********/

.alert {
	display:inline-block;
	line-height:1em;
	text-transform:uppercase;
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	border:solid var(--stroke-width) var(--yellow-color);
}
a.alert:hover {
	color:var(--dark-color);
	text-shadow:none;
	border:solid var(--stroke-width) transparent;
	background:var(--yellow-color);
}

/********** SHORT **********/
/* cf. institutions */

.alert.short {
	border:0;
	padding:0 !important;
}

/********** BLOCK **********/
/* cf. offers */

.alert.block {
	color:var(--dark-color);
	text-shadow: 0 0 0 var(--dark-color);
	background:var(--yellow-color);
}

/********** RESTORED **********/

/* in single film */
.alert.restored {
	-webkit-border-radius:-webkit-calc(var(--border-radius) * 2);
	   -moz-border-radius:-moz-calc(var(--border-radius) * 2);
	        border-radius:calc(var(--border-radius) * 2); /* V5 */
}
/* in svod preview modal */
.alert.restored.small {
	font-size:.75rem;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.alert {
		font-size:.625rem;
		padding:1em !important;
	}
}
@media (min-width:760px) {
	.alert {
		font-size:.75rem;
	}
	.alert.large {
		font-size:1rem;
	}
}
@media (min-width:1400px) {
	.alert {
		font-size:1rem;
	}
	.alert.large {
		font-size:1.25rem;
	}
}

/**********************************/
/**********************************/
/********** INTRODUCTION **********/
/**********************************/
/**********************************/

/*
in "trésors cachés"		== default
in overlay-login-svod	== normal
*/

.introduction {
	text-transform:uppercase;
}
.introduction.normal {
	text-transform:none;
}
.introduction strong {
	color:var(--red-color);
	text-shadow:none;
}
.introduction .align-right {
	text-align:right;
}

/*****************************/
/*****************************/
/********** CREDITS **********/
/*****************************/
/*****************************/

.credits {
	font-size:.75em !important;
}
.credits > * {
	font-size:inherit !important;
}
.website-credits {
	text-align:center;
}

/**************************/
/**************************/
/********** CART **********/
/**************************/
/**************************/

/*
.notification == for "mon panier"
.cart[data-cart-count]
*/

/********** PRICE **********/

.price {
	display:inline-block;
	font-size:1.25rem;
	line-height:1em;
}

/********** NOTIFICATION / DATA-CART-COUNT **********/

[data-cart-count] {
	position:relative;
}
[data-cart-count]:after {
	content: attr(data-cart-count);
}
[data-cart-count] .notification {
	display:none;
}
.notification,
[data-cart-count]:after {
	position:absolute;
	bottom:0;
	right:0;
	display:block;
	font-size:.75rem;
	line-height:1em;
	text-align:center;
	height:1em;
	width:2em;
	padding:.5em 0 !important;
	margin:-.5em;
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
	background:var(--red-color);
}

/******************************/
/******************************/
/********** NOTATION **********/
/******************************/
/******************************/

/*
in single film / single cine-club
stars in fonts.css
*/

.notation {
	position:relative;
	display:inline-block;
	font-size:0 !important;
}
.notation:before,
.notation:after {
	font-family:'icomoon';
	display:block;
	font-size:1rem;
	line-height:1em;
	letter-spacing:.25em;
}
.notation:before {
	position:absolute;
	z-index:10;
	top:0;
	left:0;
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
}
.notation:after {
	opacity:.25;
}

/**************************************/
/**************************************/
/********** IN-COLLABORATION **********/
/**************************************/
/**************************************/

/*
in single cine-club / svod...
*/

.in-collaboration {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.in-collaboration > * {
	display:inline-block;
	color:rgb(101,101,110);
	text-shadow:0 0 0 rgb(101,101,110);
}
.in-collaboration > * + * {
	margin:0 0 0 var(--gutter-gap);
}
.in-collaboration figure {
	max-width:8rem;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.in-collaboration p {
		display:block;
	}
}
@media (min-width:480px) {
	.in-collaboration p {
		display:inline-block;
	}
}

/*****************************/
/*****************************/
/********** CASTING **********/
/*****************************/
/*****************************/

/*
in single film / single cine-club
*/

.casting {
	color:var(--light-grey);
	text-shadow:0 0 0 var(--light-grey);
}
.casting h2,
.casting span {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}

/********** FESTIVAL **********/

.festival-date,
.festival-date strong {
	text-transform:uppercase;
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
}

/************************************/
/************************************/
/********** IS RECOMMENDED **********/
/************************************/
/************************************/

/*
with avatar == default
in .films
in section.focus
in section.short
*/

/********** DEFAULT **********/

.is-recommended {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-box-align:end;
	-webkit-align-items:flex-end;
	   -moz-box-align:end;
	    -ms-flex-align:end;
	        align-items:flex-end;
	color:rgb(161,161,170);
	text-shadow:0 0 0 rgb(161,161,170);
}
.is-recommended b {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.is-recommended > * + * {
	margin:0 0 -webkit-calc(var(--stroke-width) * -1) .375em !important;
	margin:0 0 -moz-calc(var(--stroke-width) * -1) .375em !important;
	margin:0 0 calc(var(--stroke-width) * -1) .375em !important;
}

/********** IN SECTION.FOCUS **********/

section.focus .is-recommended {
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
section.focus .is-recommended.institution {
	-webkit-box-align:start;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	    -ms-flex-align:start;
	        align-items:flex-start;
}
section.focus .is-recommended b {
	display:block;
}

/********** IN SINGLE FILM / SECTION.SHORT **********/

section.short .is-recommended {
	font-size:.875rem;
}

/* with avatar */

.is-recommended .avatar + * {
	margin-left:1rem !important;
}

/********** IN SINGLE FILM INSTITUTION **********/

.is-recommended.institution {
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-align:start;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	    -ms-flex-align:start;
	        align-items:flex-start;
}
.is-recommended.institution .avatar {
	margin:1rem 0 0 0;
}
a.is-recommended.institution:hover .avatar img {
	border:solid var(--stroke-width) var(--bright-color);
}

/**************************/
/**************************/
/********** REEL **********/
/**************************/
/**************************/

/*
.reel > .reel-description | .reel-bg
.reel		== x4 (default)
.reel.min	== x 8
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

.reel {
	display:block;
	width:100%;
	overflow:hidden;
}
.reel-description {
	font-size:0;
	margin:0 0 1rem 0;
}
.reel-description > * {
	display:inline-block;
	font-size:.875rem;
	padding:0;
}

/*****************************/
/********** REEL-BG **********/
/*****************************/

.reel-bg {
	display:block;
	font-size:0;
	white-space:nowrap;
	background:var(--black-color);
	margin:0 -.25rem;
	font-size:0;
}
.reel-bg:before {
	content:"";
	position:absolute;
	z-index:-1;
	top:0;
	left:0;
	display:block;
	height:100%;
	width:400%;
	background:var(--black-color);
}
.reel-bg:after {
	content:"";
	display:block;
	clear:both;
}
.reel-bg figure {
	display:inline-block;
	height:auto;
	width:-webkit-calc(25% - .5rem);
	width:-moz-calc(25% - .5rem);
	width:calc(25% - .5rem);
	margin:0 .25rem;
	padding:0;
	-webkit-transform-origin:center center;
	   -moz-transform-origin:center center;
	    -ms-transform-origin:center center;
	     -o-transform-origin:center center;
	        transform-origin:center center;
	overflow:hidden;
}
.reel-bg figure img,
.reel-bg figure picture {
	display:block;
	margin:auto;
	height:100%;
	width:100%;
	-o-object-fit:contain;
	   object-fit:contain;
}

/********** REEL MIN **********/

.reel.min .reel-bg {
	padding:1rem 0;
}

/********** FX BG **********/

.reel .reel-bg figure:nth-child(2) img {
	-webkit-transform:scale(1.1);
	   -moz-transform:scale(1.1);
	    -ms-transform:scale(1.1);
	     -o-transform:scale(1.1);
	        transform:scale(1.1);
}
.reel .reel-bg figure:nth-child(3) img {
	-webkit-transform:scale(1.4);
	   -moz-transform:scale(1.4);
	    -ms-transform:scale(1.4);
	     -o-transform:scale(1.4);
	        transform:scale(1.4);
}
.reel .reel-bg figure:nth-child(4) img {
	-webkit-transform:scale(1.6);
	   -moz-transform:scale(1.6);
	    -ms-transform:scale(1.6);
	     -o-transform:scale(1.6);
	        transform:scale(1.6);
}
.reel .reel-bg figure:nth-child(5) img {
	-webkit-transform:scale(1.8);
	   -moz-transform:scale(1.8);
	    -ms-transform:scale(1.8);
	     -o-transform:scale(1.8);
	        transform:scale(1.8);
}
.reel .reel-bg figure:nth-child(6) img {
	-webkit-transform:scale(2);
	   -moz-transform:scale(2);
	    -ms-transform:scale(2);
	     -o-transform:scale(2);
	        transform:scale(2);
}
.reel .reel-bg figure:nth-child(7) img {
	-webkit-transform:scale(1.8);
	   -moz-transform:scale(1.8);
	    -ms-transform:scale(1.8);
	     -o-transform:scale(1.8);
	        transform:scale(1.8);
}
.reel .reel-bg figure:nth-child(8) img {
	-webkit-transform:scale(1.6);
	   -moz-transform:scale(1.6);
	    -ms-transform:scale(1.6);
	     -o-transform:scale(1.6);
	        transform:scale(1.6);
}

/****************************/
/********** @MEDIA **********/
/****************************/

@media (min-width:0px) {
	.reel-description .title {
		font-size:1rem;
	}
	.reel-description > * + * {
		margin:.25rem 0 0 0 !important;
	}
	.reel-description .film-infos {
		display:block;
		font-size:.75rem;
	}
	.reel-bg {
		padding:1rem 0;
	}
	/* min */
	.reel.min .reel-bg figure {
		width:-webkit-calc(100% / 6 - .5rem);
		width:-moz-calc(100% / 6 - .5rem);
		width:calc(100% / 6 - .5rem);
	}
	.reel .reel-bg figure:nth-child(7),
	.reel .reel-bg figure:nth-child(8) {
		display:none;
	}
}
@media (min-width:760px) {
	.reel-description {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:baseline;
		-webkit-align-items:baseline;
		   -moz-box-align:baseline;
		    -ms-flex-align:baseline;
		        align-items:baseline;
	}
	.reel-description > * {
		white-space:nowrap;
	}
	.reel-description .title {
		display:inline-block !important;
		white-space:nowrap;
		overflow:hidden;
		-o-text-overflow:ellipsis;
		   text-overflow:ellipsis;
		min-width:0;
		max-width:100%;
		font-size:1.5rem;
	}
	.reel-description > * + * {
		margin:0 0 0 2rem !important;
	}
	.reel-description .film-infos {
		display:inline-block;
		font-size:.875rem;
	}
	.reel-bg {
		padding:2rem 0;
	}
	/* min */
	.reel.min .reel-bg figure {
		width:-webkit-calc(100% / 8 - .5rem);
		width:-moz-calc(100% / 8 - .5rem);
		width:calc(100% / 8 - .5rem);
	}
	.reel .reel-bg figure:nth-child(7),
	.reel.min .reel-bg figure:nth-child(8) {
		display:inline-block;
	}
}
@media (min-width:1000px) {
	.reel-description .title {
		font-size:2rem;
	}
	.reel.min .reel-description .title {
		font-size:1.5rem;
	}
}

/********************************/
/********************************/
/********** FILM-INFOS **********/
/********************************/
/********************************/

/*
reel / focus / list / etc.
*/

.film-infos > * {
	display:inline-block;
	margin:0 1em .5em 0 !important;
}
.film-infos > *:last-child() {
	margin:0 1em 0 0 !important;
}
.film-infos .year:before,
.film-infos .country:before,
.film-infos .duration:before {
	font-family:"icomoon";
	display:inline-block;
	font-size:.75em;
	padding-right:1em;
}

/********** IN INSTITUTION **********/

section.focus.institution .film-infos .director {
	display:block;
}

/*********************************/
/*********************************/
/********** FILM-STATUS **********/
/*********************************/
/*********************************/

/* in single film / single cine-club */

.film-status > * {
	display:inline-block;
	vertical-align:middle;
	font-size:.875rem;
	white-space:nowrap;
}
.film-status > * + * {
	margin:0 0 0 1em;
}

/* for restored version */

.film-status p {
	display:block;
	margin:0 0 1rem 0;
}

/***********************************/
/***********************************/
/********** FILM-VERSIONS **********/
/***********************************/
/***********************************/

/*
in single film + add-film-to-cart
*/

.film-versions {
	color:rgb(161,161,170);
	text-shadow:0 0 0 rgb(161,161,170);
}
.film-versions span,
nav.film-versions {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.film-versions:not(nav) .has-tooltip {
	border-bottom:solid var(--stroke-width);
	margin-bottom:-webkit-calc(var(--stroke-width) * -1) !important;
	margin-bottom:-moz-calc(var(--stroke-width) * -1) !important;
	margin-bottom:calc(var(--stroke-width) * -1) !important;
}
.version-audio:before,
.version-dubbing:before,
.version-subtitles:before,
.version-loss:before {
	font-family:"icomoon";
	margin-right:.5em;
}
.film-versions .unavailable {
	opacity:.33;
}

/********** IN CART OVERLAY **********/

.film-versions.columns .version-audio,
.film-versions.columns .version-dubbing,
.film-versions.columns .version-subtitles,
.film-versions.columns .version-loss {
	display:block;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.film-versions.columns li + li {
		margin:var(--gutter-gap) 0 0 0;
	}
	.film-versions.columns .version-audio,
	.film-versions.columns .version-dubbing,
	.film-versions.columns .version-subtitles,
	.film-versions.columns .version-loss {
		margin-bottom:-webkit-calc(var(--gutter-gap) / 2);
		margin-bottom:-moz-calc(var(--gutter-gap) / 2);
		margin-bottom:calc(var(--gutter-gap) / 2);
	}
}
@media (min-width:760px) {
	.film-versions.columns li + li {
		margin:0 0 0 var(--gutter-gap);
	}
}

/********************************/
/********************************/
/********** FILM-NOTES **********/
/********************************/
/********************************/

/*
in add-film-to-cart
*/

.film-notes {
	font-size:.75rem;
	background:var(--dark-color);
    -webkit-border-radius:-webkit-calc(var(--border-radius) * 2);
       -moz-border-radius:-moz-calc(var(--border-radius) * 2);
            border-radius:calc(var(--border-radius) * 2);
}
.film-notes hr {
	border-bottom:solid var(--stroke-width) var(--light-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.film-notes {
		padding:1rem;
	}
	.film-notes hr {
		margin-bottom:1rem;
	}
	.film-notes + .film-notes {
		margin-top:1rem;
	}
}
@media (min-width:760px) {
	.film-notes {
		padding:2rem;
	}
}
@media (min-width:1000px) {
	.film-notes hr {
		margin-bottom:2rem;
	}
}

/********************************/
/********************************/
/********** POST-INFOS **********/
/********************************/
/********************************/

/*
in single-post / posts
*/

.post-infos .date {
	display:block;
	font-size:.875em;
}

/**********************************/
/**********************************/
/********** WATCH STATUS **********/
/**********************************/
/**********************************/

/*
.watch-status:after == progress bar
V4 fix				== .watch-status:after > .watch-status-bar
*/

.watch-status {
	position:absolute;
	z-index:10;
	bottom:0;
	left:0;
	right:0;
	font-size:0;
	line-height:0;
	height:.75rem;
	background:var(--bright-color);
	background:-webkit-repeating-linear-gradient(135deg,var(--bright-color),var(--bright-color) .5rem,rgb(231,231,240) .5rem,rgb(231,231,240) 1rem);
	background:-moz-repeating-linear-gradient(135deg,var(--bright-color),var(--bright-color) .5rem,rgb(231,231,240) .5rem,rgb(231,231,240) 1rem);
	background:-o-repeating-linear-gradient(135deg,var(--bright-color),var(--bright-color) .5rem,rgb(231,231,240) .5rem,rgb(231,231,240) 1rem);
	background:repeating-linear-gradient(-45deg,var(--bright-color),var(--bright-color) .5rem,rgb(231,231,240) .5rem,rgb(231,231,240) 1rem);
	-webkit-box-shadow:inset 0 0 .25rem rgba(var(--black-color),.5);
	   -moz-box-shadow:inset 0 0 .25rem rgba(var(--black-color),.5);
	        box-shadow:inset 0 0 .25rem rgba(var(--black-color),.5);
}
.watch-status small {
	visibility:hidden;
}
.watch-status-bar {
	position:absolute;
	bottom:0;
	left:0;
	display:block;
	height:100%;
	width:0;
	background:var(--red-color);
	-webkit-box-shadow:0 0 .25rem rgba(var(--black-color),.5);
	   -moz-box-shadow:0 0 .25rem rgba(var(--black-color),.5);
	        box-shadow:0 0 .25rem rgba(var(--black-color),.5);
	opacity:var(--alpha-rgb);
}
.watch-status.in-progress .watch-status-bar {
	width:50%;
}
.watch-status.done .watch-status-bar {
	width:100%;
}

/********** VIDEO LINK WATCH STATUS **********/

.video-link .watch-status {
	-webkit-transform:translate3d(0,100%,0);
	   -moz-transform:translate3d(0,100%,0);
	        transform:translate3d(0,100%,0);
	opacity:0;
}
.video-link:hover .watch-status {
	-webkit-transform:translate3d(0,0,0);
	   -moz-transform:translate3d(0,0,0);
	        transform:translate3d(0,0,0);
	opacity:1;
}

/****************************/
/****************************/
/********** SUM-UP **********/
/****************************/
/****************************/

/*
in single-film
based on .columns [.columns to remove ? !!!]
.sum-up			== default (eg. in film content)
.sum-up.large	== in single-selection.php (for offers)
nav.sum-up		== in single-film.php
*/

/********** DEFAULT **********/

.sum-up {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	text-align:center;
	text-transform:uppercase;
}
.sum-up > * {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	-webkit-flex-basis: 0;
	    -ms-flex-preferred-size: 0;
	        flex-basis: 0;
}
.sum-up > * + * {
	margin-top:0;
	margin-left: var(--gutter-gap);
}
.sum-up strong {
	color:var(--red-color);
	text-shadow:none;
}

/********** AS NAV **********/

nav.sum-up:hover a {
	opacity:.25;
}
nav.sum-up:hover a:hover {
	opacity:1;
}

/** in single-film **/

section.short + section nav.sum-up {
	margin-top:-webkit-calc(var(--gutter-gap) / 2);
	margin-top:-moz-calc(var(--gutter-gap) / 2);
	margin-top:calc(var(--gutter-gap) / 2);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.sum-up {
		font-size:1rem;
	}
}
@media (min-width:760px) {
	.sum-up {
		font-size:1.25rem;
	}
}
@media (min-width:1000px) {
	.sum-up {
		font-size:2rem;
	}
	.sum-up.large {
		font-size:2.5rem;
	}
}

/**************************/
/**************************/
/********** TAGS **********/
/**************************/
/**************************/

/*
in single-post
*/

ul.tags {
	font-size:0;
}
ul.tags li {
	display:inline-block;
	list-style:none;
	text-transform:uppercase;
	line-height:1em;
	padding:1em;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	ul.tags {
		margin:-.125rem;
	}
	ul.tags li {
		font-size:.375rem;
		line-height:1em;
		padding:1em;
		margin:.125rem;
	}
}
@media (min-width:480px) {
	ul.tags li {
		font-size:.5rem;
	}
}
@media (min-width:760px) {
	ul.tags {
		margin:-.25rem;
	}
	ul.tags li {
		font-size:.625rem;
		margin:.25rem;
	}
}

/*******************************/
/*******************************/
/********** HAS-BONUS **********/
/*******************************/
/*******************************/

/*
nav.has-bonus	== links
ul.has-bonus	== infos
*/

/********** DEFAULT **********/

.has-bonus {
	display:-webkit-box !important;
	display:-webkit-flex !important;
	display:-moz-box !important;
	display:-ms-flexbox !important;
	display:flex !important;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	padding:0 !important;
	border:0 !important;
}
.has-bonus > * {
	display:block;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	text-transform:uppercase;
	white-space:nowrap;
	background:var(--red-color);
	line-height:1em;
	padding:1em !important;
}
.has-bonus > * + * {
	margin:0 0 0 1em !important;
}
.has-bonus > *:before {
	display:inline-block;
	font-family:'icomoon';
	font-size:inherit;
	line-height:0;
	margin-right:1em;
}
.has-bonus > .archives {
	background:var(--bonus-archives-color);
}
.has-bonus > .alt {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--yellow-color);
}

/********** NAV HAS-BONUS **********/

.list.films.selection nav.has-bonus {
	margin:1rem 0 0 0;
}
nav.has-bonus a:hover {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
	background:var(--bright-color);
}
nav.has-bonus a.archives:hover {
	color:var(--bonus-archives-color);
	text-shadow:0 0 0 var(--bonus-archives-color);
}

/********** UL HAS-BONUS **********/

/* in archive-films.php */

.grid ul.has-bonus {
	position:absolute;
	bottom:0;
	right:0;
	margin:1rem;
}
.grid.list ul.has-bonus {
	top:50%;
	right:0;
	bottom:auto;
	width:auto;
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.has-bonus > * {
		font-size:.5rem;
	}
}
@media (min-width:1000px) {
	.has-bonus > * {
		font-size:.625rem;
	}
}

/*****************************/
/*****************************/
/********** ANCHORS **********/
/*****************************/
/*****************************/

/*
a				== default inline
a > .label		== hidden (button with no text)
a with icon-*** == inline-flex (cf. fonts.css)

a.detonate
a.read-more
a.more-link == eg. "top des listes" > .grid .item link to single film
a.list-link == eg. index > "liste du mois"


nav.switch > a
buttons in navigation.css
*/

/************************************/
/********** DEFAULT INLINE **********/
/************************************/

a {
	position:relative;
	font-size:inherit;
	line-height:inherit;
	color:inherit;
	outline:0;
	text-decoration:none;
}
.read-more,
.read-more > a,
p > a {
	border-bottom:solid var(--stroke-width);
	margin-bottom:-webkit-calc(var(--stroke-width) * -1) !important;
	margin-bottom:-moz-calc(var(--stroke-width) * -1) !important;
	margin-bottom:calc(var(--stroke-width) * -1) !important;
}
p > a:hover,
a.read-more:hover,
.read-more > a:hover,
a:hover .read-more {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
}

/******************************/
/********** DETONATE **********/
/******************************/

/* cf. button.rounded */

a.detonate {
	font-size:1rem;
	line-height:1em;
	padding:1.25rem 2.25rem;
	-webkit-border-radius:3rem;
	   -moz-border-radius:3rem;
	        border-radius:3rem;
	border:none;
	background:var(--red-color);
}
a.detonate:hover {
	color:var(--red-color);
	text-shadow:none;
	border:none;
	background:var(--bright-color);
}
a.detonate:before {
	display:inline-block;
	vertical-align:middle;
	font-family:'icomoon';
	font-size:inherit;
	font-size:1.25em;
	line-height:0;
	margin:-.125em 1em 0 -.75em;
}

/*******************************/
/********** READ-MORE **********/
/*******************************/

nav.read-more {
	border:0;
}
.read-more > a {
	float:left;
}
.read-more > a + a {
	margin:0 0 0 1rem;
}
.read-more > a:before,
a.read-more:before {
	content:"";
	display:block;
	padding-top:1rem;
}
.read-more:after {
	content:"";
	clear:both;
}

/*************************************/
/********** COLLECTION-LINK **********/
/*************************************/
/* in institutions */

.collection-link {
	z-index:30;
	font-size:inherit;
	line-height:1em;
}
a.collection-link:hover {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
}

/**************************************/
/********** INSTITUTION-LINK **********/
/**************************************/
/* in institutions */

.institution-link {
	position:absolute;
	z-index:30;
	top:0;
	right:0;
	border:0;
	width:6rem;
	margin:1rem;
	background:var(--black-color);
}
.institution-link:hover {
	opacity:.5;
}
.institution-link .avatar.institution img {
	border:0;
}

/*******************************/
/********** MORE-LINK **********/
/*******************************/
/* in extras grid top */

.more-link {
	position:absolute;
	z-index:10;
	top:0;
	left:0;
	right:0;
	bottom:0;
}

/*******************************/
/********** LIST LINK **********/
/*******************************/

.list-link {
	position:relative;
	display:inline-block;
	line-height:normal; /* prevent on anchor bug */
	color:var(--red-color);
	text-shadow:none;
	text-transform:uppercase;
}
.list-link:after {
	content:"";
	position:absolute;
	left:0;
	bottom:.0625rem;
	height:.0625rem;
	width:100%;
	background:var(--red-color);
}
.list-link:hover:after,
a:hover .list-link:after {
	-webkit-transform:translateY(-.125rem);
	   -moz-transform:translateY(-.125rem);
	    -ms-transform:translateY(-.125rem);
	     -o-transform:translateY(-.125rem);
	        transform:translateY(-.125rem);
}

/* in index */

.section-title * + .list-link {
	margin-left:.5rem;
}

/********************************/
/********** WITH ICONS **********/
/********************************/

a [class^="icon-"] + span:not(.label),
a [class*=" icon-"] + span:not(.label) {
	margin:0 0 0 .5rem;
}
a span {
	display:inline-block;
	font-family:inherit;
	font-size:inherit;
	line-height:inherit;
	color:inherit;
	text-shadow:inherit;
}

/*********************************/
/********** WITH LABELS **********/
/*********************************/

a .label,
button .label {
	position:absolute !important;
	font-size:0 !important;
}

/***************************/
/********** ICONS **********/
/***************************/

a.offer:before,
a.add-to-playlist:before,
a.watch:before,
a.cart:before,
a.login:before {
	display:inline-block;
	font-family:'icomoon';
	font-size:inherit;
	line-height:0;
	margin-right:1em;
}
a.validate:after,
a.back:before,
a.previous:before,
a.next:after {
	font-family:'icomoon';
	position:absolute;
	top:50%;
	padding:1rem;
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
}
a.back,
a.previous {
	text-align:left !important;
	padding-left:3em !important;
}
a.next {
	text-align:right !important;
	padding-right:3em !important;
}
a.back:before,
a.previous:before {
	left:0;
}
a.validate:after,
a.next:after {
	right:0;
}

/********** BEHAVIOURS **********/

:focus {
	outline-color:transparent;
	outline-style:none;
}
::-moz-selection {
	background:var(--yellow-color);
}
::selection {
	background:var(--yellow-color);
}


/********************************/
/********** VIDEO LINK **********/
/********************************/

a.video-link {
	position:relative;
	display:block;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	text-transform:uppercase;
	overflow:hidden;
}
a.video-link:before {
	position:absolute;
	z-index:30;
	top:50%;
	left:50%;
	display:block;
	font-family:'icomoon';
	-webkit-filter:drop-shadow(0 0 var(--stroke-width-medium) rgba(var(--black-rgb),var(--alpha-rgb)));
	        filter:drop-shadow(0 0 var(--stroke-width-medium) rgba(var(--black-rgb),var(--alpha-rgb)));
	-webkit-transform:translate3d(-50%,-50%,0);
	   -moz-transform:translate3d(-50%,-50%,0);
	        transform:translate3d(-50%,-50%,0);
	border:solid var(--stroke-width-min);
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	padding:1em;
}
a.video-link:after {
	content:"";
	position:absolute;
	top:50%;
	left:50%;
	display:block;
	height:-webkit-calc(100% + .25em);
	height:-moz-calc(100% + .25em);
	height:calc(100% + .25em);
	width:-webkit-calc(100% + .25em);
	width:-moz-calc(100% + .25em);
	width:calc(100% + .25em);
	background:var(--darker-color);
	opacity:0;
	-webkit-transform:translate3d(-50%,-50%,0);
	   -moz-transform:translate3d(-50%,-50%,0);
	        transform:translate3d(-50%,-50%,0);
}
a.video-link .label {
	z-index:20;
	top:50%;
	left:50%;
	white-space:nowrap;
	margin:3em 0 0 0;
	padding:1rem;
	-webkit-transform:translate3d(-50%,-40%,0);
	   -moz-transform:translate3d(-50%,-40%,0);
	        transform:translate3d(-50%,-40%,0);
	opacity:0;
}
a.video-link:hover .label {
	-webkit-transform:translate3d(-50%,-50%,0);
	   -moz-transform:translate3d(-50%,-50%,0);
	        transform:translate3d(-50%,-50%,0);
	opacity:1;
}
a.video-link:hover:before {
	-webkit-transform:translate3d(-50%,-50%,0) scale(.8);
	   -moz-transform:translate3d(-50%,-50%,0) scale(.8);
	        transform:translate3d(-50%,-50%,0) scale(.8);
}
a.video-link:hover:after {
	opacity:var(--alpha-rgb);
}
a.video-link:before,
a.video-link .label,
a.video-link.readable .label {
	line-height:1em;
}
.video-link img {
	display:block;
	width:100%;
	max-width:none;
}

/********** IN PICTURE VIDEO LINK **********/

/** can be combined with more-link = ex. in svod [V5] **/

a.video-link.more-link {
	position:absolute;
	z-index:15;
	top:0;
	right:0;
	bottom:0;
	left:0;
	margin:0;
}
a.video-link.more-link.teasing {
	opacity:0;
}
a.video-link.more-link:after {
	opacity:0;
}
a.video-link.more-link .label {
	margin-top:3.5em;
}
a.video-link.more-link:hover {
	opacity:1;
}
a.video-link.more-link:hover:before {
	-webkit-transform:translate3d(-50%,-50%,0) scale(1.2);
	   -moz-transform:translate3d(-50%,-50%,0) scale(1.2);
	        transform:translate3d(-50%,-50%,0) scale(1.2);
}
a.video-link.more-link:hover:after {
	opacity:.5;
}
section.focus a.video-link.more-link:hover + .infos {
	opacity:0;
}

/** in preview **/

.preview-toggle a.video-link {
	display:none;
}
.preview.active a.video-link {
	display:block;
}
a.video-link.large {
	font-size:1.5rem;
}

/********** BONUS VIDEO LINK **********/

.bonus a.video-link:hover {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.bonus a.video-link:after {
	background:var(--red-color);
}
.bonus a.video-link.archives:after {
	background:var(--bonus-archives-color);
}

/********** BONUS VIDEO LINK IMG **********/
/* forced 16/9 ratio = different sources (eg. youtube) */

.bonus .video-link figure {
	position:relative;
	height:0;
	padding-top:56.25%;
	width:100%;
}
.bonus .video-link figure > * {
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:100%;
	-o-object-fit:cover;
	   object-fit:cover;
}
.bonus .video-link figure img {
	height:100%;
	width:100%;
	-o-object-fit:cover;
	   object-fit:cover;
}

/********** VIDEO LINK READABLE **********/
/* cf. selection teaser */

a.video-link.readable:before,
a.video-link.readable:after {
	display:none;
}
a.video-link.readable .label {
	position:absolute;
	top:50%;
	left:50%;
	margin:0;
	background:var(--red-color);
	opacity:1;
	-webkit-transform:translate3d(-50%,-50%,0);
	   -moz-transform:translate3d(-50%,-50%,0);
	        transform:translate3d(-50%,-50%,0);
}
a.video-link.readable .label:before {
	font-family:'icomoon';
	display:inline-block;
	font-size:.75rem;
	line-height:1em;
	margin:0 .5rem 0 0;
}
a.video-link.readable:hover .label:before {
	-webkit-transform:scale(.8);
	   -moz-transform:scale(.8);
	    -ms-transform:scale(.8);
	     -o-transform:scale(.8);
	        transform:scale(.8);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	a.video-link:before {
		font-size:.5em;
	}
	a.video-link .label,
	a.video-link.readable .label {
		font-size:.625em !important;
	}
}
@media (min-width:1000px) {
	a.video-link:before {
		font-size:.75em;
	}
	a.video-link .label,
	a.video-link.readable .label {
		font-size:.75em !important;
	}
}

/****************************/
/****************************/
/********** MEDIAS **********/
/****************************/
/****************************/

/******************************/
/********** IMG FULL **********/
/******************************/

img.full {
	display:block;
	height:auto;
	width:100%;
	margin-left:auto;
	margin-right:auto;
}

/*************************************/
/********** IMG WITHIN TEXT **********/
/*************************************/

.alignleft {
	float:left;
	margin-right:var(--margin-normal);
}
.alignright {
	float:right;
	margin-left:var(--margin-normal);
}
.aligncenter {
    display:block;
}
.alignleft:after,
.alignright:after,
.aligncenter:after {
	content:"";
    display:block;
    clear:both;
}

/****************************************/
/********** CSA CLASSIFICATION **********/
/****************************************/

.csa-classification {
	display:inline-block !important;
	vertical-align:middle;
	padding:0;
}
.csa-classification img {
	position:relative !important;
	margin:0 !important;
	opacity:1 !important;
	height:1em;
	width:1em;
}
.title .csa-classification,
.film-title .csa-classification {
	margin-left:.5em;
	margin-top:-.25rem;
	/*margin-right:.5rem;*/ /* if csa is first */
}

/***************************/
/********** COVER **********/
/***************************/

figure.cover {
	position: relative;
}
figure.cover img {
	width:100%;
}

/**************************/
/********** FULL **********/
/**************************/

figure.full,
figure.full img {
	max-width: none;
	width: 100%;
}

/****************************/
/********** OBJECT **********/
/****************************/

/* for user payment icons */

@media (min-width:0px) {
	figure.object {
		max-width:10rem;
	}
}
@media (min-width:1000px) {
	figure.object {
		max-width:20rem;
	}
}

/*****************************/
/********** PAYMENT **********/
/*****************************/

.payment-icons svg > * {
	fill:var(--bright-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.payment-icons .icon-lock-on {
		font-size:1rem;
	}
	.payment-icons svg {
		width:2rem;
		max-height:1.5rem;
	}
}
@media (min-width:760px) {
	.payment-icons .icon-lock-on {
		font-size:2rem;
	}
	.payment-icons svg {
		width:3rem;
		max-height:2.5rem;
	}
}

/*******************************/
/********** LACINETEK **********/
/*******************************/

.lacinetek {
	display:block;
}
.lacinetek svg {
	display:block;
	height:100%;
	/*width: 100%;*/
	margin:auto;
	fill:var(--bright-color);
}

/********** @MEDIA **********/
/*
@media (min-width:0px) {
	.lacinetek svg {
		width:5rem;
	}
}
@media (min-width:1000px) {
	.lacinetek svg {
		width:7rem;
	}
}
@media (min-width:1200px) {
	.lacinetek svg {
		width:10rem;
	}
}
*/
@media (min-width:0px) {
	.lacinetek svg {
		width:5rem;
	}
}
@media (min-width:760px) {
	.lacinetek svg {
		width:7rem;
	}
}
@media (min-width:1000px) {
	.lacinetek svg {
		width:10rem;
	}
}

/********************************/
/********************************/
/********** BACKGROUND **********/
/********************************/
/********************************/

/*
.background-container > .background
.background
.background.contain
.background.blurred [OUT]
.background.fixed

.has-background	== index => listes de
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

.background-container {
	position:absolute;
	z-index:-1;
	top:0;
	left:-100%;
	right:-100%;
	height:100%;
	width:auto;
	overflow:hidden;
	padding:0 !important;
	margin:0 !important;
}
.background {
	position:absolute;
	z-index:-1;
	top:0;
	left:0;
	height:100%;
	width:100%;
	margin:0 !important;
	padding:0 !important;
	max-width:none;
	pointer-events: none;
	-webkit-background-size:cover !important;
	   -moz-background-size:cover !important;
	     -o-background-size:cover !important;
	        background-size:cover !important;
	background-repeat:no-repeat !important;
	background-position:center center !important;
}
.background.contain {
	-webkit-background-size:contain !important;
	   -moz-background-size:contain !important;
	     -o-background-size:contain !important;
	        background-size:contain !important;
	background-position:left !important; /* single institution ? */
}

/*******************************/
/********** POSITIONS **********/
/*******************************/

.bg-top-left {
	background-position:top left !important;
}
.bg-top-center {
	background-position:top center !important;
}
.bg-top-right {
	background-position:top right !important;
}
.bg-center-left {
	background-position:center left !important;
}
.bg-center-right {
	background-position:center right !important;
}
.bg-bottom-left {
	background-position:bottom left !important;
}
.bg-bottom-center {
	background-position:bottom center !important;
}
.bg-bottom-right {
	background-position:bottom right !important;
}

/***************************/
/********** FIXED **********/
/***************************/

.background.fixed {
	position: fixed;
}

/*****************************/
/********** FIGURES **********/
/*****************************/

figure.background {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
}
figure.background img,
figure.background picture {
	display:block;
	margin:auto;
	height:100%;
	width:100%;
	-o-object-fit:cover;
	   object-fit:cover;
}
figure.background.contain img,
figure.background.contain picture {
	-o-object-fit:contain;
	   object-fit:contain;
}

/********** @MEDIA **********/

@media (max-width:760px) {
	section.focus figure.background img,
	section.focus figure.background picture {
		-o-object-fit:cover;
		   object-fit:cover;
	}
}

/****************************/
/****************************/
/********** AVATAR **********/
/****************************/
/****************************/

/********** DEFAULT **********/

.avatar {
	position:relative;
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex; /* V4 */
	vertical-align:middle;
	height:4rem;
	width:4rem;
	-webkit-background-size:cover !important;
	   -moz-background-size:cover !important;
	     -o-background-size:cover !important;
	        background-size:cover !important;
	background-position:center center !important;
}
.avatar img,
.avatar picture {
	display:block;
	margin:auto;
	height:100%;
	width:100%;
	-o-object-fit:contain;
	   object-fit:contain;
}

/********** FULL **********/

.avatar.full {
	height:100%;
	width:100%;
}

/********** INSTITUTION **********/

.avatar.institution {
	height:auto;
	width:100%;
	max-width:10rem;
}
.avatar.institution.large {
	max-width:none;
}
.avatar.institution img {
	border:solid var(--stroke-width) var(--light-color);
}

/********** IN FOCUS **********/

section.focus .avatar {
	height:auto;
}

/********** IN PLAYLIST **********/

/** for user **/

.avatar.playlist > [class^="icon-"],
.avatar.playlist > [class*=" icon-"] {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	height:100%;
	width:100%;
}
.avatar.playlist > [class^="icon-"]:before,
.avatar.playlist > [class*=" icon-"]:before {
	font-size:10em;
	line-height:1em;
}

/*********************************/
/*********************************/
/********** VIDEOPLAYER **********/
/*********************************/
/*********************************/

/*
.videoplayer			== default
.videoplayer.youtube	== for youtube video
.videoplayer.vimeo		== for vimeo video /!\ not tested yet
.videoplayer video		== html5 player /!\ not working yet
> data-background		== for background image / if none youtube default
> id == video id
.videoplayer-container == for overlay max-width 1024px
*/

/********** VIDEOPLAYER **********/

.videoplayer {
	position:relative;
	-webkit-background-size:cover !important;
	   -moz-background-size:cover !important;
	     -o-background-size:cover !important;
	        background-size:cover !important;
	background-position:center center !important;
	background:var(--black-color);
	min-height:8rem;
	width:100%;
	padding:56.25% 0 0 0 !important;
}
.videoplayer iframe {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

/********** VIDEOPLAYER-CONTAINER **********/

.videoplayer-container {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	max-height:100vh; /* custom in overlays.css */
	max-width:64rem;
	width:100%;
	margin-right:auto;
	margin-left:auto;
}
.videoplayer-container.full {
	max-width:var(--max-width);
}

/** for IOS **/

.videoplayer-container:before {
	padding-top: 56.25%;
	content: '';
}

/********** if async videoplayer **********/

.videoplayer.youtube,
.videoplayer.vimeo {
	cursor:pointer;
}
.videoplayer.youtube:before,
.videoplayer.vimeo:before {
	display:none;
}
.videoplayer.loaded {
	display:block;
	padding:0 !important;
}
.videoplayer.link {
	padding:56.25% 0 0 0;
}
.videoplayer .play {
	position:absolute;
	z-index:30;
	top:50%;
	left:50%;
	display:block;
	font-family:'icomoon';
	line-height:1em;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	border:solid var(--stroke-width-min);
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	padding:1em;
	-webkit-filter:drop-shadow(0 0 var(--stroke-width-medium) rgba(var(--black-rgb),var(--alpha-rgb)));
	        filter:drop-shadow(0 0 var(--stroke-width-medium) rgba(var(--black-rgb),var(--alpha-rgb)));
	-webkit-transform:translate3d(-50%,-50%,0);
	   -moz-transform:translate3d(-50%,-50%,0);
	        transform:translate3d(-50%,-50%,0);
}
.videoplayer .play:hover {
	-webkit-transform:translate3d(-50%,-50%,0) scale(.8);
	   -moz-transform:translate3d(-50%,-50%,0) scale(.8);
	        transform:translate3d(-50%,-50%,0) scale(.8);
}
.videoplayer .play:before {
	display:block;
    font-family:"icomoon";
    color:inherit;
    font-size:inherit;
    line-height:inherit;
}

/********** HTML5 VIDEO **********/

video {
	display:block;
	height:auto;
	width:100%;
}
.videoplayer.chrome video::-internal-media-controls-download-button {
	display:none;
}
.videoplayer.chrome video::-webkit-media-controls-enclosure {
	overflow:hidden;
}
.videoplayer.chrome video::-webkit-media-controls-panel {
	width:-webkit-calc(100% + 32px);
	width:calc(100% + 32px);
	margin-left:auto;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.videoplayer .play {
		font-size:1rem;
	}
}
@media (min-width:1000px) {
	.videoplayer .play {
		font-size:1.5rem;
	}
}

/****************************/
/****************************/
/********** OFFERS **********/
/****************************/
/****************************/

/*
.offers
.offers.screens
.offers.apps [V4]
.offers.blocks [V5]
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

.offers .infos > * + * {
	margin-top:0;
}
.offers .title {
	line-height: inherit;
	text-transform: uppercase;
}
.offers .price {
	white-space: nowrap;
	line-height: inherit;
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
}
.offers small {
	display:block;
	font-size: .875rem;
	line-height:1em;
}
.offers em {
	font-style: normal;
}
.offers nav {
	margin-left:auto;
	margin-right: auto;
}

/********** OFFER-CREDIT **********/
/* in user payment */

.offer-credit,
.offer-credit.alert {
    color: var(--red-color);
    text-shadow: 0 0 0 var(--red-color);
    border-color: var(--red-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.offers .title {
		font-size:1rem;
	}
}
@media (min-width:760px) {
	.offers .title {
		font-size:1.25rem;
	}
	.offers .price {
		font-size:1.5rem;
	}
}
@media (min-width:1000px) {
	.offers.blocks .item.full .title {
		font-size:1.5rem;
	}
	.offers.blocks .item.full .price {
		font-size:2rem;
	}
}
@media (min-width:1200px) {
	.offers .title {
		font-size:1.5rem;
	}
	.offers .price {
		font-size:2rem;
	}
}

/************************************/
/********** OFFERS FIGURES **********/
/************************************/

/* ratio 192/203 == higher svod icon */

.offers figure {
	position:relative;
	margin:0;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
	width:auto;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}
.offers figure svg {
	height:100%;
	margin:0 auto;
}
.offers figure img {
	max-height:100%;
	margin:0 auto;
}

/********** SIZES **********/

.offers figure {
	max-width:12rem;
}
.offers figure.small {
	max-width:6rem;
}
.offers.blocks figure,
.offers.blocks figure.small {
	max-width:none;
}

/********** COLORS **********/

.offers figure:not(.default) svg {
	fill:var(--bright-color);
}

/********** @MEDIA **********/
/* .offers.blocks.full == big items */

@media (min-width:0px) {
	.offers.blocks figure {
		height: 6rem;
		width: 8rem;
	}
	.offers.blocks figure.small {
		height:4rem;
		width: 4rem;
	}
	.offers.blocks figure.svod-ratio {
		height: 8rem;
		width: 8rem;
	}
	.offers.blocks figure.svod-ratio.large {
		width:auto;
	}
}
@media (min-width:760px) {
	.offers.blocks figure {
		height:10rem;
		width: 12rem;
	}
	.offers.blocks figure.medium {
		height:8rem;
		width: 8rem;
	}
	.offers.blocks figure.small {
		height:6rem;
		width: 6rem;
	}
	.offers.blocks figure.svod-ratio {
		height:9rem;
		width:10rem;
	}
}
@media (min-width:1200px) {
	.offers.blocks figure.svod-ratio {
		height:12rem;
	}
	/* .offers.blocks figure.svod-ratio.large {
		width:17rem;
	} */
}

/*********************************/
/********** OFFERS BLOCKS ********/
/*********************************/

.offers.blocks .item {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	position:relative;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	width:100%;
	-webkit-border-radius:-webkit-calc(var(--border-radius) * 4);
	   -moz-border-radius:-moz-calc(var(--border-radius) * 4);
	        border-radius:calc(var(--border-radius) * 4);
	color: var(--bright-color);
	text-shadow: 0 0 0 var(--bright-color);
	background:var(--dark-color);
}
.offers.blocks .item-content {
	position: relative;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	width:100%;
	margin:auto;
}

/********** ALERT **********/

.offers.blocks .alert.promo {
	position:absolute;
	z-index:10;
	top:0;
	left:0;
	right:0;
	margin:2em -1em 0 -1em;
	line-height:normal;
	padding:1.5em !important;
	-webkit-border-radius: -webkit-calc(var(--border-radius) * 2);
	   -moz-border-radius: -moz-calc(var(--border-radius) * 2);
	        border-radius: calc(var(--border-radius) * 2);
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}
.offers.blocks .alert.promo + * {
	margin-top:0;
}

/********** INFOS **********/

.offers.blocks .infos {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	text-align:left;
}
.offers.blocks .infos > * {
	margin:0;
}
.offers.blocks .infos > * + * {
	margin:.5em 0 0 0;
}
.offers.blocks .infos + * {
	margin-top:1rem;
}

/********** BUTTON **********/

.offers.blocks .button {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}
.offers.blocks .button:hover {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
	background:var(--bright-color);
}

/********** AS NAVIGATION **********/

/*
nav.offers.blocks .item:before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	border: solid var(--stroke-width) rgba(var(--yellow-rgb), 0);
	-webkit-border-radius:-webkit-calc(var(--border-radius) * 4);
	   -moz-border-radius:-moz-calc(var(--border-radius) * 4);
	        border-radius:calc(var(--border-radius) * 4);
	pointer-events: none;
}
nav.offers.blocks .item:hover:before {
	border: solid var(--stroke-width) rgba(var(--yellow-rgb), 1);
}
*/
nav.offers.blocks .item a.item-content {
	height:100%;
}
nav.offers.blocks .item a.item-content:before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	border: solid var(--stroke-width) rgba(var(--yellow-rgb), 0);
	-webkit-border-radius:-webkit-calc(var(--border-radius) * 4);
	   -moz-border-radius:-moz-calc(var(--border-radius) * 4);
	        border-radius:calc(var(--border-radius) * 4);
	pointer-events: none;
}
nav.offers.blocks .item a.item-content:hover:before,
nav.offers.blocks .item a.item-content.active:before {
	border: solid var(--stroke-width) rgba(var(--yellow-rgb), 1);
}

/********** @MEDIA **********/
/* .offers.blocks.full == big items */

@media (min-width:0px) {
	.offers.blocks {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.offers.blocks .item + .item {
		margin:var(--gutter-gap) 0 0 0;
	}
	.offers.blocks .item-content {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.offers.blocks .item-content {
		padding:-webkit-calc(var(--gutter-gap) * 2);
		padding:-moz-calc(var(--gutter-gap) * 2);
		padding:calc(var(--gutter-gap) * 2);
	}
}
@media (min-width:1000px) {
	.offers.blocks {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.offers.blocks .item {
		width: -webkit-calc(100% / 3 - var(--gutter-gap));
		width: -moz-calc(100% / 3 - var(--gutter-gap));
		width: calc(100% / 3 - var(--gutter-gap));
		max-width:-webkit-calc(50% - var(--gutter-gap) / 2);
		max-width:-moz-calc(50% - var(--gutter-gap) / 2);
		max-width:calc(50% - var(--gutter-gap) / 2);
	}
	.offers.blocks .item + .item {
		margin:0 0 0 var(--gutter-gap);
	}
	.offers.blocks .item-content {
		padding:-webkit-calc(var(--gutter-gap) * 1);
		padding:-moz-calc(var(--gutter-gap) * 1);
		padding:calc(var(--gutter-gap) * 1);
	}
	.offers.blocks .item-content > * + * {
		margin: var(--gutter-gap) 0 0 0;
	}
	.offers.blocks .item-content > * + .price {
		margin: -webkit-calc(var(--gutter-gap) / 2) 0 0 0;
		margin: -moz-calc(var(--gutter-gap) / 2) 0 0 0;
		margin: calc(var(--gutter-gap) / 2) 0 0 0;
	}
	.offers.blocks .item.full .item-content {
		height:100%;
	}
	.offers.blocks .inline {
		width:100%;
	}
}
@media (min-width:1200px) {
	.offers.blocks .item-content {
		padding:-webkit-calc(var(--gutter-gap) * 1.5);
		padding:-moz-calc(var(--gutter-gap) * 1.5);
		padding:calc(var(--gutter-gap) * 1.5);
	}
}

/************************************/
/********** OFFERS COLUMNS **********/
/************************************/

/* for overlays gift, add to cart */

/********** DEFAULT **********/

/** keep inline **/

.offers.columns {
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
}
.offers.columns > * {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	-webkit-flex-basis:0;
	    -ms-flex-preferred-size:0;
	        flex-basis:0;
}
.offers.columns > * + * {
	margin-top:0;
	margin-left:var(--gutter-gap);
}

/** has-gutter **/

.offers.columns.has-gutter > *:not(:last-child):after {
	border-right:solid var(--stroke-width);
	border-bottom:none;
	right: -webkit-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
	right: -moz-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
	right: calc((var(--gutter-gap) + var(--stroke-width)) / -2);
	bottom:0;
}
.offers.columns > *.has-gutter:before {
	border-left:solid var(--stroke-width);
	border-bottom:none;
	bottom:0;
	left: -webkit-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
	left: -moz-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
	left: calc((var(--gutter-gap) + var(--stroke-width)) / -2);
}

/********** CONTENT **********/

.offers.columns .item > * + *,
.offers.columns .infos > * + * {
	margin-top:-webkit-calc(var(--gutter-gap) / 2);
	margin-top:-moz-calc(var(--gutter-gap) / 2);
	margin-top:calc(var(--gutter-gap) / 2);
	margin-top:var(--gutter-gap);
}

.offers.columns .infos {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}
.offers.columns nav.switch {
	margin-left:auto;
	margin-right:auto;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.offers.columns .item > * + *,
	.offers.columns .infos > * + * {
		margin-top:var(--gutter-gap);
	}

	/* columns.baseline */
	.offers.columns.baseline > *,
	.offers .columns.baseline > * {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		-webkit-box-align:start;
		-webkit-align-items:flex-start;
		   -moz-box-align:start;
		    -ms-flex-align:start;
		        align-items:flex-start;
		-webkit-flex-basis:auto;
		    -ms-flex-preferred-size:auto;
		        flex-basis:auto; /* IOS fix */
	}
	.offers.columns.baseline figure,
	.offers .columns.baseline figure {
		width:20%;
	}
	.offers.columns.baseline .infos,
	.offers .columns.baseline .infos {
		width:80%;
		padding:0;
		margin:0 0 0 1rem;
	}
}
@media (min-width:760px) {
	.offers.columns .item > * + *,
	.offers.columns .infos > * + * {
		margin-top:-webkit-calc(var(--gutter-gap) / 2);
		margin-top:-moz-calc(var(--gutter-gap) / 2);
		margin-top:calc(var(--gutter-gap) / 2);
	}
}
@media (min-width:1000px) {

	/* columns.baseline */
	.offers.columns.baseline > *,
	.offers .columns.baseline > * {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.offers.columns.baseline figure,
	.offers .columns.baseline figure {
		width:100%;
	}
	.offers.columns.baseline .infos,
	.offers .columns.baseline .infos {
		width:100%;
		margin:1rem 0 0 0;
	}
}

/**********************************/
/********** OFFERS SCREENS ********/
/**********************************/

/*
.offers.screens
.offers.screens.large == no border
*/

.offers.screens {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	-webkit-box-align:baseline;
	-webkit-align-items:baseline;
	   -moz-box-align:baseline;
	    -ms-flex-align:baseline;
	        align-items:baseline;
	border:solid var(--stroke-width);/* var(--bright-color);*/
	font-size:.625rem;
	text-transform:uppercase;
}
.offers.screens > * {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:start;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	    -ms-flex-pack:start;
	        justify-content:flex-start;
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
}
.offers.screens .title {
	font-size: 2em;
	text-transform: none;
}
.offers.screens figure:not(.device) {
	max-width:8rem;
}
.offers.screens .streams {
	margin-top:var(--margin-normal);
}
.offers.screens .streams li {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
.offers.screens .streams [class^="icon-"] {
	display:block;
	font-size:4em;
}
.offers.screens .streams span:not([class^="icon-"]) {
	margin-top:.75em;
}

/********** ALTERNATIVES **********/

.offers.screens.large {
	border:none;
}

/********** FIGURES **********/

.offers.screens svg path.alt {
	opacity:.25;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.offers.screens {
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
	}
	.offers.screens > * {
		-webkit-flex-basis:15%;
		    -ms-flex-preferred-size:15%;
		        flex-basis:15%;
		margin:var(--gutter-gap);
	}
}
@media (min-width:640px) {
	.offers.screens {
		-webkit-flex-wrap:nowrap;
		    -ms-flex-wrap:nowrap;
		        flex-wrap:nowrap;
		padding:var(--gutter-gap) -webkit-calc(var(--gutter-gap) / 2);
		padding:var(--gutter-gap) -moz-calc(var(--gutter-gap) / 2);
		padding:var(--gutter-gap) calc(var(--gutter-gap) / 2);
	}
	.offers.screens > * {
		-webkit-flex-basis:25%;
		    -ms-flex-preferred-size:25%;
		        flex-basis:25%;
		margin:0 -webkit-calc(var(--gutter-gap) / 2);
		margin:0 -moz-calc(var(--gutter-gap) / 2);
		margin:0 calc(var(--gutter-gap) / 2);
	}
}

/*********************************/
/********** OFFERS APPS **********/
/*********************************/

.offers.apps {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	text-align:center;
	margin-left:auto;
	margin-right:auto;
}
.offers.apps > * {
	width:auto;
	margin-left:auto;
	margin-right:auto;
}
.offers.apps figure:not(.devices) {
	max-width:8rem;
}
.offers.apps figure.devices {
	max-width:16rem;
}
.offers.apps figure svg {
	max-width:none;
	width:100%;
}

/***************************/
/***************************/
/********** FILMS **********/
/***************************/
/***************************/

/*
for .list and .grid

.list.films
.grid.films
.grid.list.films
*/

/********** UNAVAILABLE **********/

.films .unavailable .cover,
.films .unavailable .infos > *,
.films .unavailable .is-recommended {
	opacity:.33;
}
.films .unavailable .tooltip {
	opacity:1;
}

/********** FILMS INFOS **********/

.films .infos > .title,
.films .infos > .csa-classification {
	display:inline-block;
	vertical-align:middle;
}
.films .infos > * + * {
	margin:.25rem 0 0 0;
}
.films .infos > * + p {
	margin:1.5rem 0 0 0;
}
/*
.films .title {
	display:-webkit-box !important;
	display:-webkit-flex !important;
	display:-moz-box !important;
	display:-ms-flexbox !important;
	display:flex !important;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
*/
.films .title .film-status {
	-webkit-align-self:flex-start;
	    -ms-flex-item-align:start;
	        align-self:flex-start;
	margin-left:auto;
	padding:0;
}

/********** FILMS FORMAT **********/

.films .format {
	display:inline-block;
	font-size:.75rem;
	line-height:.75rem;
}
.films .format:before {
	display:inline-block;
	font-family:'icomoon';
	font-size:.75em;
	line-height:0;
	margin-right:1em;
}

/********** FILM QUOTATIONS **********/
/* in archive-top-directors-films */

.film-quotations {
	display:inline-block;
	font-size:.5rem;
	line-height:1em;
	text-align: center;
	padding:.25rem 0;
	margin:-.25rem 0;
	height:1rem;
	width:1rem;
	color:inherit;
	text-shadow:inherit;
}

/********** FILM LINK **********/

.films .film-link {
	height:100%;
	position:relative;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.films .film-link > * {
	display:block;
	width:100%;
}
.films .film-link .duration {
	top:0;
	right:0;
}

.svod .films .film-link {
	background:var(--grey-color);
}

/********** FILMS SMALL **********/
/* film-status */

.films small {
	font-size:.675rem;
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	text-transform:uppercase;
	padding-left:1em;
}
.films .unavailable small {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
}
.films .unavailable.soon small {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
}

/********** FILMS USER AND PLAYLIST STATUS **********/

/* color and opacity temp for test !!!! */

.user-status,
.playlist-status {
	font-size:.875rem;
	padding:0 !important;
	margin:0 !important;
}
.films .in-playlist .playlist-status:before,
.films .is-rented .user-status:before,
.films .is-bought .user-status:before {
	display:inline-block;
	font-family:'icomoon';
	font-size:.75em;
	line-height:0;
	margin-left:1em;
}
.films .user-status small {
	color:inherit;
	text-shadow:inherit;
	padding-left:.25em;
}
.films .playlist-status small {
	padding-left:.25em;
}
.films .user-status,
.films .playlist-status {
	opacity:.25;
}
.films .item:hover .user-status,
.films .item:hover .playlist-status {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	opacity:1;
}

/********** PLAYLISTS **********/
/* V4 */

.playlists .cover {
	aspect-ratio: 16/9;
}

/****************************/
/****************************/
/********** INLINE **********/
/****************************/
/****************************/

/*
.inline
.inline.full
.inline.films (bundle list)
inline.short
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

.inline {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.inline > * {
	display:inline-block;
	vertical-align:middle;
	margin-top:0 !important;
}
ul.inline {
	vertical-align:top;
}

/***********************************/
/********** INLINE CUSTOM **********/
/***********************************/

.centered .inline {
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}
.inline .button {
	white-space: nowrap;
}
.inline.short > * {
	width:auto;
}
.inline.full {
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
}
.inline.full > * {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
}

/*************************************/
/********** INLINE PARTNERS **********/
/*************************************/

/*
in footer		== cf. footer
in festival 	== logos (white bg)

inline gap from .inline
*/

/********** DEFAULT **********/

.inline.partners {
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}

/********** IN FOOTER **********/

footer .inline.partners {
	overflow:hidden;
}
footer * + .inline.partners {
	margin-top:.5rem !important;
}
footer .inline.partners img {
	max-height:none;
	-webkit-filter:grayscale(100%);
	        filter:grayscale(100%);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	/* partners */
	.inline.partners {
		margin-left:auto;
		margin-right:auto;
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
	}
	.inline.partners img {
		max-height:2rem;
		max-width:6rem;
	}
	.inline.partners img.vertical {
		max-height:4rem;
	}
	.inline.partners img.horizontal {
		max-width:6rem;
	}
	/* footer partners */
	footer .inline.partners > * {
		width:-webkit-calc(100% / 8);
		width:-moz-calc(100% / 8);
		width:calc(100% / 8);
	}
	footer .inline.partners img {
		max-height:none;
		max-width:100%;
	}
}
@media (min-width:760px) {
	/* footer partners */
	footer .inline.partners > * {
		width:-webkit-calc(100% / 10);
		width:-moz-calc(100% / 10);
		width:calc(100% / 10);
	}
}
@media (min-width:1000px) {
	/* partners */
	.inline.partners {
		-webkit-flex-wrap:unset;
		    -ms-flex-wrap:unset;
		        flex-wrap:unset;
	}
	.inline.partners > * {
		width:auto;
	}
	.inline.partners img {
		max-width:100%;
	}
}

/**********************************/
/********** INLINE FILMS **********/
/**********************************/

/* in single-film == bundle (+ slider.css) */

.inline.films > * > a > * {
	font-size:.875rem;
}
.inline.films > * > a > .title {
	font-size:1rem;
}
.inline.films > * > a > * + * {
	margin:.25em 0 0 0;
}
.inline.films .film-link {
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
}
.inline.films .infos {
	display:block;
	margin:1rem 0 0 0;
}
.inline.films .title {
	font-size:1rem;
}
.inline.films .director {
	font-size:.75rem;
}
.inline.films .cover {
	position:relative;
}
.inline.films > .item .cover:before {
	content:"";
	position:absolute;
	z-index:20;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:solid var(--stroke-width) transparent;
	pointer-events:none;
}
.inline.films > .item:hover .cover:before {
	border:solid var(--stroke-width) var(--yellow-color);
}

/****************************/
/********** @MEDIA **********/
/****************************/

@media (min-width:0px) {
	.inline > * + * {
		margin-left:.5rem;
	}
}
@media (min-width:760px) {
	.inline > * + * {
		margin-left:1rem;
	}
	.inline.films > * {
		max-width:20rem;
	}
}

/**************************/
/**************************/
/********** LIST **********/
/**************************/
/**************************/

/*
.films == for .list.films / .grid.films / .grid.list.films

.list.films (director list) == default
.list.films.has-comments (director list with comments)
.list.films.short (ex. selection anchors) ???

.list.films.selection (selection) == with figure
.list.films.director-list (director list)

cf. grid.css
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

.list > * {
	display:block;
}

/********************************/
/********** LIST FILMS **********/
/********************************/

.list.films .item,
.list.films .item-content {
	position: relative;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
}
.list.films > li + li {
	margin:0;
}
.list.films .item-content {
	width:100%;
	padding:1rem;
}
.list.films > .item:before {
	content:"";
	position:absolute;
	z-index:20;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:solid var(--stroke-width) rgba(var(--yellow-rgb), 0);
	pointer-events:none;
}
.list.films > .item:hover:before {
	border:solid var(--stroke-width) rgba(var(--yellow-rgb), 1) !important;
}

/********** LIST FILMS HAS-COMMENTS **********/
/*
in single-director == .list.films.has-comments
in single-playlist == .list.films.has-comments
*/

.list.films.has-comments .item,
.list.films.has-comments .item-content {
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
}
.list.films.has-comments .item-content {
	position:relative;
}
.list.films.has-comments .item-content > * {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
}
.list.films.has-comments .item-content > .film-actions {
	-webkit-box-flex:auto;
	-webkit-flex-grow:auto;
	   -moz-box-flex:auto;
	    -ms-flex-positive:auto;
	        flex-grow:auto;
}
.list.films.has-comments .item-content > .infos,
.list.films.has-comments .item-content > .is-recommended {
	width:100%;
}
.list.films.has-comments .film-event {
	position:absolute;
	top:0;
	left:0;
	margin:-1.5rem 0 0 0;
	padding-left:0;
}
.list.films.has-comments .is-recommended {
	padding:0;
}
.list.films.has-comments > * > .duration {
	right:0;
}
.list.films.has-comments .collapsible {
	margin:0;
}

/********** LIST FILMS IS-DRAGGABLE **********/
/* hover is on item-content NOT item */

.list.films.is-draggable .item-content {
	min-height:6rem; /* for 2 arrows */
}
.list.films.is-draggable .item:before {
	display:none;
}
.list.films.is-draggable .item-content:before {
	content: "";
	position: absolute;
	z-index: 20;
	inset:0 0 0 3rem;
	border: solid var(--stroke-width) rgba(var(--yellow-rgb), 0);
	pointer-events: none;
}
.list.films.is-draggable .item-content:hover:before {
	border: solid var(--stroke-width) rgba(var(--yellow-rgb), 1);
}
.list.films.is-draggable .item:hover nav.film-actions {
	opacity:0;
}
.list.films.is-draggable .item .item-content:hover + nav.film-actions,
.list.films.is-draggable .item nav.film-actions:hover {
	opacity: 1;
}
.list.films.is-draggable .item .film-actions {
	right:0;
}
.drag {
	cursor:-webkit-grab;
	cursor:-moz-grab;
	cursor:grab;
}

/** only 1 item **/

.list.films.is-draggable .item:first-child:last-child .item-content:before {
	top:0;
	right:0;
	bottom:0;
	left:0;
}
.list.films.is-draggable .item:first-child:last-child .film-actions {
	right:0;
}
.list.films.is-draggable .item:first-child:last-child nav.list-actions {
	display:none;
}

/********** LIST FILMS SELECTION **********/
/* in single-selection.php == .selection */

.list.films.selection > *:before {
	border-top:solid var(--stroke-width) var(--grey-color);
}
.list.films.selection .item-content {
	height:100%;
	position:relative;
	-webkit-box-align:start;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	    -ms-flex-align:start;
	        align-items:flex-start;
}
.list.films.selection .item-content > * {
	width:100%;
}
.list.films.selection .title {
	display:inline-block !important;
	vertical-align:middle;
}
.list.films.selection .original:before {
	content:"\a";
	white-space:pre;
}
.list.films.selection .item-content .duration {
	top:0;
	right:0;
}
.list.films.selection .is-recommended {
	font-size:.75rem;
}

/********** LIST FILMS DIRECTOR **********/
/* in single-director.php == .director-list (different from .director) */

.list.films.director-list > *:before {
	border-top:solid var(--stroke-width) var(--light-color);
}
.list.director-list.films .item-content {
	color:var(--lighter-color);
	text-shadow:0 0 0 var(--lighter-color);
}
.list.director-list.films .title,
.list.director-list.films b,
.list.director-list.films .director,
.list.director-list.films .comments {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.list.director-list.films .infos {
	position:relative;
	-webkit-box-flex:3 !important;
	-webkit-flex-grow:3 !important;
	   -moz-box-flex:3 !important;
	    -ms-flex-positive:3 !important;
	        flex-grow:3 !important;
}
.list.director-list.films .original {
	margin:0;
	padding:0 0 0 1rem;
	color:var(--lighter-color);
	text-shadow:0 0 0 var(--lighter-color);
}
.list.films.director-list .title .film-status {
	position:absolute;
	top:0;
	left:0;
	margin:-1.5rem 0 0 0;
	padding-left:0;
}
.list.films.director-list .is-recommended {
	-webkit-box-flex:3 !important;
	-webkit-flex-grow:3 !important;
	   -moz-box-flex:3 !important;
	    -ms-flex-positive:3 !important;
	        flex-grow:3 !important;
}

/********** LIST PLAYLIST FILMS **********/
/* in single-playlist.php == .playlist */

.list.films.playlist > *:before {
	border-top:solid var(--stroke-width) var(--light-color);
}
.list.films.playlist .item-content {
	color:var(--lighter-color);
	text-shadow:0 0 0 var(--lighter-color);
}
.list.films.playlist .title,
.list.films.playlist b,
.list.films.playlist .director,
.list.films.playlist .comments {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.list.films.playlist .infos {
	position:relative;
	-webkit-box-flex:3 !important;
	-webkit-flex-grow:3 !important;
	   -moz-box-flex:3 !important;
	    -ms-flex-positive:3 !important;
	        flex-grow:3 !important;
}
.list.films.playlist .original {
	margin:0;
	padding:0 0 0 1rem;
	color:var(--lighter-color);
	text-shadow:0 0 0 var(--lighter-color);
}
.list.films.playlist .title .film-status {
	position:absolute;
	top:0;
	left:0;
	margin:-1.5rem 0 0 0;
	padding-left:0;
}
.list.films.playlist .is-recommended {
	-webkit-box-flex:3 !important;
	-webkit-flex-grow:3 !important;
	   -moz-box-flex:3 !important;
	    -ms-flex-positive:3 !important;
	        flex-grow:3 !important;
}
.list.films.playlist .infos {
	margin:0;
}

/* list.films playlist in overlay */

.overlay .list.films.playlist .cover {
	max-width:6rem;
}
.overlay .list.films.playlist .title {
	font-size:1rem;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	/* default list */
	.list.films .item,
	.list.films .item-content {
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.list.films .item > * {
		width:100%;
	}
	.list.films .title {
		font-size:1rem;
	}
	.list.films .original {
		font-size:.75rem;
	}
	/* has-comments && is-draggable == actions + drag or comment */
	.list.films.has-comments .item-content {
		padding:1rem 1rem 1rem 4rem;
	}
	.list.films.is-draggable .item-content {
		padding:1rem 4rem;
	}
	.list.films.is-draggable .item:first-child:last-child .item-content {
		padding:1rem 4rem 1rem 1rem;
	}
	/* has-comments */
	.list.films.has-comments .item-content > * {
		-webkit-box-ordinal-group:2;
		-webkit-order:1;
		   -moz-box-ordinal-group:2;
		    -ms-flex-order:1;
		        order:1;
	}
	.list.films.has-comments .item-content > .is-recommended {
		-webkit-box-ordinal-group:4;
		-webkit-order:3;
		   -moz-box-ordinal-group:4;
		    -ms-flex-order:3;
		        order:3;
	}
	.list.films.has-comments .film-bonus {
		-webkit-box-ordinal-group:5;
		-webkit-order:4;
		   -moz-box-ordinal-group:5;
		    -ms-flex-order:4;
		        order:4;
	}
	.list.films.has-comments .film-infos,
	.list.films.has-comments .is-recommended,
	.list.films.has-comments .comments {
		font-size:.75rem;
	}
	.list.films.has-comments .film-bonus {
		margin:0 auto 0 0;
		min-width:8.688rem;
	}
	.list.films.has-comments .has-bonus a {
		margin:1rem 0 0 0;
	}
	.list.films.has-comments .has-bonus * + * {
		margin:1rem 0 0 1em !important;
	}
	.list.films.has-comments .is-recommended,
	.list.films.has-comments .collapsible.active {
		margin:1rem 0 0 0 !important;
	}
	/* selection */
	.list.films.selection .film-infos,
	.list.films.selection .is-recommended,
	.list.films.selection p {
		font-size:.75rem;
	}
	.list.films.selection .item-content {
		padding:1rem;
	}
	.list.films.selection .item-content > * + * {
		margin:1rem 0 0 0;
	}
	/* director-list */
	.list.director-list.films .infos {
		margin:0;
	}
	/* playlist */
	.list.films.playlist .item-content {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.list.films.playlist .cover,
	.list.films.playlist .film-infos .year,
	.list.films.playlist .film-infos .country,
	.list.films.playlist .film-infos .duration {
		display:none;
	}
	.list.films.playlist .film-infos,
	.list.films.playlist .is-recommended,
	.list.films.playlist .comments {
		font-size:.75rem;
	}
}
@media (min-width:480px) {
	/* playlist */
	.list.films.playlist .cover {
		display:block;
		margin:0 .75rem 0 0;
		max-width:6rem;
	}
	.list.films.playlist .infos {
		max-width:-webkit-calc(100% - 6rem - .75rem);
		max-width:-moz-calc(100% - 6rem - .75rem);
		max-width:calc(100% - 6rem - .75rem);
	}
}
@media (min-width:640px) {
	/* playlist */
	.list.films.playlist .film-infos .year,
	.list.films.playlist .film-infos .country,
	.list.films.playlist .film-infos .duration {
		display:inline-block;
	}
}
@media (min-width:760px) {
	/* default */
	.list.films .item > * {
		-webkit-flex-basis:100%;
		    -ms-flex-preferred-size:100%;
		        flex-basis:100%;
	}
	.list.films .item,
	.list.films .item-content {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.list.films .title {
		font-size:1.25rem;
	}
	.list.films .original {
		font-size:.875rem;
	}
	/* selection */
	.list.films.selection .film-infos,
	.list.films.selection .is-recommended,
	.list.films.selection p {
		font-size:.875rem;
	}
	.list.films.selection .item-content {
		padding:2rem;
	}
	.list.films.selection .item > * {
		width:auto;
	}
	.list.films.selection .item-content > * + * {
		margin:0 0 0 2rem;
	}
	.list.films.selection .item-content .film-link {
		width:60%;
	}
	/* playlist */
	.list.films.playlist .cover {
		margin:0 1.25rem 0 0;
		max-width:10rem;
	}
	.list.films.playlist .infos {
		max-width:-webkit-calc(100% - 10rem - 1.5rem);
		max-width:-moz-calc(100% - 10rem - 1.5rem);
		max-width:calc(100% - 10rem - 1.5rem);
	}
}
@media (min-width:1000px) {
	/* has-comments */
	.list.films.has-comments .item-content {
		padding:1.25rem 4rem;
	}
	.list.films.has-comments .item-content > *:not(.film-actions) {
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
	}
	.list.films.has-comments .item-content .comments {
		-webkit-flex-basis:100%;
		    -ms-flex-preferred-size:100%;
		        flex-basis:100%;
	}
	.list.films.has-comments .film-infos,
	.list.films.has-comments .comments {
		font-size:.875rem;
	}
	.list.films.has-comments .is-recommended {
		font-size:.8125rem;
	}
	.list.films.has-comments .is-recommended {
		font-size:.8125rem;
	}
	.list.films.has-comments .item-content > *,
	.list.films.has-comments .item-content > .is-recommended,
	.list.films.has-comments .has-bonus {
		-webkit-box-ordinal-group:2;
		-webkit-order:1;
		   -moz-box-ordinal-group:2;
		    -ms-flex-order:1;
		        order:1;
	}
	.list.films.has-comments .film-bonus {
		min-width:10.273rem;
	}
	.list.films.has-comments .has-bonus {
		width:auto;
		margin:0 1rem;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.list.films.has-comments .has-bonus a {
		margin:0;
	}
	.list.films.has-comments .has-bonus * + * {
		margin:1em 0 0 0 !important;
	}
	.list.films.has-comments .is-recommended {
		margin:0 !important;
		padding-right:5rem;
	}
	.list.films.has-comments .collapsible.active {
		margin:2rem 0 0 0 !important;
	}
	/* selection */
	.list.films.selection .title {
		font-size:1.25rem;
	}
	.list.films.selection .film-infos {
		font-size:1rem;
	}
	.list.films.selection .film-infos > * + * {
		margin:0 0 0 1.5em !important;
	}
	.list.films.selection .actors {
		font-size:1rem;
	}
	/* playlist */
	.list.films.playlist .film-infos,
	.list.films.playlist .comments {
		font-size:.875rem;
	}
}
@media (min-width:1200px) {
	/* default */
	.list.films .title {
		font-size:1.5rem;
	}
	/* selection */
	.list.films.selection .title {
		font-size:1.5rem;
	}
	.list.films.selection .film-infos {
		font-size:1.25rem;
	}
	.list.films.selection .film-infos > * + * {
		margin:0 0 0 2em !important;
	}
}

/*****************************/
/*****************************/
/********** RESULTS **********/
/*****************************/
/*****************************/

/*
archive == films / top
*/

/********** RESULTS CONTAINER **********/

.results-container {
	position:relative;
}
.results-container nav.contextual.fixed + nav.nav-results + .results.list {
	padding-left:4rem;
}

/********** RESULTS NAV **********/

.results-container > nav.nav-results {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	position:fixed;
	top:-webkit-calc(var(--header-height) + 3.875rem);
	top:-moz-calc(var(--header-height) + 3.875rem);
	top:calc(var(--header-height) + 3.875rem);
	left:0;
	bottom:0;
	width:5rem;
	overflow:auto;
	padding:1rem;
	background:none;
}
body.mobile .results-container > nav.nav-results {
	top:0;
}
.results-container > nav.nav-results > a {
	display:block;
	text-align:center;
	border-bottom:solid var(--stroke-width-medium) var(--bright-color);
}
.results-container > nav.nav-results > a:first-child {
	border-top:solid var(--stroke-width-medium) var(--bright-color);
	margin-top: auto !important;
}
.results-container > nav.nav-results > a:last-child {
	margin-bottom: auto !important;
}
.results-container nav.contextual + nav.nav-results {
	visibility:hidden;
	-webkit-transform:translate3d(-100%,0,0);
	   -moz-transform:translate3d(-100%,0,0);
	        transform:translate3d(-100%,0,0);
}
.results-container nav.contextual.fixed + nav.nav-results {
	visibility:visible;
	-webkit-transform:translate3d(0,0,0);
	   -moz-transform:translate3d(0,0,0);
	        transform:translate3d(0,0,0);
}
.results-container nav.contextual.fixed nav.nav-results {
	height:0;
	visibility:hidden;
}

/********** RESULTS LIST **********/

.results > li {
	position:relative;
	list-style-type:none;
}
.results-title {
	position:relative;
	display:block;
	width:100%;
	padding:1rem 0;
}
.results-title:after {
	content:"";
	position:absolute;
	bottom:0;
	left:0;
	height:var(--stroke-width);
	width:100%;
	background:var(--bright-color);
}

/********** RESULTS WITH SUB LIST **********/

.results > li > .sub.list {
	padding:0;
}
.results > li > .sub.list > li {
	position:relative;
}
.results > li > .sub.list > li .list,
.results > li > .sub.list > li + li {
	padding:1rem 0 0 0;
}
.results-sub-title {
	position:relative;
	display:inline-block;
	padding:0;
}

/********** RESULTS WITH GRID **********/

.results .results-sub-title + .grid {
	margin-top:0 !important;
}

/********** @MEDIA **********/
/* .results li.results-title => all in one results list for performance */

@media (min-width:0px) {
	.results > li  + li {
		margin-top:1rem;
	}
	.results li.results-title {
		margin-bottom:-webkit-calc(1rem - var(--stroke-width) / 2) !important;
		margin-bottom:-moz-calc(1rem - var(--stroke-width) / 2) !important;
		margin-bottom:calc(1rem - var(--stroke-width) / 2) !important;
	}
}
@media (min-width:1000px) {
	.results li.results-title {
		margin-bottom:-webkit-calc(2rem - var(--stroke-width) / 2) !important;
		margin-bottom:-moz-calc(2rem - var(--stroke-width) / 2) !important;
		margin-bottom:calc(2rem - var(--stroke-width) / 2) !important;
	}
}

/********** REACT VIRTUALIZED **********/
/* ReactVirtualized__Grid > ReactVirtualized__Grid__innerScrollContainer */

.ReactVirtualized__Grid__innerScrollContainer {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	width:100% !important;
}

@media (min-width:0px) {
	.results > div  + div {
		margin-top:1rem;
	}
	.results div.results-title {
		margin-bottom:-webkit-calc(1rem - var(--stroke-width) / 2) !important;
		margin-bottom:-moz-calc(1rem - var(--stroke-width) / 2) !important;
		margin-bottom:calc(1rem - var(--stroke-width) / 2) !important;
	}
}
@media (min-width:1000px) {
	.results div.results-title {
		margin-bottom:-webkit-calc(2rem - var(--stroke-width) / 2) !important;
		margin-bottom:-moz-calc(2rem - var(--stroke-width) / 2) !important;
		margin-bottom:calc(2rem - var(--stroke-width) / 2) !important;
	}
}

/***************************/
/***************************/
/********** BONUS **********/
/***************************/
/***************************/

/*
data-count == for number of bonus
.list.bonus == default > for exclusive
.list.bonus.archives > for archives

no more used > replaced in slider => slider.css
*/

/********** BONUS DEFAULT **********/

.bonus .item > * {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	width:100%;
}
.bonus a.title {
	display:inline-block;
	border-bottom:solid var(--stroke-width);
}

/********** BONUS ARCHIVES **********/

.bonus.archives .quote,
.list.bonus.archives .quote {
	width:100%;
	text-align:right;
	left:auto;
	-webkit-transform:none !important;
	   -moz-transform:none !important;
	    -ms-transform:none !important;
	     -o-transform:none !important;
	        transform:none !important;
}
.bonus.archives .source .title {
	display:inline-block;
}
.bonus.archives .source .title + * {
	margin-top:0;
}
.bonus.archives .source .source-date {
	color:rgb(101,101,110);
	text-shadow:0 0 0 rgb(101,101,110);
}

/********** BONUS OVERLAY **********/

.overlay .bonus.exclusive .is-about > *,
.overlay .list.bonus.exclusive .is-about > * {
	display:inline-block;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	margin-top:0;
}
.overlay .bonus.exclusive .is-about a:hover {
	color:var(--dark-color);
	text-shadow:none;
}
.overlay .bonus.archives .source,
.overlay .list.bonus.archives .source {
	font-size:1rem;
	text-align:left;
}
.overlay.red .bonus.exclusive .is-about,
.overlay.red .list.bonus.exclusive .is-about {
	color:var(--dark-color);
	text-shadow:none;
}
/*
.overlay.purple .bonus.archives .source-date,
.overlay.purple .list.bonus.archives .source-date {
	color:var(--dark-color);
	text-shadow:none;
}
*/
.overlay.bonus-archives-color .bonus.archives .source-date,
.overlay.bonus-archives-color .list.bonus.archives .source-date {
	color:var(--dark-color);
	text-shadow:none;
}

/********** BONUS SLIDERS **********/

.slider.list.bonus.has-thumbnails .slider-pagination .list-link,
.slider.bonus.exclusive.has-thumbnails .slider-pagination .list-link {
	font-size:1rem;
	line-height:normal;
}
.slider.has-thumbnails .slider-pagination .is-about {
	display:block;
	font-size:.75rem;
	line-height:normal;
}
.slider.has-thumbnails .slider-pagination * + .is-about {
	margin-top:.5rem;
}
.slider.has-thumbnails .slider-pagination .source {
	display:block;
	font-size:.75rem !important;
	line-height:normal;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}

/*****************************/
/*****************************/
/********** TOOLTIP **********/
/*****************************/
/*****************************/

/*
.tooltip		== top position / arrow bottom [default]
.tooltip.left	== left position / arrow right
.tooltip.right	== right position / arrow left
.tooltip.bottom	== bottom position / arrow top

.has-tooltip	== title (or data-tooltip ?) as tooltip content

has-tooltip > .tooltip		== only for html
#tooltipContainer			== fixed container JS created
#tooltipContainer.inGrid	== from default .grid
#tooltipContainer.inList	== from .grid.list
*/

.tooltip {
	position:absolute;
	z-index:10;
	top:0;
	left:50%;
	-webkit-transform:translate3d(-50%,-100%,0);
	   -moz-transform:translate3d(-50%,-100%,0);
	        transform:translate3d(-50%,-100%,0);
	padding:1.25rem !important;
	text-align:left;
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
	-webkit-box-shadow:rgba(var(--black-color),var(--alpha-rgb)) 0 0 .25rem;
	   -moz-box-shadow:rgba(var(--black-color),var(--alpha-rgb)) 0 0 .25rem;
	        box-shadow:rgba(var(--black-color),var(--alpha-rgb)) 0 0 .25rem;
	-webkit-border-radius:var(--border-radius);
	   -moz-border-radius:var(--border-radius);
	        border-radius:var(--border-radius);
	visibility: hidden;
	opacity: 0;
}
.tooltip.active {
	visibility: visible;
	opacity: 1;
}
.tooltip svg path {
	fill:var(--dark-color);
}
.tooltip:after {
	content:"";
	position:absolute;
	z-index:-1;
	top:100%;
	left:50%;
	-webkit-transform:translate3d(-50%,0,0) rotate(315deg);
	   -moz-transform:translate3d(-50%,0,0) rotate(315deg);
	        transform:translate3d(-50%,0,0) rotate(315deg);
	height:0;
	width:0;
	border-style:solid;
	border-width:1rem 0 0 1rem;
	border-color: transparent transparent transparent var(--bright-color);
	-webkit-box-shadow:rgba(var(--black-color),var(--alpha-rgb)) -.125rem .125rem .125rem;
	   -moz-box-shadow:rgba(var(--black-color),var(--alpha-rgb)) -.125rem .125rem .125rem;
	        box-shadow:rgba(var(--black-color),var(--alpha-rgb)) -.125rem .125rem .125rem;
	-webkit-transform-origin:center center;
	   -moz-transform-origin:center center;
	    -ms-transform-origin:center center;
	     -o-transform-origin:center center;
	        transform-origin:center center;
	margin:-.5625rem 0 0 0;
}

/********** TOOLTIP POSITION **********/

.tooltip.bottom {
	top:100%;
	-webkit-transform:translate3d(-50%,0,0);
	   -moz-transform:translate3d(-50%,0,0);
	        transform:translate3d(-50%,0,0);
}
.tooltip.left,
.tooltip.right {
	top:50%;
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
}
.tooltip.left {
	left:0%;
	-webkit-transform:translate3d(-100%,-50%,0);
	   -moz-transform:translate3d(-100%,-50%,0);
	        transform:translate3d(-100%,-50%,0);
}
.tooltip.right {
	left:100%;
}
.tooltip.left:after {
	top:50%;
	left:100%;
	-webkit-transform:rotate(225deg);
	   -moz-transform:rotate(225deg);
	    -ms-transform:rotate(225deg);
	     -o-transform:rotate(225deg);
	        transform:rotate(225deg);
	margin:0 0 0 -.5rem;
}
.tooltip.right:after {
	top:50%;
	left:0;
	-webkit-transform:rotate(45deg);
	   -moz-transform:rotate(45deg);
	    -ms-transform:rotate(45deg);
	     -o-transform:rotate(45deg);
	        transform:rotate(45deg);
	margin:0 0 0 -.5rem;
}
.tooltip.bottom:after {
	top:0%;
	-webkit-transform:rotate(135deg);
	   -moz-transform:rotate(135deg);
	    -ms-transform:rotate(135deg);
	     -o-transform:rotate(135deg);
	        transform:rotate(135deg);
	margin:-.5rem 0 0 0;
}

/********** FIXED TOOLTIP **********/

.tooltip,
#tooltipContainer .tooltip {
	border:0 !important;
}
#tooltipContainer {
	position:fixed;
	z-index:1200;
	top:0;
	left:0;
	width:100%;
	-webkit-transform:translate3d(-50%,0,0);
	   -moz-transform:translate3d(-50%,0,0);
	        transform:translate3d(-50%,0,0);
}
#tooltipContainer .tooltip > * {
	display:block !important;
	width:100%;
	font-size:.75rem;
}
.tooltip-toggle + .tooltip,
#tooltipContainer .tooltip {
    z-index:1100;
    visibility:hidden;
    opacity:0;
    top:1rem;
    width:100% !important;
    pointer-events:none;
    border:none;
}
#tooltipContainer .tooltip.active {
	top:-1rem;
	visibility:visible;
	opacity:1;
}

/********** HAS-TOOLTIP BUTTON **********/

a.has-tooltip:hover:after {
	content:attr(title);
	position:absolute;
	top:0;
	left:50%;
	display:block;
	white-space:nowrap;
	font-size:.75rem;
	line-height:1em;
	padding:1em;
	margin:-1em 0;
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
	-webkit-transform:translate3d(-50%,-100%,0);
	   -moz-transform:translate3d(-50%,-100%,0);
	        transform:translate3d(-50%,-100%,0);
}

/***********************************/
/***********************************/
/********** REACT LOADING **********/
/***********************************/
/***********************************/

.blueprint h2:not(.section-title),
.blueprint h3:not(.section-title),
.blueprint h4:not(.section-title),
.blueprint h5:not(.section-title),
.blueprint h6:not(.section-title),
.blueprint .title,
.blueprint .title-alt,
.blueprint .title-alt strong,
.blueprint p:not(.centered),
.blueprint .alert,
.blueprint blockquote,
.blueprint .source,
.blueprint .source-date,
.blueprint ul:not(.columns):not(.list):not(.results):not(.slides):not(.grid) li,
.blueprint ol li,
.blueprint figure,
.blueprint .videoplayer-container,
.blueprint .videoplayer,
.blueprint .original,
.blueprint .director,
.blueprint .year,
.blueprint .country,
.blueprint .duration,
.blueprint .date,
.blueprint .comments,
.blueprint .price,
.blueprint .collection-link,
.blueprint .list-link,
.blueprint .offer-infos,
.blueprint .is-recommended b,
.blueprint .slider.min .slide-content {
	background:var(--blueprint-color) !important;
	color:transparent !important;
	text-shadow:none !important;
	opacity:1;
}
.blueprint .offers .description + p {
	background:none !important;
}
.blueprint .grid figure {
	background:-webkit-repeating-linear-gradient(135deg,rgba(var(--grey-rgb),.5),rgba(var(--grey-rgb),.5) .25rem,rgba(var(--dark-rgb),.5) .25rem,rgba(var(--dark-rgb),.5) .5rem) !important;
	background:-moz-repeating-linear-gradient(135deg,rgba(var(--grey-rgb),.5),rgba(var(--grey-rgb),.5) .25rem,rgba(var(--dark-rgb),.5) .25rem,rgba(var(--dark-rgb),.5) .5rem) !important;
	background:-o-repeating-linear-gradient(135deg,rgba(var(--grey-rgb),.5),rgba(var(--grey-rgb),.5) .25rem,rgba(var(--dark-rgb),.5) .25rem,rgba(var(--dark-rgb),.5) .5rem) !important;
	background:repeating-linear-gradient(-45deg,rgba(var(--grey-rgb),.5),rgba(var(--grey-rgb),.5) .25rem,rgba(var(--dark-rgb),.5) .25rem,rgba(var(--dark-rgb),.5) .5rem) !important;
}
.blueprint .csa-classification,
.blueprint .film-status,
.blueprint .has-bonus,
.blueprint .list-link:after,
.blueprint .slide-bg,
.blueprint .background,
.blueprint figure img,
.blueprint figure svg,
.blueprint .videoplayer-container .videoplayer {
	opacity:0 !important;
}
