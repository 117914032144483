@charset "UTF-8";

/*
flex gap == margin-left instead
grid gap == ok
*/

/*
.grid [default]
.grid.list
.grid.min
.grid.archive
.grid.posts

.grid.films / .grid.list.films / .grid.archive.films / .grid.list.archive.films / .grid.alt.films / .grid.min.films
.grid.directors / .grid.list.directors / .grid.archive.directors

single-film == .grid == gallery

archive-top == .grid.films / .grid.list.films (small figure)
archive-films == .grid.films.archive / .grid.list.films.archive (no figure)
archive-news == .grid.films.alt (text inside / not working in list)

single-selection == .list.films
single-director == .list.films
playlist == .list.films

single-film == .grid.min == see also
single-film == .grid.min.films == see also films
*/

/*******************************/
/*******************************/
/********** GRID SVOD **********/
/*******************************/
/*******************************/

/* for sliders and grids */

.films.svod .svod-status.fixed {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease);
	-o-transition:
	opacity var(--duration-fast) var(--ease);
	-moz-transition:
	opacity var(--duration-fast) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease);
}

/********** GRID FILMS USER **********/

.films.svod .svod-status.fixed {
	position:absolute;
	bottom:auto;
	top: -1rem;
	left: 1rem;
	-webkit-transform: translate3d(0,-100%,0);
	   -moz-transform: translate3d(0,-100%,0);
	        transform: translate3d(0,-100%,0);
	width:auto;
	color: var(--dark-color);
	text-shadow: 0 0 0 none;
	background:var(--yellow-color);
	line-height:1em;
	padding:.5em;
	margin:0;
	opacity: 1;
}
.films.svod .svod-status:before {
	display: inline-block;
    font-family: 'icomoon';
    font-size: .75em;
    line-height: 0;
	margin-right: .5em;
}
.films.svod .item:hover .svod-status.fixed {
    opacity: 0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.films.svod .svod-status.fixed {
		font-size:.625rem;
	}
}
@media (min-width:760px) {
	.films.svod .svod-status.fixed {
		font-size:.75rem;
	}
}
@media (min-width:1000px) {
	.films.svod .svod-status.fixed {
		font-size:.875rem;
	}
}

/**************************/
/**************************/
/********** GRID **********/
/**************************/
/**************************/

/********** DEFAULT **********/

/*
.grid {
	display:-ms-grid;
	display:grid;
}
*/
.grid,
.grid .item,
.grid .item-content {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
}
.grid {
	-webkit-flex-wrap:wrap;
		-ms-flex-wrap:wrap;
			flex-wrap:wrap;
}
.grid .item {
	position:relative;
}
.grid .item-content {
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	width:100%;
	margin:0;
}

/********** GRID BORDERS **********/

.grid .item:before {
	content:"";
	position:absolute;
	z-index:20;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border: solid var(--stroke-width) rgba(var(--yellow-rgb), 0);
	pointer-events:none;
}
.grid .item:hover:before {
	border: solid var(--stroke-width) rgba(var(--yellow-rgb), 1);
}

/********** GRID ITEM-CONTENT **********/

.grid .item-content img {
	width:100%;
	max-width:none;
}

/********** GRID INFOS **********/

.grid .infos {
	position:relative;
	font-size:.875rem;
	padding:1rem;
	margin:0;
	pointer-events:none;
}
.grid .infos > * {
	pointer-events:auto;
}
.grid .infos > * + * {
	margin:.25rem 0 0 0;
}

/*******************************/
/*******************************/
/********** GRID LIST **********/
/*******************************/
/*******************************/

/*
films archive ==> cf. .list.films
directors list
*/

.grid.list.films .item:before {
	border-top:solid var(--stroke-width) rgba(var(--yellow-rgb), 0) !important;
}
.grid.list.films .item:hover:before {
	border-top:solid var(--stroke-width) rgba(var(--yellow-rgb), 1) !important;
}

/********** GRID LIST ARCHIVE **********/

.grid.list.archive.films .item-content > * {
	width:100%;
}
.grid.list.archive.films .film-link img.cover {
	display:none !important;
}

/******************************/
/******************************/
/********** GRID MIN **********/
/******************************/
/******************************/

/*
in single-film == see also == films / directors
*/

.grid.min > * > a {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-orient:horizontal !important;
	-webkit-box-direction:normal !important;
	-webkit-flex-direction:row !important;
	   -moz-box-orient:horizontal !important;
	   -moz-box-direction:normal !important;
	    -ms-flex-direction:row !important;
	        flex-direction:row !important;
}
.grid.min .item.hidden {
	display:none;
}

/********************************/
/********************************/
/********** HAS EXTRAS **********/
/********************************/
/********************************/

/*
in archive-top == .grid.films
in archive-news == .grid.posts
*/

.grid .has-extras {
	overflow:hidden;
}
.grid .has-extras .item-content {
	height:100%;
}
.grid .has-extras:hover > img {
	-webkit-filter:blur(.25rem);
	        filter:blur(.25rem);
}
.grid .extras {
	font-size:.75rem;
	padding:1rem;
}
.grid .extras .is-recommended {
	z-index:30;
}
.grid:not(.list) .extras {
	position:absolute;
	z-index:5;
	top:0;
	bottom:0;
	right:0;
	left:0;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.grid .has-extras .extras > * {
		display:none;
	}
	.grid .has-extras .extras .more-link {
		display:block;
	}
}
@media (min-width:760px) {
	.grid .has-extras .extras {
		visibility:hidden;
		opacity:0;
		background:rgba(var(--grey-rgb), var(--alpha-rgb));
		overflow:hidden;
	}
	.grid .has-extras .extras > * {
		display:block;
	}
	.grid .has-extras:hover .extras {
		visibility:visible;
		opacity:1;
	}
	/** posts **/
	.grid.posts .has-extras:hover .extras {
		margin:1rem 0 0 0;
	}
	.grid.posts .has-extras .extras {
		max-height:0;
	}
	.grid.posts .has-extras:hover .extras {
		max-height:100rem;
	}
}

/*********************************/
/*********************************/
/********** HAS TOOLTIP **********/
/*********************************/
/*********************************/

/*
in archive-films.php : only desktop
*/

/************************************/
/********** TOOLTIP-TOGGLE **********/
/************************************/

.grid .has-tooltip .tooltip-toggle {
	position:absolute;
	z-index:20;
	top:0;
	left:0;
	font-size:.75rem;
	line-height:1em;
	padding:.5em;
	margin:1em;
	height:1.5rem;
	width:1.5rem !important;
	opacity:0;
	color:var(--grey-color);
	text-shadow:none;
	background:var(--bright-color);
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
}
.grid .has-tooltip:hover .tooltip-toggle {
	opacity:1;
}
.grid .has-tooltip .tooltip-toggle:before {
	margin-right:0;
}

/*****************************/
/********** TOOLTIP **********/
/*****************************/

#tooltipContainer.inGrid {
	-webkit-transform:none;
	   -moz-transform:none;
	    -ms-transform:none;
	     -o-transform:none;
	        transform:none;
}
.grid .has-tooltip .tooltip > *,
#tooltipContainer.inGrid .tooltip > * {
	-webkit-box-flex:2;
	-webkit-flex-grow:2;
	   -moz-box-flex:2;
	    -ms-flex-positive:2;
	        flex-grow:2;
	-webkit-flex-basis:0;
	    -ms-flex-preferred-size:0;
	        flex-basis:0;
}
.grid .has-tooltip .tooltip > * + *,
#tooltipContainer.inGrid .tooltip > * + * {
	margin:1rem 0 0 0;
}
.grid .has-tooltip .tooltip > figure,
.grid .has-tooltip .tooltip > img,
#tooltipContainer.inGrid .tooltip > figure,
#tooltipContainer.inGrid .tooltip > img {
	float:left;
	width:40%;
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	margin:0 1rem 0 0;
}
.grid .has-tooltip .tooltip > figure + *,
.grid .has-tooltip .tooltip > img + *,
#tooltipContainer.inGrid .tooltip > figure + *,
#tooltipContainer.inGrid .tooltip > img + * {
	margin:0;
}
.grid .has-tooltip .tooltip > .is-recommended,
#tooltipContainer.inGrid .tooltip > .is-recommended {
	-webkit-flex-basis:100%;
	    -ms-flex-preferred-size:100%;
	        flex-basis:100%;
}
.grid .has-tooltip .tooltip > .is-recommended b,
#tooltipContainer.inGrid .tooltip > .is-recommended b {
	color:var(--dark-color);
	text-shadow:none;
}
.grid .has-tooltip .tooltip .duration:before,
#tooltipContainer.inGrid .tooltip .duration:before {
	font-family:"icomoon";
	display:inline-block;
	font-size:.75em;
	padding-right:.75em;
}

/*******************************/
/********** GRID LIST **********/
/*******************************/

.grid.list .has-tooltip .tooltip-toggle {
	top:0;
	left:0;
	bottom:0;
	right:auto;
}

/****************************/
/********** @MEDIA **********/
/****************************/

@media (min-width:0px) {
	/* tooltip-toggle */
	.grid .has-tooltip .tooltip-toggle {
		display:none;
	}
	/* default and list */
	.grid .has-tooltip .tooltip,
	#tooltipContainer.inGrid .tooltip {
		display:-webkit-box !important;
		display:-webkit-flex !important;
		display:-moz-box !important;
		display:-ms-flexbox !important;
		display:flex !important;
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
		-webkit-box-align:center !important;
		-webkit-align-items:center !important;
		   -moz-box-align:center !important;
		    -ms-flex-align:center !important;
		        align-items:center !important;
		-webkit-box-pack:justify !important;
		-webkit-justify-content:space-between !important;
		   -moz-box-pack:justify !important;
		    -ms-flex-pack:justify !important;
		        justify-content:space-between !important;
		max-width:none;
	}
	.grid .has-tooltip .tooltip:after,
	#tooltipContainer.inGrid .tooltip:after {
		left:0%;
		-webkit-transform:translate3d(100%,0,0) rotate(315deg);
		   -moz-transform:translate3d(100%,0,0) rotate(315deg);
		        transform:translate3d(100%,0,0) rotate(315deg);
	}
}
@media (min-width:760px) {
	/* tooltip-toggle */
	.grid .has-tooltip .tooltip-toggle {
		display:block;
	}
	/* list */
	.grid.list .has-tooltip .title {
		padding-left:3rem !important;
	}
}
@media (min-width:1000px) {
	/* default */
	.grid .has-tooltip .tooltip,
	#tooltipContainer.inGrid .tooltip {
		display:block !important;
	}
	/* list */
	.grid.list .has-tooltip .tooltip,
	#tooltipContainer.inGrid.inList .tooltip {
		display:-webkit-box !important;
		display:-webkit-flex !important;
		display:-moz-box !important;
		display:-ms-flexbox !important;
		display:flex !important;
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
		-webkit-box-align:center !important;
		-webkit-align-items:center !important;
		   -moz-box-align:center !important;
		    -ms-flex-align:center !important;
		        align-items:center !important;
		-webkit-box-pack:justify !important;
		-webkit-justify-content:space-between !important;
		   -moz-box-pack:justify !important;
		    -ms-flex-pack:justify !important;
		        justify-content:space-between !important;
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.grid.list .has-tooltip .tooltip:after,
	#tooltipContainer.inGrid.inList .tooltip:after {
		left:0%;
		-webkit-transform:translate3d(100%,0,0) rotate(315deg);
		   -moz-transform:translate3d(100%,0,0) rotate(315deg);
		        transform:translate3d(100%,0,0) rotate(315deg);
	}
	.grid.list .has-tooltip .tooltip > *,
	#tooltipContainer.inGrid.inList .tooltip > * {
		display:inline-block !important;
		color:var(--dark-color) !important;
		text-shadow:none !important;
	}
	.grid.list .has-tooltip .tooltip > figure,
	.grid.list .has-tooltip .tooltip > img,
	#tooltipContainer.inGrid.inList .tooltip > figure,
	#tooltipContainer.inGrid.inList .tooltip > img {
		margin:0;
	}
	.grid.list .has-tooltip .tooltip > .is-recommended,
	#tooltipContainer.inGrid.inList .tooltip > .is-recommended {
		margin:1rem 0 0 0;
	}
	.grid.list .has-tooltip .tooltip > * + *,
	#tooltipContainer.inGrid.inList .tooltip > * + * {
		margin:0 0 0 1rem;
	}
}
@media (min-width:1200px) {
	/* list */
	.grid.list .has-tooltip .tooltip,
	#tooltipContainer.inGrid.inList .tooltip {
		width:auto !important;
		max-width:none;
		-webkit-transform:translate3d(-100%,-100%,0);
		   -moz-transform:translate3d(-100%,-100%,0);
		        transform:translate3d(-100%,-100%,0);
	}
}

/********************************/
/********************************/
/********** GRID FILMS **********/
/********************************/
/********************************/

/*
.grid.films
.grid.list.films
.grid.min.films
.grid.archive.films
.grid.list.archive.films

grid.films working as grid.list.films in mobile
*/

/********** DEFAULT **********/

.grid.films .item {
	background:var(--grey-color);
}
.grid.films .film-link {
	width:100%;
}
/*
.grid.films .infos .title {
	white-space: nowrap;
	min-width: 0;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;
}
*/
.grid.films .infos .year:before,
.grid.films .infos .country:before,
.grid.films .infos .duration:before {
	font-family:"icomoon";
	display:inline-block;
	font-size:.75em;
	padding-right:.75em;
}
.grid.films.list .infos > .year:before,
.grid.films.list .infos > .country:before,
.grid.films.list .infos > .duration:before {
	display:none;
}

/********** GRID MIN FILMS **********/
/* @media*/

/********** GRID LIST FILMS **********/

.grid.list.films .film-link {
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
}

/********** GRID FILMS FORMAT **********/
/* in user page */

.grid.films .format {
	position:absolute;
	top:0;
	left:0;
	white-space:nowrap;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--darker-color);
	width:auto;
	pointer-events:none;
}

/********** GRID COLLECTION-LINK **********/
/* in institution(s) */

.grid.films .collection-link {
	display:block;
	color:var(--bright-color);
	text-shadow:var(--bright-color);
}

/********** GRID FILMS USER **********/

.grid.films.user .user-status.fixed {
	position:absolute;
	width:auto;
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	padding:1em;
	opacity:1;
	pointer-events:none;
}
.grid.films.user .user-status.fixed:before {
	margin-left:0;
	margin-right:.5em;
}
.grid.films.user > * .has-bonus {
	display:none!important;
}
.grid.films.user .film-actions .icon-play {
	display:none;
}
.grid.films.user .infos .title span:first-child {
	display:inline-block !important;
	border-bottom:solid var(--stroke-width) transparent;
}
.grid.films.user .infos:hover .title span:first-child {
	border-bottom:solid var(--stroke-width);
}

/********** GRID LIST FILMS USER **********/

.grid.films.list.user .more-link {
	border-left:none !important;
}
.grid.films.list.user .film-actions .icon-play {
	display:block;
}
/* cf. @media */

/********** @MEDIA **********/

@media (min-width:0px) {
	/* default */
	.grid.films .cover {
		margin:0;
		width:25%;
		max-width:10.75rem; /* V5 */
	}
	.grid.films .infos {
		display:block;
		width:75%;
		font-size:.75rem;
		margin:0;
		padding:1rem;
		color:var(--lighter-color);
		text-shadow:0 0 0 var(--lighter-color);
	}
	.grid.films .infos .title {
		font-size:.875rem;
		color:var(--bright-color);
		text-shadow:0 0 0 var(--bright-color);
	}
	.grid.films .infos .year,
	.grid.films .infos .country,
	.grid.films .infos .duration {
		display:none;
	}
	.grid.films ul.has-bonus {
		opacity:0;
	}
	.grid.films .format {
		font-size:.625rem;
		padding:.625rem !important;
		margin:.0625rem 0;
	}
	.grid.films .user-status.fixed {
		bottom:0;
		left:-25%;
		-webkit-transform:translate3d(-50%,-50%,0);
		   -moz-transform:translate3d(-50%,-50%,0);
		        transform:translate3d(-50%,-50%,0);
	}
	/* archive : default and list */
	.grid.films.archive .item-content {
		padding:1rem;
	}
	.grid.films.archive .cover {
		display:none;
	}
	.grid.films.archive .infos {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
		-webkit-box-pack:justify;
		-webkit-justify-content:space-between;
		   -moz-box-pack:justify;
		    -ms-flex-pack:justify;
		        justify-content:space-between;
		padding:0;
		margin:0 -1rem;
		width:-webkit-calc(100% + 2rem);
		width:-moz-calc(100% + 2rem);
		width:calc(100% + 2rem);
		color:var(--bright-color);
		text-shadow:0 0 0 var(--bright-color);
	}
	.grid.films.archive .infos > * {
		-webkit-box-flex:1;
		-webkit-flex-grow:1;
		   -moz-box-flex:1;
		    -ms-flex-positive:1;
		        flex-grow:1;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
		padding:0 1rem;
		margin:0;
	}
	.grid.films.archive .infos > * + * {
		border-left:solid var(--stroke-width) var(--dark-color);
	}
	.grid.films.archive .infos .title,
	.grid.films.archive .infos .director {
		display:inline-block;
	}

	/* playlists partners */
	.grid.films.archive.partners .film-link {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.grid.films.archive.partners .cover {
		display:block;
		width:100%;
		max-width:none;
	}

	/* institutions */
	.grid.films .collection-link {
		font-size:.625rem;
		line-height:1em;
		margin:.5rem 0 0 0;
	}
	/* list user */
	.grid.list.films.user .infos {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
		-webkit-box-pack:justify;
		-webkit-justify-content:space-between;
		   -moz-box-pack:justify;
		    -ms-flex-pack:justify;
		        justify-content:space-between;
		padding:0;
		margin:0 -1rem;
		width:-webkit-calc(100% + 2rem);
		width:-moz-calc(100% + 2rem);
		width:calc(100% + 2rem);
	}
	.grid.list.films.user .infos > * {
		-webkit-box-flex:1;
		-webkit-flex-grow:1;
		   -moz-box-flex:1;
		    -ms-flex-positive:1;
		        flex-grow:1;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
		line-height:1rem;
		padding:0 1rem;
		margin:0;
	}
	.grid.list.films.user .infos .title,
	.grid.list.films.user .infos .director {
		-webkit-box-flex:2;
		-webkit-flex-grow:2;
		   -moz-box-flex:2;
		    -ms-flex-positive:2;
		        flex-grow:2;
	}
	.grid.list.films.user .film-link .cover {
		height:3rem;
		width:auto;
	}
	.grid.list.films.user .format {
		margin:0;
	}
	.grid.list.films.user .user-status.fixed {
		position:relative;
		top:auto;
		left:auto;
		-webkit-transform:none;
		   -moz-transform:none;
		    -ms-transform:none;
		     -o-transform:none;
		        transform:none;
		border-left:none;
		font-size:1rem;
	}
	.grid.list.films.user .format {
		position:relative;
		top:auto;
		left:auto;
		color:inherit;
		text-shadow:inherit;
		background:none;
		padding:0 !important;
	}
	.grid.list.films.user .item-content {
		padding-right:3rem;
	}
	.grid.list.films.user .infos > * + * {
		border-left:solid var(--stroke-width) var(--dark-color);
	}
	.grid.list.films.user .cover,
	.grid.list.films.user .film-actions,
	.grid.list.films.user .format,
	.grid.list.films.user .infos .year,
	.grid.list.films.user .infos .country,
	.grid.list.films.user .infos .duration {
		display:none;
	}
}
@media (min-width:480px) {
	/* default */
	.grid.films .infos .year,
	.grid.films .infos .country,
	.grid.films .infos .duration {
		display:inline-block;
		margin:0 0 0 .5em;
	}
	/* archive */
	.grid.films.archive .infos .year,
	.grid.films.archive .infos .country,
	.grid.films.archive .infos .duration {
		display:none;
	}
	/* institutions */
	.grid.films .collection-link {
		font-size:inherit;
	}
}
@media (min-width:760px) {
	/* default */
	.grid.films .film-link {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.grid.films .cover {
		display:block;
		width:100%;
		max-width:none;
	}
	.grid.films .infos {
		width:100%;
	}
	.grid.films .infos .year,
	.grid.films .infos .country,
	.grid.films .infos .duration {
		margin:0 0 0 1em;
	}
	.grid.films .infos .title,
	.grid.films .infos .director {
		display:block;
	}
	.grid.films .infos .year {
		margin:.25rem 0 0 0;
	}
	.grid.films ul.has-bonus {
		opacity:1;
	}
	.grid.films .format {
		font-size:.75rem;
		padding:.75rem !important;
		margin:1rem;
	}
	.grid.films .user-status.fixed {
		position:absolute;
		bottom:auto;
		top:-1rem;
		left: 1rem;
		font-size:1.5rem;
		-webkit-transform: translate3d(0,-100%,0);
		   -moz-transform: translate3d(0,-100%,0);
		        transform: translate3d(0,-100%,0);
	}
	/* list */
	.grid.list.films .infos > * {
		line-height:1rem;
	}
	.grid.list.films ul.has-bonus {
		padding:0;
		margin:0 .75rem !important;
	}
	/* min */
	.grid.min.films .film-link {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.grid.min.films .cover {
		max-width:10.75rem;
	}
	.grid.min.films .infos .director {
		display:inline-block;
		margin:0 1em 0 0;
	}
	/* institutions */
	.grid.films .collection-link {
		position:absolute;
		top:0;
		right:0;
		padding:0;
		margin:-1rem 1rem 0 0 !important;
		-webkit-transform: translate3d(0,-100%,0);
		   -moz-transform: translate3d(0,-100%,0);
		        transform: translate3d(0,-100%,0);
	}
	/* list user */
	.grid.list.films.user .item-content {
		padding-right:1rem;
	}
	.grid.list.films.user .film-actions {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
	}
	.grid.list.films.user .format {
		display:inline-block;
	}
}
@media (min-width:1000px) {
	/* default */
	.grid.films .infos > * {
		line-height:normal;
		padding:0;
		margin:0;
	}
	.grid.films .infos > * + * {
		border-left:none;
		margin:.25rem 0 0 0;
	}
	.grid.films .infos .title {
		color:var(--bright-color);
		text-shadow:0 0 0 var(--bright-color);
		font-size:1rem;
	}
	.grid.films .infos .year,
	.grid.films .infos .country,
	.grid.films .infos .duration {
		display:inline-block;
	}
	.grid.films .has-bonus {
		margin:1rem;
	}
	/* grid.min */
	.grid.min.films .infos {
		font-size:.875rem;
	}
	/* list */
	.grid.list.films .infos {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
		 -webkit-box-pack:justify;
		 -webkit-justify-content:space-between;
		    -moz-box-pack:justify;
		     -ms-flex-pack:justify;
		         justify-content:space-between;
		padding:0;
		margin:0 -1rem;
		width:-webkit-calc(100% + 2rem);
		width:-moz-calc(100% + 2rem);
		width:calc(100% + 2rem);
	}
	.grid.list.films .infos > * + * {
		border-left:solid var(--stroke-width) var(--dark-color);
	}
	.grid.list.films .infos > .title,
	.grid.list.films .infos > .director {
		-webkit-box-flex:2;
		-webkit-flex-grow:2;
		   -moz-box-flex:2;
		    -ms-flex-positive:2;
		        flex-grow:2;
	}
	.grid.list.films .infos > .extras {
		-webkit-box-flex:4;
		-webkit-flex-grow:4;
		   -moz-box-flex:4;
		    -ms-flex-positive:4;
		        flex-grow:4;
	}
	.grid.list.films .film-link .cover {
		width:10%;
		margin:-1rem 1rem -1rem -1rem;
	}
	.grid.list.films .has-bonus {
		width:auto;
		margin:0 .675rem;
	}
	/* archive */
	.grid.films.archive .item-content {
		padding:0;
	}
	.grid.list.films.archive .item-content {
		padding:1rem;
	}
	.grid.films.archive .cover {
		display:block;
		width:100%;
		max-width:none;
	}
	.grid.films.archive .infos .year,
	.grid.films.archive .infos .country,
	.grid.films.archive .infos .duration {
		display:inline-block;
	}
	.grid.films.archive .film-link {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.grid.films.archive .cover {
		display:block;
		width:100%;
		max-width:none;
	}
	.grid.films.archive .infos {
		display:block;
		width:100%;
		padding:1rem;
		color:var(--lighter-color);
		text-shadow:0 0 0 var(--lighter-color);
	}
	.grid.films.archive .infos > * {
		padding:0;
	}
	.grid.films.archive .infos > * + * {
		border-left:none;
		margin:.25rem 0 0 0;
	}
	.grid.films.archive .infos .title {
		display:block;
		margin:0;
	}
	.grid.films.archive .infos .director {
		display:block;
	}
	.grid.films.archive .infos .country,
	.grid.films.archive .infos .duration {
		margin:0 0 0 1em;
	}
	.grid.films.archive .has-bonus {
		margin:1rem;
	}
	/* archive list */
	.grid.list.films.archive .cover {
		display:none;
	}
	.grid.list.films.archive .infos {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
		-webkit-box-pack:justify;
		-webkit-justify-content:space-between;
		   -moz-box-pack:justify;
		    -ms-flex-pack:justify;
		        justify-content:space-between;
		padding:0;
		margin:0 -1rem;
		width:-webkit-calc(100% + 2rem);
		width:-moz-calc(100% + 2rem);
		width:calc(100% + 2rem);
		color:var(--bright-color);
		text-shadow:0 0 0 var(--bright-color);
	}
	.grid.list.films.archive .infos > * {
		-webkit-box-flex:1;
		-webkit-flex-grow:1;
		   -moz-box-flex:1;
		    -ms-flex-positive:1;
		        flex-grow:1;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
		line-height:1rem;
		padding:0 1rem;
		margin:0;
	}
	.grid.list.films.archive .infos .title,
	.grid.list.films.archive .infos .director {
		-webkit-box-flex:2;
		-webkit-flex-grow:2;
		   -moz-box-flex:2;
		    -ms-flex-positive:2;
		        flex-grow:2;
	}
	.grid.list.films.archive .infos > * + * {
		border-left:solid var(--stroke-width) var(--dark-color);
	}
	.grid.list.films.archive .has-bonus {
		margin:0 .675rem;
	}
	/* list user */
	.grid.list.films.user .infos .duration {
		display:inline-block;
	}
}
@media (min-width:1200px) {
	/* grid.min */
	.grid.min.films .infos .title {
		font-size:1.25rem;
	}
	/* list user */
	.grid.list.films.user .infos .year,
	.grid.list.films.user .infos .country {
		display:inline-block;
	}
}

/********** GRID ARCHIVE FILMS **********/

/* list and default are the same up to 1000px */

@media (max-width:1000px) {
	.grid.films.archive .cover {
		display:none;
	}
	.grid.films.archive .infos {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
		-webkit-box-pack:justify;
		-webkit-justify-content:space-between;
		   -moz-box-pack:justify;
		    -ms-flex-pack:justify;
		        justify-content:space-between;
		padding:0;
		margin:0 -1rem;
		width:-webkit-calc(100% + 2rem);
		width:-moz-calc(100% + 2rem);
		width:calc(100% + 2rem);
		color:var(--bright-color);
		text-shadow:0 0 0 var(--bright-color);
	}
	.grid.films.archive .infos > * {
		-webkit-box-flex:1;
		-webkit-flex-grow:1;
		   -moz-box-flex:1;
		    -ms-flex-positive:1;
		        flex-grow:1;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
		line-height:1rem;
		padding:0 1rem;
		margin:0;
	}
	.grid.films.archive .infos .title,
	.grid.films.archive .infos .director {
		-webkit-box-flex:2;
		-webkit-flex-grow:2;
		   -moz-box-flex:2;
		    -ms-flex-positive:2;
		        flex-grow:2;
	}
	.grid.films.archive .infos > * + * {
		border-left:solid var(--stroke-width) var(--dark-color);
	}
	.grid.films.archive .has-bonus {
		top:50%;
		right:0;
		bottom:auto;
		width:auto;
		-webkit-transform:translate3d(0,-50%,0);
		   -moz-transform:translate3d(0,-50%,0);
		        transform:translate3d(0,-50%,0);
		margin:0 .75rem;
	}
	.grid.films.archive .film-status {
		display:none !important;
	}
	/* nav film-actions */
	.grid.films.archive nav.film-actions {
		position: relative;
	}
}

/******************************************/
/******************************************/
/********** GRID FILMS PLAYLISTS **********/
/******************************************/
/******************************************/

/* PLAYLISTS == grid, list, slider */

/********** PLAYLISTS PARTNERS **********/

.grid.films.playlists.partners {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	   -moz-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
}

/********** FOR USERS **********/

.grid.list.films.playlists .infos .title:first-child {
	-webkit-box-flex:.5;
	-webkit-flex-grow:.5;
	   -moz-box-flex:.5;
	    -ms-flex-positive:.5;
	        flex-grow:.5;
}

/********** GRID LINK **********/
/* grid .item.link == button item (cf. slide.link) */

.grid .item.link {
	aspect-ratio:5/3;
}
.grid .item.link .button {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	/*gap:.5em;*/
	text-align:center;
	height:100%;
}
.grid .item.link .button:before {
	font-size:1.5em;
	line-height:1em;
}

/********** PLAYLISTS CONTENT **********/

.grid.films.playlists:not(.archive) .cover {
	max-width:none;
	width:100%;
}
.grid.films.playlists:not(.archive) .infos {
	position:absolute;
	z-index:10;
	bottom:0;
	left:0;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:start;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	    -ms-flex-align:start;
	        align-items:flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}
.grid.films.playlists:not(.archive) .infos h3 {
	line-height:1em;
	font-family:inherit;
	text-transform:uppercase;
	color:var(--bright-color) !important;
	text-shadow:0 0 0 var(--bright-color) !important;
	background:var(--black-color);
	margin:0;
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	-webkit-line-clamp: 1; /* number of lines to show */
			line-clamp: 1; 
}
.grid.films.playlists:not(.archive) .infos h4 {
	display:block;
	line-height:1em;
	text-transform:none;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--black-color);
	margin:0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.grid.films.playlists:not(.archive) .infos h3 {
		font-size:.75rem;
		line-height:1em;
		padding:.875rem;
	}
	.grid.films.playlists:not(.archive) .infos h4 {
		font-size:.625rem;
		line-height:1em;
		padding:.875rem;
		margin-top:-.875rem;
	}
}
@media (min-width:1000px) {
	.grid.films.playlists:not(.archive) h3 {
		font-size:.875rem;
	}
	.grid.films.playlists:not(.archive) .infos h4 {
		font-size:.75rem;
	}
}
@media (min-width:1200px) {
	.grid.films.playlists:not(.archive) .infos h3 {
		font-size:1rem;
	}
	.grid.films.playlists:not(.archive) .infos h4 {
		font-size:.875rem;
	}
}

/************************************/
/************************************/
/********** GRID DIRECTORS **********/
/************************************/
/************************************/

/********** GRID DIRECTORS DEFAULT **********/

.grid.directors .item {
	background:var(--grey-color);
}
.grid.directors .item a.title-alt {
	width:100%;
	line-height:1.5em;
}

/********** GRID DIRECTORS PASSED **********/

.grid.directors .item.passed a.title-alt {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
}
.grid.directors .item.passed a.title-alt {
	-webkit-transform: translate3d(0,-.5rem,0);
	   -moz-transform: translate3d(0,-.5rem,0);
	        transform: translate3d(0,-.5rem,0);
}
.grid.directors .item.passed a.title-alt:before {
	content:"Les films de leur vie";
	position:absolute;
	bottom:0;
	left:1rem;
	display:block;
	font-size:.625rem;
	line-height:1em;
	letter-spacing:.0625em;
	text-transform:uppercase;
	-webkit-transform: translate3d(0,-50%,0);
	   -moz-transform: translate3d(0,-50%,0);
	        transform: translate3d(0,-50%,0);
}

/********** GRID DIRECTORS FEATURED **********/

.grid.directors .item.featured a.title-alt:before {
	position:absolute;
	top:50%;
	left:0;
	display:block;
	font-family:'icomoon';
	font-size:.75rem;
	line-height:1em;
	-webkit-transform: translate3d(0,-50%,0);
	   -moz-transform: translate3d(0,-50%,0);
	        transform: translate3d(0,-50%,0);
}

/********** GRID DIRECTORS FEATURED PASSED **********/

.grid.directors .item.featured.passed a.title-alt:before {
	content:"Les films de leur vie";
	top:auto;
	bottom:0;
	left:1rem;
	font-size:.625rem;
	line-height:1em;
	letter-spacing:.0625em;
}
.grid.directors .item.featured.passed a.title-alt strong:before {
	position:absolute;
	top:50%;
	left:0;
	display:block;
	font-family:'icomoon';
	font-size:.75rem;
	line-height:.75rem;
	-webkit-transform: translate3d(0,-50%,0);
	   -moz-transform: translate3d(0,-50%,0);
	        transform: translate3d(0,-50%,0);
}

/********** GRID LIST DIRECTORS **********/

.grid.list.directors .item {
	min-height:auto;
	border-bottom:solid var(--stroke-width);
	background:none;
}
.grid.list.directors .item:before {
	display:none;
}
.grid.list.directors a.title-alt {
	padding:1rem;
}
.grid.list.directors a.title-alt span:first-child {
	display:block;
}
.grid.list.directors a.title-alt:after {
	font-family:'icomoon';
	display:inline-block;
	opacity:0;
	padding:0;
}
.grid.list.directors a.title-alt:hover:after {
	opacity:1;
	padding:0 0 0 1em;
}
.grid.list.directors a.title-alt:hover {
	color:var(--red-color);
	text-shadow:none;
}

/********** GRID ARCHIVE DIRECTORS **********/

.grid.archive.directors .item {
	background:var(--grey-color);
}

/********** GRID LIST ARCHIVE DIRECTORS **********/

.grid.list.archive.directors .item {
	width:100%;
	border: solid var(--stroke-width) transparent;
	border-top:solid var(--stroke-width) var(--dark-color);
}
.grid.list.archive.directors .item:hover {
	border:solid var(--stroke-width) var(--yellow-color);
}
.grid.list.archive.directors .film-link {
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
}
.grid.list.archive.directors a.title-alt span:last-child {
    font-size:inherit;
}
.grid.list.archive.directors a.title-alt:after {
	display:none;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.grid.directors .item,
	.grid.directors .item-content {
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
	}
	.grid.directors .cover {
		width:15%;
		max-width:10rem;
		margin:0;
	}
}
@media (min-width:760px) {
	.grid.directors .item,
	.grid.directors .item-content {
		-webkit-box-align:start;
		-webkit-align-items:flex-start;
		   -moz-box-align:start;
		    -ms-flex-align:start;
		        align-items:flex-start;
	}
	.grid.directors.min .item,
	.grid.directors.min .item-content {
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
	}
	.grid.directors .cover {
		width:100%;
		max-width:none;
	}
	/* archive */
	.grid.directors.archive .item-content {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
}
@media (min-width:1000px) {
	/* list */
	.grid.list.directors .title-alt strong {
		font-size:1.25rem;
	}
}
@media (min-width:1400px) {
	/* list */
	.grid.list.directors .title-alt strong {
		font-size:1.375rem;
	}
}

/**************************************/
/**************************************/
/********** GRID FILM-STATUS **********/
/**************************************/
/**************************************/

.grid .film-status {
	position:absolute;
	top:0;
	right:0;
	margin:-1rem 1rem 0 0 !important;
	-webkit-transform: translate3d(0,-100%,0);
	   -moz-transform: translate3d(0,-100%,0);
	        transform: translate3d(0,-100%,0);
}
.grid.min .film-status {
	margin:1rem;
	padding:0 !important;
	-webkit-transform: translate3d(0,0,0);
	   -moz-transform: translate3d(0,0,0);
	        transform: translate3d(0,0,0);
}
.grid.list.archive .director {
	position:relative;
}
.grid.list.archive .film-status {
	margin:0 1rem !important;
	-webkit-transform: translate3d(0,0,0);
	   -moz-transform: translate3d(0,0,0);
	        transform: translate3d(0,0,0);
}
.grid.min.films .title .film-status {
	-webkit-flex-basis:100%;
	    -ms-flex-preferred-size:100%;
	        flex-basis:100%;
	text-align:left;
	margin-left:0;
	padding-left:0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.grid .film-status {
		display:none !important;
	}
}
@media (min-width:760px) {
	.grid .film-status {
		display:block !important;
	}
	.grid.list .film-status {
		display:none !important;
	}
}
@media (min-width:1200px) {
	.grid.list .film-status {
		display:block !important;
	}
}

/***********************************/
/***********************************/
/********** GRID PLAYLIST **********/
/***********************************/
/***********************************/

.grid.playlist .item {
	padding:0 3rem;
}
.grid.playlist .item-content {
	padding-left:0;
}

/********************************/
/********************************/
/********** GRID BONUS **********/
/********************************/
/********************************/

.grid.bonus .item:before {
	border:none;
}

/********************************/
/********************************/
/********** GRID POSTS **********/
/********************************/
/********************************/

/*
.grid.posts (also used in .slider.grid.posts)
/!\ OUT : replaced by sliders
 */

.grid.posts .item {
	background:var(--darker-color);
}
.grid.posts .item-content {
	width:100%;
}

/********** GRID POSTS INFOS **********/

.grid.posts .infos {
	position:absolute;
	z-index:5;
	bottom:0;
	left:0;
	width:100%;
	background:var(--darker-color);
}

/********** WITH EXTRAS **********/

.grid.posts .extras {
	position:relative;
	padding:0;
	background:none;
}
.grid.posts .post-infos {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
}
.grid.posts .post-infos > * {
	padding:0;
}
.grid.posts .date {
	color:var(--lighter-color);
	text-shadow:0 0 0 var(--lighter-color);
}
.grid.posts .item h3 {
	white-space:nowrap;
	overflow:hidden;
	-o-text-overflow:ellipsis;
	   text-overflow:ellipsis;
	/*
	overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    -webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-line-clamp:1;
	*/
}
.grid.posts .item:hover h3 {
	white-space:normal;
	-o-text-overflow:none;
	   text-overflow:none;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.grid.posts .item-content {
		padding:0 0 3.25rem 0;
	}
	.grid.posts .item h3 {
		font-size:.75rem;
	}
	.grid.posts .infos p {
		display:none;
	}
	.grid.posts .date {
		margin:0;
		font-size:.5rem;
		padding-left:.5rem;
	}
	.grid.posts .post-infos + * {
		padding:.5rem 0 0 0;
	}
}
@media (min-width:480px) {
	.grid.posts .item h3 {
		font-size:1rem;
	}
	.grid.posts .date {
		font-size:.875rem;
	}
}
@media (min-width:760px) {
	.grid.posts .item-content {
		padding:0 0 6.125rem 0;
	}
	.grid.posts .infos p {
		display:block;
	}
}

/*****************************/
/*****************************/
/********** GALLERY **********/
/*****************************/
/*****************************/

.grid.gallery > .item:before {
	display:none;
}
.grid.gallery figure {
	width:100%;
}
.grid.gallery img {
	max-width:none;
	height:100%; /* bug IOS */
	width:100%;
}

/********** ZOOM **********/

a.enlarge:before {
	display:inline-block;
	font-family:'icomoon';
	font-size:inherit;
	line-height:0;
}
.grid .enlarge {
	overflow:hidden;
}
.grid .enlarge:before {
	position:absolute;
	z-index:10;
	top:50%;
	left:50%;
	-webkit-transform: translate3d(-50%,-50%,0);
	   -moz-transform: translate3d(-50%,-50%,0);
	        transform: translate3d(-50%,-50%,0);
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	opacity:0;
}
.grid .enlarge:hover:before {
	opacity:1;
}

/*******************************/
/*******************************/
/********** GRID NEWS **********/
/*******************************/
/*******************************/

/*
specific to archive news
liste du mois : 502 x 480 px
SVOD : 1280 x 720 px

1400px > 28.28% + 71.72% no gutter
1400px > 27.63% + 70.01% with 2rem gutter
*/

/********** DEFAULT **********/

.grid.news .item-content {
	height:100%;
}

/********** GRID NEWS INFOS **********/

.grid.news .infos {
	position:absolute;
	z-index:10;
	bottom:0;
	left:0;
	width:auto;
	margin:2rem;
}
.grid.news .alert {
	top:0;
	margin:2rem;
	-webkit-transform:none;
	   -moz-transform:none;
	    -ms-transform:none;
	     -o-transform:none;
	        transform:none;
}
.grid.news .button.small {
	position:absolute;
	z-index:10;
	bottom:0;
	right:0;
	width:auto;
	margin:2rem;
	background:rgba(var(--black-rgb),.5);
	border:solid var(--stroke-width) transparent !important;
}
.grid.news .button.small:hover {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	border:solid var(--stroke-width) var(--yellow-color) !important;
	background:none !important;
}
.grid.news .infos {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
}
.grid.news .infos > * {
	padding:0;
}

.grid.news .infos > * {
	font-size:1rem;
}
.grid.news .title-alt {
	font-size:1.5rem;
}
.grid.news .background:after,
.grid.news figure:after {
	content:"";
	position:absolute;
	z-index:5;
	bottom:0;
	left:0;
	height:100%;
	width:100%;
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--black-rgb),1)), to(rgba(var(--black-rgb),0)));
	background: -webkit-linear-gradient(bottom, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	background: -moz-linear-gradient(bottom, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	background: -o-linear-gradient(bottom, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	background: linear-gradient(0deg, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	opacity:var(--alpha-rgb);
}

/********** GRID NEWS SLIDER **********/

/* cf. news > discover slider */

.grid.news .item .slider {
	height:100%;
	width:100%;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.grid.news .item figure {
		height:100%;
		width:100%;
		overflow:hidden;
	}
	.grid.news .item.month-list figure,
	.grid.news .item.month-list figure img {
		width:100%;
		max-width:none;
	}
}

/**********************************/
/**********************************/
/********** GRID / SIZES **********/
/**********************************/
/**********************************/

/********** GRID | MIN | LIST **********/

@media (min-width:0px) {
	/*
	.grid,
	.grid.list {
		display: -ms-grid;
		display: grid;
		gap: .0625rem;
	}
	.grid,
	.grid.min,
	.grid.list {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}
	*/
	.grid,
	.grid.list {
		margin-top: -.03125rem;
		margin-bottom: -.03125rem;
		width:100%;
		max-width:inherit;
		-webkit-transform: none;
		   -moz-transform: none;
		    -ms-transform: none;
		     -o-transform: none;
		        transform: none;
	}
	* + .grid {
		margin-top:-webkit-calc(1rem - .03125rem);
		margin-top:-moz-calc(1rem - .03125rem);
		margin-top:calc(1rem - .03125rem);
	}
	.grid .item,
	.grid.list .item {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		width:100%;
		/*margin:0;*/
		margin:.03125rem 0;
	}
}
@media (min-width:760px) {
	/*
	.grid {
		/*
		-ms-grid-columns: 1fr 1rem 1fr;
		grid-template-columns: repeat(2, 1fr);
		gap:1rem;
	}
	*/
	.grid {
		margin-top: -.5rem;
		margin-bottom: -.5rem;
		width: -webkit-calc(100% + 1rem);
		width: -moz-calc(100% + 1rem);
		width: calc(100% + 1rem);
		-webkit-transform: translateX(-.5rem);
		   -moz-transform: translateX(-.5rem);
		    -ms-transform: translateX(-.5rem);
		     -o-transform: translateX(-.5rem);
		        transform: translateX(-.5rem);
	}
	* + .grid {
		margin-top:-webkit-calc(1rem - .5rem);
		margin-top:-moz-calc(1rem - .5rem);
		margin-top:calc(1rem - .5rem);
	}
	.grid .item {
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -webkit-flex-direction:column;
           -moz-box-orient:vertical;
           -moz-box-direction:normal;
            -ms-flex-direction:column;
                flex-direction:column;
		width:-webkit-calc((100% / 2) - 1rem);
		width:-moz-calc((100% / 2) - 1rem);
		width:calc((100% / 2) - 1rem);
		margin:.5rem;
	}
	/*
	.grid.list {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		gap: .0625rem;
	}
	*/
	* + .grid.list {
		margin-top:-webkit-calc(1rem - .03125rem);
		margin-top:-moz-calc(1rem - .03125rem);
		margin-top:calc(1rem - .03125rem);
	}
	/*
	.grid.min {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		gap: .25rem;
	}
	*/
	.grid.min {
		margin-top: -.125rem;
		margin-bottom: -.125rem;
		width: -webkit-calc(100% + .25rem);
		width: -moz-calc(100% + .25rem);
		width: calc(100% + .25rem);
		-webkit-transform: translateX(-.125rem);
		   -moz-transform: translateX(-.125rem);
		    -ms-transform: translateX(-.125rem);
		     -o-transform: translateX(-.125rem);
		        transform: translateX(-.125rem);
	}
	* + .grid.min {
		margin-top:-webkit-calc(1rem - .125rem);
		margin-top:-moz-calc(1rem - .125rem);
		margin-top:calc(1rem - .125rem);
	}
	.grid.min .item {
		width:100%;
		margin:.125rem 0;
	}
}
@media (min-width:1000px) {
	/*
	.grid {
		-ms-grid-columns: 1fr 2rem 1fr 2rem 1fr;
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;
	}
	.grid.list {
		gap: .0625rem 2rem;
	}
	*/
	.grid {
		margin-top: -1rem;
		margin-bottom: -1rem;
		width: -webkit-calc(100% + 2rem);
		width: -moz-calc(100% + 2rem);
		width: calc(100% + 2rem);
		max-width: -webkit-calc(var(--max-width) + 2rem);
		max-width: -moz-calc(var(--max-width) + 2rem);
		max-width: calc(var(--max-width) + 2rem);
		-webkit-transform: translateX(-1rem);
		   -moz-transform: translateX(-1rem);
		    -ms-transform: translateX(-1rem);
		     -o-transform: translateX(-1rem);
		        transform: translateX(-1rem);
	}
	.grid .item {
		width:-webkit-calc((100% / 3) - 2rem);
		width:-moz-calc((100% / 3) - 2rem);
		width:calc((100% / 3) - 2rem);
		margin:1rem;
	}
	* + .grid {
		margin-top:-webkit-calc(2rem - 1rem);
		margin-top:-moz-calc(2rem - 1rem);
		margin-top:calc(2rem - 1rem);
	}
	* + .grid.list {
		margin-top:-webkit-calc(2rem - .03125rem);
		margin-top:-moz-calc(2rem - .03125rem);
		margin-top:calc(2rem - .03125rem);
	}
}
@media (min-width:1400px) {
	/*
	.grid {
		-ms-grid-columns: (1fr)[4];
		grid-template-columns: repeat(4, 1fr);
	}
	*/
	.grid .item {
		width:-webkit-calc((100% / 4) - 2rem);
		width:-moz-calc((100% / 4) - 2rem);
		width:calc((100% / 4) - 2rem);
	}
}

/* directly in main > section == 1432 (grid + margin) + 128 (section padding) */

@media (min-width:1532px) {
	main > section > .grid {
		-webkit-transform: translateX(-1vw);
		   -moz-transform: translateX(-1vw);
		    -ms-transform: translateX(-1vw);
		     -o-transform: translateX(-1vw);
		        transform: translateX(-1vw);
	}
}
/*@media (min-width:1528px) {*/
@media (min-width:1560px) {
	main > section > .grid {
		-webkit-transform: none;
		   -moz-transform: none;
		    -ms-transform: none;
		     -o-transform: none;
		        transform: none;
	}
}

/********** GRID DIRECTORS **********/

@media (min-width:0px) {
	/*
	.grid.min.directors {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}
	.grid.list.directors {
		-ms-grid-columns: 1fr 0 1fr;
		grid-template-columns: repeat(2, 1fr);
		gap:0;
	}
	*/
	.grid.min.directors .item {
		width:100%;
	}
	.grid.list.directors {
		margin-top:0;
		margin-bottom:0;
	}
	* + .grid.list.directors {
		margin-top:1rem;
	}
	.grid.list.directors .item {
		width:50%;
		margin:0;
	}
}
@media (min-width:760px) {
	/*
	.grid.list.directors {
		-ms-grid-columns: (1fr)[3];
		grid-template-columns: repeat(3, 1fr);
	}
	*/
	.grid.directors .item {
		width:-webkit-calc(100% / 3 - 1rem);
		width:-moz-calc(100% / 3 - 1rem);
		width:calc(100% / 3 - 1rem);
	}
	.grid.list.directors .item {
		width:-webkit-calc(100% / 3);
		width:-moz-calc(100% / 3);
		width:calc(100% / 3);
	}
}
@media (min-width:1200px) {
	/*
	.grid.directors {
		-ms-grid-columns: (1fr)[5];
		grid-template-columns: repeat(5, 1fr);
	}
	.grid.list.directors {
		-ms-grid-columns: (1fr)[4];
		grid-template-columns: repeat(4, 1fr);
	}
	*/
	.grid.directors .item {
		width:-webkit-calc(100% / 3 - 2rem);
		width:-moz-calc(100% / 3 - 2rem);
		width:calc(100% / 3 - 2rem);
	}
	* + .grid.list.directors {
		margin-top:2rem;
	}
}
@media (min-width:1600px) {
	/*
	.grid.list.directors {
		-ms-grid-columns: (1fr)[5];
		grid-template-columns: repeat(5, 1fr);
	}
	*/
	.grid.list.directors .item {
		width:-webkit-calc(100% / 5);
		width:-moz-calc(100% / 5);
		width:calc(100% / 5);
	}
}

/********** GRID INSTITUTIONS **********/

@media (min-width:1400px) {
	/*
	.grid.institutions {
		-ms-grid-columns: 1fr .25rem 1fr .25rem 1fr .25rem 1fr .25rem 1fr;
		grid-template-columns: repeat(5, 1fr);
		gap: .25rem;
	}
	*/
	.grid.institutions .item {
		width:-webkit-calc((100% / 5) - .25rem);
		width:-moz-calc((100% / 5) - .25rem);
		width:calc((100% / 5) - .25rem);
		margin:.125rem;
	}
}

/********** GRID GALLERY **********/

@media (min-width:760px) {
	/*
	.grid.gallery {
		-ms-grid-columns: (1fr)[2];
		grid-template-columns: repeat(2, 1fr);
	}
	*/
	.grid.gallery {

	}
	.grid.gallery .item {
		width:-webkit-calc((100% / 2) - 1rem);
		width:-moz-calc((100% / 2) - 1rem);
		width:calc((100% / 2) - 1rem);
	}
}
/********** GRID PARTNERS **********/

@media (min-width:1000px) {
	/*
	.grid.films.archive.partners,
	.grid.films.partners {
		-ms-grid-columns: (1fr)[3];
		grid-template-columns: repeat(3, 1fr);
	}
	*/
	.grid.films.archive.partners .item,
	.grid.films.partners .item {
		width:-webkit-calc((100% / 3) - 2rem);
		width:-moz-calc((100% / 3) - 2rem);
		width:calc((100% / 3) - 2rem);
	}
}
@media (min-width:1200px) {
	/*
	.grid.films.archive.partners,
	.grid.films.partners {
		-ms-grid-columns: (1fr)[4];
		grid-template-columns: repeat(4, 1fr);
	}
	*/
	.grid.films.archive.partners .item,
	.grid.films.partners .item {
		width:-webkit-calc((100% / 4) - 2rem);
		width:-moz-calc((100% / 4) - 2rem);
		width:calc((100% / 4) - 2rem);
	}
}

/********** GRID ARCHIVE FILMS **********/

/* list and default are the same up to 1000px */

@media (max-width:760px) {
	/*
	.grid.films.archive.partners,
	.grid.films.partners {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		gap: .5rem;
	}
	*/
	.grid.films.archive.partners,
	.grid.films.partners {
		margin-top: -.5rem !important;
		margin-bottom: -.5rem !important;
		width: -webkit-calc(100% + 1rem);
		width: -moz-calc(100% + 1rem);
		width: calc(100% + 1rem);
		-webkit-transform: translateX(-.5rem);
		   -moz-transform: translateX(-.5rem);
		    -ms-transform: translateX(-.5rem);
		     -o-transform: translateX(-.5rem);
		        transform: translateX(-.5rem);
	}
	* + .grid.films.archive.partners,
	* + .grid.films.partners {
		margin-top:-webkit-calc(1rem - .5rem) !important;
		margin-top:-moz-calc(1rem - .5rem) !important;
		margin-top:calc(1rem - .5rem) !important;
	}
	.grid.films.archive.partners .item,
	.grid.films.partners .item {
		width:100% !important;
		margin:.5rem 0 !important;
	}
}
@media (max-width:1000px) {
	/*
	.grid.archive.films {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		gap: .0625rem;
	}
	.grid.archive.films .item {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.grid.films.archive.partners,
	.grid.films.partners {
		-ms-grid-columns: 1fr 1rem 1fr;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
	}
	*/
	.grid.archive.films {
		margin-top: -.03125rem;
		margin-bottom: -.03125rem;
		width: -webkit-calc(100% + .0625rem);
		width: -moz-calc(100% + .0625rem);
		width: calc(100% + .0625rem);
		-webkit-transform: translateX(-.03125rem);
		   -moz-transform: translateX(-.03125rem);
		    -ms-transform: translateX(-.03125rem);
		     -o-transform: translateX(-.03125rem);
		        transform: translateX(-.03125rem);
	}
	* + .grid.archive.films {
		margin-top:-webkit-calc(1rem - .03125rem);
		margin-top:-moz-calc(1rem - .03125rem);
		margin-top:calc(1rem - .03125rem);
	}
	.grid.archive.films .item {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
			-moz-box-orient:horizontal;
			-moz-box-direction:normal;
			-ms-flex-direction:row;
				flex-direction:row;
		width:100%;
		margin:.03125rem 0;
	}
	.grid.films.archive.partners,
	.grid.films.partners {
		margin-top: -.5rem;
		margin-bottom: -.5rem;
		width: -webkit-calc(100% + 1rem);
		width: -moz-calc(100% + 1rem);
		width: calc(100% + 1rem);
		-webkit-transform: translateX(-.5rem);
		   -moz-transform: translateX(-.5rem);
		    -ms-transform: translateX(-.5rem);
		     -o-transform: translateX(-.5rem);
		        transform: translateX(-.5rem);
	}
	* + .grid.films.archive.partners,
	* + .grid.films.partners {
		margin-top:-webkit-calc(1rem - .5rem);
		margin-top:-moz-calc(1rem - .5rem);
		margin-top:calc(1rem - .5rem);
	}
	.grid.films.archive.partners .item,
	.grid.films.partners .item {
		width:-webkit-calc((100% / 2) - 1rem);
		width:-moz-calc((100% / 2) - 1rem);
		width:calc((100% / 2) - 1rem);
		margin:.5rem;
	}
}

/********** GRID FILMS PLAYLISTS **********/

@media (max-width:760px) {
	/*
	.grid.films.playlists {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		gap: .5rem 0;
	}
	*/
	.grid.films.playlists {
		margin-top: -.5rem;
		margin-bottom: -.5rem;
		width: -webkit-calc(100% + 1rem);
		width: -moz-calc(100% + 1rem);
		width: calc(100% + 1rem);
		-webkit-transform: translateX(-.5rem);
		   -moz-transform: translateX(-.5rem);
		    -ms-transform: translateX(-.5rem);
		     -o-transform: translateX(-.5rem);
		        transform: translateX(-.5rem);
	}
	* + .grid.films.playlists {
		margin-top:-webkit-calc(1rem - .5rem);
		margin-top:-moz-calc(1rem - .5rem);
		margin-top:calc(1rem - .5rem);
	}
	.grid.films.playlists .item {
		margin:.5rem 0;
	}
}

/********** GRID NEWS **********/

@media (min-width:0px) {
	/*
	.grid.news {
		gap: 1rem;
	}
	*/
	.grid.news .item  {
		height:12rem;
		width:100%;
		margin:0;
	}
	.grid.news .item.svod-list {
		margin:1rem 0 0 0;
	}

}
@media (min-width:760px) {
	/*
	.grid.news {
		-ms-grid-columns: 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr;
		grid-template-columns: repeat(10, 1fr);
		gap: 2rem;
	}
	*/
	.grid.news {
		width:  100%;
		max-width: var(--max-width);
		-webkit-transform: none;
		   -moz-transform: none;
		    -ms-transform: none;
		     -o-transform: none;
		        transform: none;
	}
	.grid.news .item {
		height:auto;
	}
	/*
	.grid.news .item.month-list {
		-ms-grid-column-span: 3;
		grid-column: span 3;
	}
	.grid.news .item.svod-list,
	.grid.news .item.playlist-list {
		-ms-grid-column-span: 7;
		grid-column: span 7;
	}
	*/
	.grid.news .item.month-list {
		width:-webkit-calc(30% - 1rem);
		width:-moz-calc(30% - 1rem);
		width:calc(30% - 1rem);
	}
	.grid.news .item.svod-list {
		margin:0;
		margin-left:auto;
		width:-webkit-calc(70% - 1rem);
		width:-moz-calc(70% - 1rem);
		width:calc(70% - 1rem);
	}
}
@media (min-width:1000px) {
	/*
	.grid.news {
		gap: 4rem;
	}
	*/
	.grid.news .item.month-list {
		width:-webkit-calc(30% - 2rem);
		width:-moz-calc(30% - 2rem);
		width:calc(30% - 2rem);
	}
	.grid.news .item.svod-list {
		width:-webkit-calc(70% - 2rem);
		width:-moz-calc(70% - 2rem);
		width:calc(70% - 2rem);
	}
}