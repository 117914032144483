//@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap");
//@import url("https://fonts.googleapis.com/css?family=Oswald|Playfair+Display|Roboto&display=swap");

@import "admin.scss";
@import "cart.scss";
@import "coil.scss";
@import "forms.scss";
@import "grid.scss";
@import "hybrid/styles.css";
@import "hybrid/fonts.css";
@import "hybrid/structure.css";
@import "hybrid/colors.css";
@import "hybrid/navigation.css";
@import "hybrid/form.css";
@import "hybrid/grid.css";
@import "hybrid/slider.css";
@import "hybrid/table.css";
@import "hybrid/overlays.css";
@import "hybrid/animations.css";
@import "hybrid/vars.css";
@import "lists-of.scss";
@import "loaders.scss";
@import "modal.scss";
@import "overlays.scss";
@import "profile.scss";
@import "skeleton.scss";

a {
	cursor: pointer;
}

#wrapper {
	overflow: hidden;
}

/*
.focus.front {
	&:not(.active) {
		display: none;
	}
}
*/

/*
.hidden {
	display: none !important;
}
*/

// .slider-container {
// 	overflow: hidden;
// }

#treasures-slider {

	.slider-container {
		ul.slides {

			&:hover>* {
				opacity: 0.2;
			}

			&>*:hover {
				opacity: 1;
			}

			li.slide {
				.slide-content {
					&:after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						height: 0.25rem;
						width: 100%;
						background: transparent;
					}
				}

				.slide-content.active {
					&:after {
						background: #d92e41;
					}
				}

				.slide-content:hover:before {
					border: solid 0.0625rem transparent;
				}
			}
		}
	}
}

.offers {
	&.blocks {
		.item {
			&.active {
				a {
					&:before {
						border: solid 0.0625rem #ffff99;
					}
				}
			}
		}
	}
}

.offers {
	.button {
		&.icon-check {
			color: #d92e41;
			text-shadow: none;
			background: white !important;
		}
	}
}

.director-filmo {
	a {
		//text-decoration: underline;
		border-bottom: 1px solid white;
	}
}

.saved-card {
	cursor: pointer;
	border: 1px solid white;
	display: flex;
	padding: 10px;
	margin: 10px;

	span {
		margin-right: 10px;
		margin-left: 10px;
	}

	&.active {
		background: white;
		color: black;
	}
}

nav.filters>a.active {
	pointer-events: all;
}

.ReactVirtualized__Grid__innerScrollContainer {
	width: 100% !important;
}

.mobile-video {
	.overlay-content {
		position: absolute !important;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		padding: 0;
		top: 100px;
		bottom: -300px;

		.videoplayer-container {
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			position: absolute;

			.videoplayer {
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;
				position: absolute;
			}
		}
	}
}

.access-info {
	position: relative;
	z-index: 1200;
	padding: 10px;
	display: flex;

	span {
		flex: 1,
	}
}

.about-team-section {
	font {
		color: #ffffff !important;
	}
}

/* Anchor Navigation */

// html {
// 	scroll-behavior: smooth;
// }

.section-anchor {
	position: relative;
	z-index: 1;

	&:target {
		padding-top: (1rem * 2 + 1rem) + (.875rem * 2.5 * 2 + .875rem) + (1.5rem * 2 + .875rem) + (.875rem * 2 + .875rem) + 2rem;
		margin-top: ((1rem * 2 + 1rem) + (.875rem * 2.5 * 2 + .875rem) + (1.5rem * 2 + .875rem) + (.875rem * 2 + .875rem)) * -1;
		z-index: 0;
	}
}

.anchor {
	display: block;
	position: relative;
	top: -155px;
	visibility: hidden;
}

.columnizer[data-columns="1"] {
	column-count: 1 !important;
}

.text-black {
	color: rgb(21, 21, 30) !important;
	text-shadow: 0 0 0 rgb(21, 21, 30) !important;
}

.is-connecting {
	text-align: center;
	padding: 1em;
	&:before {
		height: .70rem;
		width: .70rem;
	}
	@media (min-width: 760px) {
		padding-top: 1.2rem;
		width: 3rem;
	}
	@media (min-width: 1000px) {
		padding-top: 1.7rem;
		width: 3rem;
	}
	@media (min-width: 1100px) {
		padding-top: 1.4rem;
		width: 3rem;
	}
}

.temp-cookie-pro-style-cancellation {
	color: white !important;
	border: 0px solid !important;
	padding: 0.4375rem !important;
	font-size: .875rem !important;
}

.temp-cookie-pro-style-cancellation:hover {
	color: #d92e41 !important;
	background: transparent !important;
}

.scrollable-x {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.scrollbar-hidden {
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
}

body.subscribed.is-vod {
	--header-height: 4rem;
	header::after{
		content: none;
	}
}

nav.film-actions .film-is-loading {
	display: flex;
	width: 3rem;
	height: 3rem;
	flex: 1;
	justify-content: center;
	align-items: center;

	.loader {
		display: block;

		&:before {
			border-color: var(--dark-color);
			border-left-color: transparent;
		}
	}
}
