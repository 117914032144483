.input-container {
	.visible {
		visibility: visible !important;
		height: auto  !important;
		opacity: 1  !important;
		overflow: visible  !important;
	}
	.autocompletion {
		.films, .directors {
			.item-selected::before {
				border: solid 0.0625rem #ffff99;
			}
		}
	}
}
