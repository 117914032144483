#lists-of {
	position: fixed;
	background: white;
	top: 80px;
	left: 0;
	right: 0;
	bottom: 40px;
	display: flex;
	.list-link-container {
		margin: 5px;
		a {
			font-size: 12px;
			color: black;
		}
	}
	&.show {

	}
	&.hide {
		display: none;
	}
}

/*
.slider-controls {
	padding: 2rem 0 0 0;
}
*/

.slider-inner {
	overflow-x: hidden;
}

.slider-slides--transition {
  transition: transform 0.25s;
}
