.coil-item {
	opacity: 0;
	margin-top: 0 !important;
	&.active {
		opacity: 1;
	}
}

.trans-coil {
	.coil-item {
	-webkit-transition:all 1s cubic-bezier(.75,0,.25,1);
	-moz-transition:all 1s cubic-bezier(.75,0,.25,1);
	-ms-transition:all 1s cubic-bezier(.75,0,.25,1);
	transition:all 1s cubic-bezier(.75,0,.25,1);
		opacity: 0;
		&.active {
			opacity: 1;
		}
	}

}
