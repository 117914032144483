.checkout {
	width: 100%;

	.StripeElement {
		background: white;
		padding: 1rem;
		margin-bottom: 10px;
	}

}

.is-loading.alt:before {
	border-color: rgb(21, 21, 30) rgb(21, 21, 30) rgb(21, 21, 30) transparent !important;
}

button.is-loading:empty::before {
	margin: 0 !important;
}
