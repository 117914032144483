@charset "UTF-8";

/********************************/
/********************************/
/********** ANIMATIONS **********/
/********************************/
/********************************/

/***************************/
/********** FORMS **********/
/***************************/

input[type="radio"],
input[type="checkbox"] {
	-webkit-transition:
	background var(--duration-fast) var(--ease);
	-o-transition:
	background var(--duration-fast) var(--ease);
	-moz-transition:
	background var(--duration-fast) var(--ease);
	transition:
	background var(--duration-fast) var(--ease);
}
.select-container:after {
	-webkit-transition:
	-webkit-transform var(--duration-fast) var(--ease);
	transition:
	-webkit-transform var(--duration-fast) var(--ease);
	-o-transition:
	-o-transform var(--duration-fast) var(--ease);
	-moz-transition:
	transform var(--duration-fast) var(--ease), -moz-transform var(--duration-fast) var(--ease);
	transition:
	transform var(--duration-fast) var(--ease);
	transition:
	transform var(--duration-fast) var(--ease), -webkit-transform var(--duration-fast) var(--ease), -moz-transform var(--duration-fast) var(--ease), -o-transform var(--duration-fast) var(--ease);
}

/*******************************/
/********** STRUCTURE **********/
/*******************************/

section.focus .infos {
	-webkit-transition:
		opacity var(--duration-fast) var(--ease);
	-o-transition:
		opacity var(--duration-fast) var(--ease);
	-moz-transition:
		opacity var(--duration-fast) var(--ease);
	transition:
		opacity var(--duration-fast) var(--ease);
}
/********** MOBILE HEADER **********/

@media (max-width:1000px) {
	header #heading {
		-webkit-transition:
		-webkit-transform var(--duration-fast) var(--ease);
		transition:
		-webkit-transform var(--duration-fast) var(--ease);
		-o-transition:
		-o-transform var(--duration-fast) var(--ease);
		-moz-transition:
		transform var(--duration-fast) var(--ease), -moz-transform var(--duration-fast) var(--ease);
		transition:
		transform var(--duration-fast) var(--ease);
		transition:
		transform var(--duration-fast) var(--ease), -webkit-transform var(--duration-fast) var(--ease), -moz-transform var(--duration-fast) var(--ease), -o-transform var(--duration-fast) var(--ease);
	}
}

/********************************/
/********** NAVIGATION **********/
/********************************/

.play,
button,
input[type=submit],
a,
nav a {
	-webkit-transition:
	color var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	width var(--duration-fast) var(--elastic),
	-webkit-text-decoration var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--elastic);
	transition:
	color var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	width var(--duration-fast) var(--elastic),
	-webkit-text-decoration var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--elastic);
	-o-transition:
	color var(--duration-fast) var(--ease),
	text-decoration var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	width var(--duration-fast) var(--elastic),
	-o-transform var(--duration-fast) var(--elastic);
	-moz-transition:
	color var(--duration-fast) var(--ease),
	text-decoration var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	width var(--duration-fast) var(--elastic),
	transform var(--duration-fast) var(--elastic),
	-moz-text-decoration var(--duration-fast) var(--ease),
	-moz-transform var(--duration-fast) var(--elastic);
	transition:
	color var(--duration-fast) var(--ease),
	text-decoration var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	width var(--duration-fast) var(--elastic),
	transform var(--duration-fast) var(--elastic);
	transition:
	color var(--duration-fast) var(--ease),
	text-decoration var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	width var(--duration-fast) var(--elastic),
	transform var(--duration-fast) var(--elastic),
	-webkit-text-decoration var(--duration-fast) var(--ease),
	-moz-text-decoration var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--elastic),
	-moz-transform var(--duration-fast) var(--elastic),
	-o-transform var(--duration-fast) var(--elastic);
}
a:before,
nav a:before,
a:after,
nav a:after {
	-webkit-transition:
	border-color var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	padding var(--duration-fast) var(--elastic),
	width var(--duration-fast) var(--elastic),
	-webkit-transform var(--duration-fast) var(--elastic);
	transition:
	border-color var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	padding var(--duration-fast) var(--elastic),
	width var(--duration-fast) var(--elastic),
	-webkit-transform var(--duration-fast) var(--elastic);
	-o-transition:
	border-color var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	padding var(--duration-fast) var(--elastic),
	width var(--duration-fast) var(--elastic),
	-o-transform var(--duration-fast) var(--elastic);
	-moz-transition:
	border-color var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	padding var(--duration-fast) var(--elastic),
	width var(--duration-fast) var(--elastic),
	transform var(--duration-fast) var(--elastic),
	-moz-transform var(--duration-fast) var(--elastic);
	transition:
	border-color var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	padding var(--duration-fast) var(--elastic),
	width var(--duration-fast) var(--elastic),
	transform var(--duration-fast) var(--elastic);
	transition:
	border-color var(--duration-fast) var(--ease),
	background-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease),
	padding var(--duration-fast) var(--elastic),
	width var(--duration-fast) var(--elastic),
	transform var(--duration-fast) var(--elastic),
	-webkit-transform var(--duration-fast) var(--elastic),
	-moz-transform var(--duration-fast) var(--elastic),
	-o-transform var(--duration-fast) var(--elastic);
}

/* > out */
nav .menu * > .submenu {
	-webkit-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	-o-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	-moz-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
}
/* > in */
nav .menu *:hover > .submenu,
nav .menu *:active > .submenu {
	-webkit-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	-o-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	-moz-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
}
/* > out */
.autocompletion {
	-webkit-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	-o-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	-moz-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
}
/* > in */
.input-container:hover .autocompletion {
	-webkit-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	-o-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	-moz-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
	transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--elastic);
}

nav.film-actions {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease);
	-o-transition:
	opacity var(--duration-fast) var(--ease);
	-moz-transition:
	opacity var(--duration-fast) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease);
}
a.video-link .label {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--elastic);
	transition:
	opacity var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--elastic);
	-o-transition:
	opacity var(--duration-fast) var(--ease),
	-o-transform var(--duration-fast) var(--elastic);
	-moz-transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-fast) var(--elastic),
	-moz-transform var(--duration-fast) var(--elastic);
	transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-fast) var(--elastic);
	transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-fast) var(--elastic),
	-webkit-transform var(--duration-fast) var(--elastic),
	-moz-transform var(--duration-fast) var(--elastic),
	-o-transform var(--duration-fast) var(--elastic);
}
nav.contextual.checkout li:before {
	-webkit-transition:
	-webkit-transform var(--duration-fast) var(--elastic);
	transition:
	-webkit-transform var(--duration-fast) var(--elastic);
	-o-transition:
	-o-transform var(--duration-fast) var(--elastic);
	-moz-transition:
	transform var(--duration-fast) var(--elastic), -moz-transform var(--duration-fast) var(--elastic);
	transition:
	transform var(--duration-fast) var(--elastic);
	transition:
	transform var(--duration-fast) var(--elastic), -webkit-transform var(--duration-fast) var(--elastic), -moz-transform var(--duration-fast) var(--elastic), -o-transform var(--duration-fast) var(--elastic);
}
#primary a.on-air:before {
	-webkit-animation:breathing var(--duration-slow) infinite both;
	   -moz-animation:breathing var(--duration-slow) infinite both;
	     -o-animation:breathing var(--duration-slow) infinite both;
	        animation:breathing var(--duration-slow) infinite both;
}
a.detonate:hover:before {
	-webkit-animation:spinner var(--duration-fast) linear;
	   -moz-animation:spinner var(--duration-fast) linear;
	     -o-animation:spinner var(--duration-fast) linear;
	        animation:spinner var(--duration-fast) linear;
}

.watch-status {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--ease);
	-o-transition:
	opacity var(--duration-fast) var(--ease),
	-o-transform var(--duration-fast) var(--ease);
	-moz-transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-fast) var(--ease),
	-moz-transform var(--duration-fast) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-fast) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease),
	transform var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--ease),
	-moz-transform var(--duration-fast) var(--ease),
	-o-transform var(--duration-fast) var(--ease);
}

@media (max-width:1000px) {
	/* > out */
	#primary {
		-webkit-transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease);
		-o-transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		-o-transform var(--duration-fast) var(--ease);
		-moz-transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease),
		-moz-transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease),
		-moz-transform var(--duration-fast) var(--ease),
		-o-transform var(--duration-fast) var(--ease);
	}
	/* > in */
	#primary.expanded {
		-webkit-transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease);
		-o-transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		-o-transform var(--duration-fast) var(--ease);
		-moz-transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease),
		-moz-transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease),
		-moz-transform var(--duration-fast) var(--ease),
		-o-transform var(--duration-fast) var(--ease);
	}
}

/*********************************/
/********** COLLAPSIBLE **********/
/*********************************/

.collapsible > * {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fastest);
	-o-transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fastest);
	-moz-transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fastest);
	transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fastest);
}
/* > out */
.collapsible {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--ease),
	visibility .01s linear var(--duration-fast);
	-o-transition:
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--ease),
	visibility .01s linear var(--duration-fast);
	-moz-transition:
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--ease),
	visibility .01s linear var(--duration-fast);
	transition:
	opacity var(--duration-fast) var(--ease),
	max-height var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--ease),
	visibility .01s linear var(--duration-fast);
}
/* > in */
.collapsible.active {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fastest),
	max-height var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--ease),
	visibility .01s linear;
	-o-transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fastest),
	max-height var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--ease),
	visibility .01s linear;
	-moz-transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fastest),
	max-height var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--ease),
	visibility .01s linear;
	transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fastest),
	max-height var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--ease),
	visibility .01s linear;
}

/**************************/
/********** TABS **********/
/**************************/

.nav-tabs.blocks a > *:before {
	-webkit-transition:
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease);
	-o-transition:
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease);
	-moz-transition:
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease);
	transition:
	border-color var(--duration-fast) var(--ease),
	opacity var(--duration-fast) var(--ease);
}
.tabs > * {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease);
	-o-transition:
	opacity var(--duration-fast) var(--ease);
	-moz-transition:
	opacity var(--duration-fast) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease);
}

/****************************/
/********** OFFERS **********/
/****************************/

nav.offers.blocks .item:before {
	-webkit-transition:
	border-color var(--duration-fast) var(--ease);
	-o-transition:
	border-color var(--duration-fast) var(--ease);
	-moz-transition:
	border-color var(--duration-fast) var(--ease);
	transition:
	border-color var(--duration-fast) var(--ease);
}

/***************************/
/********** GRIDS **********/
/***************************/

.slider.inline.films .slide .cover:before,
.grid .item:before,
.list .item:before,
.list.films .item-content:before {
	-webkit-transition:
	border-color var(--duration-fast) var(--ease);
	-o-transition:
	border-color var(--duration-fast) var(--ease);
	-moz-transition:
	border-color var(--duration-fast) var(--ease);
	transition:
	border-color var(--duration-fast) var(--ease);
}
.offers.grid .item-content {
	-webkit-transition:
	-webkit-filter var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--elastic);
	transition:
	-webkit-filter var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--elastic);
	-o-transition:
	filter var(--duration-fast) var(--ease),
	-o-transform var(--duration-fast) var(--elastic);
	-moz-transition:
	filter var(--duration-fast) var(--ease),
	transform var(--duration-fast) var(--elastic),
	-moz-transform var(--duration-fast) var(--elastic);
	transition:
	filter var(--duration-fast) var(--ease),
	transform var(--duration-fast) var(--elastic);
	transition:
	filter var(--duration-fast) var(--ease),
	transform var(--duration-fast) var(--elastic),
	-webkit-filter var(--duration-fast) var(--ease),
	-webkit-transform var(--duration-fast) var(--elastic),
	-moz-transform var(--duration-fast) var(--elastic),
	-o-transform var(--duration-fast) var(--elastic);
}
/* > out */
.grid .extras {
	-webkit-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--elastic),
	max-height var(--duration-fast) var(--elastic);
	-o-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--elastic),
	max-height var(--duration-fast) var(--elastic);
	-moz-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--elastic),
	max-height var(--duration-fast) var(--elastic);
	transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--elastic),
	max-height var(--duration-fast) var(--elastic);
}
/* > in */
.grid .has-extras:hover .extras {
	-webkit-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--elastic),
	max-height var(--duration-fast) var(--elastic);
	-o-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--elastic),
	max-height var(--duration-fast) var(--elastic);
	-moz-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--elastic),
	max-height var(--duration-fast) var(--elastic);
	transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease),
	margin var(--duration-fast) var(--elastic),
	max-height var(--duration-fast) var(--elastic);
}

/*****************************/
/********** SLIDERS **********/
/*****************************/

.slider .slide:before,
.slider.grid.multirows .slide-content:before {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease), /* for tabs */
	border-color var(--duration-fast) var(--ease);
	-o-transition:
	opacity var(--duration-fast) var(--ease), /* for tabs */
	border-color var(--duration-fast) var(--ease);
	-moz-transition:
	opacity var(--duration-fast) var(--ease), /* for tabs */
	border-color var(--duration-fast) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease), /* for tabs */
	border-color var(--duration-fast) var(--ease);
}

/******************************/
/********** OVERLAYS **********/
/******************************/

.overlay {
	-webkit-transition:
	opacity var(--duration-fast) var(--easeInQuint),
	visibility .01s linear var(--duration-fast),
	-webkit-transform -webkit-calc(var(--duration-fast) / 2) var(--easeInQuint);
	transition:
	opacity var(--duration-fast) var(--easeInQuint),
	visibility .01s linear var(--duration-fast),
	-webkit-transform calc(var(--duration-fast) / 2) var(--easeInQuint);
	-o-transition:
	opacity var(--duration-fast) var(--easeInQuint),
	visibility .01s linear var(--duration-fast),
	-o-transform calc(var(--duration-fast) / 2) var(--easeInQuint);
	-moz-transition:
	opacity var(--duration-fast) var(--easeInQuint),
	transform -moz-calc(var(--duration-fast) / 2) var(--easeInQuint),
	visibility .01s linear var(--duration-fast),
	-moz-transform -moz-calc(var(--duration-fast) / 2) var(--easeInQuint);
	transition:
	opacity var(--duration-fast) var(--easeInQuint),
	transform calc(var(--duration-fast) / 2) var(--easeInQuint),
	visibility .01s linear var(--duration-fast);
	transition:
	opacity var(--duration-fast) var(--easeInQuint),
	transform calc(var(--duration-fast) / 2) var(--easeInQuint),
	visibility .01s linear var(--duration-fast),
	-webkit-transform calc(var(--duration-fast) / 2) var(--easeInQuint),
	-moz-transform calc(var(--duration-fast) / 2) var(--easeInQuint),
	-o-transform calc(var(--duration-fast) / 2) var(--easeInQuint);
}
body.onload .overlay {
	-webkit-transition: none;
	-o-transition: none;
	-moz-transition: none;
	transition: none;
}
.overlay-content {
	-webkit-transition:
	opacity var(--duration-fast) var(--easeInQuint);
	-o-transition:
	opacity var(--duration-fast) var(--easeInQuint);
	-moz-transition:
	opacity var(--duration-fast) var(--easeInQuint);
	transition:
	opacity var(--duration-fast) var(--easeInQuint);
}
.clickoutside {
	-webkit-transition:
	opacity -webkit-calc(var(--duration-fast) / 2) var(--easeInQuint),
	visibility .01s linear var(--duration-fast);
	-o-transition:
	opacity calc(var(--duration-fast) / 2) var(--easeInQuint),
	visibility .01s linear var(--duration-fast);
	-moz-transition:
	opacity -moz-calc(var(--duration-fast) / 2) var(--easeInQuint),
	visibility .01s linear var(--duration-fast);
	transition:
	opacity calc(var(--duration-fast) / 2) var(--easeInQuint),
	visibility .01s linear var(--duration-fast);
}

/** stacked **/

.preview.modal .preview-content,
.overlay .overlay-content {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease);
	-o-transition:
	opacity var(--duration-fast) var(--ease);
	-moz-transition:
	opacity var(--duration-fast) var(--ease);
	transition:
	opacity var(--duration-fast) var(--ease);
}
.overlay.active {
	-webkit-transition:
	visibility .01s linear,
	opacity var(--duration-normal) var(--easeOutQuint) -webkit-calc(var(--duration-normal) / 2),
	-webkit-transform -webkit-calc(var(--duration-normal) / 2) var(--easeOutQuint) -webkit-calc(var(--duration-normal) / 4);
	transition:
	visibility .01s linear,
	opacity var(--duration-normal) var(--easeOutQuint) calc(var(--duration-normal) / 2),
	-webkit-transform calc(var(--duration-normal) / 2) var(--easeOutQuint) calc(var(--duration-normal) / 4);
	-o-transition:
	visibility .01s linear,
	opacity var(--duration-normal) var(--easeOutQuint) calc(var(--duration-normal) / 2),
	-o-transform calc(var(--duration-normal) / 2) var(--easeOutQuint) calc(var(--duration-normal) / 4);
	-moz-transition:
	visibility .01s linear,
	opacity var(--duration-normal) var(--easeOutQuint) -moz-calc(var(--duration-normal) / 2),
	transform -moz-calc(var(--duration-normal) / 2) var(--easeOutQuint) -moz-calc(var(--duration-normal) / 4),
	-moz-transform -moz-calc(var(--duration-normal) / 2) var(--easeOutQuint) -moz-calc(var(--duration-normal) / 4);
	transition:
	visibility .01s linear,
	opacity var(--duration-normal) var(--easeOutQuint) calc(var(--duration-normal) / 2),
	transform calc(var(--duration-normal) / 2) var(--easeOutQuint) calc(var(--duration-normal) / 4);
	transition:
	visibility .01s linear,
	opacity var(--duration-normal) var(--easeOutQuint) calc(var(--duration-normal) / 2),
	transform calc(var(--duration-normal) / 2) var(--easeOutQuint) calc(var(--duration-normal) / 4),
	-webkit-transform calc(var(--duration-normal) / 2) var(--easeOutQuint) calc(var(--duration-normal) / 4),
	-moz-transform calc(var(--duration-normal) / 2) var(--easeOutQuint) calc(var(--duration-normal) / 4),
	-o-transform calc(var(--duration-normal) / 2) var(--easeOutQuint) calc(var(--duration-normal) / 4);
}
.overlay.active .overlay-content {
	-webkit-transition:
	opacity var(--duration-normal) var(--easeOutQuint) -webkit-calc(var(--duration-normal) / 2);
	-o-transition:
	opacity var(--duration-normal) var(--easeOutQuint) calc(var(--duration-normal) / 2);
	-moz-transition:
	opacity var(--duration-normal) var(--easeOutQuint) -moz-calc(var(--duration-normal) / 2);
	transition:
	opacity var(--duration-normal) var(--easeOutQuint) calc(var(--duration-normal) / 2);
}
body.overlayed .clickoutside {
	-webkit-transition:
	opacity -webkit-calc(var(--duration-normal) / 2) var(--easeOutQuint),
	visibility .01s linear -webkit-calc(var(--duration-normal) / 2);
	-o-transition:
	opacity calc(var(--duration-normal) / 2) var(--easeOutQuint),
	visibility .01s linear calc(var(--duration-normal) / 2);
	-moz-transition:
	opacity -moz-calc(var(--duration-normal) / 2) var(--easeOutQuint),
	visibility .01s linear -moz-calc(var(--duration-normal) / 2);
	transition:
	opacity calc(var(--duration-normal) / 2) var(--easeOutQuint),
	visibility .01s linear calc(var(--duration-normal) / 2);
}

/* > out */
.has-sticky-infos .is-sticky-infos {
	-webkit-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease);
	-o-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease);
	-moz-transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease);
	transition:
	visibility .01s linear var(--duration-fast),
	opacity var(--duration-fast) var(--ease);
}
/* > in */
.has-sticky-infos a:hover > .is-sticky-infos {
	-webkit-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease);
	-o-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease);
	-moz-transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease);
	transition:
	visibility .01s linear,
	opacity var(--duration-fast) var(--ease);
}

/******************************/
/********** TOOLTIPS **********/
/******************************/

/* > out */
.tooltip {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease),
	top var(--duration-fast) var(--elastic),
	visibility .01s linear var(--duration-fast);
	-o-transition:
	opacity var(--duration-fast) var(--ease),
	top var(--duration-fast) var(--elastic),
	visibility .01s linear var(--duration-fast);
	-moz-transition:
	opacity var(--duration-fast) var(--ease),
	top var(--duration-fast) var(--elastic),
	visibility .01s linear var(--duration-fast);
	transition:
	opacity var(--duration-fast) var(--ease),
	top var(--duration-fast) var(--elastic),
	visibility .01s linear var(--duration-fast);
}
/* > in */
.tooltip.active {
	-webkit-transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fast),
	top var(--duration-fast) var(--elastic) var(--duration-fast),
	visibility .01s linear;
	-o-transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fast),
	top var(--duration-fast) var(--elastic) var(--duration-fast),
	visibility .01s linear;
	-moz-transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fast),
	top var(--duration-fast) var(--elastic) var(--duration-fast),
	visibility .01s linear;
	transition:
	opacity var(--duration-fast) var(--ease) var(--duration-fast),
	top var(--duration-fast) var(--elastic) var(--duration-fast),
	visibility .01s linear;
}
body.onload .tooltip {
	-webkit-transition: none;
	-o-transition: none;
	-moz-transition: none;
	transition: none;
}

/*****************************/
/********** INSTANT **********/
/*****************************/

.instant-transition {
	-webkit-transition:none;
	-moz-transition:none;
	-o-transition:none;
	transition:none;
}

/*****************************/
/********** SPINNER **********/
/*****************************/

@-webkit-keyframes spinner {
	0%, 50%, 100% { -webkit-animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); }
	0% { -webkit-transform: rotate(0); }
	50% { -webkit-transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@-moz-keyframes spinner {
	0%, 50%, 100% { -moz-animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); }
	0% { -moz-transform: rotate(0); }
	50% { -moz-transform: rotate(180deg); }
	100% { -moz-transform: rotate(360deg); }
}
@-o-keyframes spinner {
	0%, 50%, 100% { -o-animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); }
	0% { -o-transform: rotate(0); transform: rotate(0); }
	50% { -o-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes spinner {
	0%, 50%, 100% { -webkit-animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); -moz-animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); -o-animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); }
	0% { -webkit-transform: rotate(0); -moz-transform: rotate(0); -o-transform: rotate(0); transform: rotate(0); }
	50% { -webkit-transform: rotate(180deg); -moz-transform: rotate(180deg); -o-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}

/*******************************/
/********** BREATHING **********/
/*******************************/

@-webkit-keyframes breathing {
	0% { -webkit-transform:scale(1); }
	50% { -webkit-transform:scale(1.2); }
	100% { -webkit-transform:scale(1); }
}
@-moz-keyframes breathing {
	0% { -moz-transform:scale(1); }
	50% { -moz-transform:scale(1.2); }
	100% { -moz-transform:scale(1); }
}
@-o-keyframes breathing {
	0% { -o-transform:scale(1); transform:scale(1); }
	50% { -o-transform:scale(1.2); transform:scale(1.2); }
	100% { -o-transform:scale(1); transform:scale(1); }
}
@keyframes breathing {
	0% { -webkit-transform:scale(1); -moz-transform:scale(1); -o-transform:scale(1); transform:scale(1); }
	50% { -webkit-transform:scale(1.2); -moz-transform:scale(1.2); -o-transform:scale(1.2); transform:scale(1.2); }
	100% { -webkit-transform:scale(1); -moz-transform:scale(1); -o-transform:scale(1); transform:scale(1); }
}

/****************************/
/****************************/
/********** LOADER **********/
/****************************/
/****************************/

.loader:before,
.is-loading:before {
	-webkit-animation: spinner var(--duration-normal) infinite linear;
	   -moz-animation: spinner var(--duration-normal) infinite linear;
	     -o-animation: spinner var(--duration-normal) infinite linear;
	        animation: spinner var(--duration-normal) infinite linear;
}
/* icon only */
.loader:before {
	content:"" !important;
	display:inline-block;
	height:1rem;
	width:1rem;
	margin:-.25rem;
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
	border-style:solid;
	border-width:0.125rem;
	border-color: var(--bright-color) var(--bright-color) var(--bright-color) transparent;
}
/* loading txt */
.is-loading {
	pointer-events:none !important;
}
.is-loading:before {
	content:"";
	display:inline-block;
	vertical-align:middle;
	height:.75em;
	width:.75em;
	margin:-.375em .375em -.375em -.375em;
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
	border-style:solid;
	border-width:0.125em;
	border-color: var(--bright-color) var(--bright-color) var(--bright-color) transparent;
}
.button.is-loading:before {
	margin:0;
	margin-right:1em;
}
.check.is-loading:before {
	content:"" !important;
	display:inline-block;
	height:.875rem;
	width:.875rem;
	padding:0;
	margin:.875rem;
	-webkit-border-radius:50%;
	-moz-border-radius:50%;
	border-radius:50%;
	border-style:solid;
	border-width:0.125rem;
	border-color: var(--bright-color) var(--bright-color) var(--bright-color) transparent;
}