.skeleton {
	background: #ffffff10 !important;
	color: transparent !important;
	text-shadow: none !important;
	border: none !important;
	opacity: 1;

	background-repeat: no-repeat;
  background-image:
    /* layer 2: avatar */
    /* white circle with 16px radius */
    radial-gradient(circle 16px, white 99%, transparent 0),
    /* layer 1: title */
    /* white rectangle with 40px height */
    linear-gradient(white 40px, transparent 0),
    /* layer 0: card bg */
    /* gray rectangle that covers whole element */
    linear-gradient(gray 100%, transparent 0);

	&:after {
		background: none !important;
	}

	* {
		visibility: hidden !important;
	}
}

.skeleton-container {
	background: #ffffff10 !important;
	&:after, &:before {
		background: #ffffff10 !important;
	}
}

.skel-loading {
	.skel {
		background: #ffffff10 !important;
		color: transparent !important;
		text-shadow: none !important;
		border: none !important;
		opacity: 1;

		background-repeat: no-repeat;
		background-image:
			/* layer 2: avatar */
			/* white circle with 16px radius */
			radial-gradient(circle 16px, white 99%, transparent 0),
			/* layer 1: title */
			/* white rectangle with 40px height */
			linear-gradient(white 40px, transparent 0),
			/* layer 0: card bg */
			/* gray rectangle that covers whole element */
			linear-gradient(gray 100%, transparent 0);

		&:after {
			background: none !important;
		}

		* {
			visibility: hidden !important;
		}
	}
}

img {
	&.skeleton {
		opacity: 0;
	}
}
