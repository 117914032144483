@charset "UTF-8";

/****************************/
/********** COLORS **********/
/****************************/

/*
--alpha-rgb : .9;
--black-color : 4,4,15;
--black-color :rgba(var(--red), 1);
--black-color-alpha :rgba(var(--red), var(--alpha));

// default
black : 4,4,15;
darker : 11,11,20
dark : 21,21,30;
grey : 31,31,40;
light : 61,61,70;
lighter : 131,131,140; // item-content in director-list.films, original
bright : 255,255,255;

//exceptions
rgb(101,101,110) // in-collaboration, casting, bonus source, nav.light...
rgb(161,161,170) // is-recommended, film-versions...
rgb(211,211,220) // table-section
rgb(221,221,230) // table-section
rgb(231,231,240) // table, watch-status... less usage ?
rgb(245,245,245) // overlay.light...
rgb(246,246,255) // table

// colors
yellow : 255,255,153;
red : 217,46,65;
purple : 143,61,160; // OUT

// custom
private : 241,241,250; // for user...
blueprint : 41,41,50; // for react loading

// bonus
archives : 253,155,57;
exclusive : 217,46,65; // red

// svod | tvod
tvod : 94,62,145;
svod : 0,179,174;
*/

body {
	--alpha-off-rgb: .125; /* previously .15 */
	--alpha-rgb: .95; /* previously .9 */
	--black-rgb: 4,4,15;
	--darker-rgb: 11,11,20;
	--dark-rgb: 21,21,30;
	--grey-rgb: 31,31,40;
	--light-rgb: 61,61,70;
	--lighter-rgb: 131,131,140;
	--bright-rgb: 255,255,255;
	--yellow-rgb: 255,255,153;
	--red-rgb: 217,46,65;
	/*--purple-rgb: 143,61,160;*/ /* out => bonus-archives-rgb */
	--private-rgb: 241,241,250;
	--blueprint-rgb: 41,41,50;
	--bonus-exclusive-rgb: 217,46,65; /* red */
	--bonus-archives-rgb: 253,155,57;
	--tvod-rgb: 94,62,145;
	--svod-rgb: 0,179,174;
	--black-color: rgb(var(--black-rgb));
	--darker-color: rgb(var(--darker-rgb));
	--dark-color: rgb(var(--dark-rgb));
	--grey-color: rgb(var(--grey-rgb));
	--light-color: rgb(var(--light-rgb));
	--lighter-color: rgb(var(--lighter-rgb));
	--bright-color: rgb(var(--bright-rgb));
	--yellow-color : rgb(var(--yellow-rgb));
	--red-color : rgb(var(--red-rgb));
	/*--purple-color : rgb(var(--purple-rgb));*/
	--private-color: rgb(var(--private-rgb));
	--blueprint-color: rgb(var(--blueprint-rgb));
	--bonus-exclusive-color : rgb(var(--bonus-exclusive-rgb));
	--bonus-archives-color : rgb(var(--bonus-archives-rgb));
	--tvod-color : rgb(var(--tvod-rgb));
	--svod-color: rgb(var(--svod-rgb));
}

/********************************/
/********** ANIMATIONS **********/
/********************************/

/* https://easings.net/fr */

body {
	/* duration */
	--duration-fastest: .125s;
	--duration-fast: .25s;
	--duration-normal: .5s;
	--duration-slow: 1s;
	/* easing */
	--ease-fast: cubic-bezier(0,.5,.5,1);
	--elastic: cubic-bezier(1, 0.01, 0, 1.22);
	--ease: ease;
	--easeInQuint: cubic-bezier(0.64, 0, 0.78, 0);
	--easeOutQuint: cubic-bezier(0.22, 1, 0.36, 1);
}

/*******************************/
/********** STRUCTURE **********/
/*******************************/

/********** VARIOUS **********/

body {
	--max-width: 1400px;
	--stroke-width: .0625rem; /* == default */
	--stroke-width-min: .125rem; /* == for navbar */
	--stroke-width-medium: .25rem; /* == for results */
	--stroke-width-large: .375rem; /* == for section-title */
	--border-radius: .125rem;
}

/********** MARGIN **********/

/*
for tag spacing (cf. fonts.css)
--margin-normal == --gutter-gap !!!
*/

@media (min-width:0px) {
	body {
		--margin-short: .5rem;
		--margin-normal: 1rem;
		--margin-large: 1rem;
	}
}
@media (min-width:1000px) {
	body {
		--margin-large: 2rem;
	}
}

/********** SECTIONS **********/

@media (min-width:0px) {
	body {
		--section-gap: 1rem;
	}
}
@media (min-width:1000px) {
	body {
		--section-gap: 2rem;
	}
}
@media (min-width:1200px) {
	body {
		--section-gap: 4rem;
	}
}

/********** GUTTER **********/

@media (min-width:0px) {
	body {
		--gutter-gap:1rem;
	}
}
@media (min-width:1000px) {
	body {
		--gutter-gap:2rem;
	}
}

/********** HEADER **********/

@media (min-width: 0px) {
	body {
		--heading-height: 3rem;
		--header-height: 3rem;
	}
	body.is-vod {
		--header-height: 6rem;
	}
}
@media (min-width: 1000px) {
	body {
		--heading-height: 4rem;
		--header-height: 4rem;
		--lacinetek-width:10rem;
	}	
	body.is-vod {
		--header-height: 7rem;
	}
}

/********** VIEWPORT 100VH FIX **********/

/** default **/

body {
	--viewport-height: 100vh;
	--viewport-width : 100vw;
}

/** touch device only **/

@media (pointer:coarse) {
	body {
		--viewport-height: 90vh; /* js rectified */
	}
}