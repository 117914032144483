@charset "UTF-8";

/*
.slider == .slider > .slider-container > .slides > .slide > .slide-content
.slider.min == slide-content:hover has borders fx
.slider.grid == slider with grid behaviour (shared behaviours)

.slider.discover
.slider.selections
.slider.films
.slider.institutions

.slider nav
.slider-pagination == dots
.slider-controls == previous + next

.slide-bg == for viewer (cf. in gallery overlay)
*/

/****************************/
/****************************/
/********** SWIPER **********/
/****************************/
/****************************/

/*
JS added
overflow visible == for preview
*/

/********** DEFAULT **********/

.swiper-container {
	position:relative;
	width:100%;
}
.swiper-container,
.swiper-wrapper,
.swiper-slide {
	overflow: visible !important;
}

/********** HIDE **********/

.swiper-notification {
	position:absolute !important;
	opacity: 0;
	height:0;
}

/********** FOR LI **********/

.swiper-slide {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height:auto;
	margin:0;
}

/********** SWIPER-SLIDE-CONTENT **********/

.swiper-container .swiper-slide .swiper-slide-content {
	position:relative;
	height:100%;
	width:100%;
}

/********** ON / OFF **********/
/* JS > if < 2 slides */

.swiper-container.off .swiper-wrapper {
	-webkit-transform: translate3d(0,0,0) !important;
	   -moz-transform: translate3d(0,0,0) !important;
	        transform: translate3d(0,0,0) !important;
}
.swiper-container.off .swiper-controls .previous,
.swiper-container.off .swiper-controls .next,
.swiper-container.off + .swiper-controls .previous,
.swiper-container.off + .swiper-controls .next {
	display: none;
}

/********** NAVIGATION **********/

.swiper-controls .swiper-button-disabled {
	opacity:.25;
	pointer-events:none !important;
}

/** reset swiperjs **/

.slider .swiper-pagination {
	position:relative;
	bottom:auto;
}
.swiper-pagination-bullet {
	margin:0;
	opacity:1;
	background:var(--bright-color);
}
.slider .slider-pagination a.swiper-pagination-bullet-active {
	background: var(--red-color);
}
.slider.has-thumbnails .slider-pagination a.swiper-pagination-bullet-active {
	opacity:1;
}

/*****************************/
/*****************************/
/********** MASKING **********/
/*****************************/
/*****************************/

/** hide overflown elements... **/

.slider .slider-container {
	-webkit-clip-path: inset(-100vh 0 -100vh 0);
	        clip-path: inset(-100vh 0 -100vh 0);
}
.slider.min .slider-container {
	-webkit-clip-path: inset(-100vh -.125rem -100vh -.125rem);
	        clip-path: inset(-100vh -.125rem -100vh -.125rem);
}

/****************************/
/****************************/
/********** SLIDER **********/
/****************************/
/****************************/

.slider {
	position:relative;
	width: 100%;
}
.slider .slider-container {
	position:relative;
	display:block;
	width:100%;
	height:100%;
}
.slider .slides {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	height:100%;
	width:100%;
}

/********** SCROLLBARS **********/

/* https://codepen.io/chriscoyier/pen/XwbNwX */

.slider .slides {
	overflow-x:auto;
	 -webkit-overflow-scrolling: touch;
	scroll-behavior:auto;
	pointer-events:none;
}
.slider .slide,
.slider nav a {
	pointer-events:auto;
}

/* slider custom scrollbar */

.slider .slides::-webkit-scrollbar {
	height:0;
	width:0;
}
.slider .slides::-webkit-scrollbar-thumb,
.slider .slides::-webkit-scrollbar-track {
	background:transparent;
}
.slides {
	scrollbar-width:none;
	-ms-overflow-style:none;
}
.slider .slide:first-child nav.controls a,
.slider .slide:target nav.controls a {
	z-index:10;
}

/********** SLIDER SLIDE **********/

.slider .slide {
	position:relative;
	list-style:none;
	-webkit-flex-shrink:0;
	    -ms-flex-negative:0;
	        flex-shrink:0;
	background-position:center center !important;
	background-repeat:no-repeat !important;
	-webkit-background-size:contain !important;
	   -moz-background-size:contain !important;
	     -o-background-size:contain !important;
	        background-size:contain !important;
	padding:0;
	overflow:hidden;
	max-width:none;
	width:100%;
	margin:0; /* due to ul li + li */
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:stretch;
	-webkit-align-items:stretch;
	   -moz-box-align:stretch;
	    -ms-flex-align:stretch;
	        align-items:stretch;
}

/********** SLIDER SLIDE-CONTENT **********/

.slider .slide-content {
	position:relative;
	display:block;
	width:100%;
	padding:0;
}
.slider .slide-content figure:not(.avatar),
.slider .slide-content img {
	width:100%;
	max-width:none;
}

/********** SLIDER VIEWER **********/

.slider .slide-bg {
	display:block;
	height:100%;
	width:100%;
	-webkit-background-size:contain !important;
	   -moz-background-size:contain !important;
	     -o-background-size:contain !important;
	        background-size:contain !important;
	background-repeat:no-repeat !important;
	background-position:center center !important;
}

/*********************************/
/*********************************/
/********** SLIDER FULL **********/
/*********************************/
/*********************************/

/** cf. svod focus **/

.slider.full {
	max-width: none;
}

/********** INFOS FIXED **********/

.slider .infos.fixed {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	   -moz-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	   -moz-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
	max-width: none;
	padding:-webkit-calc(var(--gutter-gap) * 2) -webkit-calc(var(--gutter-gap) * 4);
	padding:-moz-calc(var(--gutter-gap) * 2) -moz-calc(var(--gutter-gap) * 4);
	padding:calc(var(--gutter-gap) * 2) calc(var(--gutter-gap) * 4);
}
.slider .infos.fixed .svod-infos {
	height: 100%;
}

/********** SVOD-INFOS **********/

.slider .svod-infos {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
		-moz-box-orient: vertical;
		-moz-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
		-moz-box-align: start;
		-ms-flex-align: start;
			align-items: flex-start;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
		-moz-box-pack: end;
		-ms-flex-pack: end;
			justify-content: flex-end;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
		-moz-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	line-height:1em;
}
.slider .svod-infos .type {
	font-size:.625em;
	line-height:1em;
	text-transform: uppercase;
}
.slider .svod-infos .title {
	line-height:1em;
	text-transform: uppercase;
	font-size: 1.5em;
	white-space: nowrap;
}
.slider .svod-infos .alert {
	white-space: nowrap;
}
.slider .svod-infos .button {
	margin-top:auto;
	white-space: nowrap;
}
.slider .svod-infos * + .title {
	margin-top:0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.slider .svod-infos {
		font-size:1em;
	}
	.slider.full .svod-infos * + .alert {
		margin-top:-webkit-calc(var(--gutter-gap) / 2);
		margin-top:-moz-calc(var(--gutter-gap) / 2);
		margin-top:calc(var(--gutter-gap) / 2);
	}
	.slider.full .svod-infos * + .button {
		margin-top:var(--gutter-gap) !important;
	}
}
@media (min-width:760px) {
	.slider .svod-infos {
		font-size:1.25em;
	}
	.slider .svod-infos .type {
		margin-top:auto;
	}
	.slider .svod-infos * + .alert {
		margin-top:-webkit-calc(var(--gutter-gap) * 2 / 3);
		margin-top:-moz-calc(var(--gutter-gap) * 2 / 3);
		margin-top:calc(var(--gutter-gap) * 2 / 3);
	}
}
@media (min-width:1000px) {
	.slider .svod-infos {
		font-size:1.5em;
	}
	.slider .svod-infos * + .button {
		margin-top:-webkit-calc(var(--gutter-gap) * 2 / 3) !important;
		margin-top:-moz-calc(var(--gutter-gap) * 2 / 3) !important;
		margin-top:calc(var(--gutter-gap) * 2 / 3) !important;
	}
}
@media (min-width:1200px) {
	.slider .svod-infos {
		font-size:3em;
	}
	.slider .svod-infos * + .button {
		margin-top:var(--gutter-gap) !important;
	}
}

/********************************/
/********************************/
/********** SLIDER NAV **********/
/********************************/
/********************************/

/*
.slider-controls == default
.slider-controls.full
.slider-controls.min
.slider-controls.min.alt
.has-thumbnails ... .slider-controls
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

.slider .slider-controls {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	width:100%;
	font-size:0;
	line-height:0;
	margin-top:0;
	pointer-events:none;
}
.slider .slider-controls > * {
	margin:0;
}
.slider .slider-controls a {
	pointer-events:auto;
}
.slider .slider-controls a.disabled {
	pointer-events:none;
}
.slider .slider-controls a.active,
.slider .slider-controls a:hover {
	color:var(--red-color);
	text-shadow:none;
}

/********** ON LOAD... **********/
/* to move to slider itself... */

body.onload .slider .slider-controls,
.slider.onload .slider-controls {
	opacity: 0;
	visibility: hidden;
}

/********** PREVIOUS / NEXT **********/

.slider .slider-controls .previous,
.slider .slider-controls .next {
	font-size:0;
}
.slider .slider-controls .previous:before,
.slider .slider-controls .next:after {
	font-family:'icomoon';
	position:relative;
	display:block;
	line-height:1em;
	height:1em;
	width:1em;
	-webkit-transform:none;
	   -moz-transform:none;
	    -ms-transform:none;
	     -o-transform:none;
	        transform:none;
}

/******************************************/
/********** SLIDER CONTROLS FULL **********/
/******************************************/

.slider .slider-controls.full {
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
}

/*****************************************/
/********** SLIDER CONTROLS MIN **********/
/*****************************************/

.slider .slider-controls.min  {
	margin:0;
}
.slider .slider-controls.min .previous,
.slider .slider-controls.min .next {
	position:absolute;
	z-index:30;
	top:50%;
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
	font-size:0;
	padding:0;
	-webkit-border-radius:var(--border-radius);
	   -moz-border-radius:var(--border-radius);
	        border-radius:var(--border-radius);
	width:auto;
	color:var(--bright-color);
	text-shadow: 0 0 0 var(--bright-color);
	background:var(--red-color);
}
.slider .slider-controls.min .previous,
.slider .slider-controls.min .next {
	margin:0;
}
.slider .slider-controls.min .previous:hover,
.slider .slider-controls.min .next:hover {
	color:var(--red-color);
	text-shadow: 0 0 0 var(--red-color);
	background:var(--bright-color);
}
.slider .slider-controls.min .previous {
	left:0;
}
.slider .slider-controls.min .next {
	right:0;
}
.slider .slider-controls.min .disabled {
	display:none;
}

/*********************************************/
/********** SLIDER CONTROLS MIN ALT **********/
/*********************************************/

.slider .slider-controls.min.alt .previous,
.slider .slider-controls.min.alt .next {
	position:absolute;
	z-index:30;
	top:50%;
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
	font-size:0;
	padding:0;
	-webkit-border-radius: 0;
	   -moz-border-radius: 0;
	        border-radius: 0;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	width:auto;
	background:none;
}
.slider .slider-controls.min.alt .previous:hover,
.slider .slider-controls.min.alt .next:hover {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
}
.slider .slider-controls.min.alt .previous {
	margin-left:1rem;
}
.slider .slider-controls.min.alt .next {
	margin-right:1rem;
}
.slider .slider-controls.min.alt .previous:before,
.slider .slider-controls.min.alt .next:after {
	font-size:2rem;
	width:1em;
}
.slider .slider-controls.min.alt .slider-pagination {
	margin:auto var(--gutter-gap) var(--gutter-gap) var(--gutter-gap);
}

/*****************************************/
/********** SLIDER MIN CONTROLS **********/
/*****************************************/

.slider.min .slider-controls,
.slider-controls.min {
	position:absolute;
	top:0;
	left: 0;
	right:0;
	bottom:0;
	z-index:500;
	pointer-events: none;
}
.slider.min .slider-controls > * {
	z-index:100;
	pointer-events: auto;
}
.slider.min .slider-controls:before,
.slider.min .slider-controls:after {
	content:"";
	position: absolute;
	z-index: -1;
	top:0;
	height:100%;
	width:4rem;
	pointer-events: auto;
}
.slider.min .slider-controls:before {
	left:0;
}
.slider.min .slider-controls:after {
	right:0;
}

/***************************************/
/********** SLIDER PAGINATION **********/
/***************************************/

.slider .slider-pagination {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	font-size:0;
	line-height:0;
	padding:0;
	width:auto;
}
.slider .slider-pagination li {
	list-style:none;
}
.slider .slider-pagination a {
	display:block;
	background:var(--bright-color);
	text-align:center;
	height:.375rem;
	width:.375rem;
	padding:.375rem;
	margin:.375rem !important;
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
}
.slider .slider-pagination a:hover,
.slider .slider-pagination a.active {
	background:var(--red-color);
}

/******************************************************/
/********** HAS-THUMBNAILS SLIDER PAGINATION **********/
/******************************************************/

/* for bonus archives */

.slider.has-thumbnails .slider-pagination {
	-webkit-box-align:stretch;
	-webkit-align-items:stretch;
	   -moz-box-align:stretch;
	    -ms-flex-align:stretch;
	        align-items:stretch;
}
.slider.has-thumbnails .slider-pagination a {
	height:auto;
	width:100%;
	max-width:12rem;
	background:none;
	-webkit-border-radius:0;
	   -moz-border-radius:0;
	        border-radius:0;
	opacity:.25;
	text-align:left;
}
.slider.has-thumbnails .slider-pagination a.active {
	font-size:.875rem;
	line-height:normal;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.slider.has-thumbnails.slider-pagination a:hover,
.slider.has-thumbnails .slider-pagination a.active {
	opacity:1;
}
.slider.has-thumbnails .slider-pagination a .thumbnail-infos {
	font-size:.875rem;
	line-height:normal;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	opacity:0;
}
.slider.has-thumbnails .slider-pagination a.active .thumbnail-infos,
.slider.has-thumbnails .slider-pagination a:hover .thumbnail-infos {
	opacity:1;
}
.slider.has-thumbnails .slider-pagination p {
	width:200%;
}

/********** @MEDIA **********/

/* no thumbnails in mobile */

@media (max-width:760px) {
	.slider.has-thumbnails .slider-pagination a {
		font-size: 0;
		background: var(--bright-color);
		height: 0.375rem;
		width: 0.375rem;
		padding: 0.375rem;
		margin: 0.375rem !important;
		-webkit-border-radius: 50%;
		   -moz-border-radius: 50%;
		        border-radius: 50%;
	}
	.slider.has-thumbnails .slider-pagination a > * {
		position:absolute;
		height: 0;
		width: 0;
		opacity: 0;
		margin: 0;
		padding: 0;
		overflow: hidden;
	}
}

/****************************/
/********** @MEDIA **********/
/****************************/

@media (min-width:0px) {
	.slider .slider-controls .previous:before,
	.slider .slider-controls .next:after {
		font-size:.625rem;
		padding:.6875rem;
	}
	.slider .slider-controls .previous:hover:before {
		padding:.6875rem .875rem .6875rem .5rem;
	}
	.slider .slider-controls .next:hover:after {
		padding:.6875rem .5rem .6875rem .875rem;
	}
	/* min */
	.slider .slider-controls.min .previous {
		margin-left:-.5rem;
	}
	.slider .slider-controls.min .next {
		margin-right:-.5rem;
	}
}
@media (min-width:760px) {
	.slider .slider-controls .previous:before,
	.slider .slider-controls .next:after {
		font-size:1rem;
		padding:1em;
	}
	.slider .slider-controls .previous:hover:before {
		padding:1rem 1.5rem 1rem .5rem;
	}
	.slider .slider-controls .next:hover:after {
		padding:1rem .5rem 1rem 1.5rem;
	}
}
@media (min-width:1000px) {
	/* min */
	.slider .slider-controls.min .previous {
		margin-left:-1.5rem;
	}
	.slider .slider-controls.min .next {
		margin-right:-1.5rem;
	}
}

/********************************/
/********************************/
/********** SLIDER MIN **********/
/********************************/
/********************************/

/*
cf. front > discover (font-size bigger @1200px)
cf. front > institutions
cf. selection > previous selections
*/

.slider.min .slide {
	padding:0 .125rem;
}

/****************************************/
/********** SLIDER MIN CONTENT **********/
/****************************************/

.slider.min .slide-content:before {
	content:"";
	position:absolute;
	z-index:20;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:solid var(--stroke-width) transparent;
	pointer-events:none;
}
.slider.min .slide-content:hover:before {
	border:solid var(--stroke-width) var(--yellow-color);
}
.slider.min .slide .slide-content .infos {
	position:absolute;
	z-index:10;
	bottom:0;
	left:0;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	line-height: 1em;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.slider.min .slide-content .infos h3 {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	text-transform:uppercase;
	line-height: 1em;
	background:var(--black-color);
}
.slider.min .slide-content .infos h3 strong {
	padding-left:.25em;
}
.slider.min .slide-content .infos h3 .date {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
	white-space:nowrap;
}
.slider.min .slide-content .infos h3 .date:before {
	content:"";
	display:inline-block;
	border-left:solid var(--stroke-width);
}

/**************************************/
/********** SLIDER MIN FILMS **********/
/**************************************/

.slider.min.films .slide-content .infos .title {
	padding:0;
	text-transform:none;
	background:none;
	text-shadow:0 0 0.125rem var(--darker-color);
}
.slider.min.films .is-recommended {
	position:absolute;
	top:0;
	right:0;
	background:var(--black-color);
	margin:1rem;
}
.slider.min.films .is-recommended:hover {
	opacity:.5;
}

/*********************************************/
/********** SLIDER MIN INSTITUTIONS **********/
/*********************************************/

.slider.min.institutions .infos {
	top:0;
	right:0;
}
.slider.min.institutions .infos figure {
	margin:0 auto;
}
.slider.min.institutions .slide-bg img {
	opacity:.75;
}
.slider.min.institutions .slide-bg:after {
	content:"";
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:var(--black-color);
	opacity:.75;
}
.slider.min .slide-content .infos h3.hidden {
	width:0 !important;
	padding:0 !important;
}

/********** SLIDER MIN INSTITUTIONS TABS **********/

.slider.min.institutions.has-tabs .slide-content {
	padding:0 1em;
}
.slider.min.institutions.has-tabs .slide-content:before,
.slider.min.institutions.has-tabs .slide:after {
	display:none;
}

/****************************/
/********** @MEDIA **********/
/****************************/

@media (min-width:0px) {
	.slider.min .slide .slide-content .infos {
		font-size:.625rem;
	}
	.slider.min .slide-content .infos h3 {
		font-size:.75rem;
		padding:.875rem;
	}
	.slider.min .slide-content .infos h3 .date:before {
		height:.5rem;
		margin:0 .25rem;
	}
}
@media (min-width:1000px) {
	.slider.min .slide .slide-content .infos {
		font-size:.75rem;
		padding:.875rem;
	}
	.slider.min .slide .slide-content h3 {
		font-size:.875rem;
		padding:1rem;
	}
	.slider.min .slide-content .infos h3 .date:before {
		height:.75rem;
		margin:0 .5rem;
	}
}
@media (min-width:1200px) {
	.slider.min .slide .slide-content .infos {
		font-size:.875rem;
		padding:1rem;
	}
	.slider.min .slide-content .infos h3 {
		font-size:1rem;
		padding:1rem;
	}
	.slider.min .slide-content .infos h3 .date:before {
		height:.75rem;
		margin:0 .5rem;
	}
}
@media (min-width:1400px) {
	/* discover */
	.slider.min.discover .slide-content .infos h3 {
		font-size:1.25rem;
	}
}

/*********************************/
/*********************************/
/********** SLIDER GRID **********/
/*********************************/
/*********************************/

/*
slider grid > posts / films
working as .grid.films and slider.min == cf. grid.css
.slider.grid.films
.slider.grid.posts
.slider.grid.gallery
*/

/********** DEFAULT **********/

.slider.grid {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	gap:0;
	-webkit-transform:none;
	   -moz-transform:none;
	    -ms-transform:none;
	     -o-transform:none;
	        transform:none;
}
.slider.grid .slider-container {
	background:none;
}
.slider.grid .slide:before {
	content:"";
	position:absolute;
	z-index:20;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:solid var(--stroke-width) transparent;
	pointer-events:none;
}
.slider.grid .slide:hover:before {
	border:solid var(--stroke-width) var(--yellow-color);
}
.slider.grid .slider-controls {
	background:none;
}

/********** SLIDER MULTIROWS **********/

.slider.grid.multirows .slide {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	gap: var(--gutter-gap);
}
.slider.grid.multirows .slide:before {
	display: none;
}
.slider.grid.multirows .slide .slide-content:before {
	content:"";
	position:absolute;
	z-index:20;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:solid var(--stroke-width) transparent;
	pointer-events:none;
}
.slider.grid.multirows .slide .slide-content:hover:before {
	border:solid var(--stroke-width) var(--yellow-color);
}

/********** SLIDER GRID FILMS **********/

/*  keep block aspect */

.slider.grid .film-link {
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
}
.slider.grid.films .film-link {
	background:var(--grey-color);
}
section.grey .slider.grid.films .film-link {
	background:var(--dark-color);
}
.slider.grid .cover {
	display:block;
	width:100%;
	max-width:none;
}
.slider.grid .infos {
	display:block;
	width:100%;
	padding:1rem;
	color:rgb(131,131,140);
	text-shadow:0 0 0 rgb(131,131,140);
}
.slider.grid .infos .title,
.slider.grid .infos .director {
	display:block;
}
.slider.grid .infos .year {
	margin:.25rem 0 0 0;
}
.slider.grid ul.has-bonus {
	opacity:1;
}

/********** SLIDER GRID POSTS **********/

.slider.grid.posts .infos {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.grid.posts .slide h3 {
	white-space:nowrap;
	overflow:hidden;
	-o-text-overflow:ellipsis;
	   text-overflow:ellipsis;
}
.grid.posts .slide:hover h3 {
	white-space:normal;
	-o-text-overflow:none;
	   text-overflow:none;
}

/********** SLIDER GRID GALLERY **********/

.slider.grid.gallery .slide:before {
	display:none;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.slider.grid.posts .slide-content {
		padding:0 0 3.25rem 0;
	}
	.grid.posts .slide h3 {
		font-size:.75rem;
	}
}
@media (min-width:480px) {
	.grid.posts .slide h3 {
		font-size:1rem;
	}
}
@media (min-width:760px) {
	.slider.grid.posts .slide-content {
		padding:0 0 6.125rem 0;
	}
}

/***********************************/
/***********************************/
/********** SLIDER INLINE **********/
/***********************************/
/***********************************/

/* .slider.inline.films => slider pack in single-film.php */

.slider.inline {
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
}
.slider.inline .slider-container {
	max-width:none;
}
.slider.inline.films .slide {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
}
.slider.inline.films .slide .cover:before {
	content:"";
	position:absolute;
	z-index:20;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border: solid var(--stroke-width) rgba(var(--yellow-rgb), 0);
	pointer-events:none;
}
.slider.inline.films .slide:hover .cover:before {
	border:solid var(--stroke-width) var(--yellow-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.slider.inline.films .slide {
		-webkit-flex-basis:100%;
		    -ms-flex-preferred-size:100%;
		        flex-basis:100%;
	}
}
@media (min-width:480px) {
	.slider.inline .slider-container {
		width:-webkit-calc(100% + .25rem);
		width:-moz-calc(100% + .25rem);
		width:calc(100% + .25rem);
		margin:0 -.25rem;
	}
	.slider.inline.films .slide {
		-webkit-flex-basis:50%;
		    -ms-flex-preferred-size:50%;
		        flex-basis:50%;
		padding:0 .125rem;
	}
}
@media (min-width:760px) {
	.slider.inline .slider-container {
		width:-webkit-calc(100% + 1rem);
		width:-moz-calc(100% + 1rem);
		width:calc(100% + 1rem);
		margin:0 -.5rem;
	}
	.slider.inline.films .slide {
		-webkit-flex-basis:25%;
		    -ms-flex-preferred-size:25%;
		        flex-basis:25%;
		padding:0 .5rem;
	}
}
@media (min-width:1000px) {
	.slider.inline .slider-container {
		width:-webkit-calc(100% + 2rem);
		width:-moz-calc(100% + 2rem);
		width:calc(100% + 2rem);
		margin:0 -1rem;
	}
	.slider.inline.films .slide {
		padding:0 1rem;
	}
}

/*********************************/
/*********************************/
/********** SLIDER LIST **********/
/*********************************/
/*********************************/

/*
slider list > bonus
working as .list.bonus == cf. styles.css
.slider.list.bonus
*/

/********** DEFAULT **********/

.slider.list.bonus .slide {
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
}
.list.bonus .slide > * {
	width:100%;
}

/********** ARCHIVES **********/

.slider.list.bonus.archives .slide {
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-flex:unset;
	-webkit-flex-grow:unset;
	   -moz-box-flex:unset;
	    -ms-flex-positive:unset;
	        flex-grow:unset;
}
.slider.list.bonus.archives .slide > * + * {
	margin:1rem 0 0 0;
}

/********** @MEDIA **********/

/* default == exclusive */

@media (min-width:0px) {
	/** default **/
	.slider.list.bonus .slider-container {
		width:-webkit-calc(100% + var(--gutter-gap) / 8);
		width:-moz-calc(100% + var(--gutter-gap) / 8);
		width:calc(100% + var(--gutter-gap) / 8);
		margin:0 -webkit-calc(var(--gutter-gap) / 16 * -1);
		margin:0 -moz-calc(var(--gutter-gap) / 16 * -1);
		margin:0 calc(var(--gutter-gap) / 16 * -1);
	}
	.slider.list.bonus .slide {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
		-webkit-flex-basis:100%;
		    -ms-flex-preferred-size:100%;
		        flex-basis:100%;
		padding:0 -webkit-calc(var(--gutter-gap) / 8);
		padding:0 -moz-calc(var(--gutter-gap) / 8);
		padding:0 calc(var(--gutter-gap) / 8);
	}
	.slider.list.bonus .slide > * + * {
		margin:var(--gutter-gap) 0 0 0;
	}
}
@media (min-width:480px) {
	/* archives */
	.slider.list.bonus.archives .slide {
		-webkit-flex-basis:50%;
		    -ms-flex-preferred-size:50%;
		        flex-basis:50%;
	}
}
@media (min-width:760px) {
	/** default **/
	.slider.list.bonus .quote {
		font-size:.875rem;
	}
	.slider.list.bonus .slider-container {
		width:-webkit-calc(100% + var(--gutter-gap) / 4);
		width:-moz-calc(100% + var(--gutter-gap) / 4);
		width:calc(100% + var(--gutter-gap) / 4);
		margin:0 -webkit-calc(var(--gutter-gap) / 8 * -1);
		margin:0 -moz-calc(var(--gutter-gap) / 8 * -1);
		margin:0 calc(var(--gutter-gap) / 8 * -1);
	}
	/* = 1 */
	.slider.list.bonus .slide:first-child:last-child > *:first-child {
		margin:0 -webkit-calc(var(--gutter-gap) / 2) 0 0;
		margin:0 -moz-calc(var(--gutter-gap) / 2) 0 0;
		margin:0 calc(var(--gutter-gap) / 2) 0 0;
	}
	.slider.list.bonus .slide:first-child:last-child > *:last-child {
		margin:0 0 0 -webkit-calc(var(--gutter-gap) / 2);
		margin:0 0 0 -moz-calc(var(--gutter-gap) / 2);
		margin:0 0 0 calc(var(--gutter-gap) / 2);
	}
	/* < 2 */
	.slider.list.bonus .slide {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		-webkit-flex-basis:50%;
		    -ms-flex-preferred-size:50%;
		        flex-basis:50%;
		padding:0 -webkit-calc(var(--gutter-gap) / 2);
		padding:0 -moz-calc(var(--gutter-gap) / 2);
		padding:0 calc(var(--gutter-gap) / 2);
	}
	.slider.list.bonus .slide > * + * {
		margin:0 0 0 var(--gutter-gap);
		padding:0;
	}
	/* > 2 */
	.slider.list.bonus[data-count="2"] .slide,
	.slider.list.bonus[data-count="3"] .slide,
	.slider.list.bonus[data-count="4"] .slide {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.slider.list.bonus[data-count="2"] .slide > * + *,
	.slider.list.bonus[data-count="3"] .slide > * + *,
	.slider.list.bonus[data-count="4"] .slide > * + * {
		margin:1rem 0 0 0;
	}
	/** archives **/
	.slider.list.bonus.archives .slide {
		-webkit-flex-basis:-webkit-calc(100% / 3);
		    -ms-flex-preferred-size:calc(100% / 3);
		        flex-basis:-moz-calc(100% / 3);
		        flex-basis:calc(100% / 3);
	}
}
@media (min-width:1000px) {
	/** default **/
	.slider.list.bonus .slider-container {
		width:-webkit-calc(100% + var(--gutter-gap));
		width:-moz-calc(100% + var(--gutter-gap));
		width:calc(100% + var(--gutter-gap));
		margin:0 -webkit-calc(var(--gutter-gap) / 2 * -1);
		margin:0 -moz-calc(var(--gutter-gap) / 2 * -1);
		margin:0 calc(var(--gutter-gap) / 2 * -1);
	}
	.slider.list.bonus .slide {
		-webkit-flex-basis:-webkit-calc(100% / 3);
		    -ms-flex-preferred-size:calc(100% / 3);
		        flex-basis:-moz-calc(100% / 3);
		        flex-basis:calc(100% / 3);
		padding:0 -webkit-calc(var(--gutter-gap) / 2);
		padding:0 -moz-calc(var(--gutter-gap) / 2);
		padding:0 calc(var(--gutter-gap) / 2);
	}
	/** archives **/
	.slider.list.bonus.archives .slide {
		-webkit-flex-basis:25%;
		    -ms-flex-preferred-size:25%;
		        flex-basis:25%;
	}
}
@media (min-width:1400px) {
	/** default **/
	.slider.list.bonus .slide {
		-webkit-flex-basis:25%;
		    -ms-flex-preferred-size:25%;
		        flex-basis:25%;
	}
	.slider.list.bonus[data-count="2"] .slide > * + * {
		margin:var(--gutter-gap) 0 0 0;
	}
	/* > 2 */
	.slider.list.bonus[data-count="3"] .slide > * + *,
	.slider.list.bonus[data-count="4"] .slide > * + * {
		margin:var(--gutter-gap) 0 0 0;
	}
	.slider.list.bonus[data-count="3"] .avatar,
	.slider.list.bonus[data-count="4"] .avatar {
		height:3rem;
		width:3rem;
	}
	.slider.list.bonus[data-count="3"] .list-link,
	.slider.list.bonus[data-count="4"] .list-link {
		font-size:1.25rem;
	}
}

/**************************************/
/**************************************/
/********** SLIDER CINE-CLUB **********/
/**************************************/
/**************************************/
/* OK */

/* for cine-club slider */

.slide-infos {
	position:relative;
}
.slide-extras {
	padding:1rem;
	background:var(--dark-color);
}
.slide-extras .infos {
	position:relative !important;
	display:block !important;
	margin:0 !important;
	color:var(--lighter-color);
	text-shadow:0 0 0 var(--lighter-color);
}
.slide-extras .infos .title {
	display:block;
	font-size:1rem;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.slide-extras .infos .director {
	display:block;
	margin:0;
}
.slide-extras .infos .title + .director {
	padding:.5rem 0 0 0;
}
.slide-extras .infos p {
	font-size:.75rem;
	line-height: 1em;
}

/************************************/
/************************************/
/********** SECTION SLIDER **********/
/************************************/
/************************************/

/********** @MEDIA **********/

@media (min-width:0px) {
	section.slider .slide {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	section.slider .infos {
		padding:1.25rem;
		width:-webkit-calc(100% - 2.5rem);
		width:-moz-calc(100% - 2.5rem);
		width:calc(100% - 2.5rem);
	}
}
@media (min-width:1000px) {
	section.slider .slide {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	section.slider .infos {
		padding:4rem;
		width:-webkit-calc(100% - 8rem);
		width:-moz-calc(100% - 8rem);
		width:calc(100% - 8rem);
	}
}

/**********************************/
/**********************************/
/********** SLIDER SIZES **********/
/**********************************/
/**********************************/

/********** @MEDIA **********/

@media (min-width:0px) {
	/* slider min */
	.slider.min .slide {
		width:50%;
	}
	.slider.min.institutions.has-tabs .slide {
		width:-webkit-calc(100% / 3);
		width:-moz-calc(100% / 3);
		width:calc(100% / 3);
	}
	/* slider.grid */
	.slider.grid .slider-container {
		width:-webkit-calc(100% + .25rem);
		width:-moz-calc(100% + .25rem);
		width:calc(100% + .25rem);
		margin:0 -.125rem;
	}
	.slider.grid .slide {
		width:50%;
		padding:.125rem;
	}
	.slider.grid .slide:before {
		margin:.125rem;
	}
}
@media (min-width:760px) {
	/* slider min */
	.slider.min .slide {
		width:-webkit-calc(100% / 3);
		width:-moz-calc(100% / 3);
		width:calc(100% / 3);
	}
	.slider.min.institutions.has-tabs .slide {
		width:-webkit-calc(100% / 6);
		width:-moz-calc(100% / 6);
		width:calc(100% / 6);
	}
	/* slider.grid */
	.slider.grid .slider-container {
		-webkit-clip-path: inset(-100vh -.4375rem -100vh -.4375rem);
		clip-path: inset(-100vh -.4375rem -100vh -.4375rem);
	}
	.slider.grid .slider-container {

		width:-webkit-calc(100% + 1rem);

		width:-moz-calc(100% + 1rem);

		width:calc(100% + 1rem);
		margin:0 -.5rem;
	}
	.slider.grid .slide {
		width:50%;
		padding:.5rem;
	}
	.slider.grid .slide:before {
		margin:.5rem;
	}
}
@media (min-width:1000px) {
	/* slider min */
	.slider.min .slide {
		width:25%;
	}
	/* slider.grid */
	.slider.grid .slider-container {
		-webkit-clip-path: inset(-100vh -.9375rem -100vh -.9375rem);
			clip-path: inset(-100vh -.9375rem -100vh -.9375rem);
	}
	.slider.grid .slider-container {
		width:-webkit-calc(100% + 2rem);
		width:-moz-calc(100% + 2rem);
		width:calc(100% + 2rem);
		margin:0 -1rem;
	}
	.slider.grid .slide {
		width:-webkit-calc(100% / 3);
		width:-moz-calc(100% / 3);
		width:calc(100% / 3);
		padding:1rem;
	}
	.slider.grid .slide:before {
		margin:1rem;
	}
	/* gallery */
	.slider.grid.gallery .slide {
		width:50%;
	}
	/* slider min institutions */
	.slider.min.institutions .slide {
		width:-webkit-calc(100% / 5);
		width:-moz-calc(100% / 5);
		width:calc(100% / 5);
	}
	/* slider min filters */
	.slider.min.filters .slide {
		width:-webkit-calc(100% / 6);
		width:-moz-calc(100% / 6);
		width:calc(100% / 6);
	}
}
@media (min-width:1400px) {
	/* slider.grid */
	.slider.grid:not(.posts) .slide {
		width:25%;
	}
}
