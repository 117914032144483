.admin-btn {
	margin-right: 140px !important;
}

.edit-content-btn {
	position: relative;

	.editable-content {
		display: none;
		position: absolute;
		z-index: 20;
		top: -5px;
		left: -5px;
		background: pink;
		width: calc(100% + 10px);
		height: calc(100% + 10px);
		align-items: center;
		justify-content: center;
	}

	&:hover {
		.editable-content {
			display: flex;
		}
	}
}

.edit-btn {
	display: none !important;
	div {
		display: inline-block;
		margin-left: 3px;
		width: 5px;
		height: 5px;
		background-color: yellow;
		border-radius: 100%;

		&:hover {
			width: 10px;
			height: 10px;
		}
	}
}

#admin-panel {
	display: flex;
	position: fixed;
	bottom: 0;
	right: 20px;
	left: 20px;
	height: 65px;
	background: #ffffff80; //#D92E41;
	padding: 10px;
	z-index: 100000;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	align-items: center;

	a {
		font-size: 1.5em;
		width: 54px;
		height: 54px;
		background: white;
		color: black;
		padding: 10px;
		border-radius: 100%;
		margin-left: 5px;
		margin-right: 5px;
		&:before {
			margin-right: 5px;
			margin-left: 5px;
		}
	}
	@media (max-width:1020px) {
		a {
			width: 40px;
			height: 40px;
			font-size: 1em;
			padding: 7px;
		}
	}
	@media (max-width:820px) {
		a {
			width: 30px;
			height: 30px;
			font-size: .8em;
			padding: 3px;
		}
	}

	#admin-left {
		flex: 1;

	}
	#admin-middle {
		display: flex;
		justify-content: center;
	}
	#admin-right {
		flex: 1;
		display: flex;
		justify-content: flex-end;
	}
}

body.overlayed {
	#admin-panel {
		background: #00000080;
		color: white;

		a {
			color: white;
			background: black;
		}
	}
}

.overlay.admin {

	.overlay-container {
		position: auto;
		background: white !important;
		color: black;
	}

	.overlay-title {
		&.with-create {
			display: inline-block;
			margin-right: 30px;
		}
	}

	.overlay-header {
		a {
			border-radius: 100%;
			&:before {
				margin: 0;
			}
		}

		button {
			&.admin-destroy {
				position: absolute;
				top: 32px;
				right: 150px;
				&:before {
					content: "\e911";
				}
			}
		}

		.destroying, .destroyed {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 15px;
			border: 2px solid #d92e41;
			background: #d92e4180;
			&.destroyed {
				background: #347fef80;
				border: 2px solid #347fef;
			}
			color: white;
			text-transform: uppercase;

			span {
				flex: 1;
			}
			a {
				text-transform: uppercase;
				padding-right: 10px;
				font-size: 1.5rem;
				&:hover {
					font-size: 800;
				}
			}
		}
	}

	.overlay-content {
		position: initial;
		padding-top: 0;
		padding-bottom: 100px;

		label {
			//position: absolute;
			color: lightgray;
			font-size: 2rem;
			//margin-top: -40px;
			margin-bottom: 10px;
			text-transform: uppercase;
			line-height: 1;
		}
	}

	.overlay-content {
		form {
			.input-container {
				&[class*=" icon-"] {
					&:before {
						top: 77px;
					}
				}
			}
		}
	}
	.overlay-content, .overlay-header {

		.input-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			font-size: 1em;
			line-height: 1;
			margin: 1rem 0 0 0;
			//margin-bottom: 30px;
			select {
				-webkit-appearance: none;
		    -moz-appearance: none;
   		 	appearance: none;
				margin: 0;
			}
			input, select, textarea {
				border: 1px solid lightgray;
			}
			textarea {
				margin-top: 0px !important;
			}
			.react-select {
				//div[class*="-control"]:first-child,
				margin: 0px;
				div[class*="-placeholder"]:first-child {
					padding-left: 30px !important;
				}
				input {
					padding-left: 30px !important;
				}
				span[class*="Text"]:first-child {
					display: none;
				}
				div[class*="Input"] {
					width: 100%;
					> div {
						width: 100%;
						input {
							width: 100% !important;
						}
					}
				}
			}
		}

		.admin-form-message {
			flex: 1;
			color: #ff990080;
			margin-top: 5px;
			font-size: 0.8rem;
			width: 100%;
		}
	}

	.grid.list.directors a:after, a.next:after {
    content: "\e93c";
	}
}

.admin-back-btn {
	text-transform: uppercase;
	font-family: 'oswaldlight';
	color: lightgray;
	padding-top: 0.2rem;
	&:hover {
		color: black;
	}
}

.admin-loader {
	position: fixed;
	z-index: 200000;
	background: #ffffff;
	top: 10px;
	bottom: 10px;
	right: 10px;
	left: 10px;
	display: none;

	&.active {
		display: flex;
	}

	flex-direction: column;
	align-items: center;
	justify-content: center;

	span {
		color: #d92e41;
		margin-top: 10px;
	}
}

.products-picker, .files-picker, .directors-picker {
	//margin-top: 40px;
	.products-picker-list, .directors-picker-list, .files-picker-list {
		display: flex;
		flex-wrap: wrap;
		padding: 0 !important;
		.picker-product, .picker-file, .picker-director {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 5px;
		  position: relative;
			background: #EDEDED;
			padding: 5px;
			font-family: 'oswaldlight';
			&:after
			{
				z-index: -1;
				position: absolute;
				content: "";
				bottom: 15px;
				right: 10px;
				left: auto;
				width: 50%;
				top: 80%;
				max-width:300px;
				background: #777;
				-webkit-box-shadow: 0 15px 10px #777;
				-moz-box-shadow: 0 15px 10px #777;
				box-shadow: 0 15px 10px #777;
				-webkit-transform: rotate(3deg);
				-moz-transform: rotate(3deg);
				-o-transform: rotate(3deg);
				-ms-transform: rotate(3deg);
				transform: rotate(3deg);
			}

			span {
				padding: 5px;
			}

			.picker-product-image, .picker-director-image, .picker-file-image {
				height: 150px;
				width: 200px;
				background-size: cover !important;
				background-position: center center !important;
				border: 2px solid white;
			}
		}
	}
}

.admin-save {
	position: absolute;
	top: -90px;
	right: 85px;
}

.ck-balloon-panel {
	z-index: 999999 !important;
}

.admin-languages {
	&:not(.minified) {
		position: absolute;
		top: -90px;
		right: 225px;
		display: flex;
		margin-bottom: 5px;
	}

	&.minified {
		position: absolute;
		top: 25px;
		right: 0px;
		margin: 0px
	}

	a:first-child {
		&:after {
			content: "|";
			padding-left: 5px;
			font-weight: normal;
			color: lightgray;
		}
	}
	a {
		padding: 5px;
		color: lightgray;
		&.active {
			font-weight: 800;
			color: #d92e41;
		}
	}
}

.localized-input {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 10px;

	.localized-component {
		flex: 1;
		width: 100%;
		display: none;
		visibility: hidden;

		&.active {
			display: block;
			visibility: visible;
		}
		.ck-editor {
			width: 100%;
		}

		button {
			transform: none;
		}
	}
}

.grid.directors.with-image {
	li {
		flex-direction: row;
		img {
			width: 75px;
			margin: 0;
			padding: 0;
		}
		margin-bottom: 10px;
	}
}
#admin-header-select-lists {
	div[class*="MenuList"] {
		div:last-child {
			&:empty {
				&:before {
					content: "Créer le réalisateur et sa liste";
				}
			}
		}
	}
}
#admin-header-select-selections {
	div[class*="MenuList"] {
		div:last-child {
			&:empty {
				&:before {
					content: "Créer la sélection";
				}
			}
		}
	}
}

.picker-remove {
	font-size: 0;
	&:before {
		content: "\e911";
		font-size: .7rem;
	}
	font-family: 'icomoon';
	color: red;
	font-weight: bold;
	position: absolute;
	top: 15px;
	right: 15px;
}

.image-edit {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;

	img {
		width: 200px
	}
}

.admin-form-header {
	display: flex;

	.admin-form-header-content {
		flex: 1;
	}
}

.inputs-row {
	display: flex;
	margin: 0;

	div:first-child {
		margin-right: 10px !important;
	}
}
