form {
	.input-container {
		&>span {
			position: absolute;
			top: 42px;
			left: 0;
			font-size: 12px;
			width: 100%;
			text-align: right;
			color: #d92e41;
		}
	}
	.input-container.gift-message {
		span {
			position: absolute;
			top: 90px;
			left: 0;
			font-size: 12px;
			width: 100%;
			text-align: right;
			color: #d92e41;
		}
	}
	input[type=checkbox] + span {
		top: 42px;
		left: 0;
		font-size: 12px;
		width: 100%;
		text-align: right;
		color: #d92e41;
	}

	.react-datepicker-wrapper + span {
		top: 55px;
		left: 0;
		font-size: 12px;
		width: 100%;
		text-align: right;
		color: #d92e41;

	}
}

input[type=checkbox].alt {
	border-color: rgb(21, 21, 21);
}

.react-datepicker-wrapper {
	width: 100%;
}

.form-error,.white-text {
	color: white !important;
	text-shadow: 0 0 0 white !important;
}
