@charset "UTF-8";

/*****************************/
/*****************************/
/********** DEFAULT **********/
/*****************************/
/*****************************/

/*
table or .table
.table > .thead | .tbody | .tfoot > .tr > .td

.table == default
.table.cart | .table.cart.short | .table.cart.summary == cart
*/

/********** TABLE **********/

.table {	
	display:-webkit-box;	
	display:-webkit-flex;	
	display:-moz-box;	
	display:-ms-flexbox;	
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	width:100%;
	padding:0;
	color:var(--dark-color);
	text-shadow:none;
	-webkit-border-radius:var(--border-radius);
	   -moz-border-radius:var(--border-radius);
	        border-radius:var(--border-radius);
}
.table > * {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	-webkit-flex-basis:0;
	    -ms-flex-preferred-size:0;
	        flex-basis:0;
	list-style:none;
	text-align:center;
}

/********** TABLE TR **********/

.table .tr {
	position:relative;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
	border:none;
}
.table .tr > * {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	padding:0;
	margin:0;
}

/********** TABLE TD **********/

.table .td {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.table .td > * {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	padding:0;
	margin:0;
}

/********** SIZES **********/

.table .td.short {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	   -moz-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
}
.table .td.min {
	-webkit-box-flex: 0;
	-webkit-flex-grow: 0;
	   -moz-box-flex: 0;
	    -ms-flex-positive: 0;
	        flex-grow: 0;
}

/********** VERTICAL **********/
/* cf. promo */

.table .td.vertical {
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
}
.table .td.vertical > * + * {
	margin:1rem 0 0 0;
}

/********** COLORS **********/

.table .tr {
	background:var(--bright-color);	
}
.table .tr:nth-child(even) {
	background:rgb(246,246,255);
}
.table .tr.blank {
	background:none;
}
.table .tr.alt {
	background:rgb(231,231,240);
}
.table .thead .tr {
	background:inherit;
}

/********************************/
/********************************/
/********** TABLE CART **********/
/********************************/
/********************************/

.table.cart .tfoot .tr {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);	
}
.table.cart .tfoot .tr:first-child {
	margin:var(--margin-large) 0 0 0;
}
.table.cart .tfoot .tr:nth-child(odd) {
	background:rgb(246,246,255);
}
.table.cart .tr:first-child {
	-webkit-border-radius:var(--border-radius) var(--border-radius) 0 0;
	   -moz-border-radius:var(--border-radius) var(--border-radius) 0 0;
	        border-radius:var(--border-radius) var(--border-radius) 0 0;
}
.table.cart .tr.promo {
	-webkit-border-radius:var(--border-radius) !important;
	   -moz-border-radius:var(--border-radius) !important;
	        border-radius:var(--border-radius) !important;
	background:var(--bright-color) !important;
	margin:var(--margin-large) 0 !important;
}
.table.cart .tr.promo + .tr {
	-webkit-border-radius:var(--border-radius) var(--border-radius) 0 0 !important;
	   -moz-border-radius:var(--border-radius) var(--border-radius) 0 0 !important;
	        border-radius:var(--border-radius) var(--border-radius) 0 0 !important;
}

/**************************************/
/********** TABLE CART SHORT **********/
/**************************************/

.table.cart.short {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.table.cart.short .tbody .tr {
	background:none;
}

/****************************************/
/********** TABLE CART SUMMARY **********/
/****************************************/

.table.cart.summary {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.table.cart.summary .tbody .tr {
	background:none;
}
.table.cart.summary .tr + .tr {
	border-top:solid var(--stroke-width) var(--light-color);
}
.table.cart.summary:not(.short) .td + .td {
    border-left: none;
}
.table.cart.summary figure img {
	width:100%;
}
.table.cart.summary figure svg path {
	fill:var(--bright-color);
}
.table.cart.summary a > .title {
	display:-webkit-inline-box !important;
	display:-webkit-inline-flex !important;
	display:-moz-inline-box !important;
	display:-ms-inline-flexbox !important;
	display:inline-flex !important;
	border-bottom: solid var(--stroke-width) transparent;
}
.table.cart.summary a:hover > .title {
	border-bottom: solid var(--stroke-width);
}
.table.cart.summary .alert.short {
	font-size:inherit;
	line-height:inherit;
}
.table.cart.summary * + .alert.short {
	margin-top:1rem;
}
.table.cart.summary .columns > * + * {
	margin:0 1rem;
}
.table.cart.summary .alert.short + .buttons {
	margin-top:.5rem !important;
}
.table.cart.summary .comments {
	font-size:.75rem;
}
.table.cart.summary * + .comments {
	margin-top:.5rem;
}
.table.cart.summary:not(.short) .buttons.small {
	-webkit-box-pack:start;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	    -ms-flex-pack:start;
	        justify-content:flex-start;
	-webkit-flex-wrap:nowrap;
	    -ms-flex-wrap:nowrap;
	        flex-wrap:nowrap;
	text-align:center;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.table.cart.summary .td {
		-webkit-flex-basis:100%;
		    -ms-flex-preferred-size:100%;
		        flex-basis:100%;
		text-align:left;
	}
	.table.cart.summary .td + .td {
		padding-top:1rem;
	}
	.table.cart.summary:not(.short) .inline {
		width:100%;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.table.cart.summary:not(.short) figure {
		max-width:none;
		width:100%;
	}
	.table.cart.summary:not(.short) .inline .infos {
		width:100%;
		padding:1rem 0 0 0;
	}
	.table.cart.summary:not(.short) .title {
		font-size:1rem;
	}
	.table.cart.summary:not(.short) .buttons.small {
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
	}
	.table.cart.summary .offers figure {
		max-width:6rem;
	}
	.table.cart.summary .offers .infos {
		text-align:center;
	}
}
@media (min-width:480px) {
	.table.cart.summary.short .td {
		-webkit-flex-basis:50%;
		    -ms-flex-preferred-size:50%;
		        flex-basis:50%;
	}
	.table.cart.summary.short .td:first-child {
		-webkit-flex-basis:100%;
		    -ms-flex-preferred-size:100%;
		        flex-basis:100%;
	}
	.table.cart.summary.short .td:last-child {
		text-align:right;
	}
}
@media (min-width:640px) {
	/* summary */
	.table.cart.summary.short .td,
	.table.cart.summary.short .td:first-child,
	.table.cart.summary.short .td:last-child {
		-webkit-flex-basis:-webkit-calc(100% / 3);
		    -ms-flex-preferred-size:calc(100% / 3);
		        flex-basis:-moz-calc(100% / 3);
		        flex-basis:calc(100% / 3);
		text-align:center;
	}
	.table.cart.summary.short .td + .td {
		border-left: solid var(--stroke-width) var(--lighter-color);
	}
}
@media (min-width:760px) {
	.table.cart.summary .tbody .tr {
		margin:0 -1rem;
	}
	.table.cart.summary .td + .td {
		padding-top:0;
	}
	.table.cart.summary:not(.short) .td {
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
	}
	.table.cart.summary:not(.short) .inline {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.table.cart.summary:not(.short) figure {
		max-width:20rem;
		width:auto;
	}
	.table.cart.summary:not(.short) .inline .infos {
		padding:0 0 0 1rem;
	}
	.table.cart.summary:not(.short) .buttons.small a {
		-webkit-box-flex:0;
		-webkit-flex-grow:0;
		   -moz-box-flex:0;
		    -ms-flex-positive:0;
		        flex-grow:0;
		-webkit-flex-basis:30%;
		    -ms-flex-preferred-size:30%;
		        flex-basis:30%;
		white-space:nowrap;
	}
	/** offers **/
	.table.cart.summary .offers figure {
		max-width:8rem;
	}
	.table.cart.summary .offers .infos {
		text-align:left;
	}
	.table.cart.summary .offers h3 {
		font-size:1rem;
	}
	.table.cart.summary .columns {
		margin-top:1rem !important;
	}
}
@media (min-width:1000px) {
	.table.cart.summary:not(.short) .inline .infos {
		padding: 0 0 0 2rem;
	}
	.table.cart.summary:not(.short) .title {
		font-size:1.25rem;
	}
	.table.cart.summary:not(.short) .director {
		font-size:1rem;
	}
	/** offers **/
	.table.cart.summary .offers figure {
		max-width:12rem;
	}
	.table.cart.summary .offers figure svg {
		max-width:10rem;
	}
	.table.cart.summary .offers h3 {
		font-size:1.25rem;
	}
}
@media (min-width:1200px) {
	.table.cart.summary:not(.short) .title {
		font-size:1.5rem;
	}
	.table.cart.summary:not(.short) .director {
		font-size:1.25rem;
	}
	/** offers **/
	.table.cart.summary .offers h3 {
		font-size:1.5rem;
	}
}

/**********************************/
/**********************************/
/********** TABLE CUSTOM **********/
/**********************************/
/**********************************/

/********** TABLE-SECTION **********/
/* in cart */

.table .table-section {
	color:var(--dark-color);
	text-shadow:none;
	background:rgb(221,221,230) !important;
	border-top: solid var(--stroke-width) rgb(211,211,220);
}
.table.short .table-section,
.overlay .table .table-section {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.table .tbody .table-section {
	border-top:0;
}
.table.short .tbody .table-section {
	background:none !important;
}
.table .section-title {
	text-align:left;
	padding-left:1rem !important;
}

/********** TABLE ACTIONS **********/

.table .actions {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-flex:0;
	-webkit-flex-grow:0;
	   -moz-box-flex:0;
	    -ms-flex-positive:0;
	        flex-grow:0;
	-webkit-flex-basis:0 !important;
	    -ms-flex-preferred-size:0 !important;
	        flex-basis:0 !important;
	font-size:0;
	line-height:0;
	border-left:none !important;
	margin:-.5rem;
}
.table .actions.disabled {
	opacity:0 !important;
}
.table .actions a {
	font-size:0 !important;
	line-height:0 !important;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	margin:.5rem;
}
.table .actions a:hover:after {
	-webkit-transform:scale(.8);
	   -moz-transform:scale(.8);
	    -ms-transform:scale(.8);
	     -o-transform:scale(.8);
	        transform:scale(.8);
}
.table .actions a:before,
.table .actions a:after {
	display:block;
    font-family:'icomoon';
	font-size:1rem;
    line-height:1em;
	padding:0;
	margin:0;
}
.table .actions .remove:after {
	display:block;
	font-family:'icomoon';
	padding:.5rem;
}

/********** TABLE BUTTONS **********/

.table .buttons {
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}

/********** TABLE PROMO **********/

.table .promo p {
	width:100%;
	text-align:left;
}

/********** TABLE GIFT-INFOS **********/
/* gift / cart */

.table .gift-infos {
	font-size:.75rem;
	text-align:right;
}
.table .gift-infos * + * {
	padding:.5rem 0 0 0 !important;
}
.table .gift-infos .collapsible.active {
	margin:1rem 0 0 0 !important;
}
.table.cart .icon-gift {
	padding-right:.25em;
}
.table.cart.short .icon-gift {
	padding:0 !important;
}

/********** TABLE FILM **********/
/* style for film element */

.table .infos {
	text-align:left;
}
.table figure + .infos {
	padding-left:1rem;
}
.table .director {
	display:block;
}
.table .format {
	white-space:nowrap;
}
.table.short .format {
	white-space:normal;
}
.table.cart .infos p {
	text-align:left;
}
.table.cart small {
	padding-left:0;
}
.table .tr .td .inline > * {
	margin:0;
}

/* button = cf. ajouter un cinépass */

.table .td.add-to-cart {
	text-align:left !important;
}

/****************************/
/****************************/
/********** COLORS **********/
/****************************/
/****************************/

.table .thead {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--darker-color);
}

/********** PRIVATE **********/

body.private .table .thead {
	background:var(--dark-color);
}

/********** OVERLAY **********/

.overlay .table .tbody .tr {
	background:none !important;
}
.overlay .table .tr + .tr {
	border-top:solid var(--stroke-width) var(--lighter-color);
}

/****************************/
/****************************/
/********** @MEDIA **********/
/****************************/
/****************************/

/********** DEFAULT **********/

@media (min-width:0px) {
	.table {
		font-size:.75rem;
		overflow:auto hidden;
	}
	.table .tr {
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
		padding:1rem 0;
	}
	.table .td {
		padding:0 1rem;
	}
	.table .infos * + * {
		margin:0 !important;
	}
}
@media (min-width:760px) {
	.table {
		font-size:.875rem;
		overflow:hidden;
	}
	.table .tr {
		-webkit-flex-wrap:nowrap;
		    -ms-flex-wrap:nowrap;
		        flex-wrap:nowrap;
		padding:1rem;
	}
	.table .td {
		-webkit-box-flex:1;
		-webkit-flex-grow:1;
		   -moz-box-flex:1;
		    -ms-flex-positive:1;
		        flex-grow:1;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
		text-align:center;
		padding:0 1rem;
	}
	.table .td + .td {
		border-left:solid var(--stroke-width) var(--lighter-color);
	}
	.table .infos * + * {
		margin:.5rem 0 0 0 !important;
	}
	.table .td.actions,
	.table .td:last-child {
		text-align:center;
	}
	.table .td.large {
		-webkit-box-flex:2;
		-webkit-flex-grow:2;
		   -moz-box-flex:2;
		    -ms-flex-positive:2;
		        flex-grow:2;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
	}
	.table .tfoot .tr .td:first-child {
		text-align:left;
	}
	.table .tbody .tr:last-child {
		-webkit-border-radius:0 0 var(--border-radius) var(--border-radius);
		   -moz-border-radius:0 0 var(--border-radius) var(--border-radius);
		        border-radius:0 0 var(--border-radius) var(--border-radius);
	}
}

/********** CART **********/

@media (min-width:0px) {
	/* default */
	.table.cart {
		overflow:hidden;
	}
	.table.cart .td.large {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
		-webkit-box-align:start;
		-webkit-align-items:flex-start;
		   -moz-box-align:start;
		    -ms-flex-align:start;
		        align-items:flex-start;
		-webkit-flex-basis:100%;
		    -ms-flex-preferred-size:100%;
		        flex-basis:100%;
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
	}
	.table.cart .td.large + .td.large {
		-webkit-box-ordinal-group:5;
		-webkit-order:4;
		   -moz-box-ordinal-group:5;
		    -ms-flex-order:4;
		        order:4;
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		-webkit-flex-wrap:unset;
		    -ms-flex-wrap:unset;
		        flex-wrap:unset;
		padding:0 1rem;
	}
	/* tbody */
	.table.cart .tbody .td.large + .td.large > * {
		padding:1rem 0 0 0;
	}
	.table.cart .tbody .td.large {
		padding:0 4rem 0 1rem;
	}
	/* content */
	.table.cart .inline {
		-webkit-box-pack:start;
		-webkit-justify-content:flex-start;
		   -moz-box-pack:start;
		    -ms-flex-pack:start;
		        justify-content:flex-start;
	}
	.table.cart .inline .infos {
		padding:0 0 0 1rem;
	}
	.table.cart .inline + .infos {
		padding:1rem 0 0 0;
	}
	.table.cart figure {
		max-width:4rem;
	}
	.table.cart .actions {
		position:absolute;
		top:1rem;
		right:0;
		-webkit-box-flex:0;
		-webkit-flex-grow:0;
		   -moz-box-flex:0;
		    -ms-flex-positive:0;
		        flex-grow:0;
	}
	.table.cart .price {
		font-size:1rem;
		text-align:right;
		padding-top:1rem;
	}
	/* tfoot */
	.table.cart .tfoot .td.large {
		-webkit-box-flex:6;
		-webkit-flex-grow:6;
		   -moz-box-flex:6;
		    -ms-flex-positive:6;
		        flex-grow:6;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
		-webkit-flex-wrap:nowrap;
		    -ms-flex-wrap:nowrap;
		        flex-wrap:nowrap;
	}
	.table.cart .tfoot .td.large + .td.large {
		-webkit-box-flex:4;
		-webkit-flex-grow:4;
		   -moz-box-flex:4;
		    -ms-flex-positive:4;
		        flex-grow:4;
		-webkit-flex-basis:100%;
		    -ms-flex-preferred-size:100%;
		        flex-basis:100%;
		border-left:0;
	}
	.table.cart .tfoot .total {
		text-align:left;
	}
	.table.cart .tfoot .price {
		text-align:right;
		padding-top:0;
	}
	/* short */
	.table.cart.short .infos {
		text-align:left;
	}
}
@media (min-width:480px) {
	.table.cart .td.large {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.table.cart .inline {
		width:50%;
	}
	.table.cart .inline + .infos {
		padding:0 0 0 1rem;
	}
}
@media (min-width:760px) {
	/* default */
	.table.cart {
		font-size:.75rem;
	}
	.table.cart .tr {
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
	}
	.table.cart .td.large {
		-webkit-box-flex:6;
		-webkit-flex-grow:6;
		   -moz-box-flex:6;
		    -ms-flex-positive:6;
		        flex-grow:6;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
		-webkit-flex-wrap:nowrap;
		    -ms-flex-wrap:nowrap;
		        flex-wrap:nowrap;
	}
	.table.cart .td.large + .td.large {
		-webkit-box-flex:4;
		-webkit-flex-grow:4;
		   -moz-box-flex:4;
		    -ms-flex-positive:4;
		        flex-grow:4;
		-webkit-flex-basis:100%;
		    -ms-flex-preferred-size:100%;
		        flex-basis:100%;
		border-left:0;
	}
	/* tbody */
	.table.cart .tbody .td.large {
		padding:0 1rem;
	}
	/* content */
	.table.cart .inline {
		width:100%;
	}
	.table.cart .inline + .infos {
		padding:0 0 0 1rem;
	}
	.table.cart .title {
		font-size:.875rem;
	}
	.table.cart .price {
		text-align:inherit;
		padding-top:0;
	}
	.table.cart .actions {
		position:relative;
		top:auto;
		right:auto;
	}
	/* short */
	.table.cart.short .td {
		-webkit-flex-basis:12%;
		    -ms-flex-preferred-size:12%;
		        flex-basis:12%;
	}
	.table.cart.short .td.large {
		-webkit-flex-basis:24%;
		    -ms-flex-preferred-size:24%;
		        flex-basis:24%;
	}
	.table.cart.short .tfoot .td.large {
		-webkit-flex-basis:24%;
		    -ms-flex-preferred-size:24%;
		        flex-basis:24%;
	}
	.table.cart.short .infos {
		text-align:center;
	}
	.table.cart.short .inline .infos {
		text-align:left;
	}
	.table.cart.short .td.large .infos {
		text-align:left;
	}
	.table.cart.short .td.large + .td {
		-webkit-box-flex:2;
		-webkit-flex-grow:2;
		   -moz-box-flex:2;
		    -ms-flex-positive:2;
		        flex-grow:2;
	}
}
@media (min-width:1000px) {
	/* default */
	.table.cart .td.large {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
	}
	.table.cart .inline + .infos {
		padding: 0 1rem;
	}
	.table.cart .td.large + .td.large {
		-webkit-box-ordinal-group:unset;
		-webkit-order:unset;
		   -moz-box-ordinal-group:unset;
		    -ms-flex-order:unset;
		        order:unset;
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		-webkit-flex-wrap:unset;
		    -ms-flex-wrap:unset;
		        flex-wrap:unset;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
		padding:0 2rem;
		border-left:solid var(--stroke-width) var(--lighter-color);
	}
	/* tbody */
	.table.cart .tbody .td.large {
		padding:0 1rem;
	}
	.table.cart .tbody .td.large + .td.large > * {
		padding:0;
	}
	/* tfoot */
	.table.cart .tfoot .td.large + .td.large {
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
	}
	.table.cart .tfoot .total,
	.table.cart .tfoot .price {
		font-size:1.5rem;
		line-height:1em;
	}
	.table.cart .tfoot .price {
		margin:0 -1rem 0 0;
	}
	/* short */
	.table.cart.short .tr {
		padding:1rem 0;
	}
}
@media (min-width:1200px) {
	/* default */
	.table.cart,
	.table.cart .format {
		font-size:.875rem;
	}
	/* short */
	.table.cart.short .format {
		font-size:.75rem;
	}
	/* content */
	.table.cart .title {
		font-size:1rem;
	}
	.table.cart .price {
		font-size:1rem;
		line-height:1em;
	}

	.table.cart figure {
		max-width:6rem;
	}
}

/********** MOBILE ONLY **********/

@media (max-width:760px) {
	.table:not(.cart) {
		position:relative;
		display:-ms-grid;
		display:grid;
		grid-auto-flow:column;
		grid-auto-columns:auto 1fr;
		white-space:nowrap;
	}
	.table:not(.cart) > * {
		display:-ms-inline-grid;
		display:inline-grid;
		grid-auto-flow:column;
	}
	.table:not(.cart) .thead {
		position:-webkit-sticky;
		position:sticky;
		z-index:10;
		left:0;
		white-space:nowrap;
	}
	.table:not(.cart) .tr {
		display:-ms-inline-grid;
		display:inline-grid;
		grid-auto-flow:row;
		padding:0;
	}
	.table:not(.cart) .tbody .tr {
		-ms-grid-columns:1fr;
		grid-template-columns:1fr;
	}
	.table:not(.cart) .tbody .tr > *:nth-child(1) {
		-ms-grid-column:1;
		grid-column:1;
	}
	.table:not(.cart) .td + .td {
		border-left: none;
		border-top:solid var(--stroke-width) var(--lighter-color);
	}
	.table:not(.cart) .td {
		text-align:left;
		min-height:3.5rem; /* button height */
		width:100%;
	}
}