@charset "UTF-8";

/*****************************/
/*****************************/
/********** PRIVATE **********/
/*****************************/
/*****************************/

body.private main {
	background:var(--private-color);
}
body.private main section {
	color:var(--dark-color);
	text-shadow:none;
}
body.private nav.filters.red a.active,
body.private nav.filters.red a:hover {
	background:var(--dark-color);
}
body.private .is-recommended b {
	color:var(--dark-color);
	text-shadow:none;
}
body.private nav.contextual {
	background:var(--light-color);
}
body.private .loader:before {
	border-color: var(--dark-color) var(--dark-color) var(--dark-color) transparent;
}

/********** LIST HAS-COMMENTS **********/
/* list playlist */

body.private main .list.films.has-comments nav.playlist-actions {
	color: var(--dark-color);
	text-shadow:none;
}
body.private main .list.films.has-comments a.collapsible-toggle:before {
    color: var(--dark-color);
    text-shadow:none;
}

/********** LIST FILMS PLAYLIST **********/
/* list as table in user playlist */

body.private main .list.films.playlist .item:before {
	border-top: solid var(--stroke-width) transparent;
}
body.private main .list.films.playlist .item:hover:before {
	border-top: solid var(--stroke-width) var(--yellow-color);
}
body.private main .list.films.playlist .item {
	background:rgb(221,221,230);
}
body.private main .list.films.playlist .item:nth-child(even) {
	background:rgb(231,231,240);
}

/***************************/
/***************************/
/********** STYLE **********/
/***************************/
/***************************/

.yellow hr,
.red hr,
.black hr,
.grey hr {
	border-bottom:solid var(--stroke-width) var(--bright-color);
}
/* for svg */
.dark-text-color {
	fill:var(--dark-color);
}
/* test offers */
h2.red,
span.red {
	color:var(--red-color);
	text-shadow:none;
}
span.black {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
}
.background.red {
	background: var(--red-color);
}
.background.yellow {
	background: var(--yellow-color);
}
/*
.background.purple {
	background: var(--purple-color);
}
*/
.background.bonus-exclusive-color {
	background:var(--bonus-exclusive-color);
}
.background.bonus-archives-color {
	background:var(--bonus-archives-color);
}

/******************************/
/******************************/
/********** OVERLAYS **********/
/******************************/
/******************************/

.overlay.svod-color .overlay-container,
.overlay .overlay-content.svod-color,
.overlay .overlay-footer.svod-color {
	background:var(--svod-color);
}
.overlay.tvod-color .overlay-container,
.overlay .overlay-content.tvod-color,
.overlay .overlay-footer.tvod-color {
	background:var(--tvod-color);
}
.overlay.red,
.overlay.red .list-link,
.overlay.dark .overlay-container,
.overlay.dark h1,
.overlay.dark h2 {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.overlay.red .main-title strong,
.overlay.red p a:not(.button):hover {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
}
/*
.overlay.red .overlay-title,
.overlay.purple .overlay-title {
	border-left:solid .375rem var(--grey-color);
}
*/
.overlay.red .overlay-title {
	border-left:solid .375rem var(--grey-color);
}
.overlay.red .list-link:after {
	background:var(--bright-color);
}
.overlay.red .overlay-container {
	background:var(--red-color);
}
.overlay.yellow .overlay-container {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
	background:rgba(var(--yellow-rgb), var(--alpha-rgb));
}
.overlay.yellow .offers figure svg path {
	fill:var(--dark-color);
}
.overlay.dark .overlay-container {
	background:rgba(var(--dark-rgb),var(--alpha-rgb));
}
.overlay.light .overlay-container {
	background:rgba(var(--light-rgb),var(--alpha-rgb));
}
.overlay-header.light,
.overlay-footer.light {
	background:var(--light-color);
}
.overlay-footer.grey {
	background:var(--grey-color);
}
/*
.overlay.purple .overlay-container {
	background:rgba(var(--purple-rgb), var(--alpha-rgb));
}
*/
.overlay.bonus-archives-color .overlay-title,
.overlay.bonus-exclusive-color .overlay-title {
	border-left:solid .375rem var(--grey-color);
}
.overlay.bonus-archives-color .overlay-container {
	background:rgba(var(--bonus-archives-rgb), var(--alpha-rgb));
}
.overlay.bonus-exclusive-color .overlay-container {
	background:rgba(var(--bonus-exclusive-rgb), var(--alpha-rgb));
}

/**************************/
/**************************/
/********** GRID **********/
/**************************/
/**************************/

body.private .list.films > *:before {
	border-top:solid var(--stroke-width) rgb(201,201,210);
}
body.private main .grid.films > * {
	background:rgb(221,221,230);
}
body.private main .grid.films .infos .title {
	color:var(--grey-color);
	text-shadow:0 0 0 var(--grey-color);
}
body.private main .grid.list.films > * .infos > *,
body.private main .grid.films .infos {
	color:var(--light-color);
	text-shadow:0 0 0 var(--light-color);
}
body.private main .grid.films .extras {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.red .grid.list.directors a.title-alt:hover {
	color:var(--grey-color);
	text-shadow:none;
}
section.grey .grid .item {
	background:var(--dark-color);
}

/* playlist films - V4 */

body.private main .list.playlist.films .item-content,
body.private main .list.playlist.films .title,
body.private main .list.playlist.films b,
body.private main .list.playlist.films .director,
body.private main .list.playlist.films .comments {
    color:var(--dark-color);
	text-shadow:none;
}
body.private section.focus .focus-nav.alt {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
body.private section.focus .focus-nav.alt a:hover {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
	background: var(--yellow-color);
}

/*****************************/
/********** SLIDERS **********/
/*****************************/

section.tvod-color .svod .film-link {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
	background:var(--bright-color);
}
section.tvod-color .svod .films .infos .title {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
}
section.grey .svod .film-link {
	background:var(--dark-color);
}

/****************************/
/****************************/
/********** BANNER **********/
/****************************/
/****************************/

/* TO DO yellow, etc. */

.banner.red .collapsible-toggle:hover,
.banner.red .buttons a:hover,
.banner.red .button:hover {
	color:var(--dark-color);
	text-shadow: 0 0 0 var(--dark-color);
	background:var(--bright-color);
}

/*****************************/
/*****************************/
/********** SECTION **********/
/*****************************/
/*****************************/

/*
section.blue,
section.yellow,
section.red,
section.black,
section.dark,
section.grey
*/

/* background */

/*
section.bonus-exclusive-color:after,
section.bonus-archives-color:after,
*/
section.svod-color:after,
section.tvod-color:after,
section.yellow:after,
section.red:after,
section.black:after,
section.dark:after,
section.grey:after {
	content:"";
	position:absolute;
	z-index:-1;
	top:0;
	left:50%;
    height: 100%;
    width: var(--viewport-width);
	-webkit-transform: translate3d(-50%,0,0);
	   -moz-transform: translate3d(-50%,0,0);
	        transform: translate3d(-50%,0,0);
}
section.tvod-color:after {
	background:var(--tvod-color);
}
section.svod-color:after {
	background:var(--svod-color);
}
section.yellow:after {
	background:var(--yellow-color);
}
section.red:after {
	background:var(--red-color);
}
section.black:after {
	background:var(--black-color);
}
section.dark:after {
	/*background:rgb(11,11,20);*/
	background:var(--darker-color);
}
section.grey:after {
    background:var(--grey-color);
}
/*
section.bonus-exclusive-color:after {
    background:var(--bonus-exclusive-color);
}
section.bonus-archives-color:after {
    background:var(--bonus-archives-color);
}
*/

/* text */

section.yellow {
	color:var(--dark-color);
	text-shadow:none;
}
section.yellow .title {
	color:var(--dark-color);
	text-shadow:none;
}
section.yellow .button {
	background:var(--dark-color);
}
/*
section.tvod-color,
section.svod-color {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
}
*/
section.red .main-title strong {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
}

/* switch */

section.red .switch a:hover {
	color: var(--red-color);
	text-shadow: 0 0 0 var(--red-color);
	background: var(--bright-color);
}
section.tvod-color .switch a.active,
section.tvod-color .switch a:hover {
	color: var(--dark-color);
	text-shadow: 0 0 0 var(--dark-color);
}
section.svod-color .switch a.active,
section.svod-color .switch a:hover {
	color: var(--svod-color);
	text-shadow: 0 0 0 var(--svod-color);
}
section.tvod-color .switch a.active,
section.svod-color .switch a.active {
	background: var(--bright-color);
}
section.tvod-color .switch a:not(.active):hover,
section.svod-color .switch a:not(.active):hover {
	background: rgba(var(--bright-rgb),.66);
}