@charset "UTF-8";

/****************************/
/****************************/
/********** GLOBAL **********/
/****************************/
/****************************/

html,
body {
	margin: 0;
	padding: 0;
	border: 0;
	height: 100%;
	width: 100%;
}

/********** BODY **********/
/* overflow-x hidden == for swipers */

body {
	-webkit-overflow-scrolling:touch;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background: var(--dark-color);
	overflow-x: hidden;
}

/********** WRAPPER **********/
/*
flex			== footer at bottom
#wrapper:after	== replace body background
*/

#wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	min-height: 100%;
	background: inherit;
	margin: 0;
	padding: 0;
}

/**************************/
/**************************/
/********** MAIN **********/
/**************************/
/**************************/

main {
	position:relative;
	display:block;
	margin:0;
	padding-top:var(--header-height);
}
main > * {
	z-index:5;
}
/* for preview hover */
main > section:hover {
	z-index:20;
}

/****************************/
/****************************/
/********** HEADER **********/
/****************************/
/****************************/

/*
header == sticky > no padding-top to #wrapper
header == sticky > #wrapper needs overflow:visible for position:sticky
*/

header {
	position:fixed;
	z-index:1100;
	top:0;
	left:0;
	width:100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	   -moz-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	   -moz-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	background: var(--grey-color);
}
header:before {
	content:"";
	position:absolute;
	z-index:-1;
	top:100%;
	left:0;
	height:1rem;
	width:100%;
	opacity:.5;
	pointer-events:none;
	background: var(--black-color);
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--black-rgb),0)), to(rgba(var(--black-rgb),1)));
	background: -webkit-linear-gradient(bottom, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%);
	background: -moz-linear-gradient(bottom, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%);
	background: -o-linear-gradient(bottom, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%);
	background: linear-gradient(0deg, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%);
}
header > * + * {
	margin:0 0 0 -webkit-calc(var(--gutter-gap) / 2);
	margin:0 0 0 -moz-calc(var(--gutter-gap) / 2);
	margin:0 0 0 calc(var(--gutter-gap) / 2);
}

/** cart only if has something **/

header [data-cart-count] {
	z-index:1300;
}

/** fixed navigation if tvod or svod content **/

body.is-vod header:after {
	content:"";
	height:3rem;
	width:100%;
	opacity:1;
	pointer-events:none;
	background: var(--dark-color);
}

/****************************/
/****************************/
/********** FOOTER **********/
/****************************/
/****************************/

footer {
	position:relative;
	width:100%;
	padding:0 var(--gutter-gap);
	background:var(--black-color);
}
footer section + section {
	border-top:solid var(--stroke-width) var(--grey-color);
}
footer .title {
	text-transform:uppercase !important;
	color:rgb(101,101,110);
	text-shadow:0 0 0 rgb(101,101,110);
}
footer .title + *:not(.partners) {
	margin:.5rem 0 0 0 !important;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	footer .title {
		font-size:.75rem;
	}
	footer * + .title {
		margin-top:1rem;
	}
}
@media (min-width:760px) {
	footer .title {
		font-size:.875rem;
	}
	footer * + .title {
		margin-top:1.5rem;
	}
}
@media (min-width:1000px) {
	footer * + .title {
		margin-top:2rem;
	}
}

/******************************/
/******************************/
/********** SECTIONS **********/
/******************************/
/******************************/

/*
section
section.short
section.full
section.block
section.hidden (for cart)
section.has-background
section.focus
section.focus.splitted [OUT ?]
section.focus.has-splitted
section.focus.has-slider

main.has-sticky > section.is-sticky (for cart / films archive) [OUT ?]

left | right	== --section-gap (up to 4rem)
top | bottom	== --gutter-gap (up to 2rem)
*/

/********** DEFAULT **********/

section {
	position:relative;
	clear:both;	
	-webkit-background-size:cover !important;	
	   -moz-background-size:cover !important;	
	     -o-background-size:cover !important;	
	        background-size:cover !important;
	background-position:center center !important;
	padding-top:var(--gutter-gap);
	padding-bottom:var(--gutter-gap);
	margin-left:auto;
	margin-right:auto;
	max-width: var(--max-width);
}

/********** SHORT **********/

section.short {
	padding-top:0;
	padding-bottom:0;
}

/********** FULL **********/

section.full {
	padding:0 !important;
	max-width:none;
}

/********** FOR CART **********/

section.hidden {
	visibility:hidden;
}
section.splitted.inline {
	margin:0 auto;
}

/***********************************/
/********** MAIN SECTIONS **********/
/***********************************/

/** default **/

main > section {
	max-width: none;
	padding-left:var(--section-gap);
	padding-right: var(--section-gap);
}
main section > *,
main section > .slider {
	max-width:var(--max-width);
	margin-left:auto;
	margin-right:auto;
}
main > section:first-child,
main > section:not(.focus):first-child + section.focus,
main > nav + section.focus {
	margin-top:0 !important;
}
main > section:last-child:not(.is-sticky) {
	margin-bottom:var(--gutter-gap);
}

/** in sovd partenaire **/

main > section.focus.full + section.block {
	margin-top:-webkit-calc(var(--gutter-gap) * 2);
	margin-top:-moz-calc(var(--gutter-gap) * 2);
	margin-top:calc(var(--gutter-gap) * 2);
}

/** with background OR color **/

main > section.black,
main > section.red,
main > section.grey,
main > section.tvod-color,
main > section.svod-color,
main > section.has-background {
	padding-top:var(--section-gap);
	padding-bottom:var(--section-gap);
	margin-top:var(--gutter-gap);
	margin-bottom:var(--gutter-gap);
}
main > section:last-child.black,
main > section:last-child.red,
main > section:last-child.grey,
main > section:last-child.tvod-color,
main > section:last-child.svod-color,
main > section:last-child.has-background {
	margin-bottom: 0;
}

/** in page-offers-gift **/

main > section.focus.full + section.red:not(.block),
main > section.focus.full + section.tvod-color:not(.block),
main > section.focus.full + section.svod-color:not(.block) {
	margin-top:-webkit-calc(var(--gutter-gap) * -1);
	margin-top:-moz-calc(var(--gutter-gap) * -1);
	margin-top:calc(var(--gutter-gap) * -1);
	padding-top:-webkit-calc(var(--gutter-gap) * 2);
	padding-top:-moz-calc(var(--gutter-gap) * 2);
	padding-top:calc(var(--gutter-gap) * 2);
}

/********** MAIN SECTIONS > SECTIONS **********/

main section > section {
	padding:0;
}
main section > * + section {
	margin-top: var(--gutter-gap);
}
body.mobile main > section {
	overflow: hidden;
}

/**************************************/
/********** OVERLAY SECTIONS **********/
/**************************************/

.preview.modal section {
	max-width: none;
	padding-top:var(--gutter-gap);
	padding-bottom:var(--gutter-gap);
}
.overlay section {
	max-width: none;
	overflow: hidden;
	padding-top:var(--gutter-gap);
	padding-bottom:var(--gutter-gap);
}
.preview.modal section.focus.has-splitted,
.overlay section.focus.has-splitted {
	padding:var(--section-gap) !important;
}
.preview.modal section > section,
.overlay section > section {
	padding:0;
}
.preview.modal section > *,
.overlay section > * {
	max-width:var(--max-width);
	margin-left:auto;
	margin-right:auto;
}

/***********************************/
/***********************************/
/********** SECTION BLOCK **********/
/***********************************/
/***********************************/

/* in partnership / single film pack */

/********** DEFAULT **********/

section.block {
	margin-top:var(--gutter-gap);
	margin-bottom:var(--gutter-gap);
	padding:0;
	padding:var(--gutter-gap);
	margin-left:auto;
	margin-right:auto;
	max-width: var(--max-width);
	overflow:hidden;
}
section.short.block {
	padding:1rem;
}

/********** SECTION BLOCK PARTNERSHIP **********/

/* based on red block / partnership (telerama / figaro) */

section.block.partnership {
	/*font-size:.875rem;*/
	font-size: 1rem;
}
section.block.partnership .credits {
	font-size:inherit !important;
}
section.block.partnership .credits .director {
	color:var(--light-color);
}
section.block.partnership * + .credits {
	margin-top:1rem;
}
section.block.partnership .title {
	font-size:1rem;
}
section.block.partnership * + .title {
	margin-top:2rem;
}
section.block.partnership .title + * {
	margin-top:.5rem;
}
section.block.partnership p a:hover {
	color:var(--dark-color);
	text-shadow:none;
}
section.block.partnership h2 {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align:end;
	-webkit-align-items:flex-end;
	   -moz-box-align:end;
	    -ms-flex-align:end;
	        align-items:flex-end;
}
section.block.partnership h2 strong,
section.block.partnership .title {
	color:var(--dark-color);
	text-shadow:none;
}
section.block.partnership h2 .logo {
	display:inline-block;
	width:8rem;
	margin-left:.25em;
}
section.block.partnership .button {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--dark-color) !important;
}
section.block.partnership .button:hover {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color) !important;
}

/********************************************/
/********************************************/
/********** SECTION HAS BACKGROUND **********/
/********************************************/
/********************************************/

/*
.background		== cf. svod focus
has-background	== cf. index
*/

section .background {
	z-index:1;
}
section.has-background > .background {
	right:0;
	width:auto;
	opacity:.5;
	z-index: -1;
}

/*****************************************************/
/*****************************************************/
/********** SECTION HAS-SPLITTED / SPLITTED **********/
/*****************************************************/
/*****************************************************/

/********** DEFAULT **********/
/* eg. cine-club */

section.splitted {
	padding:0;
	margin-top:var(--gutter-gap);
	margin-bottom:var(--gutter-gap);
	max-width: var(--max-width);
}

/********** FOCUS **********/
/* in single-discover | archive-institutions | archive-playlist [OUT?] */

section.focus.has-splitted,
section.focus.splitted {
	padding-top:var(--gutter-gap);
	padding-bottom:var(--gutter-gap);
}
section.focus.splitted:before,
section.focus.has-splitted:before {
	display:none;
}
section.focus.has-splitted .splitted {
	width:100%;
}
section.focus.has-splitted .infos {
	padding:0;
}
section.focus.has-splitted .infos .focus-nav.alt {
	margin-top:1rem;
}

/********** @MEDIA **********/

@media (min-width:1200px) {
	section.focus.has-splitted,
	section.focus.splitted {
		padding:var(--section-gap) 0;
	}
}

/** @media == 1400 + 64 px * 2 (--section-gap) **/

@media (max-width:1528px) {
	section.has-splitted,
	section.splitted {
		margin-left:var(--section-gap) !important;
		margin-right:var(--section-gap) !important;
	}
	section > section.has-splitted,
	section > section.splitted {
		margin-left:auto !important;
		margin-right:auto !important;
	}
}

/***********************************/
/***********************************/
/********** SECTION FOCUS **********/
/***********************************/
/***********************************/

/*
section.focus		== default
focus.full			== in svod [test]
focus.has-splitted	== in discover

img ratio == 1920 x 768 px == 100% / 40%
img ratio == 1600 x 575 px == 100% / 35.9375%
*/

/********** FOCUS DEFAULT **********/

section.focus {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-box-align:end;
	-webkit-align-items:flex-end;
	   -moz-box-align:end;
	    -ms-flex-align:end;
	        align-items:flex-end;
	padding:0;
	margin-top:var(--gutter-gap);
	margin-bottom:var(--gutter-gap);
	margin-left:auto;
	margin-right:auto;
	max-width: var(--max-width);
}
section.focus:before {
	content:"";
	display:block;
	height:0;
	width:100%;
	margin:0 0 0 -100%;
	pointer-events: none;
}
section.focus:after {
	content:"";
	position:absolute;
	z-index:5;
	bottom:0;
	left:0;
	height:100%;
	width:100%;
	background:var(--black-color);
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--black-rgb),1)), to(rgba(var(--black-rgb),0)));
	background: -webkit-linear-gradient(bottom, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	background: -moz-linear-gradient(bottom, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	background: -o-linear-gradient(bottom, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	background: linear-gradient(0deg, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	opacity:var(--alpha-rgb);
	pointer-events: none;
}

/** @media == 1400 + 64 px * 2 (--section-gap) **/

@media (max-width:1528px) {
	section.focus {
		margin-left:var(--section-gap) !important;
		margin-right:var(--section-gap) !important;
	}
	section > section.focus {
		margin-left:auto !important;
		margin-right:auto !important;
	}
}

/********** FOCUS FULL **********/

section.focus.full {
	margin-left:0 !important;
	margin-right:0 !important;
	max-width: none;
}

/********** FOCUS HAS-GRID **********/
/* cf. archive-news */

section.focus.has-grid .infos {
	padding:0;
}
section.focus.has-grid:before,
section.focus.has-grid:after {
	display:none;
}

/********** FOCUS HAS-SLIDER **********/
/* section focus with slider cf. svod TEST */

section.focus.has-slider:before {
	display:none;
}

/********** FOCUS NO FX **********/
/* remove gradient fx for columns focus */

section.focus.no-fx:before,
section.focus.no-fx:after {
	display:none;
}

/********** FOCUS COLORS **********/

section.focus.black {
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}

/********** FOCUS INFOS **********/

section.focus .infos {
	position:relative;
	z-index:10;
	display:block;
	margin:0;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
section.focus .infos > * + * {
	margin:.5rem 0 0 0;
}
section.focus .infos a > .title {
	display:inline-block;
	border-bottom:solid var(--stroke-width) transparent;
}
section.focus .infos a:hover .title {
	border-bottom:solid var(--stroke-width);
}
section.focus .title {
	display:inline-block !important;
	text-transform:none;
}
section.focus .infos .is-recommended {
	padding:0;
}
section.focus .infos .is-recommended.institution {
	padding:2em 0 0 0;
}

/********** FOCUS BUTTON SMALL **********/
/* for mobile teaser */

section.focus .button.small {
	position:absolute;
	z-index:20;
	top:0;
	left:0;
	width:auto;
	margin:var(--gutter-gap);
	background:rgba(var(--black-rgb),.5);
	border:solid var(--stroke-width) transparent !important;
}
section.focus .button.small:hover {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	border:solid var(--stroke-width) var(--yellow-color) !important;
	background:none !important;
}

/********** FOCUS NAV **********/

.focus-nav {
	position:relative;
	z-index:10;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
}
.focus-nav a {
	display:block;
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	font-size:inherit;
	line-height:inherit;
	padding:1rem;
	background:rgba(var(--black-rgb),.5);
	border:solid var(--stroke-width) transparent;
}
.focus-nav a.alt {
	background:var(--red-color);
}
.focus-nav a:hover {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	border:solid var(--stroke-width) var(--yellow-color);
	background:none;
}
.focus-nav strong {
	padding-right:.5em;
}

/********** FOCUS NAV ALT **********/
/* focus-nav.alt = inline cf. single-playlist -- V4 */

section.focus .focus-nav.alt {
	position:relative;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	padding:0;
	gap:.125rem;
}
section.focus .focus-nav.alt > * {
	-webkit-box-flex:unset;
	-webkit-flex-grow:unset;
	   -moz-box-flex:unset;
	    -ms-flex-positive:unset;
	        flex-grow:unset;
	margin:0;
}

/********** FOCUS FRONT **********/

section.focus.front .infos a > * {
	display:block;
}
section.focus.front .infos a > * + * {
	margin:.5rem 0 0 0;
}
section.focus.front .focus-nav a {
	-webkit-border-radius:3em;
	   -moz-border-radius:3em;
	        border-radius:3em;
}
section.focus.front .focus-nav a:before {
	margin-left:-.75em;
}
section.focus.front .focus-nav a.detonate {
	border-color:var(--red-color);
	background:var(--red-color);
}
section.focus .focus-nav a.detonate:hover {
	color:var(--red-color);
	text-shadow:none;
	border-color:var(--bright-color);
	background:var(--bright-color);
}

/********** FOCUS HAS-VIDEOPLAYER **********/

section.focus .videoplayer {
	z-index:10;
}
section.focus.has-videoplayer:before {
	z-index:10;
	padding:35.9375% 0 0 0;
}
section.focus.has-videoplayer .videoplayer {
	padding:35.9375% 0 0 0 !important;
}
section.focus.has-videoplayer .infos {
	position:absolute;
	z-index:20;
	bottom:0;
	left:0;
}

/********** FOCUS FILM-EVENT / ALERT **********/
/* cf. index.php / cf. selection du mois */

section.focus a.film-event,
section.focus .alert.fixed {
	position:absolute;
	z-index:10;
	top:0;
	right:0;
	margin:1rem;
}

/********** FOCUS FILM **********/

section.focus .original {
	display:block;
}
section.focus .infos .film-infos > * {
	display:inline-block;
}
section.focus .csa-classification {
	width:1.5rem;
}

/********** FOCUS INSTITUTION **********/

section.focus.institution {
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
section.focus.institution:before {
	display:none;
}
section.focus.institution .infos {
	padding:0;
}
section.focus.institution .infos:after {
	content:"";
	position:absolute;
	z-index:5;
	bottom:0;
	left:0;
	height:100%;
	width:100%;
	background:-webkit-gradient(linear, left bottom, left top, from(rgba(var(--black-rgb),1)), to(rgba(var(--black-rgb),0)));
	background:-webkit-linear-gradient(bottom, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	background:-moz-linear-gradient(bottom, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	background:-o-linear-gradient(bottom, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	background:linear-gradient(0deg, rgba(var(--black-rgb),1) 0%, rgba(var(--black-rgb),0) 100%);
	opacity:var(--alpha-rgb);
}
section.focus.institution .content {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	z-index:10;
}
section.focus.institution .collection-link {
	display:block;
}
section.focus.institution .content img {
	margin:0;
	padding:0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	/* default */
	section.focus:before {
		padding:50% 0 0 0;
	}
	/* focus infos */
	section.focus .infos {
		width:100%;
		padding:1rem;
	}
	section.focus .title {
		font-size:1.5rem;
	}
	/* focus nav */
	section.focus .focus-nav {
		width:100%;
		font-size:.75rem;
		line-height:1em;
	}
	/* focus.alt = festival */
	section.focus.alt {
		-webkit-box-orient:vertical;
		-webkit-box-direction:reverse;
		-webkit-flex-direction:column-reverse;
		   -moz-box-orient:vertical;
		   -moz-box-direction:reverse;
		    -ms-flex-direction:column-reverse;
		        flex-direction:column-reverse;
	}
	section.focus.alt:before {
		padding:0 !important;
	}
	section.focus.alt .background {
		position:relative;
		height:0 !important;
		padding-top:50% !important;
	}
	/* front */
	section.focus.front .infos > a + a {
		margin:.5rem 0 0 0;
	}
	section.focus.front .focus-nav {
		margin:.5rem;
	}
	section.focus.front .focus-nav > * {
		margin:.5rem;
		padding:1rem 1rem 1rem 1.5rem;
	}
	/* film */
	section.focus .infos * + .film-infos {
		margin:1em 0 0 0;
	}
	/* institution */
	section.focus.institution {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	section.focus.institution .content {
		position:absolute;
		bottom:0;
		left:0;
		padding:1rem;
	}
	section.focus.institution .collection-link {
		padding:1rem 0 0 0;
	}
	section.focus.institution .is-recommended {
		margin:auto 0 1rem 0;
	}
	/* focus has-splitted alt - V4 */
	section.focus.has-splitted.alt,
	section.focus.splitted.alt {
		padding:1rem;
	}
	/* focus nav alt - V4 */
	section.focus .focus-nav.alt > * {
		padding:.5rem;
	}
}
@media (min-width:760px) {
	/* default */
	section.focus:before {
		padding:35.9375% 0 0 0;
	}
	/* focus infos */
	section.focus .infos {
		max-width:79.75%;
		padding:2rem;
	}
	/* focus nav */
	section.focus .focus-nav {
		position:absolute;
		z-index:10;
		bottom:0;
		right:0;
		padding:2rem;
		margin-bottom:0;
		width:auto;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	section.focus .focus-nav.inline {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		padding:1rem;
	}
	section.focus .focus-nav > * + * {
		margin:.125rem 0 0 0;
	}
	section.focus .infos + nav {
		margin:1rem 0 0 0;
	}
	/* focus.alt = festival */
	section.focus.alt {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	section.focus.alt:before {
		padding:35.9375% 0 0 0 !important;
	}
	section.focus.alt .background {
		position:absolute;
		height:100% !important;
		padding-top:0% !important;
	}
	/* front */
	section.focus.front .infos > a + a {
		margin:2rem 0 0 0;
	}
	section.focus.front .focus-nav {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	section.focus.front .focus-nav > * {
		margin:0;
		padding:1.25em 2.25em;
	}
	section.focus.front .focus-nav > * + * {
		margin:0 0 0 2em;
	}
	/* film */
	section.focus .infos * + .film-infos {
		margin:2em 0 0 0;
	}	
	/* institution */
	section.focus.institution .infos {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
	}
	section.focus.institution .infos:after {
		display:none;
	}
	section.focus.institution .collection-link {
		padding:2em 0 0 0;
	}
	section.focus.institution .content {
		position:relative;
		bottom:auto;
		left:auto;
		padding:2rem 0 0 0;
		margin:0 0 0 2rem;
	}
	section.focus.institution .is-recommended {
		margin:auto 0 2rem 0;
	}
	/* focus has-splitted alt - V4 */
	section.focus.has-splitted.alt,
	section.focus.splitted.alt {
		padding:2rem;
	}
	/* focus nav alt - V4 */
	section.focus .focus-nav.alt > * {
		padding:1rem;
	}
}
@media (min-width:1200px) {
	/* focus infos */
	section.focus .title {
		font-size:2.5rem;
	}
	/* film */
	section.focus .is-recommended .list-link {
		font-size:1.5rem;
	}
	/* focus nav */
	section.focus .focus-nav {
		font-size:1rem;
		line-height:1em;
	}
}
@media (min-width:1400px) {
	/* focus infos */
	section.focus .infos {
		padding:4rem;
	}
	/* focus nav */
	section.focus .focus-nav {
		padding:4rem;
	}
	/* focus.front */
	section.focus.front .title {
		font-size:3rem;
		line-height:1em;
	}
	section.focus.front .infos,
	section.focus.front .focus-nav {
		padding:4rem 8rem;
	}
	/* institution */
	section.focus.institution .content {
		padding:3rem 0 0 0;
		margin:0 0 0 4rem;
	}
	section.focus.institution .is-recommended {
		margin:auto 0 4rem 0;
	}
	/* focus has-splitted alt - V4 */
	section.focus.has-splitted.alt,
	section.focus.splitted.alt {
		padding:4rem;
	}
}

/***************************************/
/***************************************/
/********** SECTION CINE-CLUB **********/
/***************************************/
/***************************************/

/* in single-cine-club.php */

section.cine-club .content {
	font-size:.875rem;
}
section.cine-club .content .director {
	font-size:1.25rem;
}
section.cine-club .content .director + *,
section.cine-club .content * + .excerpt {
	margin:2rem 0 0 0;
}

/********** @MEDIA **********/

/* based on focus title */

@media (min-width:0px) {
	section.cine-club .content h2 {
		font-size:1.5rem;
	}
}
@media (min-width:1200px) {
	section.cine-club .content h2 {
		font-size:2.5rem;
	}
}

/*********************************/
/*********************************/
/********** MAINTENANCE **********/
/*********************************/
/*********************************/

/* for maintenance */

body.maintenance main {
	height:100vh;
}
body.maintenance main section {
	position:absolute;
	top:50%;
	left:50%;
	padding:var(--section-gap);
	-webkit-transform:translate3d(-50%,-50%,0);
	   -moz-transform:translate3d(-50%,-50%,0);
	        transform:translate3d(-50%,-50%,0);
	background:rgba(var(--dark-rgb),var(--alpha-rgb));
}

/*************************/
/*************************/
/********** 404 **********/
/*************************/
/*************************/

/* for 404 content */

.oups .infos {
	position:absolute;
	z-index:10;
	top:50%;
	left:50%;
	-webkit-transform:translate3d(-60%,-80%,0);
	   -moz-transform:translate3d(-60%,-80%,0);
	        transform:translate3d(-60%,-80%,0);
}
.oups figure {
	pointer-events:none;
}

/********************************/
/********************************/
/********** HAS-STICKY **********/
/********************************/
/********************************/

/* cf. page-cart */

/********** IS-STICKY **********/

main.has-sticky section.is-sticky {
	position:-webkit-sticky;
	position:sticky;
	bottom:0;
}

/********** @MEDIA **********/
/* above bottom buttons in mobile */

@media (min-width:0px) {
	main.has-sticky section.is-sticky {
		z-index:2000;
	}	
}
@media (min-width:1000px) {
	main.has-sticky section.is-sticky {
		z-index:20;
	}
}

/*****************************/
/*****************************/
/********** COLUMNS **********/
/*****************************/
/*****************************/

/*
default : 1 | 1 | 1 ...

.columns.centered
.columns.vertical
.columns.short

.columns.inline == keep inline...

.columns.has-gutter
.columns > .has-gutter
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

.columns {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
}
.columns > * {
	position: relative;
	list-style-type:none;
	padding:0;
}

/********** CUSTOM **********/

.columns.centered > * {
	text-align:center;
}
.columns.vertical {
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
}
.columns.baseline {
	-webkit-box-align:baseline;
	-webkit-align-items:baseline;
	   -moz-box-align:baseline;
	    -ms-flex-align:baseline;
	        align-items:baseline;
}
.columns.vertical-centered {
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	/** default **/
	.columns {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.columns > * + * {
		margin-top:var(--gutter-gap);
	}
	/** has-gutter **/
	.columns.has-gutter > *:not(:last-child):after {
		content:"";
		position:absolute;
		top:0;
		right:0;
		left:0;
		bottom: -webkit-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		bottom: -moz-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		bottom: calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		border-bottom:solid var(--stroke-width);
		pointer-events: none;
	}
	.columns > *.has-gutter:before {
		content:"";
		position:absolute;
		top:0;
		right:0;
		left:0;
		bottom: -webkit-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		bottom: -moz-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		bottom: calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		border-bottom:solid var(--stroke-width);
		pointer-events: none;
	}
	/** forced **/
	.columns.inline {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.columns.inline > * {
		-webkit-box-flex:1;
		-webkit-flex-grow:1;
		   -moz-box-flex:1;
		    -ms-flex-positive:1;
		        flex-grow:1;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
	}
}
@media (min-width:760px) {
	/** default **/
	.columns {
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	}
	.columns > * {
		-webkit-box-flex:1;
		-webkit-flex-grow:1;
		   -moz-box-flex:1;
		    -ms-flex-positive:1;
		        flex-grow:1;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
	}
	.columns > * + * {
		margin-top:0;
		margin-left:var(--gutter-gap);
	}
	/** columns vertical **/
	.columns.vertical {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.columns.vertical > * {
		margin-top:var(--gutter-gap);
		margin-left:0;
	}
	/** has-gutter **/
	.columns.has-gutter > *:not(:last-child):after {
		border-right:solid var(--stroke-width);
		border-bottom:none;
		right: -webkit-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		right: -moz-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		right: calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		bottom:0;
	}
	.columns > *.has-gutter:before {
		border-left:solid var(--stroke-width);
		border-bottom:none;
		bottom:0;
		left: -webkit-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		left: -moz-calc((var(--gutter-gap) + var(--stroke-width)) / -2);
		left: calc((var(--gutter-gap) + var(--stroke-width)) / -2);
	}
}

/**************************************/
/********** SEE-ALSO COLUMNS **********/
/**************************************/

/* in single-film.php */

.see-also * + .collapsible-toggle.simple {
	margin-top:.5rem;
} 

/********** @MEDIA **********/

@media (min-width:0px) {
	.see-also * + .collapsible-toggle.simple + .grid.min {
		margin-top:-webkit-calc(.5rem - .03125rem);
		margin-top:-moz-calc(.5rem - .03125rem);
		margin-top:calc(.5rem - .03125rem);
	} 
}
@media (min-width:760px) {
	.see-also * + .collapsible-toggle.simple + .grid.min {
		margin-top:-webkit-calc(.5rem - .125rem);
		margin-top:-moz-calc(.5rem - .125rem);
		margin-top:calc(.5rem - .125rem);
	}
}
@media (min-width:1200px) {
	.see-also.columns > * {
		max-width:50%;
	} 
	.see-also.columns > * + * {
		margin-left:-webkit-calc(var(--gutter-gap) * 2);
		margin-left:-moz-calc(var(--gutter-gap) * 2);
		margin-left:calc(var(--gutter-gap) * 2);
	} 
}

/******************************/
/******************************/
/********** SPLITTED **********/
/******************************/
/******************************/

/*
.splitted
.splitted.has-gutter
.splitted.vertical-centered
.splitted.selection-guest (specific to single-selection.php)
.splitted.inline
.splitted.short == less padding
*/


/********** DEFAULT **********/

.splitted {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	text-align:left;
	overflow:hidden;
}

/********** SPLITTED VERTICAL-CENTERED **********/
/* in single-institution */

.splitted.vertical-centered {
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}

/********** SPLITTED VIDEOS **********/
/* in single-selection */

.splitted.selection-guest {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:end;
	-webkit-align-items:flex-end;
	   -moz-box-align:end;
	    -ms-flex-align:end;
	        align-items:flex-end;
}
.splitted.selection-guest > *:first-child {
	-webkit-box-flex:2;
	-webkit-flex-grow:2;
	   -moz-box-flex:2;
	    -ms-flex-positive:2;
	        flex-grow:2;
	width:100%;
	margin:0;
}
.splitted.selection-guest > *:last-child {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
}
.selection-guest .title-alt {
	display:inline-block;
}
.selection-guest .title-alt strong {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
.splitted.selection-guest .source {
	display:block;
}

/********** SPLITTED CONTENT **********/
/* cf. single institution */

/********** HAS SHAREBAR **********/
/* cf. single film */

.splitted nav.sharebar {
	-webkit-box-pack:end;
	-webkit-justify-content:flex-end;
	   -moz-box-pack:end;
	    -ms-flex-pack:end;
	        justify-content:flex-end;
}

/********** SPLITTED ALT **********/
/* cf. overlay-restrictions */

.splitted.alt > :last-child {
	width:auto;
	white-space:nowrap;
}

/********** SPLITTED INLINE **********/

/* auto width / cf. cart */

/********** @MEDIA **********/

@media (min-width:0px) {
	.splitted {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.splitted > * {
		padding:0;
		width:100%;
	}
	.splitted > :last-child {
		margin:1rem 0 0 0;
	}
	/* has-gutter */
	.splitted.has-gutter > :last-child {
		border-top:solid var(--stroke-width);
		margin-top:1rem;
	}
	/* selection-guest */
	.selection-guest .title-alt {
		font-size:1rem;
	}
}
@media (min-width:760px) {
	/* selection-guest */
	.selection-guest .title-alt {
		font-size:1.5rem;
	}
	.selection-guest .source {
		font-size:1rem;
	}
	/* inline */
	.splitted.inline > * {
		width:auto !important;
	}
}
@media (min-width:1000px) {
	.splitted {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
	}
	.splitted > *:first-child {
		width:60%;
		margin:0 1rem 0 0;
	}
	.splitted > *:last-child {
		width:40%;
		margin:0 0 0 1rem;
	}
	.splitted.invert > *:first-child {
		width:40%;
	}
	.splitted.invert > *:last-child {
		width:60%;
	}
	.splitted.large > *:first-child {
		width:80%;
	}
	.splitted.large > *:last-child {
		width:20%;
	}
	.splitted.invert.large > *:first-child {
		width:20%;
	}
	.splitted.invert.large > :last-child {
		width:80%;
	}
	.splitted.in-half > :first-child,
	.splitted.in-half > :last-child {
		width:50%;
	}
	/* has-gutter */
	.splitted.has-gutter > :last-child {
		border-top:none;
		border-left:solid var(--stroke-width);
		margin-top:0;
	}
	/* cart payment */
	.splitted.cart-payment > :first-child {
		width:30%;
	}
	.splitted.cart-payment > :last-child {
		width:70%;
	}
	/* selection-guest */
	.splitted.selection-guest > *:first-child {
		padding:0;
	}
	/* NB. only if quote */
	#selection-guest {
		width:80%;
	}
	.splitted.selection-guest > *:last-child {
		width:100%;
	}
}
@media (min-width:1200px) {
	.splitted > :first-child {
		margin: 0 2rem 0 0;
	}
	.splitted > :last-child {
		margin: 0 0 0 2rem;
	}
	/* short */
	.splitted.short > :first-child {
		margin: 0 1rem 0 0;
	}
	.splitted.short > :last-child {
		margin: 0 0 0 1rem;
	}
	/* selection-guest == NB. only if quote */
	.splitted.selection-guest > *:last-child {
		width:50%;
	}
	#selection-guest {
		width:60%;
	}
}