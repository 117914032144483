@charset "UTF-8";

/********************************/
/********************************/
/********** RESPONSIVE **********/
/********************************/
/********************************/

/*
no download in mobile	== preview-actions
no download in mobile	== film-actions
no player in mobile		== nav-user .menu
no player in mobile		== nav-contextual-user
*/

body.mobile nav.preview-actions .icon-download,
body.mobile nav.film-actions .icon-download,
body.mobile nav.nav-user .menu [data-overlay],
body.mobile nav.contextual nav.filters.large .icon-play {
	display:none !important;
}

/*************************/
/*************************/
/********** NAV **********/
/*************************/
/*************************/

nav a {
	display:inline-block;
}

/********** CENTERED **********/

nav.centered {
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	text-align:center;
}

/********** INLINE **********/

nav.inline {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
nav.inline > * {
	width:auto;
}
nav.inline > * + * {
	margin-left:.5rem;
}
nav.inline > nav {
	padding:0;
}

/*****************************/
/*****************************/
/********** BUTTONS **********/
/*****************************/
/*****************************/

/*
.buttons > a, .buttons > button, .button

.buttons > *, .button
.buttons.short > *
.buttons.small > *, .button.small
.buttons.centered > *, .button.centered
.button.full
.buttons.rounded > *, .button.rounded
.buttons.actions, .button.action
.buttons.list, .buttons.list.full

MARGIN !!!!
.buttons == turn margin into gap
.buttons.actions == like in tables (V4)
.buttons.list == vertical
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

.buttons {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.buttons > * {
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
}
.buttons > a,
.buttons > button,
button,
.button {
	position:relative;
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
	border:none;
	-webkit-border-radius:var(--border-radius);
	   -moz-border-radius:var(--border-radius);
	        border-radius:var(--border-radius);
}
.buttons > a:hover,
.buttons > button:hover,
button:hover,
.button:hover {
	color:var(--red-color);
	text-shadow:none;
	background:var(--bright-color);
}
.buttons > button {
	text-align:left;
	width:auto;
}
.buttons > * + * {
	margin:0;
}

/***************************/
/********** SHORT **********/
/***************************/

.buttons.short {
	width:auto;
}
.buttons.short > * {
	-webkit-box-flex:0;
	-webkit-flex-grow:0;
	   -moz-box-flex:0;
	    -ms-flex-positive:0;
	        flex-grow:0;
}

/**************************/
/********** FULL **********/
/**************************/
/* for confirmation */

.button.full {
	width:100%;
}
/******************************/
/********** CENTERED **********/
/******************************/

.buttons.centered > a,
.button.centered,
.buttons.centered > button {
	text-align:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}

/*****************************/
/********** ROUNDED **********/
/*****************************/
/* V5 */

.buttons.rounded a,
.button.rounded {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-border-radius:3rem;
	   -moz-border-radius:3rem;
	        border-radius:3rem;
	background: rgba(var(--black-rgb),.5);
	border: solid var(--stroke-width) rgba(var(--black-rgb),.5);
}
.buttons.rounded a:hover,
.button.rounded:hover,
.buttons.rounded a.active,
.button.rounded.active {
	color: var(--yellow-color);
	text-shadow: 0 0 0 var(--yellow-color);
	border: solid var(--stroke-width) var(--yellow-color);
	background: none;
}
.buttons.rounded.alt a,
.buttons.rounded a.alt,
.button.rounded.alt {
	background:var(--red-color);
}
.buttons.rounded.alt a:hover,
.buttons.rounded a.alt:hover,
.button.rounded.alt:hover,
.buttons.rounded.alt a.active,
.buttons.rounded a.alt.active,
.button.rounded.alt.active {
	color:var(--red-color);
	text-shadow:none;
	border: solid var(--stroke-width) var(--bright-color);
	background:var(--bright-color);
}

/*****************************/
/********** ACTIONS **********/
/*****************************/

/*  buttons actions used in grid-header for list playlist */

.buttons.actions {
	line-height:1em;
	width:auto;
	padding:0;
	gap:0;
}
.button.action,
.buttons.actions a {
	margin:0;
	background:none;
	-webkit-border-radius:0;
	   -moz-border-radius:0;
	        border-radius:0;
}
.buttons.actions a.alt {
	background:var(--light-color);
}

/**************************/
/********** LIST **********/
/**************************/

.buttons.list {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}
.buttons.list.full a {
	width:100%;
}

/******************************/
/********** IN TABLE **********/
/******************************/

/* cf. for user tables */

.table:not(.cart) .button,
.table:not(.cart) .buttons > a,
.button.dark,
.buttons.dark > a {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--light-color);
}
.table:not(.cart) .button:hover,
.table:not(.cart) .buttons > a:hover,
.button.dark:hover,
.buttons.dark > a:hover {
	background:var(--red-color);
}

/*******************************/
/********** HAS-PRICE **********/
/*******************************/

.buttons a.has-price,
.button.has-price {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	white-space:nowrap;
	padding:1rem;
}
.buttons a.has-price .price,
.button.has-price .price {
	position:relative;
	font-size:inherit;
	line-height:inherit;
	padding-left:2em;
	margin-left:2em;
	color:inherit;
	text-shadow:inherit;
}
.buttons a.has-price .price:before,
.button.has-price .price:before {
	content:"";
	position:absolute;
	top:50%;
	left:0;
	display:inline-block;
	height:1em;
	margin-top:.0625em; /* vertical centering fix */
	border-left:solid var(--stroke-width);
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
}

/****************************/
/********** COLORS **********/
/****************************/

.red .buttons > a,
.red .button {
	color:var(--red-color);
	text-shadow:none;
	background:var(--bright-color);
}
.red .buttons > a:hover,
.red .button:hover {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--grey-color);
}

/****************************/
/********** @MEDIA **********/
/****************************/

@media (min-width:0px) {
	.buttons {
		/*gap:.5rem;*/
		/*margin: -.25rem;*/
		margin-top:-.25rem;
		margin-bottom:-.25rem;
		width: -webkit-calc(100% + .5rem);
		width: -moz-calc(100% + .5rem);
		width: calc(100% + .5rem);
		-webkit-transform: translateX(-.25rem);
		   -moz-transform: translateX(-.25rem);
		    -ms-transform: translateX(-.25rem);
		     -o-transform: translateX(-.25rem);
		        transform: translateX(-.25rem);
	}
	* + .buttons,
	* + .buttons.short,
	* + .buttons.small {
		margin-top:-webkit-calc(var(--margin-normal) - .25rem);
		margin-top:-moz-calc(var(--margin-normal) - .25rem);
		margin-top:calc(var(--margin-normal) - .25rem);
	}
	.inline > * + .buttons,
	.inline > * + .buttons.short,
	.inline > * + .buttons.small {
		margin: 0;
		margin-left:-webkit-calc(var(--margin-normal) - .25rem);
		margin-left:-moz-calc(var(--margin-normal) - .25rem);
		margin-left:calc(var(--margin-normal) - .25rem);
	}
	.buttons > a,
	.buttons > button {
		margin:.25rem;
	}
	.buttons > a,
	.button,
	.buttons > button {
		font-size:.75rem;
		line-height:1em;
		padding:1em;
	}
	/* in table */
	.table .buttons {
		-webkit-flex-wrap:nowrap;
		    -ms-flex-wrap:nowrap;
		        flex-wrap:nowrap;
	}
	/* .buttons.min */
	.buttons.min a[class^="icon-"],
	.buttons.min a[class*=" icon-"],
	a[class^="icon-"].min,
	a[class*=" icon-"].min {
		display: block;
		font-size:0;
		line-height:0;
	}
	.buttons.min a[class^="icon-"]:before,
	.buttons.min a[class*=" icon-"]:before,
	a[class^="icon-"].min:before,
	a[class*=" icon-"].min:before {
		display: block;
		font-size:.75rem;
		line-height:1em;
		padding:1em;
		width: 1em;
		margin-right:0;
	}
	/* has-price */
	.buttons a.has-price,
	.button.has-price {
		min-width:10rem;
	}
}
@media (min-width:760px) {
	.buttons {
		/*gap:1rem;*/
		/*margin: -.5rem;*/
		margin-top:-.5rem;
		margin-bottom:-.5rem;
		width: -webkit-calc(100% + 1rem);
		width: -moz-calc(100% + 1rem);
		width: calc(100% + 1rem);
		max-width: -webkit-calc(var(--max-width) + 1rem);
		max-width: -moz-calc(var(--max-width) + 1rem);
		max-width: calc(var(--max-width) + 1rem);
		-webkit-transform: translateX(-.5rem);
		   -moz-transform: translateX(-.5rem);
		    -ms-transform: translateX(-.5rem);
		     -o-transform: translateX(-.5rem);
		        transform: translateX(-.5rem);
	}
	* + .buttons,
	* + .buttons.short,
	* + .buttons.small {
		margin-top:-webkit-calc(var(--margin-normal) - .5rem);
		margin-top:-moz-calc(var(--margin-normal) - .5rem);
		margin-top:calc(var(--margin-normal) - .5rem);
	}
	.inline > * + .buttons,
	.inline > * + .buttons.short,
	.inline > * + .buttons.small {
		margin-left:-webkit-calc(var(--margin-normal) - .5rem);
		margin-left:-moz-calc(var(--margin-normal) - .5rem);
		margin-left:calc(var(--margin-normal) - .5rem);
	}
	.buttons > a,
	.buttons > button {
		margin:.5rem;
	}
	.buttons > a,
	.buttons > button,
	.button {
		font-size:.875rem;
		line-height:1rem;
		padding:1rem;
	}
	/* small */
	.buttons.small > a,
	.button.small,
	.buttons.small > button {
		font-size:.75rem;
		line-height:1em;
		padding:1em;
	}
	/* in table */
	.table .buttons {
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
	}
	/* .buttons.min */
	.buttons.min a[class^="icon-"],
	.buttons.min a[class*=" icon-"],
	a[class^="icon-"].min,
	a[class*=" icon-"].min {
		display: -webkit-inline-box;
		display: -webkit-inline-flex;
		display: -moz-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		font-size:.875rem;
		line-height:1rem;
		padding:1rem;
	}
	.buttons.min.small a[class^="icon-"],
	.buttons.min.small a[class*=" icon-"],
	a[class^="icon-"].min.small,
	a[class*=" icon-"].min.small {
		font-size:.75rem;
		line-height:1em;
		padding:1em;
	}
	.buttons.min a[class^="icon-"]:before,
	.buttons.min a[class*=" icon-"]:before,
	a[class^="icon-"].min:before,
	a[class*=" icon-"].min:before {
		font-size:inherit;
		line-height:inherit;
		padding:0;
		margin-right:1em;
	}
	/* has-price */
	.buttons a.has-price,
	.button.has-price {
		min-width:12rem;
	}
}
@media (min-width:1400px) {
	.buttons > a,
	.button,
	.buttons > button {
		font-size:1rem;
		line-height:1em;
	}
}

/* for nav.buttons directly in main > section (cf. user > parameters) */
@media (min-width:1528px) {
	main > section > .buttons {
		-webkit-transform: none;
		   -moz-transform: none;
		    -ms-transform: none;
		     -o-transform: none;
		        transform: none;
	}
}

/**************************/
/**************************/
/********** MENU **********/
/**************************/
/**************************/

nav .menu {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height:100%;
}
nav .menu li {
	list-style:none;
	padding:0;
	margin:0;
}
nav .menu li:hover {
	z-index: 10;
}
nav .menu a {
	position:relative;
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	border:none;
	white-space:nowrap;
}

/********** FIRST LEVEL **********/

nav .menu > li {
	position:relative;
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	   -moz-box-flex:1;
	    -ms-flex-positive:1;
	        flex-grow:1;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height:100%;
}
nav .menu li > a {
	height:100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
nav.menu > li.large,
nav .menu > li.large {
	-webkit-box-flex:2;
	-webkit-flex-grow:2;
	   -moz-box-flex:2;
	    -ms-flex-positive:2;
	        flex-grow:2;
}

/********** SUBMENU **********/

nav .submenu {
	position:absolute;
	top:100%;
	left:0;
	text-align:left;
	white-space:nowrap;
	padding:0;
	overflow:hidden;
	height:auto;
	min-width:100%;
	width:auto;
	opacity:0;
	max-height:0;
	visibility:hidden;
}
nav .submenu .submenu {
	position:absolute;
	top:0;
	left:100%;
}
nav .menu *:hover > .submenu,
nav .menu *:active > .submenu {
	opacity:1;
	max-height:100rem;
	visibility:visible;
}
nav *.large:hover > .submenu,
nav *.large:active > .submenu {
	min-width:50%;
}

/********** IS-VOD **********/
/* add shadow for pages without body.is-vod */

body:not(.is-vod) nav .menu *:hover > .submenu,
body:not(.is-vod) nav .menu *:active > .submenu {
	overflow: visible;
}
body:not(.is-vod) nav .menu *:hover > .submenu:after,
body:not(.is-vod) nav .menu *:active > .submenu:after {
	content:"";
	position:absolute;
	z-index:-1;
	top:100%;
	left:0;
	height:1rem;
	width:100%;
	opacity:.5;
	pointer-events:none;
	background: var(--black-color);
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--black-rgb),0)), to(rgba(var(--black-rgb),1)));
	background: -webkit-linear-gradient(bottom, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%);
	background: -moz-linear-gradient(bottom, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%);
	background: -o-linear-gradient(bottom, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%);
	background: linear-gradient(0deg, rgba(var(--black-rgb),0) 0%, rgba(var(--black-rgb),1) 100%);
}

/********** HAS-SUBMENU **********/
/* for lang-selector / login nav */

nav .menu .has-submenu {
	position:relative;
}
nav .menu .has-submenu .submenu a {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
}
nav .menu .has-submenu > a:after {
	content:"";
	display:inline-block;
	margin-left:.5em;
	margin-top:.25rem;
	border-top:.25rem solid;
	border-right:.25rem solid transparent;
	border-left:.25rem solid transparent;
	border-bottom:0 dotted;
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
	-webkit-transform-origin:center center;
	   -moz-transform-origin:center center;
	    -ms-transform-origin:center center;
	     -o-transform-origin:center center;
	        transform-origin:center center;
}
nav .menu .has-submenu a:hover:after,
nav .menu .has-submenu:hover > a:after {
	-webkit-transform:translate3d(0,-50%,0) rotate(-180deg);
	   -moz-transform:translate3d(0,-50%,0) rotate(-180deg);
	        transform:translate3d(0,-50%,0) rotate(-180deg);
}

/*****************************/
/*****************************/
/********** PRIMARY **********/
/*****************************/
/*****************************/

/********** DEFAULT **********/

#primary {
	position: relative;
	z-index: 1100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	   -moz-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
	height:auto;
}
#primary a {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	line-height:1em;

	text-transform:uppercase;
}
#primary a:hover:before {
	-webkit-transform:scale(.8);
	   -moz-transform:scale(.8);
	    -ms-transform:scale(.8);
	     -o-transform:scale(.8);
	        transform:scale(.8);
}

/********** SUBMENU **********/

#primary .submenu {
	position: fixed;
	top:var(--heading-height);
	line-height:1em;
	opacity:0;
	max-height: 0;
	width:100%;
	visibility: hidden;
	background:var(--dark-color);
}
#primary .submenu a {
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	padding:1em;
	text-transform: none;
}
#primary .submenu a:not(.button) {
	border-top:solid var(--stroke-width-min) transparent;
	border-bottom:solid var(--stroke-width-min) transparent;
	min-height: 3rem;
}

/** hover **/

#primary:hover li.active:hover > a + .submenu,
#primary li.active > a + .submenu,
#primary li:hover > a + .submenu {
	opacity: 1;
    max-height: 100rem;
    visibility: visible;
}
#primary:hover li.active > a + .submenu {
	opacity:0;
}

/********** COLORS **********/

#primary li:hover a.tvod,
#primary li.active a.tvod {
	/*color:var(--dark-color);
	text-shadow: 0 0 0 var(--dark-color);*/
	background:var(--tvod-color);
}
#primary li.active a.tvod + .submenu a:not(.button):hover,
#primary li.active a.tvod + .submenu a:not(.button).active,
#primary li:hover a.tvod + .submenu a:not(.button):hover,
#primary li:hover a.tvod + .submenu a:not(.button).active {
	border-bottom:solid var(--stroke-width-min) var(--tvod-color);
}
#primary li:hover a.svod,
#primary li.active a.svod {
	background:var(--svod-color);
}
#primary li.active a.svod + .submenu a:not(.button):hover,
#primary li.active a.svod + .submenu a:not(.button).active,
#primary li:hover a.svod + .submenu a:not(.button):hover,
#primary li:hover a.svod + .submenu a:not(.button).active {
	border-bottom:solid var(--stroke-width-min) var(--svod-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	#primary {
		font-size:.75rem;
	}
	#primary .submenu {
		font-size: .625rem;
	}
	#primary .menu li > a {
		padding:1.5em;
	}
}
@media (min-width:760px) {
	#primary {
		font-size: .875rem;
	}
	#primary .submenu {
		font-size: .75rem;
	}
	#primary .menu li > a {
		padding:1em 1.5em;
	}
}
@media (min-width:1200px) {
	#primary {
		font-size: 1rem;
	}
	#primary .submenu {
		font-size: .875rem;
	}
}

/****************************/
/********** ON-AIR **********/
/****************************/

#primary a.on-air {
	color:var(--red-color);
	text-shadow:none;
}
#primary a.on-air:before {
	font-family:'icomoon';
	color:var(--red-color);
	text-shadow:none;
}

/*********************************/
/********** SUBMENU NAV **********/
/*********************************/

#primary .submenu > nav {
	position:relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin:0;
	min-height: 3rem;
}
#primary .submenu.in-position > nav {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	   -moz-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
}
#primary .submenu > nav p + .button {
	margin-left:1em;
}

/********** @MEDIA **********/

@media (min-width:1000px) {
	#primary .submenu.in-position > nav {
		padding-left: var(--lacinetek-width) !important;
	}
}

/********************************/
/********************************/
/********** NAVIGATION **********/
/********************************/
/********************************/

#navigation {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	   -moz-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
	height:auto;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	#navigation {
		margin-left:auto !important;
	}
}
@media (min-width:1000px) {
	#navigation {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		   -moz-box-flex: 1;
		    -ms-flex-positive: 1;
		        flex-grow: 1;
		margin-left:-webkit-calc(var(--gutter-gap) / 2) !important;
		margin-left:-moz-calc(var(--gutter-gap) / 2) !important;
		margin-left:calc(var(--gutter-gap) / 2) !important;
	}
}

/** mobile **/

@media (max-width:760px) {
	#navigation {
		position:absolute;
		z-index: 1000 !important;
		top:var(--header-height);
		left:0;
		width:100%;
		margin:0 !important;
		overflow:hidden;
		pointer-events: none;
	}
	#navigation > * {
		-webkit-transform: translate3d(0,-100%,0);
		   -moz-transform: translate3d(0,-100%,0);
		        transform: translate3d(0,-100%,0);
		pointer-events: none;
		visibility: hidden;
		margin:0 !important;
		background: var(--dark-color);
		-webkit-transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease);
		-o-transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		-o-transform var(--duration-fast) var(--ease);
		-moz-transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease),
		-moz-transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear var(--duration-fast),
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease),
		-moz-transform var(--duration-fast) var(--ease),
		-o-transform var(--duration-fast) var(--ease);
	}
	#navigation.expanded > * {
		-webkit-transform: translate3d(0,0,0);
		   -moz-transform: translate3d(0,0,0);
		        transform: translate3d(0,0,0);
		visibility: visible;
		pointer-events: auto;
		-webkit-transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease);
		-o-transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		-o-transform var(--duration-fast) var(--ease);
		-moz-transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease),
		-moz-transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease);
		transition:
		visibility .01s linear,
		opacity var(--duration-fast) var(--ease),
		transform var(--duration-fast) var(--ease),
		-webkit-transform var(--duration-fast) var(--ease),
		-moz-transform var(--duration-fast) var(--ease),
		-o-transform var(--duration-fast) var(--ease);
	}
	/** nav-user **/
	#navigation nav.nav-user {
		margin:0;
		width: 100%;
		padding:0 var(--gutter-gap) var(--gutter-gap) var(--gutter-gap);
	}
	#navigation nav.nav-user a:not(.button) {
		border:none;
		padding:1em;
	}
	/** lang-selector **/
	#navigation nav.lang-selector {
		position: absolute;
		top:var(--gutter-gap);
		right:var(--gutter-gap);
		top:0;
		right:0;
	}
}

/***************************************/
/***************************************/
/********** NAVIGATION EXPAND **********/
/***************************************/
/***************************************/

#navigation-expand {
	position:relative;
	z-index:1100;
	display:inline-block;
	padding:1rem;
	margin:0;
	margin-left:auto !important;
}
#navigation-expand .label {
	position:relative !important;
	font-size:0;
	line-height:0;
	margin:.46875rem auto;
}
#navigation-expand .label:before {
	margin-top:-.375rem;
}
#navigation-expand .label:after {
	margin-top:.375rem;
}
#navigation-expand .label:before,
#navigation-expand .label:after {
	position: absolute;
	content:'';
}
#navigation-expand .label,
#navigation-expand .label:before,
#navigation-expand .label:after {
	width:1rem;
}
#navigation-expand .label,
#navigation-expand .label:before,
#navigation-expand .label:after {
	display:block;
	height:.0625rem;
}
#navigation-expand.expanded .label:before {
	margin-top:0;
	-webkit-transform:rotate(45deg);
	   -moz-transform:rotate(45deg);
	    -ms-transform:rotate(45deg);
	     -o-transform:rotate(45deg);
	        transform:rotate(45deg);
	width:1.3525rem;
}
#navigation-expand.expanded .label:after {
	margin-top:0;
	-webkit-transform:rotate(-45deg);
	   -moz-transform:rotate(-45deg);
	    -ms-transform:rotate(-45deg);
	     -o-transform:rotate(-45deg);
	        transform:rotate(-45deg);
	width:1.3525rem;
}
#navigation-expand .label,
#navigation-expand .label:before,
#navigation-expand .label:after {
	background:var(--bright-color);
}
#navigation-expand:hover .label,
#navigation-expand:hover .label:before,
#navigation-expand:hover .label:after {
	background:var(--bright-color);
}
#navigation-expand.expanded .label {
	background:transparent !important;
}

/********** @MEDIA **********/

@media (min-width:760px) {
	#navigation-expand {
		display:none !important;
	}
}

/*******************************/
/*******************************/
/********** LACINETEK **********/
/*******************************/
/*******************************/

/* from style.css */

header .lacinetek {
	z-index:1100;
	padding:-webkit-calc(var(--gutter-gap) / 2) 0 -webkit-calc(var(--gutter-gap) / 2) -webkit-calc(var(--gutter-gap) / 2);
	padding:-moz-calc(var(--gutter-gap) / 2) 0 -moz-calc(var(--gutter-gap) / 2) -moz-calc(var(--gutter-gap) / 2);
	padding:calc(var(--gutter-gap) / 2) 0 calc(var(--gutter-gap) / 2) calc(var(--gutter-gap) / 2);
	margin-top:auto;
	margin-bottom: auto;
	/*
	padding:0;
	margin:0 1em;
	*/
}
header .lacinetek:hover {
	opacity:.75;
}
header .lacinetek svg {
	width:100%;
	transform:translateY(.25em);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	header .lacinetek {
		height:2.25rem;
		width:5rem;
	}
}
@media (min-width:760px) {
	header .lacinetek {
		height:2.5rem;
		width:7rem;
	}
}
@media (min-width:1000px) {
	header .lacinetek {
		height:4rem;
		width:10rem;
	}
}

/******************************/
/******************************/
/********** NAV USER **********/
/******************************/
/******************************/

.nav-user {
	position:relative;
	z-index:1100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	   -moz-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
	line-height:1rem;
	height:auto;
}
.nav-user a {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding: 1em;
}
header * + .nav-user {
	margin:0 0 0 -webkit-calc(var(--gutter-gap) / 2);
	margin:0 0 0 -moz-calc(var(--gutter-gap) / 2);
	margin:0 0 0 calc(var(--gutter-gap) / 2)
}
.nav-user a:not(.button) {
	border-top:solid var(--stroke-width-min) transparent;
}
.nav-user a:not(.button):hover:before {
	-webkit-transform:scale(.8);
	   -moz-transform:scale(.8);
	    -ms-transform:scale(.8);
	     -o-transform:scale(.8);
	        transform:scale(.8);
}
.nav-user a:not(.button):hover,
.nav-user a:not(.button).active {
	border-top:solid var(--stroke-width-min) var(--bright-color);
}

/********** SUBMENU **********/

.nav-user .submenu {
	right:0;
	left:auto;
	background:var(--grey-color);
}
.nav-user .submenu a {
	border-top:0 !important;
	padding:1rem !important;
	opacity:.5;
}
.nav-user .submenu a:hover {
	opacity:1;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.nav-user {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		   -moz-box-orient: vertical;
		   -moz-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		   -moz-box-align: start;
		    -ms-flex-align: start;
		        align-items: flex-start;
		font-size:.75rem;
	}
	.nav-user a:before {
		font-size:inherit;
		margin-right: 1em;
	}
	.nav-user a:not(.button) {
		margin:0;
		padding: -webkit-calc(1em - var(--stroke-width-min)) 1em 1em 1em;
		padding: -moz-calc(1em - var(--stroke-width-min)) 1em 1em 1em;
		padding: calc(1em - var(--stroke-width-min)) 1em 1em 1em;
	}
}
@media (min-width:760px) {
	.nav-user {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		   -moz-box-orient: horizontal;
		   -moz-box-direction: normal;
		    -ms-flex-direction: row;
		        flex-direction: row;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		   -moz-box-align: stretch;
		    -ms-flex-align: stretch;
		        align-items: stretch;
		font-size:0;
	}
	.nav-user .has-submenu .submenu a,
	.nav-user .lang-selector,
	.nav-user a:before,
	.nav-user a:after {
		font-size:.75rem;
		line-height: 1em;
	}
	.nav-user a:before,
	.nav-user a:after {
		display:inline-block;
	}
	.nav-user a:before {
		margin-right: 0;
	}
	.nav-user .has-submenu .submenu a:before {
		margin-right: 1em;
	}
	.nav-user a:not(.button) {
		padding: -webkit-calc(1rem - var(--stroke-width-min)) 1rem 1rem 1rem;
		padding: -moz-calc(1rem - var(--stroke-width-min)) 1rem 1rem 1rem;
		padding: calc(1rem - var(--stroke-width-min)) 1rem 1rem 1rem;
	}
}
@media (min-width:1100px) {
	.nav-user {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		   -moz-box-orient: horizontal;
		   -moz-box-direction: normal;
		    -ms-flex-direction: row;
		        flex-direction: row;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		   -moz-box-align: stretch;
		    -ms-flex-align: stretch;
		        align-items: stretch;
		font-size:.75rem;
	}
	.nav-user .has-submenu .submenu a,
	.nav-user .lang-selector,
	.nav-user a:before,
	.nav-user a:after {
		font-size:inherit;
	}
	.nav-user a:not(.icon-cart):before {
		margin-right: 1em;
	}
	.nav-user a:not(.button) {
		margin:0;
		padding: -webkit-calc(1em - var(--stroke-width-min)) 1em 1em 1em;
		padding: -moz-calc(1em - var(--stroke-width-min)) 1em 1em 1em;
		padding: calc(1em - var(--stroke-width-min)) 1em 1em 1em;
	}
}
@media (min-width:1200px) {
	.nav-user {
		font-size:.875rem;
	}
}

/***********************************/
/***********************************/
/********** LANG SELECTOR **********/
/***********************************/
/***********************************/

.lang-selector {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	   -moz-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
	height:auto;
	text-transform:uppercase;
	text-align: center;
}
.lang-selector .submenu {
	text-align: center;
	background:var(--grey-color);
}
.lang-selector .submenu a {
	opacity:.5;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}
.lang-selector .submenu a:hover {
	opacity:1;
}

/****************************/
/****************************/
/********** SOCIAL **********/
/****************************/
/****************************/

nav.sharebar,
nav.socialbar {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	white-space:nowrap;
}
nav.sharebar > *,
nav.socialbar > * {
	display:inline-block;
	vertical-align:middle;
	padding:.5rem;
	margin:0;
}
nav.sharebar > a:before,
nav.socialbar > a:before {
	font-family:'icomoon';
	display:block;
	font-size:1rem;
	line-height:1em;
	width:1em;
}
nav.sharebar:hover > *:not(span),
nav.socialbar:hover > *:not(span) {
	opacity:.66;
}
nav.sharebar:hover > a:hover,
nav.socialbar:hover > a:hover {
	opacity:1;
}

/********** LARGE **********/
/* for playlists */

nav.sharebar.large > *,
nav.socialbar.large > * {
	padding:1rem;
}
nav.sharebar.large > a:before,
nav.socialbar.large > a:before {
	font-size:2rem;
}

/******************************/
/******************************/
/********** NAV MODE **********/
/******************************/
/******************************/

nav.mode {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	margin:0;
}
nav.mode span {
	font-size:.875rem;
	line-height:1em;
	padding-right:1em;
}
nav.mode a {
	font-size:0 !important;
	line-height:0 !important;
	padding:1rem !important;
}
nav.mode a:before {
	display:block;
	font-family:'icomoon';
	font-size:1rem;
	line-height:1em;
	height:1em;
	width:1em;
}
nav.mode a.active {
	pointer-events:none;
}
nav.mode a:hover {
	color:var(--red-color);
	text-shadow:none;
	background:var(--bright-color) !important;
}

/********************************/
/********************************/
/********** CONTEXTUAL **********/
/********************************/
/********************************/

/*
nav.contextual
nav.contextual.sticky [V5]
nav.contextual.fixed
nav.contextual.relative > for navigation-top / navigation-institutions
nav.contextual.checkout
nav.contextual.institutions

nav.contextual.transparent [V5] (for svod)
nav.contextual.svod [V5]
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

nav.contextual {
	position:relative;
	z-index:550;
	margin-left:auto;
	margin-right:auto;
	margin-top:0;
	padding:0 !important;
	width:100%;
	background:var(--dark-color);
}
nav.contextual * + * {
	padding:0;
}
nav.contextual a {
	white-space:nowrap;
	margin:0;
	line-height:1em;
	padding:1em;
}
nav.contextual p a {
	white-space:normal;
	margin:0;
	line-height:1em;
	padding:0;
}
body.mobile nav.contextual {
	-webkit-transform:translate3d(0,0,0);
	   -moz-transform:translate3d(0,0,0);
	        transform:translate3d(0,0,0);
}

/********** SPACING **********/

nav.contextual + section,
.contextual nav + nav,
.contextual + .contextual {
	margin-top:0;
}

/********** FIXED / STICKY / RELATIVE **********/

nav.contextual.sticky {
	position:-webkit-sticky;
	position:sticky;
}
nav.contextual.fixed {
	position:fixed;
	left:0;
	right:0;
	max-width:none;
}
nav.contextual.fixed:after {
	content:"";
	position:absolute;
	z-index:-1;
	top:100%;
	left:0;
	height:1rem;
	width:100%;
	opacity:.5;
	background: var(--darker-color);
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--darker-rgb),0)), to(rgba(var(--darker-rgb),1)));
	background: -webkit-linear-gradient(bottom, rgba(var(--darker-rgb),0) 0%, rgba(var(--darker-rgb),1) 100%);
	background: -moz-linear-gradient(bottom, rgba(var(--darker-rgb),0) 0%, rgba(var(--darker-rgb),1) 100%);
	background: -o-linear-gradient(bottom, rgba(var(--darker-rgb),0) 0%, rgba(var(--darker-rgb),1) 100%);
	background: linear-gradient(0deg, rgba(var(--darker-rgb),0) 0%, rgba(var(--darker-rgb),1) 100%);
}
nav.contextual.sticky,
nav.contextual.fixed {
	top:var(--header-height) !important;
	left:0;
}
nav.contextual.relative {
	position:relative !important;
	top:auto !important;
	-webkit-transform:none !important;
	   -moz-transform:none !important;
	    -ms-transform:none !important;
	     -o-transform:none !important;
	        transform:none !important;
}
nav.contextual.relative:after {
	display:none !important;
}

/********** CONTEXTUAL COLLAPSIBLE **********/

nav.contextual a.collapsible-toggle {
	background:none;
}
nav.contextual a.collapsible-toggle:not(.with-icon-after) {
	padding-left:0 !important;
}
nav.contextual a.collapsible-toggle:before {
	content:"";
	display:inline-block;
	vertical-align:middle;
	margin:.3125em 1em;
	border-top:.25rem solid;
	border-right:.25rem solid transparent;
	border-left:.25rem solid transparent;
	border-bottom:0 dotted;
	-webkit-transform-origin:center center;
	   -moz-transform-origin:center center;
	    -ms-transform-origin:center center;
	     -o-transform-origin:center center;
	        transform-origin:center center;
}
nav.contextual a.collapsible-toggle:hover:before,
nav.contextual a.collapsible-toggle.active:before {
	-webkit-transform:rotate(-180deg);
	   -moz-transform:rotate(-180deg);
	    -ms-transform:rotate(-180deg);
	     -o-transform:rotate(-180deg);
	        transform:rotate(-180deg);
}
nav.contextual .collapsible {
	position:relative;
	z-index:5;
	margin:0 !important;
}

/********** USER **********/

nav.contextual .input-container {
	padding:0 !important;
	margin:auto .5rem;
}
nav.contextual .input-container input {
	padding:1rem 1rem 1rem 2.75rem !important;
}

/********** @MEDIA **********/

/*
top == header height
margin == main padding
contextual navigation TOP == OUT >> JS V5
*/

@media (min-width:0px) {
	nav.contextual > a {
		font-size:.75rem;
		line-height:1em;
	}
	nav.contextual.large > a {
		padding:1rem;
	}
}
@media (min-width:1000px) {
	nav.contextual > a {
		font-size:.75rem;
		padding:1rem;
	}
	nav.contextual.large > a {
		padding:1.5rem 1rem;
	}
}
@media (min-width:1200px) {
	nav.contextual > a {
		font-size:.875rem;
	}
	nav.contextual.large > a {
		padding:1.5rem;
	}
}

/******************************/
/********** CHECKOUT **********/
/******************************/

nav.contextual.checkout {
	text-align:center;
}
nav.contextual.checkout li {
	position:relative;
	opacity:.5;
}
nav.contextual.checkout li.active,
nav.contextual.checkout li.done {
	opacity:1;
}
nav.contextual.checkout li:before {
	position:relative;
	display:inline-block;
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
	-webkit-transform:scale(1);
	   -moz-transform:scale(1);
	    -ms-transform:scale(1);
	     -o-transform:scale(1);
	        transform:scale(1);
}
nav.contextual.checkout li.done:before {
	font-family:'icomoon';
	background:var(--red-color);
}
nav.contextual.checkout li.done:hover:before {
	-webkit-transform:scale(1.2);
	   -moz-transform:scale(1.2);
	    -ms-transform:scale(1.2);
	     -o-transform:scale(1.2);
	        transform:scale(1.2);
}
nav.contextual.checkout a {
	display:inline-block;
}
nav.contextual.checkout li.done a {
	color:var(--red-color);
	text-shadow:none;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	nav.contextual.checkout li {
		padding:1rem .25rem !important;
	}
	nav.contextual.checkout li:before {
		font-size:.625rem;
		line-height:1em;
		height:1em;
		width:1em;
		margin-right:.5em;
		padding:1em;
	}
	.contextual.checkout + section.short {
		margin-top:1rem;
	}
}
@media (min-width:760px) {
	nav.contextual.checkout li {
		padding:1rem !important;
	}
	nav.contextual.checkout li:before {
		font-size:1rem;
	}
}
@media (min-width:1200px) {
	.contextual.checkout + section.short {
		margin-top:2rem;
	}
}

/**********************************/
/********** INSTITUTIONS **********/
/**********************************/

nav.contextual.institutions {
	text-align:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}
nav.contextual.institutions a {
	opacity:.25;
	margin:0;
	background:none !important;
}
nav.contextual.institutions a:hover,
nav.contextual.institutions a.active {
	opacity:1;
}
nav.contextual.institutions a:before {
	content:"";
	position:absolute;
	bottom:0;
	left:0;
	right:0;
	background:var(--red-color);
	opacity:0;
}
nav.contextual.institutions a.active:before {
	opacity:1;
}
nav.contextual.institutions a img {
	height:100%;
	width:100%;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	nav.contextual.institutions a {
		max-width:5rem;
		padding:.5rem 0;
	}
	nav.contextual.institutions a:before {
		height:.125rem;
	}
}
@media (min-width:760px) {
	nav.contextual.institutions a {
		max-width:10rem;
		padding:1rem;
	}
	nav.contextual.institutions a:before {
		height:.25rem;
	}
}

/**********************************************/
/********** CONTEXTUAL WITH NAV MODE **********/
/**********************************************/

nav.contextual nav.mode {
	position:absolute;
}
nav.contextual nav.mode > span {
	text-transform:uppercase;
	font-size:.75rem !important;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	nav.contextual nav.mode {
		display:none;
		visibility:hidden;
		top:.1875rem;
		right:.1875rem;
	}
}
@media (min-width:1000px) {
	nav.contextual nav.mode {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		visibility:visible;
	}
}
@media (min-width:1200px) {
	nav.contextual nav.mode {
		top:.375rem;
		right:.375rem;
	}
}

/**************************/
/********** SVOD **********/
/**************************/

nav.contextual.svod > a {
	font-size:inherit;
}

/** in focus **/

nav.contextual .button.fixed {
	position:absolute;
	top:var(--gutter-gap);
	right:var(--gutter-gap);
}

/****************************/
/********** COLORS **********/
/****************************/

/********** DEFAULT **********/

nav.contextual.transparent {
	background: none;
}
nav.contextual.transparent:after {
	display: none;
}
nav.contextual.white {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
}
nav.contextual.red {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}
nav.contextual.red a:hover,
nav.contextual.red a.active {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:rgba(var(--black-rgb),.25);
}

/********** LARGE **********/
/* cf. private */

nav.contextual nav.large a:hover {
	color:var(--red-color);
	text-shadow:none;
	background:none;
}
nav.contextual nav.large a.active,
nav.contextual nav.large a.active:hover {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}

/********** WITH NAV **********/

/** like film filters **/
nav.contextual nav.red {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}
nav.contextual nav.red a:hover,
nav.contextual nav.red a.active {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:rgba(var(--black-rgb),.25);
}
/** like alphabetical filters **/
nav.contextual nav.grey {
	background:var(--grey-color);
}
nav.contextual nav.grey a:hover,
nav.contextual nav.grey a.active {
	color:var(--darker-color);
	text-shadow:none;
	background:var(--bright-color);
}

nav.contextual nav.light {
	background:rgb(101,101,110);
}
nav.contextual nav.dark {
	background:var(--dark-color);
}
nav.contextual nav.black {
	background:var(--black-color);
}

/*****************************/
/*****************************/
/********** FILTERS **********/
/*****************************/
/*****************************/

/*
nav.filters
nav.filters.alphabetical
nav.filters.has-tooltip (OUT)
nav.filters-tags
nav.filters-tags.current
nav.filters.featured (test V2)
nav.filters-controls (for featured)

nav.filters.large
nav.filters.larger ==> top list

nav.filters == light grey as default
nav.filters.grey == dark grey (cf. alphabetical)
nav.filters.red == red (cf. archive-films)
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

nav.filters {
	position:relative;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	margin:0;
}
nav.filters a,
nav.filters span {
	position:relative;
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
nav.filters span {
	text-transform:uppercase;
	white-space:nowrap;
	font-size:.75em !important;
}
nav.filters a:hover,
nav.filters a.active,
nav.filters a.active:hover {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}
nav.filters a.active {
	pointer-events:none;
}

/**********************************/
/********** MAIN FILTERS **********/
/**********************************/
/* archive films */

nav.filters.main-filters a.active:after {
	content:"";
	position:absolute;
	z-index:10;
	top:100%;
	left:50%;
	-webkit-transform:translate3d(-50%,0,0) rotate(135deg);
	   -moz-transform:translate3d(-50%,0,0) rotate(135deg);
	        transform:translate3d(-50%,0,0) rotate(135deg);
	height:0;
	width:0;
	border-style:solid;
	border-width:.75rem 0 0 .75rem;
	border-color: transparent transparent transparent var(--grey-color);
	-webkit-transform-origin:center center;
	   -moz-transform-origin:center center;
	    -ms-transform-origin:center center;
	     -o-transform-origin:center center;
	        transform-origin:center center;
	margin:-.375rem 0 0 0;
}

/*********************************/
/********** TOP FILTERS **********/
/*********************************/
/* cf. top archives */

nav.filters.larger {
	border-bottom:solid var(--stroke-width-min) var(--red-color);
}
nav.filters.larger a {
	text-transform:uppercase;
}
nav.filters.larger a.active {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}
nav.filters.larger a:hover {
	color:var(--red-color);
	text-shadow:none;
	background:none;
}

/****************************************/
/********** FILTERS CHECKBOXES **********/
/****************************************/

nav.filters.checkboxes {
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
}
nav.filters.checkboxes a {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	background:none !important;
}
nav.filters.checkboxes a:before {
	content:"";
	display:inline-block;
	vertical-align:middle;
	height:1em;
	width:1em;
	margin-right:1em;
	border:solid var(--stroke-width-min) var(--yellow-color);
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
}
nav.filters.checkboxes a:hover:before {
	background:rgba(var(--yellow-rgb),.25);
}
nav.filters.checkboxes a.active:before {
	background:var(--yellow-color);
}

/************************************/
/********** FILTERS EXPAND **********/
/************************************/

nav.contextual a.filters-expand {
	visibility:hidden;
	display:none;
	line-height:1em;
	padding:1em;
}
nav.contextual.fixed a.filters-expand {
	visibility:visible;
	display:block;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	nav.contextual a.filters-expand {
		font-size:.75rem;
	}
}
@media (min-width:760px) {
	nav.contextual a.filters-expand {
		font-size:.875rem;
	}
}

/*********************************/
/********** COLLAPSIBLE **********/
/*********************************/

/* force mas-height here */

#filters-films.active {
	max-height: 100rem !important;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	#filters-films nav.filters.for-filters-tags > *[data-overlay] {
		font-size:.75rem;
	}
}
@media (min-width:760px) {
	#filters-films nav.filters.for-filters-tags > *[data-overlay] {
		font-size:.875rem;
	}
}

/****************************/
/********** @MEDIA **********/
/****************************/

@media (min-width:0px) {
	/** default **/
	nav.filters {
		overflow:auto hidden;
	}
	nav.filters a,
	nav.filters span {
		font-size:.75rem;
		line-height:1em;
		padding:1em;
	}
	nav.filters [class^="icon-"] {
		width:2.25rem;
	}
	nav.filters [class^="icon-"]:before {
		font-size:.75rem;
		line-height:1em;
		margin:0;
	}
	/** large **/
	nav.filters.large a,
	nav.filters.large span {
		padding:1rem;
	}
	nav.filters.large [class^="icon-"] {
		width:2.875rem;
	}
	nav.filters.large [class^="icon-"]:before {
		font-size:.875rem;
	}
}
@media (min-width:760px) {
	/** default **/
	nav.filters a,
	nav.filters span {
		font-size:.875rem;
		line-height:1em;
		padding:1em;
	}
	nav.filters [class^="icon-"],
	nav.filters [class*=" icon-"] {
		width:2.625rem;
	}
	nav.filters [class^="icon-"]:before,
	nav.filters [class*=" icon-"]:before {
		font-size:.875rem;
		line-height:1em;
	}
	/** large **/
	nav.filters.large [class^="icon-"],
	nav.filters.large [class*=" icon-"] {
		width:3rem;
	}
	nav.filters.large [class^="icon-"]:before,
	nav.filters.large [class*=" icon-"]:before {
		font-size:1rem;
		line-height:1rem;
	}
	/** larger **/
	nav.filters.larger a {
		font-size:1.25rem;
		padding:1.5rem;
	}
}
@media (min-width:1000px) {
	/** default **/
	nav.filters [class^="icon-"],
	nav.filters [class*=" icon-"],
	nav.filters.large [class^="icon-"],
	nav.filters.large [class*=" icon-"] {
		font-size:.75rem;
		line-height:1em;
		width:auto;
	}
	nav.filters [class^="icon-"]:before,
	nav.filters [class*=" icon-"]:before {
		margin-right:.5em;
	}
	/** large **/
	nav.filters.large [class^="icon-"]:before,
	nav.filters.large [class*=" icon-"]:before {
		font-size:1.5em;
	}
	nav.filters.large a,
	nav.filters.large span {
		padding:1.25rem .75rem;
	}
	/** larger **/
	nav.filters.larger a,
	nav.filters.larger span {
		font-size:1rem;
		padding:1.5rem 1rem;
	}
}
@media (min-width:1200px) {
	/** default **/
	nav.filters [class^="icon-"],
	nav.filters [class*=" icon-"],
	nav.filters.large [class^="icon-"],
	nav.filters.large [class*=" icon-"] {
		font-size:.875rem;
		line-height:.875rem;
	}
	nav.filters [class^="icon-"]:before,
	nav.filters [class*=" icon-"]:before {
		margin-right:1em;
	}
	/** large **/
	nav.filters.large [class^="icon-"]:before,
	nav.filters.large [class*=" icon-"]:before {
		font-size:1.25em;
	}
	nav.filters.large a,
	nav.filters.large span {
		padding:1.5rem .75rem;
	}
	/** larger **/
	nav.filters.larger a,
	nav.filters.larger span {
		font-size:1.25rem;
		padding:1.5rem;
	}
}
@media (min-width:1400px) {
	/** default **/
	nav.filters.large [class^="icon-"]:before,
	nav.filters.large [class*=" icon-"]:before {
		font-size:1.5em;
	}
	/** large **/
	nav.filters.large a,
	nav.filters.large span {
		padding:1.5rem;
	}
	/** larger **/
	nav.filters.larger a,
	nav.filters.larger span {
		font-size:1.25rem;
		padding:1.5rem;
	}
}

/********** ICONS **********/

@media (max-width:1000px) {
	nav.filters [class^="icon-"] {
		font-size:0;
		padding:1rem;
		width:2.25rem;
	}
}

/********** VISIBILITY **********/

@media (min-width:0px) {
	nav.filters .only-desktop {
		visibility:hidden;
		display:none;
	}
}
@media (min-width:1000px) {
	nav.filters-tags.only-mobile,
	nav.filters .only-mobile {
		visibility:hidden;
		display:none !important;
	}
	nav.filters .only-desktop {
		visibility:visible;
		display:-webkit-inline-box;
		display:-webkit-inline-flex;
		display:-moz-inline-box;
		display:-ms-inline-flexbox;
		display:inline-flex;
	}
}

/************************************/
/************************************/
/********** NAV SWIPERABLE **********/
/************************************/
/************************************/

/* JS here... */

nav.swiperable {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	   -moz-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	overflow:hidden !important;
}
nav.swiperable .swiper-wrapper {
	width:auto;
}
nav.swiperable .swiper-slide {
	width:auto;
	padding:0;
	text-transform:none;
}
nav.swiperable a {
	width:auto;
}

/********** INSTITUTIONS **********/

nav.swiperable.institutions .swiper-slide {
	max-width:-webkit-calc(100% / 6);
	max-width:-moz-calc(100% / 6);
	max-width:calc(100% / 6);
}
nav.swiperable.institutions .swiper-slide:first-child {
	margin-left:auto;
}
nav.swiperable.institutions .swiper-slide:last-child {
	margin-right:auto;
}

/**********************************/
/**********************************/
/********** FILTERS-TAGS **********/
/**********************************/
/**********************************/

/*
.filters-tags
.filters-tags.current
.filters-tags.current > .filters-tags-current
*/

/********** DEFAULT **********/

.filters-tags {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	padding:0 !important;
}
.filters-tags a,
.filters-tags span {
	display:inline-block;
}
.filters-tags a {
	padding-left:2.5rem !important;
}
.filters-tags a:after {
	content:"";
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:solid var(--stroke-width) transparent;
}
.filters-tags a:before {
	font-family:'icomoon';
	content:"" !important;
	position:absolute;
	top:50%;
	left:0;
	font-size:.625rem;
	line-height:1em;
	padding:.25rem;
	margin:0 1em;
	height:1em;
	width:1em;
	border:solid var(--stroke-width) var(--bright-color);
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
}
.filters-tags a:hover {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	background:var(--dark-color);
}
.filters-tags a:hover:before {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	border:solid var(--stroke-width) var(--yellow-color);
	background:var(--dark-color);
}
.filters-tags a.active:before {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
}
.filters-tags a:hover:before,
.filters-tags a.active:hover:before {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	border:solid var(--stroke-width) var(--yellow-color);
	background:var(--dark-color);
}

/********** ABOUT-TOGGLE **********/

.filters-tags .about-toggle {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
	padding:0 !important;
	margin:0 1rem 0 -.5rem;
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
	opacity:.33;
}
.filters-tags .about-toggle:hover {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
	opacity:1;
}
.filters-tags .about-toggle:before {
	position:relative;
	-webkit-transform:none;
	   -moz-transform:none;
	    -ms-transform:none;
	     -o-transform:none;
	        transform:none;
	font-size:.5rem;
	line-height:1em;
	height:1em;
	width:1em;
	vertical-align:middle;
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
	margin:0;
	border:0 !important;
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
}
.filters-tags .about-toggle:after {
	display:none;
}
.filters-tags .about-toggle:hover:before {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
}

/********** FILTERS-TAGS LIST **********/
/* in overlay filters film */

.filters-tags.list a,
.filters-tags.list span {
	line-height:normal;
}

/********** FILM FILTERS **********/

nav.filters-wrapper {
	position:relative;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:start;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	    -ms-flex-align:start;
	        align-items:flex-start;
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
	margin:0 !important;
	width:100%;
}
nav.filters-wrapper .filters-tags.current {
	margin:0 0 0 auto;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
}
nav.filters-wrapper .collapsible.active {
	visibility:visible;
	height:auto;
	margin:0;
}

/********** FILTERS COLLAPSIBLE / HAS-FILTERS-TAGS **********/

nav.filters.for-filters-tags span {
	border:solid var(--stroke-width) transparent;
}
nav.filters.for-filters-tags a.collapsible-toggle {
	z-index:10;
	background:none;
	border:solid var(--stroke-width) transparent;
}
nav.filters.for-filters-tags a.collapsible-toggle:hover {
	border:solid var(--stroke-width) var(--lighter-color);
}
nav.filters.for-filters-tags a.collapsible-toggle.active {
	border:solid var(--stroke-width) var(--lighter-color);
	border-bottom:solid var(--stroke-width) var(--dark-color);
	pointer-events:auto;
}
nav.contextual .collapsible.has-filters-tags {
	margin:0 1rem !important;
}
nav.contextual .collapsible.has-filters-tags.active {
	padding:0 0 1rem 0 !important;
}
nav.contextual .collapsible.has-filters-tags.active nav.filters-tags {
	position:relative;
	border:solid var(--stroke-width) var(--lighter-color);
	-webkit-transform:translate3d(0,-webkit-calc(var(--stroke-width) * -1),0);
	   -moz-transform:translate3d(0,-moz-calc(var(--stroke-width) * -1),0);
	        transform:translate3d(0,calc(var(--stroke-width) * -1),0);
}

/********** FILTERS-TAGS **********/

.filters-tags > nav + nav {
	margin-top:0;
}

/********** FILTERS-TAGS CURRENT **********/

.filters-tags.current.active {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	    -ms-flex-wrap:wrap;
	        flex-wrap:wrap;
	padding:0 !important;
}
.filters-tags.current a {
	display:none;
}

/********** FILTERS-TAGS-CURRENT **********/

.filters-tags .filters-tags-current {
	display:inline-block !important;
	padding-left:0 !important;
	margin:0 !important;
}
.filters-tags .filters-tags-current:before {
	display:none !important;
}
.filters-tags .filters-tags-current:after {
	position:relative;
	content:attr(data-filters-current);
	border:0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.filters-tags a,
	.filters-tags span {
		font-size:.75rem;
		line-height:1em;
		padding:1em;
	}
	/* filters-tags.current */
	.filters-tags.current {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
	}
	/* in overlay */
	.filters-tags.current.only-mobile a.active {
		display:block;
	}
	/* filters-tags-current */
	.filters-tags-current:after {
		display:inline-block;
	}
	/* in overlay */
	.overlay .filters-tags + * {
		margin-top:1rem;
	}
	.overlay .filters-tags .filters-tags-current {
		width:100%;
	}
}
@media (min-width:760px) {
	.filters-tags a,
	.filters-tags span {
		font-size:.875rem;
	}
	/* filters-tags list */
	.filters-tags.list {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	/* filters-tags.current */
	.filters-tags.current a.active {
		display:block;
	}
	/* filters-tags-current */
	.filters-tags-current:after {
		display:none;
	}
	/* in overlay */
	.overlay .filters-tags + * {
		margin-top:0;
	}
}
@media (min-width:1000px) {
	/* filters-tags-current */
	.filters-tags .filters-tags-current {
		pointer-events:none;
	}
}

/* mobile only */

@media (max-width:1000px) {
	#filters-films nav.filters.for-filters-tags > * {
		display:none;
	}
	#filters-films nav.filters.for-filters-tags [class^="icon-"]:before,
	#filters-films nav.filters.for-filters-tags [class*=" icon-"]:before {
		margin-right:1em;
	}
	#filters-films nav.filters.for-filters-tags > *[data-overlay] {
		display:block;
		width:auto !important;
	}
}

/********************************/
/********************************/
/********** PAGINATION **********/
/********************************/
/********************************/

/* in page-user.php => for history */

nav.pagination {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	margin:-.125rem;
}
nav.pagination a {
	display:inline-block;
	vertical-align:middle;
	text-align:center;
	font-size:inherit;
	line-height:1em;
	padding:1em;
	min-width:3em;
	margin:.125rem;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--darker-color);
	-webkit-border-radius:.125rem;
	   -moz-border-radius:.125rem;
	        border-radius:.125rem;
}
nav.pagination a.active,
nav.pagination a:hover {
	background:var(--red-color);
}
nav.pagination a.active {
	cursor:default;
	pointer-events:none;
}
nav.pagination a.previous {
	text-align:left;
}
nav.pagination a.next {
	text-align:right;
}
nav.pagination a.previous:before,
nav.pagination a.next:after {
	display:inline-block;
	font-family:'icomoon';
	height:.75rem;
	width:.75rem;
	font-size:.75rem;
	line-height:.75rem;
}
nav.pagination .previous:before {
	margin-right:1rem;
}
nav.pagination .next:after {
	margin-left:1rem;
}

/********** PAGINATION NUMBERS **********/

nav.pagination > nav {
	width:auto;
	margin:0 .125rem;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	* + nav.pagination {
		margin-top:-webkit-calc(1rem - .125rem);
		margin-top:-moz-calc(1rem - .125rem);
		margin-top:calc(1rem - .125rem);
	}
	nav.pagination a.previous,
	nav.pagination a.next {
		font-size:0;
		min-width:3rem;
	}
}
@media (min-width:760px) {
	nav.pagination a {
		font-size:1rem;
		line-height:1rem;
		padding:1rem;
	}
	nav.pagination a.previous,
	nav.pagination a.next {
		font-size:inherit;
	}
}
@media (min-width:1000px) {
	* + nav.pagination {
		margin-top:-webkit-calc(2rem - .125rem);
		margin-top:-moz-calc(2rem - .125rem);
		margin-top:calc(2rem - .125rem);
	}
}

/**************************************/
/**************************************/
/********** PLAYLIST-ACTIONS **********/
/**************************************/
/**************************************/

/*
V4
playlist-actions == single-playlist >> comments
director-actions == single-director >> comments
*/

nav.playlist-actions,
nav.director-actions {
	position:absolute;
	z-index:10;
	top:0;
	left:0;
	font-size:0;
	line-height:0;
	height:100%;
	width:4rem;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
}
nav.playlist-actions a,
nav.director-actions a {
	font-size:0;
	line-height:0;
	height:3rem;
	width:3rem;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}
nav.playlist-actions a:before,
nav.director-actions a:before{
	font-family:'icomoon';
	display:block;
	padding:1rem;
	font-size:1rem;
	line-height:1rem;
	width:1rem;
}

/**********************************/
/**********************************/
/********** LIST-ACTIONS **********/
/**********************************/
/**********************************/

/*
list-actions == draggable
*/

nav.list-actions {
	position:absolute;
	z-index:10;
	top:0;
	left:0;
	font-size:0;
	line-height:0;
	height:100%;
	width:3rem !important;
	margin:0;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}
nav.list-actions a {
	font-size:0;
	line-height:0;
	height:100%;
	width:100%;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	background:rgb(191,191,200);
}
nav.list-actions a:hover {
	background:var(--yellow-color);
}
nav.list-actions a:before {
	font-family:'icomoon';
	display:block;
	font-size:1rem;
	line-height:1em;
	padding:1em;
	width:1em;
	margin:auto;
}

/**********************************/
/**********************************/
/********** FILM-ACTIONS **********/
/**********************************/
/**********************************/

/*
[.grid.films / .list.films] nav.film-actions == default
.list.films.selection nav.film-actions == in single-selection.php
.list.films.has-comments nav.film-actions == in single-director.php
.list.films.playlist nav.film-actions == OUT ?
*/

/*****************************/
/********** DEFAULT **********/
/*****************************/

nav.film-actions {
	position:relative;
	z-index:10;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
	-webkit-flex-basis:0 !important;
	    -ms-flex-preferred-size:0 !important;
	        flex-basis:0 !important;
	color:var(--dark-color);
	text-shadow:none;
	background:var(--yellow-color);
	padding:0 !important;
	margin:0 !important;
	height:auto;
	width:auto !important;
}
nav.film-actions a {
	display:inline-block;
	font-size:0;
	padding:0;
}
nav.film-actions a.disabled {
	pointer-events:none;
	opacity:.15;
}
nav.film-actions a:hover {
	color:var(--yellow-color) !important;
	text-shadow:0 0 0 var(--yellow-color) !important;
	background:var(--grey-color);
}
nav.film-actions a:before {
	display:block;
	font-family:'icomoon';
	font-size:1rem;
	line-height:1rem;
	padding:1rem;
	height:1rem;
	width:1rem;
	margin:0;
}

/****************************/
/********** @MEDIA **********/
/****************************/

@media (min-width:0px) {
	nav.film-actions {
		position:absolute;
		top:0;
		right:0;
		background:none;
		opacity:1;
	}
	nav.film-actions a {
		display:none;
	}
	nav.film-actions .add-to-playlist,
	nav.film-actions .icon-playlist-check,
	nav.film-actions .remove-from-playlist {
		display:block;
		-webkit-border-radius:100%;
		   -moz-border-radius:100%;
		        border-radius:100%;
		margin:.75rem;
		background:var(--yellow-color);
	}
	nav.film-actions .add-to-playlist:before,
	nav.film-actions .icon-playlist-check:before,
	nav.film-actions .remove-from-playlist:before {
		display:block;
		font-size:.5rem;
		line-height:.5rem;
		padding:.5rem;
		height:.5rem;
		width:.5rem;
	}
	nav.film-actions .add-to-playlist.disabled,
	nav.film-actions .icon-playlist-check.disabled,
	nav.film-actions .remove-from-playlist.disabled {
		display:none;
	}
	/* list films selection */
	.list.films.selection nav.film-actions {
		bottom:0;
		top:auto;
	}
}
@media (min-width:760px) {
	nav.film-actions {
		/*position:relative;*/
		opacity:0;
		background:var(--yellow-color);
	}
	*:hover > nav.film-actions {
		opacity:1;
	}
	nav.film-actions a {
		display:inline-block;
	}
	nav.film-actions .add-to-playlist,
	nav.film-actions .icon-playlist-check,
	nav.film-actions .remove-from-playlist {
		-webkit-border-radius:0;
		   -moz-border-radius:0;
		        border-radius:0;
		margin:0;
		background:none;
	}
	nav.film-actions .add-to-playlist:before,
	nav.film-actions .icon-playlist-check:before,
	nav.film-actions .remove-from-playlist:before {
		font-size:1rem;
		line-height:1rem;
		padding:1rem;
		height:1rem;
		width:1rem;
	}
	/* list films selection */
	.list.films.selection nav.film-actions {
		top:0;
		bottom:auto;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
		-webkit-box-pack:center;
		-webkit-justify-content:center;
		   -moz-box-pack:center;
		    -ms-flex-pack:center;
		        justify-content:center;
	}
	/* list films has-comments */
	.list.films.has-comments nav.film-actions {
		position:absolute;
		top:0;
		bottom:auto;
	}
	/* list films playlist */
	.list.films.playlist nav.film-actions {
		position:absolute;
		top:0;
		bottom:auto;
	}
	/* grid films */
	.grid.films nav.film-actions {
		position:absolute;
	}
	/* grid.list films */
	/*
	.grid.list.films nav.film-actions {
		position:relative;
	}
	*/
}

/*************************************/
/*************************************/
/********** PREVIEW ACTIONS **********/
/*************************************/
/*************************************/

/*
for SVOD
default == small in preview
large	== buttons.rounded in preview.modal
*/

nav.preview-actions {
	position:relative;
	z-index:10;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	-webkit-flex-basis:0;
	    -ms-flex-preferred-size:0;
	        flex-basis:0;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	padding:0;
	height:auto;
	width:auto;
}
nav.preview-actions a.disabled {
	pointer-events:none;
	opacity:var(--alpha-off-rgb);
}

/********** NORMAL **********/

nav.preview-actions:not(.large) a {
	display:inline-block;
	font-size:0;
	padding:0;
}
nav.preview-actions:not(.large) a:hover,
nav.preview-actions:not(.large) a.active {
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	-webkit-transform: scale(2);
	   -moz-transform: scale(2);
	    -ms-transform: scale(2);
	     -o-transform: scale(2);
	        transform: scale(2);
	background: none !important;
}
nav.preview-actions:not(.large) a:before {
	display:block;
	font-family:'icomoon';
	line-height:1em;
	padding:1em;
	height:1em;
	width:1em;
	margin:0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	nav.preview-actions a:before {
		font-size:.75rem;
	}
}
@media (min-width:760px) {
	nav.preview-actions a:before {
		font-size:1rem;
	}
}

/*******************************/
/*******************************/
/********** SECONDARY **********/
/*******************************/
/*******************************/

.secondary {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	text-align:left;
}
.secondary > a {
	display:inline-block;
	vertical-align:middle;
	font-size:.875rem;
	line-height:1em;
	padding:.5em;
}
.secondary > a:hover,
.secondary > a.active {
	color:var(--red-color);
	text-shadow:0 0 0 var(--red-color);
}

/******************************/
/******************************/
/********** NAV-SITE **********/
/******************************/
/******************************/

/* in footer == fixed */

nav.nav-site > a {
	position:fixed;
	z-index:1000;
	bottom:0;
	display:block;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--light-color);
	-webkit-border-radius:var(--border-radius);
	   -moz-border-radius:var(--border-radius);
	        border-radius:var(--border-radius);
}
nav.nav-site > a:hover {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--bright-color);
}

/********** SHARE THIS **********/

nav.nav-site .share-this {
	left:0;
	font-size:.75rem;
	line-height:1em;
	padding:1em;
	margin:1rem;
	border:0;
}

/********** SCROLL UP **********/

nav.nav-site .scroll-up {
	right:8rem;
	font-size:0;
	line-height:0;
	border:0;
	height:2.25rem;
	width:2.25rem;
	margin:1rem 1rem 1.125rem 1rem;
	padding:.75rem;
	-webkit-border-radius:50%;
	   -moz-border-radius:50%;
	        border-radius:50%;
	-webkit-transform:translate3d(0,150%,0);
	   -moz-transform:translate3d(0,150%,0);
	        transform:translate3d(0,150%,0);
	opacity:0;
}
nav.nav-site .scroll-up:after {
	font-family:'icomoon';
	display:block;
	font-size:.75rem;
	line-height:1em;
}
body.scrolled nav.nav-site .scroll-up {
	-webkit-transform:translate3d(0,0,0);
	   -moz-transform:translate3d(0,0,0);
	        transform:translate3d(0,0,0);
	opacity:1;
}

/********** HELP ME **********/

nav.nav-site .help-me {
	right:0;
	font-size:.875rem;
	line-height:1em;
	padding:1rem 2rem;
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
	-webkit-border-radius:3rem;
	   -moz-border-radius:3rem;
	        border-radius:3rem;
	border:0;
	margin:1rem;
	padding:1rem;
}
nav.nav-site .help-me:before {
	font-family:'icomoon';
	display:inline-block;
	font-size:.75rem;
	line-height:1em;
	padding:0 1em 0 0;
}

/*********************************/
/*********************************/
/********** COLLAPSIBLE **********/
/*********************************/
/*********************************/

/*
.collapsible
.collapsible-toggle
.collapsible-toggle.button / .collapsible-toggle.button.small
.collapsible-toggle.simple

cf. single director : list films
cf. single film : generic

max-height == JS updated
*/

/********** DEFAULT **********/

.collapsible {
	display:block;
	visibility:hidden;
	overflow:hidden;
	max-height:0;
	opacity:0;
	margin-top:0 !important;
}
.collapsible:not(.active) {
	max-height:0 !important;
}
.collapsible.active {
	visibility:visible;
	overflow:visible;
	max-height:100rem;
	opacity:1;
}
.collapsible > * {
	opacity:0;
}
.collapsible.active > * {
	opacity:1;
}

/********** TOGGLE BUTTON **********/

/* cf. single film */

a.collapsible-toggle.button {
	display:block;
	width:100%;
	padding:1rem 3rem 1rem 1rem !important;
	color:var(--yellow-color);
	text-shadow:0 0 0 var(--yellow-color);
	border:solid var(--stroke-width) !important;
	background:none;
}
a.collapsible-toggle:hover {
	background: none;
}
a.collapsible-toggle.button:after {
	font-family:"icomoon";
	position:absolute;
	top:50%;
	right:0;
	height:1rem;
	width:1rem;
	padding:1rem;
	-webkit-transform:translate3d(0,-50%,0);
	   -moz-transform:translate3d(0,-50%,0);
	        transform:translate3d(0,-50%,0);
}
a.collapsible-toggle.button:hover:after,
a.collapsible-toggle.button.active:after {
	-webkit-transform:translate3d(0,-50%,0) rotate(-180deg);
	   -moz-transform:translate3d(0,-50%,0) rotate(-180deg);
	        transform:translate3d(0,-50%,0) rotate(-180deg);
}

/********** TOGGLE BUTTON SMALL **********/

/* cf. offers */

a.collapsible-toggle.button.small {
	display:inline-block;
	width:auto;
	font-size:.75rem;
	line-height:1em;
	padding:1em !important;
}

a.collapsible-toggle.button.small:hover {
	color:var(--dark-color);
	text-shadow:none;
	background:var(--yellow-color) !important;
}
a.collapsible-toggle.button.small:after {
	display:none;
}

/********** TOGGLE BUTTON SIMPLE **********/

/* cf. single-film > others films */

a.collapsible-toggle.button.simple {
	border:none !important;
}

/********** TOGGLE BUTTON SECTION **********/

/* cf. single-film > others films */

a.collapsible-toggle.button.section {
	padding:0 3rem 0 0 !important;
	color:inherit;
	text-shadow: inherit;
	border:none !important;
}

/**************************/
/**************************/
/********** TABS **********/
/**************************/
/**************************/

/*
.nav-tabs
.nav-tabs.large
.nav-tabs.no-gutter
.nav-tabs.section-title
.nav-tabs.switch
.nav-tabs.institutions
.nav-tabs.blocks
.nav-tabs.blocks.fit

== .nav-tabs > span

ul.tabs > .tab
data-tabs == #tabs
*/

/**********************************/
/********** TABS DEFAULT **********/
/**********************************/

.tabs {
	display:block;
	overflow:hidden;
}
.tabs:after {
	content:"";
	display:block;
	clear:both;
}
.tabs > * {
	display:block;
	float:left;
	width:100%;
	margin-left:-9999rem;
	height:0;
	opacity:0;
}
.tabs > .active {
	margin-left:0;
	height:100%;
	opacity:1;
}
.tabs > li + li {
	margin:0;
}

.tab {
	position: relative;
}

/**************************************/
/********** NAV-TABS DEFAULT **********/
/**************************************/
/* white-space:nowrap; == for user page */

.nav-tabs {
	position:relative;
	z-index:10;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
}
.nav-tabs a {
	display:-webkit-inline-box;
	display:-webkit-inline-flex;
	display:-moz-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	text-transform:uppercase;
	white-space:nowrap;
}
.nav-tabs a + a {
	margin-left:1rem;
}
.nav-tabs a > * {
	opacity:.5;
}
.nav-tabs a:hover > *,
.nav-tabs a.active > * {
	opacity:1;
}
.nav-tabs a:not(:last-child):after {
	content:"";
	display:inline-block;
	height:1.5em;
	margin-top:.25em; /* vertical centering fix */
	margin-left:1rem;
	border-left:solid var(--stroke-width);
}

/* no-gutter == institutions... important is temporary ? */

.nav-tabs.no-gutter a:after {
	border-left:none !important;
}

/********** @MEDIA **********/

@media (min-width:760px) {
	.nav-tabs.large a {
		font-size:1.25rem;
	}
}

/*************************************/
/********** NAV TABS TITLES **********/
/*************************************/

.nav-tabs.section-title:before {
	padding-right:0;
}

/*************************************/
/********** NAV TABS SWITCH **********/
/*************************************/
/* eg. user maCinetek */

.nav-tabs.switch a {
	display:block;
	text-transform:none;
	line-height:1em;
	border:solid var(--stroke-width) var(--bright-color);
	background:none;
}
.nav-tabs.switch a:after {
	display:none;
}
.nav-tabs.switch a:hover {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}
.nav-tabs.switch a.active {
	color:var(--red-color);
	text-shadow:none;
	background:var(--bright-color);
}
.nav-tabs.switch a + a {
	margin-left:0;
}

/********** IN MOBILE **********/

.nav-tabs.switch.select-container {
	margin:0;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.nav-tabs.switch a {
		font-size:.75rem;
		padding:.875rem 1.25rem;
	}
}
@media (min-width:760px) {
	.nav-tabs.switch a {
		font-size:.875rem;
		padding:1rem 1.5rem;
	}
}

/***************************************/
/********** TABS INSTITUTIONS **********/
/***************************************/

.nav-tabs.institutions {
	margin-left:0;
	margin-right:0;
}
.nav-tabs.institutions a > * {
	opacity:1;
}
.nav-tabs.institutions a:before {
	content:"";
	position:absolute;
	top:0;
	height:.25rem;
	background:var(--red-color);
	opacity:0;
}
.nav-tabs.institutions a:hover:before,
.nav-tabs.institutions a.active:before {
	opacity:1;
}
.nav-tabs.institutions a:after {
	border-left:none;
}
.nav-tabs.institutions:hover a {
	opacity:.25;
}
.nav-tabs.institutions:hover a:hover {
	opacity:1;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.nav-tabs.institutions a {
		padding:0;
	}
	.nav-tabs.institutions a:before {
		left:0;
		right:0;
	}
}
@media (min-width:760px) {
	.nav-tabs.institutions a {
		padding:0 1rem;
	}
	.nav-tabs.institutions a:before {
		left:1rem;
		right:1rem;
	}
}

/*************************************/
/********** NAV TABS BLOCKS **********/
/*************************************/

.nav-tabs.blocks {
	margin-left:0;
	margin-right:0;
}
.nav-tabs.blocks a {
	padding:0;
}
.nav-tabs.blocks a:after {
	display:none;
}
.nav-tabs.blocks a > * {
	position:relative;
	text-align:left;
	-webkit-border-radius:.25rem;
	   -moz-border-radius:.25rem;
	        border-radius:.25rem;
	height:100%;
}
.nav-tabs.blocks a > *:before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	border:solid var(--stroke-width) var(--light-color);
	-webkit-border-radius:-webkit-calc(var(--border-radius) * 2);
	   -moz-border-radius:-moz-calc(var(--border-radius) * 2);
	        border-radius:calc(var(--border-radius) * 2);
}
.nav-tabs.blocks a > *:hover:before {
	border:solid var(--stroke-width) var(--yellow-color);
}
.nav-tabs.blocks a.active > *:before {
	border:solid var(--stroke-width) var(--bright-color);
}
.nav-tabs.blocks figure svg {
	fill:var(--bright-color);
}

/********** TABS PAYMENT **********/

section.payment .nav-tabs.blocks {
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}
section.payment .nav-tabs.blocks a > * {
	opacity:1;
}

/********** FIT **********/
/*
in payment cart / festival payment / selection renew
if < 3 == display 3 blocks
if > 3 == display select-container
*/

nav.nav-tabs.blocks.fit a:after {
	display:none;
}
nav.nav-tabs.blocks.fit a > * {
	opacity:1;
}
nav.nav-tabs.blocks.fit .infos {
	text-align:center;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-webkit-flex-direction:column;
	   -moz-box-orient:vertical;
	   -moz-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-align:baseline;
	-webkit-align-items:baseline;
	   -moz-box-align:baseline;
	    -ms-flex-align:baseline;
	        align-items:baseline;
	height:100%;
}
nav.nav-tabs.blocks.fit figure {
	max-width:6rem;
	margin:auto auto 0 auto;
}
nav.nav-tabs.blocks.fit svg {
	fill:var(--bright-color);
}

/** div.title or span.title **/

.nav-tabs.blocks.fit .title {
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:100%;
	font-size:.75em;
	margin:0 auto;
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	-webkit-box-align:end;
	-webkit-align-items:flex-end;
	   -moz-box-align:end;
	    -ms-flex-align:end;
	        align-items:flex-end;
	padding:1rem;
}
.nav-tabs.blocks.fit .title span:not(.tooltip-toggle) {
	margin:auto auto 0 auto;
}

/********** TABS BLOCKS LEGEND OPTION **********/
/* if small legend option instead of tooltip */

section.payment .nav-tabs.blocks small {
	position:absolute;
	left:50%;
	-webkit-transform:translate3d(-50%,0,0);
	   -moz-transform:translate3d(-50%,0,0);
	        transform:translate3d(-50%,0,0);
}

/********** TABS BLOCKS TOOLTIP OPTION **********/
/* if tooltip option */

section.payment .nav-tabs.blocks .tooltip-toggle,
.nav-tabs.blocks.fit .tooltip-toggle {
	position:relative;
	z-index:20;
	font-size:.75rem;
	line-height:1em;
	padding:.375rem;
	margin-left:.125em;
	height:1.5rem;
	width:1.5rem !important;
	color:var(--grey-color);
	text-shadow:none;
	background:var(--bright-color);
	-webkit-border-radius:100%;
	   -moz-border-radius:100%;
	        border-radius:100%;
}
section.payment .nav-tabs.blocks .tooltip,
.nav-tabs.blocks.fit .tooltip {
	min-width:15rem;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.nav-tabs.blocks {
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-flex-wrap:wrap;
		    -ms-flex-wrap:wrap;
		        flex-wrap:wrap;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.nav-tabs.blocks a {
		-webkit-box-flex:1;
		-webkit-flex-grow:1;
		   -moz-box-flex:1;
		    -ms-flex-positive:1;
		        flex-grow:1;
		-webkit-flex-basis:0;
		    -ms-flex-preferred-size:0;
		        flex-basis:0;
		display:block;
		width:100%;
		margin:.25rem 0;
	}
	.nav-tabs.blocks a > * {
		position:relative;
		display:-webkit-box;
		display:-webkit-flex;
		display:-moz-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		-webkit-box-align:center;
		-webkit-align-items:center;
		   -moz-box-align:center;
		    -ms-flex-align:center;
		        align-items:center;
		text-align:left;
		padding:1rem;
	}
	.nav-tabs.blocks figure svg {
		max-width:5rem;
	}
	.nav-tabs.blocks figure + * {
		margin:0 0 0 1rem;
	}
	/* fit */
	.nav-tabs.blocks.fit {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		-webkit-box-align:stretch;
		-webkit-align-items:stretch;
		   -moz-box-align:stretch;
		    -ms-flex-align:stretch;
		        align-items:stretch;
		margin:-.25rem;
	}
	nav.nav-tabs.blocks.fit a {
		margin:.25rem;
		max-width:-webkit-calc(100% / 3 - .5rem) !important;
		max-width:-moz-calc(100% / 3 - .5rem) !important;
		max-width:calc(100% / 3 - .5rem) !important;
	}
	nav.nav-tabs.blocks.fit .infos {
		padding:1rem 1rem 3rem 1rem; /* for title */
	}
	/* in splitted == page-payment */
	.splitted .nav-tabs.blocks.fit {
		-webkit-box-pack:center;
		-webkit-justify-content:center;
		   -moz-box-pack:center;
		    -ms-flex-pack:center;
		        justify-content:center;
	}
}
@media (min-width:760px) {
	.nav-tabs.blocks {
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-flex-direction:row;
		   -moz-box-orient:horizontal;
		   -moz-box-direction:normal;
		    -ms-flex-direction:row;
		        flex-direction:row;
		-webkit-box-align:stretch;
		-webkit-align-items:stretch;
		   -moz-box-align:stretch;
		    -ms-flex-align:stretch;
		        align-items:stretch;
		margin:-.5rem;
	}
	* + .nav-tabs.blocks {
		margin-top:-webkit-calc(1rem - .5rem);
		margin-top:-moz-calc(1rem - .5rem);
		margin-top:calc(1rem - .5rem);
	}
	.nav-tabs.blocks a {
		margin:.5rem;
	}
	.nav-tabs.blocks a > * {
		height:100%;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		   -moz-box-orient:vertical;
		   -moz-box-direction:normal;
		    -ms-flex-direction:column;
		        flex-direction:column;
	}
	.nav-tabs.blocks figure {
		margin:auto 0 0 0;
	}
	.nav-tabs.blocks figure + * {
		margin:1rem 0 0 0;
	}
	/* payment */
	section.payment .nav-tabs.blocks a {
		max-width:-webkit-calc(100% / 5 - 1rem);
		max-width:-moz-calc(100% / 5 - 1rem);
		max-width:calc(100% / 5 - 1rem);
	}
	/* fit */
	nav.nav-tabs.blocks.fit {
		margin:-.25rem;
	}
	* + .nav-tabs.blocks.fit {
		margin-top:-webkit-calc(1rem - .25rem) !important;
		margin-top:-moz-calc(1rem - .25rem) !important;
		margin-top:calc(1rem - .25rem) !important;
	}
	nav.nav-tabs.blocks.fit a {
		margin:.25rem;
		max-width:-webkit-calc(100% / 5 - .5rem) !important;
		max-width:-moz-calc(100% / 5 - .5rem) !important;
		max-width:calc(100% / 5 - .5rem) !important;
	}
	.nav-tabs.blocks.fit .tooltip-toggle {
		position:absolute;
		top:.5em;
		right:.5em;
	}
}
@media (min-width:1000px) {
	.nav-tabs.blocks {
		margin:-1rem;
	}
	* + .nav-tabs.blocks {
		margin-top:-webkit-calc(2rem - 1rem);
		margin-top:-moz-calc(2rem - 1rem);
		margin-top:calc(2rem - 1rem);
	}
	.nav-tabs.blocks a {
		margin:1rem;
	}
	.nav-tabs.blocks a > * {
		padding:2rem;
	}
	/* fit */
	* + .nav-tabs.blocks.fit {
		margin-top:-webkit-calc(2rem - .25rem);
		margin-top:-moz-calc(2rem - .25rem);
		margin-top:calc(2rem - .25rem);
	}
	nav.nav-tabs.blocks.fit .infos {
		padding:2rem 2rem 3rem 2rem; /* for title */
	}
	.nav-tabs.blocks.fit .tooltip-toggle {
		position:absolute;
		top:1em;
		right:1em;
	}
	/* in splitted == page-payment */
	.splitted .nav-tabs.blocks.fit {
		-webkit-box-pack:start;
		-webkit-justify-content:flex-start;
		   -moz-box-pack:start;
		    -ms-flex-pack:start;
		        justify-content:flex-start;
	}
}
@media (min-width:1200px) {
	.nav-tabs.blocks figure svg {
		max-width:10rem;
	}
	/* payment */
	section.payment .nav-tabs.blocks a {
		max-width:-webkit-calc(100% / 5 - 2rem);
		max-width:-moz-calc(100% / 5 - 2rem);
		max-width:calc(100% / 5 - 2rem);
	}
	section.payment .nav-tabs.blocks small {
		font-size:.75em;
	}
}

/****************************/
/****************************/
/********** SWITCH **********/
/****************************/
/****************************/

/********** DEFAULT **********/

.switch {
	display:-webkit-box;
	display:-webkit-flex;
	display:-moz-box;
	display:-ms-flexbox;
	display:flex;
}
.switch a {
	display:block;
	line-height:1em;
	padding:1em 1.5rem;
	border:solid var(--stroke-width) var(--bright-color);
	background:none;
}
.switch a:hover {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--red-color);
}
.switch a.active {
	color:var(--red-color);
	text-shadow:none;
	background:var(--bright-color);
}

/********** SMALL **********/

.switch.small a {
	padding:.875rem 1rem;
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.switch a {
		font-size:.75rem;
	}
}
@media (min-width:760px) {
	.switch a {
		font-size:.875rem;
	}
}

/*****************************/
/*****************************/
/********** PAYMENT **********/
/*****************************/
/*****************************/

section.payment > *:not(nav) {
	max-width:40rem;
	margin-left:auto;
	margin-right:auto;
}
.payment-mode svg > * {
	fill:var(--bright-color);
}

/****************************/
/****************************/
/********** BANNER **********/
/****************************/
/****************************/

.banner {
	white-space: initial;
	z-index:550;
}
.banner .banner-content {
	max-width: var(--max-width);
	margin-left:auto;
	margin-right:auto;
	padding:.5em !important;
}
.banner .banner-content * + .button {
	margin-top: var(--gutter-gap);
}
.banner .collapsible.fixed {
	position:absolute;
	left:0;
	right: 0;
	overflow: auto;
	max-height: -webkit-calc(var(--viewport-height) - var(--header-height) * 2) !important;
	max-height: -moz-calc(var(--viewport-height) - var(--header-height) * 2) !important;
	max-height: calc(var(--viewport-height) - var(--header-height) * 2) !important;
}
.banner .collapsible.fixed section {
	margin:0 auto;
}

/********** COLORS **********/

.banner.red {
	background:var(--red-color);
}
.banner.yellow {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
	background:var(--yellow-color);
}
.banner.red .buttons a,
.banner.red .button,
.banner.yellow .buttons a,
.banner.yellow .button {
	color:var(--bright-color);
	text-shadow:0 0 0 var(--bright-color);
	background:var(--dark-color);
}
.banner.red .buttons a:hover,
.banner.red .button:hover,
.banner.yellow .buttons a:hover,
.banner.yellow .button:hover {
	color:var(--dark-color);
	text-shadow:0 0 0 var(--dark-color);
	background:var(--bright-color);
}
.banner.red .collapsible.fixed {
	background:var(--red-color);
}
.banner.yellow .collapsible.fixed {
	background:var(--yellow-color);
}

/********** @MEDIA **********/

@media (min-width:0px) {
	.banner {
		font-size:.625rem;
	}
}
@media (min-width:760px) {
	.banner {
		font-size:.75rem;
	}
}
@media (min-width:1200px) {
	.banner {
		font-size:.875rem;
	}
}
